const moment = require("moment");


const ConvertTimeToZulu = (unix, time=null) => {
    let _time = time && time.split(':') || ["00","00"];
    // 2021-01-19T00:00:00.000Z
    return `${moment.unix(unix).format('YYYY-MM-DD')}T${_time[0]}:${_time[1]}:00.000Z`;
}

export default ConvertTimeToZulu;
