import { SvgIcon } from "@material-ui/core";
import React from "react";

function Sms() {
  return (
    <SvgIcon
      width="32"
      height="30"
      viewBox="0 0 32 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.86">
        <path d="M8.15543 0.465043C7.48004 0.561755 6.81671 0.729347 6.17643 0.965043C4.79863 1.48518 3.58597 2.36609 2.66535 3.51558C1.74474 4.66507 1.15001 6.04089 0.943428 7.49904C0.889428 7.87504 0.876429 11.041 0.889429 17.931L0.909427 27.826L1.09743 28.2081C1.34451 28.7159 1.75439 29.1264 2.26181 29.3743C2.76922 29.6222 3.34499 29.6932 3.89743 29.576C4.14072 29.5121 4.37566 29.4198 4.59743 29.301C4.74543 29.214 5.81843 28.3821 6.97843 27.4491C8.13843 26.5161 9.22543 25.6981 9.39343 25.6181C9.69343 25.4771 9.73543 25.477 17.2084 25.444C24.6344 25.404 24.7284 25.404 25.2654 25.263C26.8344 24.8826 28.2588 24.0542 29.3653 22.8786C30.4718 21.7031 31.2126 20.2311 31.4974 18.642C31.6454 17.783 31.6454 8.21705 31.4974 7.29805C31.2213 5.61729 30.4233 4.06605 29.2164 2.86406C28.0861 1.70463 26.6386 0.90483 25.0554 0.565049C24.6264 0.465049 23.8744 0.451035 16.4684 0.438035C12.0104 0.433035 8.26743 0.447043 8.15543 0.465043ZM24.4554 2.66506C25.4013 2.82861 26.2938 3.21762 27.0574 3.79918C27.821 4.38074 28.4333 5.13774 28.8424 6.00606C29.4424 7.26006 29.4124 6.87804 29.4424 12.728C29.5324 14.628 29.5033 16.5317 29.3554 18.4281C29.1271 19.5302 28.6012 20.5487 27.8349 21.3731C27.0685 22.1974 26.091 22.7961 25.0084 23.1041C24.5254 23.2521 24.5184 23.252 16.9784 23.292C8.70743 23.332 9.20343 23.3121 8.33843 23.7351C8.13043 23.8351 6.99043 24.6941 5.80243 25.6471C4.61443 26.6 3.58243 27.405 3.50243 27.447C3.45367 27.4774 3.39616 27.4905 3.33907 27.4844C3.28197 27.4782 3.22859 27.4531 3.18743 27.413L3.02643 27.306L3.03943 17.3981L3.05943 7.49004L3.20743 7.02004C3.52641 5.92842 4.13867 4.94521 4.97758 4.17735C5.81649 3.4095 6.84992 2.88644 7.96543 2.66506C8.77743 2.52006 23.6164 2.51306 24.4554 2.66506Z" />
        <path d="M8.58857 11.3151C8.13123 11.4808 7.75595 11.8174 7.54157 12.2541C7.40268 12.5027 7.3506 12.7905 7.39357 13.072C7.40391 13.312 7.45392 13.5485 7.54157 13.7721C7.731 14.1294 8.02326 14.4216 8.38057 14.611C8.82691 14.7893 9.32062 14.8105 9.78057 14.671C10.1 14.5503 10.3778 14.34 10.5805 14.0652C10.7832 13.7905 10.9023 13.463 10.9235 13.1222C10.9446 12.7814 10.8668 12.4417 10.6996 12.144C10.5323 11.8463 10.2826 11.6033 9.98057 11.4441C9.54905 11.2284 9.05237 11.1824 8.58857 11.3151Z" />
        <path d="M15.835 11.274C15.513 11.3752 15.2251 11.5631 15.003 11.817C14.8498 11.9708 14.7298 12.1543 14.6502 12.3562C14.5705 12.5581 14.533 12.7741 14.54 12.991C14.5324 13.2249 14.5726 13.4578 14.658 13.6756C14.7435 13.8934 14.8724 14.0915 15.0368 14.2578C15.2013 14.4242 15.398 14.5553 15.6148 14.6432C15.8316 14.7311 16.064 14.7739 16.2979 14.769C16.6299 14.7819 16.9583 14.6973 17.2426 14.5257C17.527 14.354 17.7548 14.1027 17.898 13.803C18.0023 13.5921 18.0641 13.3628 18.0797 13.128C18.0954 12.8932 18.0646 12.6577 17.9892 12.4348C17.9138 12.2119 17.7952 12.0061 17.6402 11.829C17.4852 11.652 17.2969 11.5072 17.0859 11.403C16.6897 11.2417 16.2557 11.197 15.835 11.274Z" />
        <path d="M22.9723 11.2871C22.6409 11.3991 22.3465 11.5995 22.1207 11.8667C21.8949 12.1339 21.7465 12.4576 21.6913 12.8031C21.6564 13.1813 21.7405 13.5611 21.9318 13.8893C22.1231 14.2175 22.412 14.4779 22.7583 14.634C23.0158 14.7533 23.3024 14.7954 23.5833 14.755C23.797 14.7578 24.009 14.7157 24.2054 14.6312C24.4017 14.5468 24.5782 14.4221 24.7233 14.2651C24.8803 14.12 25.0051 13.9435 25.0895 13.7471C25.1739 13.5507 25.2161 13.3388 25.2133 13.125C25.2536 12.8441 25.2116 12.5576 25.0923 12.3001C24.9156 11.9079 24.6057 11.5911 24.2177 11.4056C23.8296 11.2202 23.3884 11.1782 22.9723 11.2871Z" />
      </g>
    </SvgIcon>
  );
}

export default Sms;
