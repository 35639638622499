import React from "react";
import styled from "styled-components";
import { Column, Grid } from "../../theme/Grid";

const StatsCard = (props) => (
  <StatsCardContainer className={props.className}>
    <Grid style={{ height: "100%", minHeight: 73 }} className="no-gutters-all">
      <Column className="xs-12">
        <IconAlignContainer
          className={
            props.icon.color
              ? `fc-${props.icon.color} bg-${props.icon.color}-opacity`
              : `fc-primary bg-primary-opacity`
          }
        >
          <Icon className="material-icons">
            {props.icon.label ? props.icon.label : `widgets`}
          </Icon>
        </IconAlignContainer>
      </Column>
      <Column className="xs-12 col-auto">
        <TextAlignContainer>
          <StatsTitle>{props.title ? props.title : ""}</StatsTitle>
          <StatsValue>
            {props.value || props.value === 0 ? props.value : ""}
          </StatsValue>
        </TextAlignContainer>
      </Column>
    </Grid>
  </StatsCardContainer>
);

export default React.memo(StatsCard);

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #a0a0a0;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;

const Icon = styled.i`
  font-size: 24px;
`;
