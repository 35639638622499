import React, { useContext, useState } from "react";
import AppContext from "../../context/store";

import Button from "../../theme/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// import Select from '../../theme/CustomMUI/Select';

// import { getCities, getDistrictsByCityID } from '../../functions/CityDistrict';
import Input from "../../theme/CustomMUI/Input";
import MaskedInput from "../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../functions/Validate";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import FCLabel from "../../theme/FCLabel";
import AuthWithToken from "../../functions/AuthWithToken";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${require("../../assets/images/unauth_image.jpg")})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(14, 4),
    display: "flex",
    flexDirection: "column",
  },
  paperStep2: {
    margin: theme.spacing(20, 4),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const RegisterIndex = (props) => {
  const classes = useStyles();
  const context = useContext(AppContext);

  const [form, setForm] = useState({
    company: {
      name: null,
      province: null,
      district: null,
      phone: null,
      address: null,
      mail: null,
    },
    permitted: {
      name: null,
      surname: null,
      mail: null,
      phone: null,
      password: null,
    },
    code: null,
    campaign_code: null,
  });

  const [privacy, setPrivacy] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [step, setStep] = useState(1);

  const InputOnChange = async (key, sub_key, value) =>
    setForm({ ...form, [key]: { ...form[key], [sub_key]: value } });

  const RegisterSMS = () => {
    validateRegister() &&
      Axios.post(`${context.api_endpoint}/register`, {
        phone: form.permitted.phone,
      })
        .then(({ status }) => {
          if (status === 201) {
            setStep(2);
            toast.success(
              `${form.permitted.phone} numarasına doğrulama kodu başarıyla gönderildi!`
            );
          }
        })
        .catch((e) =>
          toast.error(
            `Kayıt oluşturulamadı! Bilgilerinizi gözden geçirerek tekrar deneyiniz.`
          )
        );
  };

  const RegisterApprove = () => {
    validateRegister() &&
      validateCode() &&
      Axios.post(`${context.api_endpoint}/register/approve`, { ...form })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              `Kaydınız başarıyla oluşturuldu! Panele yönlendiriliyorsunuz. (${response.status})`
            );

            gtag_report_conversion();

            Axios.post(`${context.api_endpoint}/auth/staff`, {
              mail: form.permitted.mail,
              password: form.permitted.password,
            })
              .then(async (response) => {
                if (response.status === 201) {
                  const { data } = response;
                  await AuthWithToken(data.data.token, context);
                  await setTimeout(() => {
                    props.history.push("/dashboard");
                  }, 250);
                } else {
                  toast.error(
                    "İşlem gerçekleştirilemedi! Giriş bilgilerinizi kontrol ederek tekrar deneyiniz."
                  );
                }
              })
              .catch((e) => {
                // console.log('Error: ', e);
                toast.error(e.response.data.message);
              });
          } else {
            toast.error(
              `Kayıt oluşturulamadı! Bilgilerinizi gözden geçirerek tekrar deneyiniz. (${response.status})`
            );
          }
        })
        .catch((e) => {
          if (e.response.status === 406)
            toast.error("Bu E-Posta ile oluşturulmuş bir salon kaydı mevcut!");
          else
            toast.error(
              `Kayıt oluşturulamadı! Bilgilerinizi gözden geçirerek tekrar deneyiniz.`
            );
        });
  };

  const validateFirstStep = () => {
    return Validate([
      {
        field: "İşletme Adı",
        value: form.company.name,
        condition: /\w+/,
      },
      {
        field: "Telefon Numarası",
        value: form.company.phone,
        condition: /\S{10}/,
      },
    ]);
  };

  const validateRegister = () => {
    return Validate([
      {
        field: "İşletme Adı",
        value: form.company.name,
        condition: /\w+/,
      },
      {
        field: "Yetkili Adı",
        value: form.permitted.name,
        condition: /\w+/,
      },
      {
        field: "Yetkili Soyadı",
        value: form.permitted.surname,
        condition: /\w+/,
      },
      {
        field: "Telefon Numarası",
        value: form.company.phone,
        condition: /\S{10}/,
      },
      {
        field: "E-Posta Adresi",
        value: form.company.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
      {
        field: "Şifre",
        value: form.permitted.password,
        condition: /^.{6,}$/,
      },
    ]);
  };

  const validateCode = () => {
    return Validate([
      {
        field: "Doğrulama Kodu",
        value: form.code,
        condition: /\w+/,
      },
    ]);
  };

  const gtag_report_conversion = (url) => {
    // console.log('GTAG_REPORT_CONVERSION_EXECUTED')
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    window.gtag &&
      window.gtag("event", "conversion", {
        send_to: "AW-600324306/MRUhCNLAotwBENLxoJ4C",
        event_callback: callback,
      });
    return false;
  };

  const phoneLabel = context.t(`register.phoneInputLabel`);

  return (
    <Grid container component="main" className={classes.root}>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-600324306"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-600324306');
          `}
        </script>
        <script>
          {` 
            (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5HWS8ZL');
         `}
        </script>
      </Helmet>

      <CssBaseline />
      <Grid item xs={false} sm={4} md={8} className={classes.image} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        {step === 1 && (
          <div className={`${classes.paper}`}>
            <Typography
              component="h3"
              variant="h6"
              style={{ fontWeight: 400, textAlign: "center" }}
            >
              {context.t(`register.noPaymentText`)}
            </Typography>
            <Typography
              component="h2"
              variant="h5"
              style={{ fontWeight: 600, textAlign: "center" }}
              className="mb-5"
            >
              {context.t(`register.trialText`)}
            </Typography>

            <Typography component="h1" variant="h5" className="mb-0">
              <b>{context.app.NAME}</b>
            </Typography>
            <Typography component="h1" variant="h6" className="mb-0">
              {context.t(`register.title`)}
            </Typography>

            <Grid className="pt-4" container spacing={2}>
              {formStep === 1 && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <Input
                      required
                      className="mb-0"
                      label={context.t(`register.companyNameInputLabel`)}
                      onChange={(e) =>
                        InputOnChange("company", "name", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MaskedInput
                      required
                      className="mb-0"
                      label={phoneLabel}
                      maskChar={""}
                      mask="999 999 9999"
                      startAdornment="0"
                      value={
                        form.company.phone !== null ? form.company.phone : ""
                      }
                      placeholder="500 000 0000"
                      onChange={(e) => {
                        setForm({
                          ...form,
                          company: {
                            ...form.company,
                            phone:
                              e.target.value.substring(0, 1) === "5" &&
                              e.target.value.replace(/\s/g, ""),
                          },
                          permitted: {
                            ...form.permitted,
                            phone:
                              e.target.value.substring(0, 1) === "5" &&
                              e.target.value.replace(/\s/g, ""),
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Input
                      label={context.t(`register.campaingCodeInputLabel`)}
                      value={
                        form.campaign_code !== null ? form.campaign_code : ""
                      }
                      onChange={(e) => {
                        setForm({
                          ...form,
                          campaign_code: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Button
                    className="mt-2"
                    title={context.t(`register.continueButtonTitle`)}
                    size="lg"
                    fullWidth
                    textColor="white"
                    backgroundColor="primary"
                    onClick={() => validateFirstStep() && setFormStep(2)}
                  />
                </>
              )}

              {formStep === 2 && (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      required
                      className="mb-0"
                      label={context.t(`register.staffNameInputLabel`)}
                      onChange={(e) =>
                        InputOnChange("permitted", "name", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      required
                      className="mb-0"
                      label={context.t(`register.staffSurnameInputLabel`)}
                      onChange={(e) =>
                        InputOnChange("permitted", "surname", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Input
                      required
                      className="mb-0"
                      label={context.t(`register.staffMailInputLabel`)}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          company: { ...form.company, mail: e.target.value },
                          permitted: {
                            ...form.permitted,
                            mail: e.target.value,
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Input
                      required
                      className="mb-0"
                      autoComplete="off"
                      label={context.t(`register.passwordInputLabel`)}
                      type="password"
                      onChange={(e) =>
                        InputOnChange("permitted", "password", e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={privacy}
                          onChange={(e) => setPrivacy(e.target.checked)}
                        />
                      }
                      label={
                        <FCLabel>
                          <a
                            href={context.app.PRIVACY}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {context.t(`register.privacyPolicyText`)}
                          </a>
                        </FCLabel>
                      }
                    />
                  </Grid>

                  <Button
                    className="mt-2"
                    title={context.t(`register.completeButtonTitle`)}
                    type="submit"
                    size="lg"
                    fullWidth
                    textColor="white"
                    backgroundColor="primary"
                    onClick={() =>
                      privacy
                        ? RegisterSMS()
                        : toast.warning(
                            "Gizlilik sözleşmesini kabul etmek zorunludur!"
                          )
                    }
                  />
                </>
              )}

              <Button
                className="mt-1"
                title={context.t(`register.alreadyUserButtonTitle`)}
                type="submit"
                size="lg"
                fullWidth
                textColor="primary"
                backgroundColor="white"
                onClick={() => props.history.push("/login")}
              />
            </Grid>
          </div>
        )}

        {step === 2 && (
          <div className={`${classes.paperStep2}`}>
            <Typography component="h1" variant="h5" className="mb-0">
              <b>{context.app.NAME}</b>
            </Typography>
            <Typography component="h1" variant="h6" className="mb-0">
              SMS Doğrulama
            </Typography>
            <Grid className="pt-4" container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Input
                  required
                  className="mb-0"
                  label="Onay Kodu"
                  onChange={(e) => setForm({ ...form, code: e.target.value })}
                />
              </Grid>
            </Grid>

            <Button
              className="mt-2"
              title="Kaydı Tamamla"
              type="submit"
              size="lg"
              fullWidth
              textColor="white"
              backgroundColor="primary"
              onClick={() => RegisterApprove()}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default RegisterIndex;
