import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import Select from "../../../theme/CustomMUI/Select";
import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";

import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";

import CustomMUIDatePicker from "../../../theme/CustomMUI/DatePicker";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import moment from "moment";
import Axios from "axios";
import getDayIndex from "../../../functions/GetDayIndex";
import { toast } from "react-toastify";
import Input from "../../../theme/CustomMUI/Input";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import CSwitch from "../../../theme/CustomMUI/CSwitch";

class AddAppointment extends React.Component {
  static contextType = AppContext;

  state = {
    //Input States
    customers: [],
    employees: [],
    services: [],

    //Selected States
    selected_customer: null,
    selected_employee: null,
    selected_service: null,
    selected_date: new Date(),
    selected_time: null,

    note: null,
    send_sms: true,

    //Appointment States
    unavailable_data: {
      single: [], // ["11:00", "11:20"],
      between: [], // [{ start_hour: "10:00", end_hour: "10:20" }, { start_hour: "15:30", end_hour: "16:20" },],
      day_closed: false,
    },
    unavailable_hours: [],
    available_appointment_expanded: false,

    customer_add_modal: false,
  };

  getRequiredAppointmentArrays = () => {
    Axios.get(`${this.context.api_endpoint}/company/appointments/index2`)
      .then(({ data }) => {
        this.setState({
          // services: [...data.data.services],
          employees: [...data.data],
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key },
    })
      .then(({ data }) => (arr = [...data.data.records]))
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  getStaffUnavailableHours = () => {
    Axios.get(
      `${this.context.api_endpoint}/company/appointments/staff/available`,
      {
        params: {
          staff_id: parseInt(this.state.selected_employee.id),
          date: moment(this.state.selected_date).format("YYYY-MM-DD"),
          day_index: getDayIndex(
            moment(this.state.selected_date).format("YYYY-MM-DD")
          ),
        },
      }
    )
      .then(({ data }) => {
        this.setState({
          unavailable_data: {
            ...data.data,
            single: data.data.single !== null ? data.data.single : [],
            between: data.data.between ?? [],
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .then(() => {
        this.setState({
          available_appointment_expanded: true,
          unavailable_hours: [
            ...DisabledAppointmentsGenerator(this.state.unavailable_data),
          ],
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  insertAppointment = () => {
    const {
      selected_date,
      selected_time,
      selected_service,
      selected_employee,
      selected_customer,
      send_sms,
      note,
    } = this.state;

    let appointment_date = new Date(selected_date);
    let appointment_time = selected_time.split(":");
    appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${
      appointment_time[0]
    }:${appointment_time[1]}:00Z`;

    Axios.post(`${this.context.api_endpoint}/company/appointment/insert`, {
      staff_id: selected_employee.id,
      customer_id: selected_customer.id,
      service_id: selected_service.id,
      send_sms: selected_customer
        ? selected_customer.send_sms === false
          ? false
          : send_sms
        : send_sms,
      note,
      appointment_date,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`['appointments/add'].insertToast`));
          this.props.history.push("/appointments");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount = () => {
    this.getRequiredAppointmentArrays();
  };

  helperText = (sttr, len = 0) => {
    return sttr.replace("%s", len);
  };

  render() {
    return (
      <AuthContainer limited_permission="appointment">
        <Grid>
          <Column className="xs-12" style={{ width: "342px" }}>
            <CustomMUIDatePicker
              autoOk
              openTo="date"
              variant="static"
              disableToolbar={true}
              showTodayButton={true}
              minDate={new Date()}
              value={this.state.selected_date}
              onChange={(date) => {
                this.setState({
                  selected_date: date,
                  dialog: false,

                  available_appointment_expanded: false,
                  selected_time: null,
                });
                console.log(moment(date).format("LL"));
              }}
            />
          </Column>

          <Column className="col-auto">
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-auto">
                <Select
                  label={this.context.t(`['appointments/add'].staffInputLabel`)}
                  items={this.state.employees}
                  labelKey="full_name"
                  valueKey="id"
                  selected={
                    this.state.selected_employee !== null
                      ? `${this.state.selected_employee.id}`
                      : ""
                  }
                  returnFullObject
                  handler={(selected_employee) =>
                    this.setState({
                      services: [
                        ...selected_employee.services.map((service) => {
                          return service.service !== null
                            ? { ...service.service }
                            : undefined;
                        }),
                      ].filter((item) => item !== undefined),
                      selected_employee: selected_employee,
                      available_appointment_expanded: false,
                      selected_time: null,
                    })
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-auto">
                <Select
                  label={this.context.t(
                    `['appointments/add'].serviceInputLabel`
                  )}
                  items={this.state.services}
                  labelKey="name"
                  valueKey="id"
                  selected={
                    this.state.selected_service !== null
                      ? `${this.state.selected_service.id}`
                      : ""
                  }
                  returnFullObject
                  handler={(selected_service) =>
                    this.setState({
                      selected_service,
                      available_appointment_expanded: false,
                      selected_time: null,
                    })
                  }
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-8 sm-10 md-6">
                <AutocompleteInput
                  label={this.context.t(
                    `['appointments/add'].customerInputLabel`
                  )}
                  placeholder={this.context.t(
                    `['appointments/add'].customerInputPlaceholder`
                  )}
                  labelKey="full_name"
                  valueKey="id"
                  selected={
                    this.state.selected_customer !== null
                      ? this.state.selected_customer
                      : ""
                  }
                  returnFullObject
                  selectedHandler={(selected_customer) =>
                    this.setState({
                      selected_customer,
                      send_sms: selected_customer.send_sms,
                    })
                  }
                  asyncDataService={async (keyword) =>
                    this.searchCustomerByKey(keyword)
                  }
                />
              </Column>
              <Column className="xs-4 sm-2 md-6">
                <Button
                  icon="add"
                  title={this.context.t(
                    `['appointments/add'].createNewCustomerButtonTitle`
                  )}
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  fullWidth={true}
                  size="lg"
                  onClick={() => {
                    this.setState({ customer_add_modal: true });
                  }}
                />
              </Column>
            </Grid>

            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-6">
                <CSwitch
                  label={this.context.t(
                    `['appointments/add'].sendSmsInputLabel`
                  )}
                  checked={this.state.send_sms}
                  color="primary"
                  disabled={
                    this.state.selected_customer &&
                    this.state.selected_customer.send_sms === false
                  }
                  onChange={(checked) => {
                    this.setState({ send_sms: checked.target.checked });
                  }}
                />
                {/* <Select
                  disabled={
                    this.state.selected_customer &&
                    this.state.selected_customer.send_sms === false
                  }
                  label={this.context.t(
                    `['appointments/add'].sendSmsInputLabel`
                  )}
                  items={[
                    {
                      label: this.context.t(`['appointments/add'].sendSmsTrue`),
                      value: true,
                    },
                    {
                      label: this.context.t(
                        `['appointments/add'].sendSmsFalse`
                      ),
                      value: false,
                    },
                  ]}
                  selected={`${this.state.send_sms}`}
                  labelKey="label"
                  valueKey="value"
                  handler={(send_sms) =>
                    this.setState({
                      send_sms: send_sms === "true",
                    })
                  }
                /> */}
              </Column>

              <Column className="xs-12 sm-12 md-6">
                <Input
                  label={this.context.t(`['appointments/add'].noteInputLabel`)}
                  multiline
                  rows={1}
                  helperText={this.context.t(
                    `['appointments/add'].noteInputHint`
                  )}
                  onChange={(e) =>
                    this.setState({
                      note:
                        e.target.value.length <= 255 ? e.target.value : null,
                    })
                  }
                />
              </Column>
            </Grid>

            <Button
              icon="event"
              title={this.context.t(
                `['appointments/add'].availableHoursButtonTitle`
              )}
              disabled={
                !(
                  this.state.selected_customer !== null &&
                  this.state.selected_employee !== null &&
                  this.state.selected_service !== null &&
                  this.state.selected_date !== null
                )
              }
              onClick={() => {
                this.getStaffUnavailableHours();
              }}
            />

            {this.state.available_appointment_expanded === true && (
              <>
                <Grid className="mt-4 no-gutters-all">
                  {BetweenTimeGenerator(this.context.state.company_shift).map(
                    (item, index) => {
                      const time = item.split(":");
                      const { selected_date } = this.state;
                      const x = new Date(selected_date).setHours(
                        time[0],
                        time[1],
                        0,
                        0
                      );
                      return (
                        <div
                          key={index}
                          className="col xs-6 sm-6 md-6 lg-4 xl-2 mb-2 p-horizontal-1"
                        >
                          <Button
                            iconSize={20}
                            icon={
                              this.state.unavailable_hours.includes(item) ||
                              x < new Date()
                                ? "close"
                                : "alarm_add"
                            }
                            title={item}
                            fullWidth={true}
                            textColor={
                              this.state.unavailable_hours.includes(item) ||
                              x < new Date()
                                ? "red"
                                : "green"
                            }
                            disabled={
                              this.state.unavailable_hours.includes(item) ||
                              x < new Date()
                            }
                            outlined={this.state.selected_time === item}
                            onClick={() =>
                              this.setState({ selected_time: item })
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </Grid>

                <Button
                  className="mt-2"
                  icon="check"
                  title={this.context.t(
                    `['appointments/add'].confirmButtonTitle`
                  )}
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth={true}
                  disabled={
                    !(
                      this.state.selected_customer !== null &&
                      this.state.selected_employee !== null &&
                      this.state.selected_service !== null &&
                      this.state.selected_date !== null &&
                      this.state.selected_time !== null
                    )
                  }
                  onClick={() => this.insertAppointment()}
                />
              </>
            )}
          </Column>
          <CustomerAddDialog
            open={this.state.customer_add_modal}
            createdCustomerHandler={async (data) => {
              await this.setState({
                selected_customer: {
                  ...data,
                  full_name: `${data.name} ${data.surname}`,
                },
              });
            }}
            closeHandler={() => this.setState({ customer_add_modal: false })}
          />
        </Grid>
      </AuthContainer>
    );
  }
}

export default AddAppointment;
