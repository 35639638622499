import React from "react";
import styled from "styled-components";

const HorizontalBar = (props) => {
  return (
    <div>
      <h4>{props.title}</h4>
      <BarContainer>
        <BarBox width={props.width} background={props.background}>
          <div className="content-box"></div>
        </BarBox>
        <div>{props.label}</div>
      </BarContainer>
    </div>
  );
};

export default HorizontalBar;

const BarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 0.3 linear;

  &:hover {
    transform: scale(1.1);
  }
`;

const BarBox = styled.div`
  width: 150px;
  height: 15px;
  background: #bdbdbd96;
  border-radius: 10px;

  .content-box {
    width: ${(props) => (props.width ? `${props.width}` : "0px")};
    height: 100%;
    border-radius: 10px;
    background: ${(props) =>
      props.background ? `${props.background}` : "red"};
    transition: width 2s;
  }
`;
