import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/store";
import Button from "../../theme/Button";

const TimerButton = (props) => {
  const context = useContext(AppContext);
  const [timer, setTimer] = useState(props.timer ?? 0);
  let timerInterval = null;

  useEffect(() => {
    if (timer > 0 && timerInterval === null) {
      // eslint-disable-next-line
      timerInterval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [timer]);

  return (
    <Button
      {...props}
      icon={timer > 0 ? "timer" : props.icon}
      title={
        timer > 0
          ? `${context.t(`component.timerButton.waitText`)} (${timer})`
          : props.title
      }
      backgroundColor={`${timer > 0 ? "black-opacity" : props.backgroundColor}`}
      textColor={timer > 0 ? "grey" : props.textColor}
      transitionEffect={true}
      fullWidth
      onClick={() => {
        if (timer === 0) props.onClick();
      }}
    />
  );
};

export default TimerButton;
