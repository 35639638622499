import { SvgIcon } from "@material-ui/core";
import React from "react";

function Layers() {
  return (
    <SvgIcon
      width="26"
      height="31"
      viewBox="0 0 26 31"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.86">
        <path d="M12.9297 0.887944C12.8667 0.904944 10.1447 2.51896 6.88371 4.47696C0.205706 8.48496 0.642705 8.17694 0.642705 8.88694C0.642705 9.59694 0.200706 9.28695 6.91271 13.32L12.8727 16.8969H13.6187L19.5787 13.32C26.2907 9.28895 25.8487 9.59894 25.8487 8.88694C25.8487 8.17494 26.2907 8.48695 19.5897 4.46595C14.0377 1.13595 13.6237 0.894958 13.3427 0.876958C13.2051 0.864342 13.0665 0.86802 12.9297 0.887944ZM17.8737 6.10694C20.3827 7.61694 22.4327 8.86296 22.4327 8.88596C22.4327 8.93796 13.3327 14.398 13.2457 14.398C13.1367 14.398 4.0527 8.93194 4.0697 8.87394C4.0867 8.81594 13.1417 3.37894 13.2337 3.37394C13.2807 3.37494 15.3647 4.60295 17.8737 6.10795V6.10694Z" />
        <path d="M2.62871 13.7158C2.5537 13.7448 2.1467 13.9798 1.7217 14.2328C0.774704 14.8008 0.642705 14.9678 0.642705 15.5478C0.642705 16.2598 0.200706 15.9478 6.91271 19.9808L12.8727 23.5578H13.6187L19.5787 19.9808C26.2907 15.9498 25.8487 16.2598 25.8487 15.5478C25.8487 14.9568 25.7227 14.8008 24.7007 14.1928C23.8337 13.6758 23.5927 13.5928 23.1507 13.6758C22.9916 13.7262 22.8451 13.8097 22.7207 13.9209C22.5963 14.0321 22.4969 14.1684 22.429 14.3209C22.3612 14.4733 22.3264 14.6384 22.327 14.8052C22.3277 14.9721 22.3637 15.1369 22.4327 15.2888C22.4795 15.3607 22.5133 15.4403 22.5327 15.5238C22.5267 15.5298 20.4487 16.7818 17.9107 18.3028C15.1427 19.9618 13.2597 21.0588 13.2027 21.0418C13.1277 21.0248 4.0267 15.5868 3.9637 15.5238C3.98313 15.4403 4.01697 15.3607 4.06371 15.2888C4.1286 15.1465 4.16465 14.9928 4.16978 14.8364C4.17491 14.6801 4.14903 14.5243 4.09361 14.3781C4.03819 14.2318 3.95435 14.098 3.84692 13.9843C3.73949 13.8706 3.6106 13.7794 3.46771 13.7158C3.19302 13.6412 2.90339 13.6412 2.62871 13.7158Z" />
        <path d="M2.62871 20.3769C2.5537 20.4059 2.1467 20.6409 1.7217 20.8939C0.774704 21.4619 0.642705 21.6289 0.642705 22.2089C0.642705 22.9209 0.200706 22.6089 6.91271 26.6419L12.8727 30.2189H13.6187L19.5787 26.6419C26.2907 22.6109 25.8487 22.9209 25.8487 22.2089C25.8487 21.6179 25.7227 21.4629 24.7007 20.8539C23.8337 20.3369 23.5927 20.2539 23.1507 20.3369C22.9916 20.3873 22.8451 20.4709 22.7207 20.5821C22.5963 20.6932 22.4969 20.8296 22.429 20.982C22.3612 21.1344 22.3264 21.2995 22.327 21.4663C22.3277 21.6332 22.3637 21.798 22.4327 21.9499C22.4795 22.0218 22.5133 22.1014 22.5327 22.1849C22.5267 22.1909 20.4487 23.4419 17.9107 24.9639C15.1427 26.6229 13.2597 27.7199 13.2027 27.7029C13.1277 27.6859 4.0267 22.2479 3.9637 22.1849C3.98313 22.1014 4.01697 22.0219 4.06371 21.9499C4.1286 21.8076 4.16465 21.6539 4.16978 21.4975C4.17491 21.3412 4.14903 21.1854 4.09361 21.0392C4.03819 20.8929 3.95435 20.7591 3.84692 20.6454C3.73949 20.5318 3.6106 20.4405 3.46771 20.3769C3.19302 20.3023 2.90339 20.3023 2.62871 20.3769Z" />
      </g>
    </SvgIcon>
  );
}

export default Layers;
