import React, { Component } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import Axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import { InputAdornment } from "@material-ui/core";
import Loader from "../../../assets/images/loader.gif";
import { Group, LocalMall } from "@material-ui/icons";
import RemainingDebtRed from "../../../assets/images/svg/remaining_debt_red.svg";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { flushSync } from "react-dom";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import moment from "moment";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import AutoCompleteInputWithoutEndPoint from "../../../theme/CustomMUI/AutoCompleteInputWithoutEndPoint";

let typingTimeout = null;
class PackageAdd extends Component {
  static contextType = AppContext;
  state = {
    services: [],
    staffs: [],

    pick_customer_and_package_sale_dialog: false,
    selected_customer: null,
    customer_add_modal: false,

    new_package: {
      name: null,
      all_amount: 200,
      services: {},
    },

    total: 0,
    total_loader: false,

    __generated_package: {},
  };

  componentDidMount() {
    this.getReqArrays();
  }

  CustomerDialog = () => {
    const [setCustomerAddModal] = React.useState(false);

    React.useEffect(() => {
      flushSync(() => {
        this.setState({
          customer_add_modal: this.state.customer_add_modal,
        });
      });
    }, [this.state.customer_add_modal]);
    return (
      <CustomerAddDialog
        disabledAdvanedOptions={true}
        open={this.state.customer_add_modal}
        onClick={(data) => {
          this.setState({
            selected_customer: { ...data },
          });
        }}
        createdCustomerHandler={async (data) => {
          await this.setState({
            selected_customer: {
              ...data,
              full_name: `${data.name} ${data.surname}`,
            },
          });
          await this.setState({ create_appointment_modal: true });
        }}
        closeHandler={() =>
          this.setState({
            customer_add_modal: false,
          })
        }
        setOpen={setCustomerAddModal}
      />
    );
  };

  searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key },
    })
      .then(({ data }) => (arr = [...data.data.records]))
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
      };
    });
  };

  getReqArrays = () => {
    Axios.get(`${this.context.api_endpoint}/company/receipt/index`)
      .then(({ data }) => {
        this.setState({
          staffs: [...data.data.staffs],
          services: [...data.data.services],
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  addServiceInPackage = () => {
    const { new_package } = this.state;
    this.setState({
      new_package: {
        ...new_package,
        services: {
          ...new_package.services,
          [Object.keys(new_package.services).length + 1]: {
            service_id: null,
            amount: null,
            period: 1,
            period_start: moment(new Date()).format("YYYY-MM-DD"),
          },
        },
      },
    });
  };

  deleteServiceInPackage = (key) => {
    const { new_package } = this.state;
    this.setState({
      new_package: {
        ...new_package,
        services: {
          ...new_package.services,
          [key]: undefined,
        },
      },
    });
  };

  createPackage = () => {
    const { new_package } = this.state;
    this.validatePackage() &&
      Axios.post(`${this.context.api_endpoint}/company/packet/insert`, {
        ...new_package,
        services: undefined,
        all_amount: Math.round(this.getTotal().amount),
        packet_details: [
          ...Object.keys(new_package.services)
            .filter((key) => new_package.services[key] !== undefined)
            .map((item) => Object.assign(new_package.services[item], {})),
        ],
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(this.context.t(`['packages/add'].insertToast`));
            this.props.history.push("/packages/list");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  createAndContinuie = () => {
    const { new_package } = this.state;
    this.validatePackage() &&
      Axios.post(`${this.context.api_endpoint}/company/packet/insert`, {
        ...new_package,
        services: undefined,
        all_amount: Math.round(this.getTotal().amount),
        packet_details: [
          ...Object.keys(new_package.services)
            .filter((key) => new_package.services[key] !== undefined)
            .map((item) => Object.assign(new_package.services[item], {})),
        ],
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(this.context.t(`['packages/add'].insertToast`));
            this.props.history.push({
              pathname: "/packages/sales/add",
              state: {
                packet_id: response.data.data.id,
              },
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };
  // Create

  createPackageSale = () => {
    const { new_package, selected_customer } = this.state;
    this.setState({
      create_button_disabled: true,
    });
    this.validatePackage() &&
      Axios.post(`${this.context.api_endpoint}/company/packet/open/receipt`, {
        customer_id: selected_customer.id,
        receipt_id: null,
        services: [
          ...Object.keys(new_package.services)
            .filter((key) => new_package.services[key] !== undefined)
            .map((item) =>
              Object.assign({ ...new_package.services[item] }, {})
            ),
        ],
      })
        .then((response) => {
          const {
            data: { data },
          } = response;

          if (response.status === 201) {
            this.setState({
              create_button_disabled: false,
            });
            this.props.history.push(`/receipts/detail/${data.id}`);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  PickCustomerAndPackageSaleDialog = () => {
    return (
      <AlertDialog
        containerStyle={{
          overflow: "visible",
        }}
        title={"Paket Satışı Yap"}
        maxWidth="md"
        fullWidth
        className="package-sale-dialog"
        open={this.state.pick_customer_and_package_sale_dialog}
        closeHandler={() =>
          this.setState({ pick_customer_and_package_sale_dialog: false })
        }
      >
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12 lg-9">
            <AutocompleteInput
              onRemove={() => {
                this.setState({ selected_customer: null });
              }}
              style={{ zIndex: 1000 }}
              label={this.context.t(
                `['packages/sales/add'].customerInputLabel`
              )}
              placeholder={this.context.t(
                `['packages/sales/add'].customerInputPlaceholder`
              )}
              labelKey="full_name"
              valueKey="id"
              selected={
                this.state.selected_customer !== null
                  ? this.state.selected_customer
                  : ""
              }
              returnFullObject
              selectedHandler={(selected_customer) =>
                this.setState({ selected_customer })
              }
              asyncDataService={async (keyword) =>
                this.searchCustomerByKey(keyword)
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <Button
              icon={<Group />}
              title={this.context.t(
                `['appointments/add'].createNewCustomerButtonTitle`
              )}
              backgroundColor="primary-opacity"
              textColor="primary"
              fullWidth={true}
              size="lg"
              onClick={() => {
                this.setState({
                  customer_add_modal: true,
                });
              }}
            />
          </Column>
        </Grid>

        {Object.keys(this.state.new_package.services).filter(
          (key) => this.state.new_package.services[key] !== undefined
        ).length > 0 ? (
          Object.keys(this.state.new_package.services)
            .filter((key) => this.state.new_package.services[key] !== undefined)
            .map((item, index) => (
              <Grid key={index} className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <Select
                    label={this.context.t(
                      `['packages/sales/add'].staffInputLabel`
                    )}
                    items={[...this.state.staffs]}
                    labelKey="full_name"
                    valueKey="id"
                    selected={
                      this.state.new_package.services[item].staff_id ?? null
                    }
                    handler={(staff_id) =>
                      this.fieldChanger("services", item, {
                        staff_id: parseInt(staff_id),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <DatePickerInput
                    label={this.context.t(
                      `['packages/sales/add'].startInputLabel`
                    )}
                    error={false}
                    value={moment(
                      this.state.new_package.services[item]?.period_start
                    )}
                    onChange={(period_start) =>
                      this.fieldChanger("services", item, {
                        period_start: moment(period_start).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <Select
                    label={this.context.t(
                      `['packages/sales/add'].periodBetweenInputLabel`
                    )}
                    items={[...new Array(31).keys()].map((i) => ({
                      value: i === 0 ? -1 : i,
                      label: `${i} Gün`,
                    }))}
                    labelKey="label"
                    valueKey="value"
                    selected={
                      this.state.new_package.services[item].period_between ??
                      null
                    }
                    handler={(period_between) => {
                      period_between === -1 &&
                        this.fieldChanger("services", item, {
                          period_between: 0,
                        });
                      period_between !== -1 &&
                        this.fieldChanger("services", item, {
                          period_between: parseInt(period_between),
                        });
                    }}
                  />
                </Column>
              </Grid>
            ))
        ) : (
          <NoDataContainer>
            <img
              src={require("../../../assets/images/manypixels/click_action.svg")}
              alt="Malesef bulunamadı!"
            />
            <p>{this.context.t(`['packages/sales/add'].noDataText`)}</p>
          </NoDataContainer>
        )}

        <Grid className="no-gutters-row mb-2 mt-1">
          <Column className="xs-12 sm-12 md-12 lg-auto">
            <Button
              disabled={
                Object.values(this.state.new_package.services)
                  .filter((item) => item !== undefined)
                  .filter((item) => item?.staff_id === undefined).length !==
                  0 ||
                Object.values(this.state.new_package.services)
                  .filter((item) => item !== undefined)
                  .filter((item) => item?.period_start === undefined).length !==
                  0 ||
                Object.values(this.state.new_package.services)
                  .filter((item) => item !== undefined)
                  .filter((item) => item?.period_between === undefined)
                  .length !== 0 ||
                this.state.selected_customer === null
              }
              icon="check"
              title={this.context.t(
                `['packages/add'].CompletePackageSaleButtonTitle`
              )}
              backgroundColor="teal-opacity"
              textColor="teal"
              // size="lg"
              fullWidth
              onClick={() => {
                this.createPackageSale();
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-auto">
            <Button
              icon="close"
              title={this.context.t(`['packages/add'].discardButtonTitle`)}
              backgroundColor="grey-opacity"
              textColor="grey"
              // size="lg"
              fullWidth
              onClick={() => {
                this.setState({
                  pick_customer_and_package_sale_dialog: false,
                });
              }}
            />
          </Column>
        </Grid>
      </AlertDialog>
    );
  };
  handlerServiceSelect = (item, service) => {
    this.fieldChanger("services", item, {
      service_id: service.id,
      amount: service.amount,
    });
  };

  render() {
    const { new_package, total_loader } = this.state;

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[0, 1]}
        limited_permission="sales"
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-9">
            <Input
              style={{
                borderBottom: `${
                  this.state.new_package.name === null ||
                  this.state.new_package.name === ""
                    ? "1px solid #3189ec"
                    : ""
                }`,
              }}
              label={this.context.t(`['packages/add'].name`)}
              placeholder={this.context.t(`["packages/add"].givenName`)}
              value={new_package.name ?? null}
              onChange={(e) =>
                this.setState({
                  new_package: { ...new_package, name: e.target.value ?? null },
                })
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3">
            <Button
              disabled={
                this.state.new_package.name === null ||
                this.state.new_package.name === ""
              }
              icon={<LocalMall />}
              title={this.context.t(`['packages/add'].addServiceButtonTitle`)}
              backgroundColor="blue-opacity"
              textColor="blue"
              size="lg"
              fullWidth
              onClick={this.addServiceInPackage}
            />
          </Column>
        </Grid>
        <Column className="xs-12 sm-12 md-12 lg-12">
          <SectionContainer>
            {Object.keys(new_package.services).filter(
              (key) => new_package.services[key] !== undefined
            ).length > 0 ? (
              Object.keys(new_package.services)
                .filter((key) => new_package.services[key] !== undefined)
                .map((item, index) => (
                  <Grid key={index} className="no-gutters-row">
                    <Column className="xs-12 sm-12 md-12 lg-6">
                      <AutoCompleteInputWithoutEndPoint
                        selected={false}
                        component={"PackageAdd"}
                        label={this.context.t(
                          `['packages/add'].serviceInputLabel`
                        )}
                        items={[...this.state.services]}
                        handlerPackageSelect={(service) =>
                          this.handlerServiceSelect(item, service)
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-auto lg-3">
                      <Select
                        label={this.context.t(
                          `['packages/add'].periodInputLabel`
                        )}
                        items={[...new Array(50).keys()].map((i) => ({
                          value: i + 1,
                        }))}
                        labelKey="value"
                        valueKey="value"
                        selected={new_package.services[item].period ?? null}
                        handler={(period) =>
                          this.fieldChanger("services", item, {
                            period: parseInt(period),
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-auto lg-3">
                      <Input
                        label={
                          this.context.t(
                            `['packages/add'].periodAmountInputLabel`
                          ) +
                          (this.context.state.currency
                            ? " (" + this.context.state.currency + ")"
                            : "(₺)")
                        }
                        type="number"
                        value={`${Math.round(
                          new_package.services[item].amount
                        )}`}
                        onChange={(e) =>
                          this.fieldChanger("services", item, {
                            amount:
                              e.target.value && /[0-9,.]+/.test(e.target.value)
                                ? parseFloat(e.target.value)
                                : 0,
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-1">
                      <Button
                        icon="delete_forever"
                        iconSize={20}
                        backgroundColor="red-opacity"
                        textColor="red"
                        size="lg"
                        fullWidth
                        onClick={() => {
                          this.deleteServiceInPackage(item);
                          setTimeout(() => {
                            if (
                              Object.values(
                                this.state.new_package.services
                              ).filter((item) => item !== undefined).length > 0
                            ) {
                              this.setState({
                                total: this._total().amount,
                              });
                            } else {
                              this.setState({
                                total: 0,
                              });
                            }
                          }, 250);
                        }}
                      />
                    </Column>
                  </Grid>
                ))
            ) : (
              <NoDataContainer>
                <img
                  src={require("../../../assets/images/manypixels/click_action.svg")}
                  alt="Malesef bulunamadı!"
                />
                <p>{this.context.t(`['packages/add'].noDataText`)}</p>
              </NoDataContainer>
            )}

            {Object.keys(new_package.services).filter(
              (key) => new_package.services[key] !== undefined
            ).length > 0 && (
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-auto">
                  <CustomerBoxDiv>
                    <img src={RemainingDebtRed} alt="customer_point" />
                    <CustomerPointBox>
                      <Input
                        label={
                          this.context.t(`['packages/add'].totalInputLabel`) +
                          (this.context.state.currency
                            ? " (" + this.context.state.currency + ")"
                            : "(₺)")
                        }
                        // type="number"
                        value={this.state.total}
                        onChange={async (e) => {
                          await this.setState({
                            total:
                              e.target.value && e.target.value !== "0"
                                ? parseFloat(e.target.value)
                                : 1,
                            total_loader: true,
                          });
                        }}
                        InputProps={{
                          style: {
                            fontWeight: "bold",
                          },
                          endAdornment: total_loader && (
                            <InputAdornment
                              position="end"
                              style={{ marginRight: 0 }}
                            >
                              <img
                                src={Loader}
                                width="100"
                                height="100"
                                alt="loading"
                              />
                            </InputAdornment>
                          ),
                          onKeyUp: (e) => {
                            const value = e.target.value;
                            clearTimeout(typingTimeout);
                            typingTimeout =
                              value.length > 0 &&
                              setTimeout(() => this.editTotal(), 1250);
                          },
                        }}
                      />
                    </CustomerPointBox>
                  </CustomerBoxDiv>
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-3">
                  <Button
                    className="mt-1"
                    disabled={
                      Object.values(this.state.new_package.services).filter(
                        (item) => item?.service_id === null
                      ).length !== 0 ||
                      this.state.new_package?.name === null ||
                      this.state.new_package?.name === ""
                    }
                    icon={<Group />}
                    title={this.context.t(
                      `['packages/add'].pickCostumerAndPackageSale`
                    )}
                    backgroundColor="purple-opacity"
                    textColor="purple"
                    // size="lg"
                    fullWidth
                    onClick={() => {
                      this.createAndContinuie();
                      // this.setState({
                      //   pick_customer_and_package_sale_dialog: true,
                      // });

                      console.log(
                        "paket id si ile birlikte paket satışları sayfasına gidecek, burada backend in olışturulan packet_id yi göndermesi gerekiyor."
                      );
                    }}
                  />
                </Column>
              </Grid>
            )}

            {Object.keys(new_package.services).filter(
              (key) => new_package.services[key] !== undefined
            ).length > 0 && (
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-3">
                  <Button
                    disabled={
                      Object.values(this.state.new_package.services).filter(
                        (item) => item?.service_id === null
                      ).length !== 0 ||
                      this.state.new_package?.name === null ||
                      this.state.new_package?.name === ""
                    }
                    icon="check"
                    title={this.context.t(
                      `['packages/add'].completeButtonTitle`
                    )}
                    backgroundColor="teal-opacity"
                    textColor="teal"
                    // size="lg"
                    fullWidth
                    onClick={this.createPackage}
                  />
                </Column>
              </Grid>
            )}
          </SectionContainer>
        </Column>
        <this.PickCustomerAndPackageSaleDialog />
        <this.CustomerDialog />
      </AuthContainer>
    );
  }

  validatePackage = () => {
    const { new_package } = this.state;
    let undefined_service_count = 0;
    Object.keys(new_package.services).filter(
      (key) =>
        new_package.services[key] !== undefined &&
        new_package.services[key].service_id === null &&
        ++undefined_service_count
    );

    if (undefined_service_count > 0) {
      toast.warning(this.context.t(`['packages/add'].validateServiceToast`));
      return false;
    }

    return Validate([
      {
        field: "Paket adı",
        value: new_package.name,
        condition: /\w+/,
      },
    ]);
  };

  _total = () => {
    const { new_package } = this.state;

    let total_amount = Object.keys(new_package.services)
      .filter((key) => new_package.services[key] !== undefined)
      .map((key) =>
        new_package.services[key].amount !== null
          ? new_package.services[key].amount * new_package.services[key].period
          : 0
      )
      .reduce((a, b) => a + b)
      .toFixed(2);

    return {
      amount: total_amount,

      getRatioList: () => {
        const SingleRatio = {};
        Object.keys(new_package.services)
          .filter((key) => new_package.services[key] !== undefined)
          .map(
            (key) =>
              (SingleRatio[key] =
                new_package.services[key].amount !== null
                  ? (new_package.services[key].amount *
                      new_package.services[key].period) /
                    this._total().amount
                  : 0)
          );
        return SingleRatio;
      },
    };
  };

  editTotal = () => {
    const { new_package, total } = this.state;

    // console.log(this._total().getRatioList());

    if (total !== 0 || total !== null) {
      let services = { ...new_package.services };

      [...Object.keys(this._total().getRatioList())].map(
        (key) =>
          (services = {
            ...services,
            [key]: {
              ...new_package.services[key],
              amount: parseFloat(
                (
                  (total * this._total().getRatioList()[key]) /
                  new_package.services[key].period
                ).toFixed(2)
              ),
            },
          })
      );

      this.setState({
        total_loader: false,
        new_package: {
          ...new_package,
          services,
        },
      });
    }
  };

  getTotal = () => {
    const { new_package } = this.state;

    let total_amount = Object.keys(new_package.services)
      .filter((key) => new_package.services[key] !== undefined)
      .map((key) =>
        new_package.services[key].amount !== null
          ? new_package.services[key].amount * new_package.services[key].period
          : 0
      )
      .reduce((a, b) => a + b);

    return {
      amount: total_amount,
    };
  };

  fieldChanger = (section, key, payload) => {
    const { new_package } = this.state;
    this.setState(
      {
        new_package: {
          ...new_package,
          [section]: {
            ...new_package[section],
            [key]: {
              ...new_package[section][key],
              ...payload,
            },
          },
        },
      },
      () => {
        this.setState({
          total: this._total().amount,
        });
      }
    );
  };
}

export default PackageAdd;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.04);
`;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 60px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #aaa;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;
