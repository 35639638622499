import React from "react";
import styled from "styled-components";

const HoverHelperText = (props) => {
  return (
    props.hoverHelperText && (
      <div style={{ width: "100%", position: "relative" }}>
        <InfoContainer>
          ?<div className="info-box">{props.hoverHelperText}</div>
        </InfoContainer>
      </div>
    )
  );
};

export default HoverHelperText;

const InfoContainer = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 5px;
  cursor: default;
  z-index: 1000;

  .info-box {
    width: 300px;
    height: auto;
    background: gray;
    color: white;
    border: 1px solid; #ccc;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    display: none;
    position: absolute;
    right: 50%;
    z-index: 1000;
  }

  @keyframes showBox {
    0%   {opacity: 0}
    25%  {opacity: 0.4}
    50%  {opacity: 0.6}
    75%  {opacity: 0.8}
    100% {opacity: 1}
  }

  &:hover, &:active {
    .info-box {
      display: block;
      animation: showBox 250ms ease-in-out both;
    }
  }
`;
