import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import AppContext from "../../context/store";
// import Button from '../../theme/Button';
import moment from "moment";
import Axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import Button from "../../theme/Button";
import { flushSync } from "react-dom";

class NotificationsView extends Component {
  static contextType = AppContext;
  state = {
    notifications: [],
    notifications_modal: false,
    notifications_confirm: [],
    notifications_confirm_modal: false,
    notifications_cancel: [],
    notifications_cancel_modal: false,
    deleteModal: false,
    notificationId: [],
    checkboxModal: false,
  };

  deleteNotification = () => {
    Axios.delete(`${this.context.api_endpoint}/company/notification`, {
      data: {
        notification_id: this.state.notificationId
          ? this.state.notificationId
          : [],
      },
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.data);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.status === 404) {
          toast.error(error.message);
        }
      });
  };

  setReadNotifications = () => {
    const {
      notifications_modal,
      notifications_confirm_modal,
      notifications_cancel_modal,
    } = this.state;

    Axios.post(`${this.context.api_endpoint}/company/notification/read`, {
      notification_id: [
        ...this.state.notifications
          .filter((notif) => notif.is_read === false)
          .map((notif) => notif.id),
        ...this.state.notifications_confirm
          .filter((notif) => notif.is_read === false)
          .map((notif) => notif.id),
        ...this.state.notifications_cancel
          .filter((notif) => notif.is_read === false)
          .map((notif) => notif.id),
      ],
    }).then(async (response) => {
      if (response.status === 201) {
        if (notifications_modal === true) {
          await this.setState({
            notifications: this.state.notifications.map((notif) => ({
              ...notif,
              is_read: true,
            })),
          });
        } else if (notifications_confirm_modal === true) {
          await this.setState({
            notifications_confirm: this.state.notifications_confirm.map(
              (notif) => ({
                ...notif,
                is_read: true,
              })
            ),
          });
        } else if (notifications_cancel_modal === true) {
          await this.setState({
            notifications_cancel: this.state.notifications_cancel.map(
              (notif) => ({
                ...notif,
                is_read: true,
              })
            ),
          });
        }
        const notifLength =
          this.state.notifications.filter((notif) => notif.is_read === false)
            .length +
          this.state.notifications_confirm.filter(
            (notif) => notif.is_read === false
          ).length +
          this.state.notifications_cancel.filter(
            (notif) => notif.is_read === false
          ).length;
        if (notifLength === 0) {
          await this.props.unreadCountHandler(0);
        }
      }
    });
  };

  endpointGenerator = () => {
    return this.context.api_endpoint
      .replace(/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/, "")
      .replace(/(\/api\/v1)/, "");
  };

  //TODO: Build alınırken ws'i wss yapman lazım
  conn = new WebSocket(
    `wss://${this.endpointGenerator()}/ws?id=${
      this.context.state.company_id * 924
    }`
  );
  componentDidMount() {
    this.setState({
      notifications_modal: true,
    });
    this.conn.onmessage = async (e) => {
      const notifications = e.data.split("\n");
      flushSync(() => {
        this.setState({
          notifications: [
            ...notifications
              .map((notif) => JSON.parse(notif))
              .filter(
                (notif) =>
                  notif.type !== 2 &&
                  notif.description ===
                    "Yeni bir randevunuz oluştu. İyi çalışmalar dileriz."
              ),
            ...this.state.notifications,
          ],
          notifications_confirm: [
            ...notifications
              .map((notif) => JSON.parse(notif))
              .filter(
                (notif) =>
                  notif.type !== 2 &&
                  notif.description.split(" ")[5] === "onaylamıştır."
              ),
            ...this.state.notifications_confirm,
          ],
          notifications_cancel: [
            ...notifications
              .map((notif) => JSON.parse(notif))
              .filter(
                (notif) =>
                  notif.type !== 2 &&
                  notif.description.split(" ")[5] === "iptal"
              ),
            ...this.state.notifications_cancel,
          ],
        });
      });
      const notifLength =
        this.state.notifications.filter((notif) => notif.is_read === false)
          .length +
        this.state.notifications_confirm.filter(
          (notif) => notif.is_read === false
        ).length +
        this.state.notifications_cancel.filter(
          (notif) => notif.is_read === false
        ).length;
      await this.props.unreadCountHandler(notifLength);
    };
  }

  changeNotif = (notif) => {
    if (this.state.notificationId.findIndex((item) => item === notif.id) > -1) {
      this.setState({
        notificationId: [
          ...this.state.notificationId,
          this.state.notificationId.filter((item) => item !== notif.id),
        ],
      });
    } else {
      this.setState({
        notificationId: [...this.state.notificationId, notif.id],
      });
    }
  };

  // componentDidUpdate(prevProps) {
  //   if (this.props.state !== prevProps.state && this.props.state === true)
  //     this.setReadNotifications();
  // }

  render() {
    const {
      notifications,
      notifications_modal,
      notifications_confirm,
      notifications_confirm_modal,
      notifications_cancel,
      notifications_cancel_modal,
      notificationId,
      checkboxModal,
    } = this.state;
    return (
      <BoxPopContainer
        className={this.props.state ? "show" : "hide"}
        onMouseOver={this.props.stopPropagation}
        onClick={this.props.stopPropagation}
      >
        <BoxPopInner>
          <BoxContainer>
            <BoxPopCaption>
              {this.context.t(`scaffold.notificationViewHeading`)}
            </BoxPopCaption>
            {checkboxModal === true ? (
              <div>
                <Button
                  className="ml-4"
                  title="Hepsini Sil"
                  textColor="green"
                  size="md"
                  onClick={() => {
                    this.setState({
                      notificationId: [],
                    });
                    if (notificationId.length === 0) {
                      this.setState({
                        deleteModal: true,
                      });
                    }
                  }}
                />
                <Button
                  title="İptal"
                  textColor="red"
                  size="md"
                  onClick={() => {
                    this.setState({
                      checkboxModal: false,
                    });
                  }}
                />
              </div>
            ) : (
              <Button
                icon="delete"
                title="Sil"
                textColor="red"
                size="md"
                onClick={() => {
                  this.setState({
                    checkboxModal: true,
                  });
                }}
              />
            )}
          </BoxContainer>
          <HeaderButton>
            <button
              onClick={() => {
                this.setState({
                  notifications_modal: true,
                  notifications_confirm_modal: false,
                  notifications_cancel_modal: false,
                });
                this.setReadNotifications();
              }}
              style={{
                borderLeft: "1px solid #995A1F",
                borderTop: "1px solid #995A1F",
                borderBottom: "1px solid #995A1F",
                backgroundColor: `${
                  notifications_modal === true ? "#995A1F" : "#fff"
                }`,
                color: `${notifications_modal === true ? "#fff" : "#995A1F"}`,
              }}
            >
              <p
                style={{
                  marginTop: "5px",
                  marginLeft: "38px",
                }}
              >
                Yeni
              </p>
              {this.state.notifications.filter(
                (notif) => notif.is_read === false
              ).length > 0 && (
                <span className="count">
                  {
                    this.state.notifications.filter(
                      (notif) => notif.is_read === false
                    ).length
                  }
                </span>
              )}
            </button>
            <button
              onClick={() => {
                this.setState({
                  notifications_confirm_modal: true,
                  notifications_modal: false,
                  notifications_cancel_modal: false,
                });
                this.setReadNotifications();
              }}
              style={{
                border: "1px solid #0b8bc1",
                backgroundColor: `${
                  notifications_confirm_modal === true ? "#0b8bc1" : "#fff"
                }`,
                color: `${
                  notifications_confirm_modal === true ? "#fff" : "#0b8bc1"
                }`,
              }}
            >
              <p
                style={{
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                Onaylandı
              </p>
              {this.state.notifications_confirm.filter(
                (notif) => notif.is_read === false
              ).length > 0 && (
                <span className="count">
                  {this.state.notifications_confirm.filter(
                    (notif) => notif.is_read === false
                  ).length ?? "0"}
                </span>
              )}
            </button>
            <button
              onClick={() => {
                this.setState({
                  notifications_cancel_modal: true,
                  notifications_modal: false,
                  notifications_confirm_modal: false,
                });
                this.setReadNotifications();
              }}
              style={{
                borderRight: "1px solid #9A2020",
                borderTop: "1px solid #9A2020",
                borderBottom: "1px solid #9A2020",
                backgroundColor: `${
                  notifications_cancel_modal === true ? "#9A2020" : "#fff"
                }`,
                color: `${
                  notifications_cancel_modal === true ? "#fff" : "#9A2020"
                }`,
              }}
            >
              <p
                style={{
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                İptal edildi
              </p>
              {this.state.notifications_cancel.filter(
                (notif) => notif.is_read === false
              ).length > 0 && (
                <span className="count">
                  {this.state.notifications_cancel.filter(
                    (notif) => notif.is_read === false
                  ).length ?? "0"}
                </span>
              )}
            </button>
          </HeaderButton>
          {notifications_modal && (
            <>
              {notifications.length > 0 ? (
                <ListOverflow>
                  {notifications.map((notification, index) => (
                    <NotificationCard key={index}>
                      {/*<pre>{JSON.stringify(notification, 0, 2)}</pre>*/}
                      <p>{notification.description ?? ""}</p>
                      <span className="sub" style={{ display: "flex" }}>
                        {moment(notification.created_at)
                          .local()
                          .format("DD/MM/YYYY HH:mm")}
                      </span>
                      {checkboxModal && (
                        <input
                          type="checkbox"
                          checked={
                            notificationId.findIndex(
                              (item) => item === notification.id
                            ) > -1
                          }
                          onChange={() => {
                            this.changeNotif(notification);
                          }}
                        />
                      )}
                    </NotificationCard>
                  ))}
                </ListOverflow>
              ) : (
                <NoDataContainer>
                  <img
                    src={require("../../assets/images/manypixels/click_action.svg")}
                    alt="Malesef bulunamadı!"
                  />
                  <p>{this.context.t("scaffold.notFoundNotification")}</p>
                </NoDataContainer>
              )}
            </>
          )}
          {notifications_confirm_modal && (
            <>
              {notifications_confirm.length > 0 ? (
                <ListOverflow>
                  {notifications_confirm.map((notification, index) => (
                    <NotificationCard key={index}>
                      {/*<pre>{JSON.stringify(notification, 0, 2)}</pre>*/}
                      <p>{notification.description ?? ""}</p>
                      <span className="sub" style={{ display: "flex" }}>
                        {moment(notification.created_at)
                          .local()
                          .format("DD/MM/YYYY HH:mm")}
                      </span>
                      {checkboxModal && (
                        <input
                          type="checkbox"
                          checked={
                            notificationId.findIndex(
                              (item) => item === notification.id
                            ) > -1
                          }
                          onChange={() => {
                            this.changeNotif(notification);
                          }}
                        />
                      )}
                    </NotificationCard>
                  ))}
                </ListOverflow>
              ) : (
                <NoDataContainer>
                  <img
                    src={require("../../assets/images/manypixels/click_action.svg")}
                    alt="Malesef bulunamadı!"
                  />
                  <p>{this.context.t("scaffold.notFoundNotification")}</p>
                </NoDataContainer>
              )}
            </>
          )}
          {notifications_cancel_modal && (
            <>
              {notifications_cancel.length > 0 ? (
                <ListOverflow>
                  {notifications_cancel.map((notification, index) => (
                    <NotificationCard key={index}>
                      {/*<pre>{JSON.stringify(notification, 0, 2)}</pre>*/}
                      <p>{notification.description ?? ""}</p>
                      <span className="sub" style={{ display: "flex" }}>
                        {moment(notification.created_at)
                          .local()
                          .format("DD/MM/YYYY HH:mm")}
                      </span>
                      {checkboxModal && (
                        <input
                          type="checkbox"
                          checked={
                            notificationId.findIndex(
                              (item) => item === notification.id
                            ) > -1
                          }
                          onChange={() => {
                            this.changeNotif(notification);
                          }}
                        />
                      )}
                    </NotificationCard>
                  ))}
                </ListOverflow>
              ) : (
                <NoDataContainer>
                  <img
                    src={require("../../assets/images/manypixels/click_action.svg")}
                    alt="Malesef bulunamadı!"
                  />
                  <p>{this.context.t("scaffold.notFoundNotification")}</p>
                </NoDataContainer>
              )}
            </>
          )}
          {checkboxModal && (
            <Button
              title="Sil"
              textColor="white"
              backgroundColor="red"
              size="sm"
              fullWidth
              onClick={() => {
                if (notificationId.length > 0) {
                  this.setState({
                    deleteModal: true,
                  });
                } else {
                  toast.warning("Lütfen Önce Bir Seçim Yapınız.");
                }
              }}
            />
          )}
          <AlertDialog
            disableBackdropClick={true}
            maxWidth="sm"
            fullWidth={false}
            title={
              <>
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Uyarı
                </span>
              </>
            }
            buttons={[
              {
                title: "İptal",
                icon: "close",
                backgroundColor: "red-opacity",
                textColor: "red",
              },
              {
                title: "Sil",
                icon: "check",
                backgroundColor: "green-opacity",
                textColor: "green",
                onClick: () => {
                  this.deleteNotification();
                },
              },
            ]}
            open={this.state.deleteModal}
            closeHandler={() =>
              this.setState({
                deleteModal: false,
              })
            }
          >
            <Alert severity="warning">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {notificationId.length === 0
                  ? `Tüm bildirimler kalıcı alarak silinecektir. Bu işlem geri
                      alınamaz. Emin misiniz?`
                  : `Seçtiğiniz bildirimler kalıcı olarak silinecektir. Bu işlem geri
                      alınamaz. Emin misiniz?`}
              </p>
            </Alert>
          </AlertDialog>
        </BoxPopInner>
      </BoxPopContainer>
    );
  }
}

export default withRouter(NotificationsView);

const BoxPopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;

  position: fixed;
  z-index: 999;
  right: 168px;
  top: 66px;

  width: 380px;
  max-width: 80vw;

  background: transparent;
  opacity: 0;

  &.show {
    opacity: 1;
    visibility: visible;
    transition: backdrop-filter 0s, all ease 0.25s;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.25s;
  }

  @media only screen and (max-width: 576px) {
    right: 20px !important;
    max-width: calc(100vw - 40px) !important;
  }
`;

const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BoxPopCaption = styled.h4`
  font-weight: 600;
  letter-spacing: 0.25px;
  letter-spacing: 0.75px;
  font-size: 11px;
  color: #aaa;
  padding: 8px 0;
  margin: 0;
`;

const HeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 33.33%;
    height: 30px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;

    span.count {
      height: 20px;
      display: flex;
      padding: 0 6px;
      z-index: 1;
      position: absolute;
      flex-wrap: wrap;
      font-size: 0.75rem;
      min-width: 20px;
      box-sizing: border-box;
      transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      align-items: center;
      font-family: "Inter", sans-serif !important;
      font-weight: 500;
      line-height: 1;
      align-content: center;
      border-radius: 10px;
      flex-direction: row;
      justify-content: center;

      top: 0;
      right: 0;
      transform: scale(1) translate(50%, -50%);
      transform-origin: 100% 0%;
      background-color: #ff0000;
      color: #ffffff;
    }
  }
`;

const BoxPopInner = styled.div`
  width: 100%;
  padding: 8px 16px 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
`;

const ListOverflow = styled.div`
  max-height: 40vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    transition: all ease 0.35s;
  }

  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #d0d0d0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
    transition: all ease 0.35s;
  }
`;

const NotificationCard = styled.div`
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.025);
  border: 1px solid #fafafa;
  border-radius: 5px;
  margin: 0 0 8px;

  transition: all ease 0.5s;

  p {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    font-weight: 500;
    margin: 0 0 2px;
    white-space: pre-wrap;
  }
  span.sub {
    font-size: 12px;
    margin-right: 16px;
    display: inline-block;
    color: #606060;
  }

  span.leading {
    font-size: 11px;
    font-weight: 600;
    color: #606060;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;
