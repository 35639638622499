import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  receipts: {},
  receipts_records: [],
  receiptsAll: {},
};

const receiptSlice = createSlice({
  name: "receiptSlice",
  initialState,
  reducers: {
    showReceipt: (state, action) => {
      state.receipts = action.payload.receipts;
      state.receiptsAll = action.payload;
      state.receipts_records = action.payload.receipts.records;
    },
  },
});

export const { showReceipt } = receiptSlice.actions;
export default receiptSlice.reducer;
