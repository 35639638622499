import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import { toast } from "react-toastify";
import Button from "../../../theme/Button";
import styled from "styled-components";
import {
  AppBar,
  Box,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
} from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import { Update } from "@material-ui/icons";
import WPSettings from "../../../views/Authenticated/Sms/WPSettings";
import Template from "../../../views/Authenticated/Sms/Send/Template";

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index ? <Box p={3}>{children}</Box> : null;
}
class SmsSettings extends React.Component {
  static contextType = AppContext;

  state = {
    selectedTab: 0,
    // randevudan önce gönderilecek mesajların kaç saat önce gideceği belirlenir.
    reminder: 0,

    // doğum günü hatırlatması
    reminder_birthday: true,

    // müşteriye/Personel randevu hatırlatması gitsin/gitmesin
    reminder_customer: true,
    reminder_staff: true,

    // randevu tarihine 1den fazla gün var ise bir gün öncesinden mesaj gitsin/gitmesin
    reminder_day_ago_customer: true,
    reminder_day_ago_staff: true,

    // randevu oluşturulduğu esnada sms gitsin/gitmesin
    on_appointment_customer: true,
    on_appointment_staff: true,

    // dışarıdan yeni bir randevu talebi/isteği geldiğinde sms gitsin/gitmesin
    on_request_customer: true,
    on_request_staff: true,

    // randevu isteği onaylandığında sms gitsin/gitmesin
    approve_appointment_customer: true,
    approve_appointment_staff: true,

    // randevu isteği reddedildiğinde sms gitsin/gitmesin
    decline_appointment_customer: true,
    decline_appointment_staff: true,

    // aktif bir randevu iptal edildiğinde sms gitsin /gitmesin
    cancel_appointment_customer: true,
    cancel_appointment_staff: true,

    // müşteri kaydedildiğinde randevu linki gitsin/gitmesin
    on_customer_create: true,

    // randevu sonrası gitsin/gitmesin
    after_appointment: true,

    //Randevu Güncelleme Durumu
    update_appointment_customer: true,
    update_appointment_staff: true,

    //SMS'den Randevu Katılımı İçin Link Gönderimi
    is_link_included_in_reminder_customer: true,

    // Randevuya gelemeyen müşteriye 60. günde otomatik SMS hatırlatma
    reminder_noncomer_customer: true,

    // Randevu Sürükle Ve Bırak
    drag_and_drop_appointment_customer: true,
    // Sms Kısa Kod
    sms_short_code: true,

    // Online link seçeneği
    online_link: this.context.state.online_link,
  };

  getDetails = () => {
    Axios.get(`${this.context.api_endpoint}/company/get/message/settings`).then(
      (response) => {
        const {
          data: { data },
        } = response;

        if (response.status === 200) {
          delete data.id;
          delete data.company_id;
          // console.log(data);
          this.setState({ ...data });
        }
      }
    );
  };

  saveSmsSettings = () => {
    Axios.put(`${this.context.api_endpoint}/company/update/message/settings`, {
      ...this.state,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(this.context.t(`['sms/settings'].updateToast`));
          this.getDetails();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(
            "Uzun süreli randevu hatırlatma 24 saat kala sms gönderimi yapıyor, hatırlatma saatiniz güncelleyiniz veya uzun süreli randevuyu kapatınız.",
            {
              autoClose: 3000,
            }
          );
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getDetails();
  }
  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };
  render() {
    const HOCSettingBox = this._SettingsBoxBuilder;
    const HOCSettingBoxWithNoPermission =
      this._SettingsBoxBuilderWithNoPermission;
    const { selectedTab } = this.state;
    return (
      <AuthContainer limited_permission="sms">
        <AppBar
          style={{ marginBottom: "30px" }}
          position="sticky"
          color="default"
        >
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              key={0}
              label={this.context.t(`menuItems.sms.children.settings`)}
            />
            <Tab
              key={1}
              label={this.context.t(`menuItems.sms.children.wpSettings`)}
            />
            <Tab
              key={2}
              label={this.context.t(`menuItems.sms.children.template`)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={selectedTab} index={0}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            {" "}
            <Button
              onClick={() => {
                this.context.dispatch({
                  type: "LOG_TYPE",
                  payload: { log_type: 5 },
                });
                this.context.dispatch({
                  type: "LOG_ID",
                  payload: {
                    log_id: null,
                  },
                });
                this.props.history.push("/settings/logs");
              }}
              icon="receipt"
              title="SMS işlem kayıtlarına git "
            />
          </div>
          <Grid>
            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].reminder.title`),
                description: this.context.t(
                  `['sms/settings'].reminder.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "reminder_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "reminder_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(
                  `['sms/settings'].longTermReminder.title`
                ),
                description: this.context.t(
                  `['sms/settings'].longTermReminder.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "reminder_day_ago_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "reminder_day_ago_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].requestInfo.title`),
                description: this.context.t(
                  `['sms/settings'].requestInfo.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "on_request_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "on_request_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].whenApproved.title`),
                description: this.context.t(
                  `['sms/settings'].whenApproved.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "approve_appointment_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "approve_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].whenRejected.title`),
                description: this.context.t(
                  `['sms/settings'].whenRejected.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "decline_appointment_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "decline_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].whenCreated.title`),
                description: this.context.t(
                  `['sms/settings'].whenCreated.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "on_appointment_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "on_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].whenCancelled.title`),
                description: this.context.t(
                  `['sms/settings'].whenCancelled.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "cancel_appointment_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "cancel_appointment_staff",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].whenBirthday.title`),
                description: this.context.t(
                  `['sms/settings'].whenBirthday.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "reminder_birthday",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(
                  `['sms/settings'].afterAppointment.title`
                ),
                description: this.context.t(
                  `['sms/settings'].afterAppointment.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "after_appointment",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].dragAndDrop.title`),
                description: this.context.t(
                  `['sms/settings'].dragAndDrop.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "drag_and_drop_appointment_customer",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(
                  `['sms/settings'].appointmentUpdate.title`
                ),
                description: this.context.t(
                  `['sms/settings'].appointmentUpdate.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].customerSwitchTitle`),
                  key: "update_appointment_customer",
                },
                {
                  label: this.context.t(`['sms/settings'].staffSwitchTitle`),
                  key: "update_appointment_staff",
                },
              ]}
            />

            {(this.context.current_flag &
              this.context.state.FLAGS.SALON_RANDEVU && (
              <HOCSettingBox
                boxProps={{
                  title: this.context.t(
                    `['sms/settings'].reminderNoncomerCustomer.title`
                  ),
                  description: this.context.t(
                    `['sms/settings'].reminderNoncomerCustomer.description`
                  ),
                }}
                optionList={[
                  {
                    label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                    key: "reminder_noncomer_customer",
                  },
                ]}
              />
            )) ||
              null}

            {this.state.online_link ? (
              <HOCSettingBox
                boxProps={{
                  title: this.context.t(
                    `['sms/settings'].smsAttendedApproval.title`
                  ),
                  description: this.context.t(
                    `['sms/settings'].smsAttendedApproval.description`
                  ),
                }}
                optionList={[
                  {
                    label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                    key: "is_link_included_in_reminder_customer",
                  },
                ]}
              />
            ) : (
              <HOCSettingBoxWithNoPermission
                boxProps={{
                  title: this.context.t(
                    `['sms/settings'].smsAttendedApproval.title`
                  ),
                  description: this.context.t(
                    `['sms/settings'].smsAttendedApproval.description`
                  ),
                }}
                optionList={[
                  {
                    label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                    key: "is_link_included_in_reminder_customer",
                  },
                ]}
              />
            )}

            <HOCSettingBox
              boxProps={{
                title: this.context.t(
                  `['sms/settings'].whenCustomerCreate.title`
                ),
                description: this.context.t(
                  `['sms/settings'].whenCustomerCreate.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "on_customer_create",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].smsShortcode.title`),
                description: this.context.t(
                  `['sms/settings'].smsShortcode.description`
                ),
              }}
              optionList={[
                {
                  label: this.context.t(`['sms/settings'].onOffSwitchTitle`),
                  key: "sms_short_code",
                },
              ]}
            />

            <HOCSettingBox
              boxProps={{
                title: this.context.t(`['sms/settings'].reminderHour.title`),
                description: this.context.t(
                  `['sms/settings'].reminderHour.descripton`
                ),
              }}
            >
              <Select
                className="mb-0"
                label={this.context.t(
                  `['sms/settings'].reminderHour.inputLabel`
                )}
                items={[
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[0]`
                    ),
                    value: "0",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[1]`
                    ),
                    value: "1",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[2]`
                    ),
                    value: "2",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[3]`
                    ),
                    value: "3",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[4]`
                    ),
                    value: "4",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[5]`
                    ),
                    value: "5",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[6]`
                    ),
                    value: "6",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[7]`
                    ),
                    value: "7",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[8]`
                    ),
                    value: "8",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[9]`
                    ),
                    value: "9",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[10]`
                    ),
                    value: "10",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[11]`
                    ),
                    value: "11",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[12]`
                    ),
                    value: "12",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[13]`
                    ),
                    value: "13",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[14]`
                    ),
                    value: "14",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[15]`
                    ),
                    value: "15",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[16]`
                    ),
                    value: "16",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[17]`
                    ),
                    value: "17",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[18]`
                    ),
                    value: "18",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[19]`
                    ),
                    value: "19",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[20]`
                    ),
                    value: "20",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[21]`
                    ),
                    value: "21",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[22]`
                    ),
                    value: "22",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[23]`
                    ),
                    value: "23",
                  },
                  {
                    label: this.context.t(
                      `['sms/settings'].reminderHour.options[24]`
                    ),
                    value: "24",
                  },
                ]}
                selected={`${this.state.reminder}`}
                labelKey="label"
                valueKey="value"
                handler={(reminder) =>
                  this.setState({ reminder: parseInt(reminder) })
                }
              />
            </HOCSettingBox>

            <Column className="xs-12 sm-12">
              <Button
                iconComponent={Update}
                title={this.context.t(`['sms/settings'].updateButtonTitle`)}
                backgroundColor="primary"
                textColor="white"
                fullWidth
                onClick={() => this.saveSmsSettings()}
              />
            </Column>
          </Grid>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <WPSettings />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <Template />
        </TabPanel>
      </AuthContainer>
    );
  }

  _SettingsBoxBuilderWithNoPermission = ({
    children,
    boxProps,
    optionList,
  }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                border: "1px solid rgba(255,193,0)",
                padding: "10px",
                backgroundColor: "rgba(255,193,0, 0.1)",
                borderRadius: "5px",
                color: "rgb(255,154,0)",
              }}
            >
              {this.context.t(`['sms/settings'].padiContent`)}
            </p>
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  _SettingsBoxBuilder = ({ children, boxProps, optionList }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {optionList?.map((item) => (
              <FormControlLabel
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                label={<FCLabel>{item.label}</FCLabel>}
                control={
                  <Switch
                    color="primary"
                    checked={this.state[item.key]}
                    onChange={(e) =>
                      this.setState({ [item.key]: !this.state[item.key] })
                    }
                  />
                }
              />
            ))}
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };

  _SettingsBoxBuilderWithNoPermission = ({
    children,
    boxProps,
    optionList,
  }) => {
    return (
      <Column className="xs-12 sm-12 md-4">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem) => {
              return <p className="mb-0">{descItem}</p>;
            })
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                border: "1px solid rgba(255,193,0)",
                padding: "10px",
                backgroundColor: "rgba(255,193,0, 0.1)",
                borderRadius: "5px",
                color: "rgb(255,154,0)",
              }}
            >
              {this.context.t(`['sms/settings'].padiContent`)}
            </p>
          </div>
          {children}
        </SettingsBox>
      </Column>
    );
  };
}

export default SmsSettings;

const SettingsBox = styled.div`
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;

  padding: 16px;
  margin: 0 0 16px;
  box-sizing: border-box;

  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);

  h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    color: #808080;
    font-size: 12.6px;
    line-height: 1.614;
    margin: 0 0 8px;
  }
`;
