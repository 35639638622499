import React, { useContext, useState } from "react";
import AppContext from "../../../context/store";

import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import { Grid, Column } from "../../../theme/Grid";
import AuthContainer from "../../../components/AuthContainer";
import Validate from "../../../functions/Validate";
import Axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";

const Referance = () => {
  const context = useContext(AppContext);
  const [form, setForm] = useState({
    company_name: null,
    customer_name: null,
    customer_mail: null,
    customer_phone: null,
  });

  const insertDealer = () => {
    validateForm() &&
      Axios.post(`${context.api_endpoint}/reference`, {
        ...form,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.data);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        })
        .finally(() =>
          setForm({
            company_name: "",
            customer_name: "",
            customer_mail: "",
            customer_phone: "",
          })
        );
  };

  const validateForm = () => {
    return Validate([
      {
        field: "İşletme Adı",
        value: form.company_name,
        condition: /\w+/,
      },
      {
        field: "Yetkili Adı ve Soyadı",
        value: form.customer_name,
        condition: /\w+/,
      },
      {
        field: "Telefon Numarası",
        value: form.customer_phone,
        condition: /\S{10}/,
      },
      {
        field: "E-Posta Adresi",
        value: form.customer_mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
    ]);
  };

  return (
    <AuthContainer
      // authorities={[1]}
      // makePlanUpgrade={[2]}
      limited_permission="system"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-6">
          <PlatformImage
            src={require("../../../assets/images/referance.jpg")}
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-6">
          <Input
            required
            label={context.t(`referance.createReferance.companyNameInputLabel`)}
            value={form.company_name}
            onChange={(e) => setForm({ ...form, company_name: e.target.value })}
          />
          <Input
            required
            label={context.t(`referance.createReferance.staffNameInputLabel`)}
            value={form.customer_name}
            onChange={(e) =>
              setForm({
                ...form,
                customer_name: e.target.value,
              })
            }
          />
          <Input
            required
            label={context.t(`referance.createReferance.commonMailInputLabel`)}
            value={form.customer_mail}
            onChange={(e) => {
              setForm({
                ...form,
                customer_mail: e.target.value,
              });
            }}
          />
          <MaskedInput
            required
            label={context.t(`referance.createReferance.commonPhoneInputLabel`)}
            maskChar={""}
            mask="999 999 9999"
            startAdornment="0"
            value={form.customer_phone}
            placeholder="500 000 0000"
            onChange={(e) => {
              setForm({
                ...form,
                customer_phone:
                  e.target.value.substring(0, 1) === "5" &&
                  e.target.value.replace(/\s/g, ""),
              });
            }}
          />

          <Button
            title={context.t(`referance.createReferanceButtonTitle`)}
            type="submit"
            size="md"
            fullWidth
            textColor="white"
            backgroundColor="primary"
            onClick={() => insertDealer()}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default Referance;

const PlatformImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: contain;
`;
