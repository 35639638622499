import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function AutoCompleteInputWithoutEndPoint({
  items,
  label,
  handlerPackageSelect, // props handler function
  component,
  value,
  selected,
}) {
  const handlePackageChange = (_, __package) => {
    component === "PackageAdd"
      ? handlerPackageSelect(__package)
      : handlerPackageSelect(_, __package);
  };
  return selected ? (
    <Autocomplete
      id="combo-box-demo"
      value={value ? items.filter((m) => m.id === value)[0] : ""}
      options={items}
      getOptionLabel={(option) => option.name}
      disableClearable
      onChange={handlePackageChange}
      defaultValue={value}
      renderInput={(params) => (
        <TextField placeholder={label} {...params} variant="outlined" />
      )}
    />
  ) : (
    <Autocomplete
      id="combo-box-demo"
      options={items}
      getOptionLabel={(option) => option.name}
      disableClearable
      onChange={handlePackageChange}
      renderInput={(params) => (
        <TextField placeholder={label} {...params} variant="outlined" />
      )}
    />
  );
}

export default AutoCompleteInputWithoutEndPoint;
