import React, { useState } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import styled from "styled-components";

import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Button from "../../../theme/Button";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import {
  Backdrop,
  ButtonBase,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Select from "../../../theme/CustomMUI/Select";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { flushSync } from "react-dom";
import Switch from "@material-ui/core/Switch";

class ServicesAdd extends React.Component {
  static contextType = AppContext;

  state = {
    new_service: {
      amount: null,
      bounty: 0,
      point: 0,
      process_time: null,
      repeat: null,
      show_at_url: true,
      staffs: [],
      description: null,
    },

    serviceCategories: [],
    serviceNames: [],
    selectedCategory: null,
    selectedServiceName: null,
    categoryId: null,

    selecetAllEmployees: true,

    staffs: {
      // CUSTOMER_ID: { ...customer, active: true },
    },

    isInsertServiceButtonClicked: false, // Control that button is checked to stop sending too request.

    openNewServiceDialog: false,
    moneyOrPercent: false,
  };

  insertService = () => {
    this.validateService() &&
      Axios.post(`${this.context.api_endpoint}/company/service/insert`, {
        ...this.state.new_service,
        staffs:
          Object.keys(this.state.staffs) &&
          Object.keys(this.state.staffs).length > 0
            ? Object.keys(this.state.staffs)
                .map((key) =>
                  this.state.staffs[key].active
                    ? this.state.staffs[key].id
                    : undefined
                )
                .filter((item) => item !== undefined)
            : [],
        platform_service_id: this.state.selectedServiceName
          ? this.state.selectedServiceName.id
          : null,
        type: !this.state.moneyOrPercent ? 1 : null,
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(this.context.t(`['services/detail'].insertToast`));
            this.props.history.push("/services");
            //console.log(response.data)
          }
          this.setState({
            isInsertServiceButtonClicked: false,
          });
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
          this.setState({
            isInsertServiceButtonClicked: false,
          });
        });
  };

  getStaffs = () => {
    Axios.get(`${this.context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        if (response.data.data && response.data.data.length > 0)
          this.setState({
            staffs: Object.assign(
              ...response.data.data.map((staff) => ({
                [staff.id]: { ...staff, active: true },
              })),
              {}
            ),
          });
      }
    );
  };
  getServiceCategories = async (key) => {
    key.length > 0
      ? await Axios.get(
          `${this.context.api_endpoint}/company/services/category/list/all`,
          {
            params: {
              name: key === " " ? "" : key,
            },
          }
        )
          .then(({ data }) => {
            flushSync(() => {
              this.setState({ serviceCategories: [...data.data] });
            });
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            } else {
              toast.warning(e.response.data.message);
            }
          })
      : this.setState({ serviceCategories: [] });

    return this.state.serviceCategories;
  };

  getServiceNames = async (key) => {
    key.length > 0
      ? await Axios.get(
          `${this.context.api_endpoint}/company/platform/services/list/all`,
          {
            params: {
              categoryid: this.state.categoryId,
              name: key === " " ? "" : key,
            },
          }
        ).then((response) => {
          const { data } = response;
          flushSync(() => {
            this.setState({ serviceNames: [...data.data] });
          });
        })
      : this.setState({ serviceNames: [] });

    return this.state.serviceNames;
  };

  componentDidMount() {
    this.getStaffs();
  }

  render() {
    const { new_service } = this.state;
    return (
      <AuthContainer authorities={[1]} limited_permission="services">
        <Grid className="no-gutters-all">
          <Grid className="xs-12 sm-12 md-12 lg-12 mb-2">
            {<this.ServiceInformation />}
          </Grid>
          <Column className="xs-12 sm-12 md-12 lg-9">
            <Grid>
              <Column className="xs-12 sm-12 md-6">
                <AutocompleteInput
                  required
                  label={this.context.t(
                    `['services/detail'].serviceCategoryInput`
                  )}
                  //helperText={this.context.t(`['services/detail'].serviceCategoryHint`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].serviceCategoryHint`
                  )}
                  labelKey="name"
                  valueKey="id"
                  returnFullObject
                  onRemove={() => {
                    this.setState({
                      selectedCategory: null,
                      selectedServiceName: null,
                      categoryId: null,
                    });
                    this.getServiceCategories("");
                  }}
                  selected={
                    this.state.selectedCategory !== null
                      ? this.state.selectedCategory
                      : ""
                  }
                  selectedHandler={(selected_category) =>
                    this.setState({
                      selectedCategory: selected_category,
                      categoryId: selected_category.id,
                    })
                  }
                  asyncDataService={async (keyword) =>
                    this.getServiceCategories(keyword)
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-6">
                <AutocompleteInput
                  required
                  disabled={this.state.selectedCategory ? false : true}
                  label={this.context.t(`['services/detail'].serviceNameInput`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].serviceNameHint`
                  )}
                  labelKey="name"
                  valueKey="id"
                  returnFullObject
                  onRemove={() => {
                    this.setState({ selectedServiceName: null });
                    this.getServiceNames("");
                  }}
                  selected={
                    this.state.selectedServiceName !== null
                      ? this.state.selectedServiceName
                      : ""
                  }
                  selectedHandler={(selected_category_name) =>
                    this.setState({
                      selectedServiceName: selected_category_name,
                    })
                  }
                  asyncDataService={async (keyword) =>
                    this.getServiceNames(keyword)
                  }
                />
              </Column>
            </Grid>

            <Grid>
              <Column className="xs-12 sm-12 md-6">
                <Input
                  required
                  label={
                    this.context.t(`['services/detail'].amountInputLabel`) +
                    (this.context.state.currency
                      ? " (" + this.context.state.currency + ")"
                      : "(₺)")
                  }
                  value={new_service.amount ?? ""}
                  //helperText={this.context.t(`['services/detail'].amountInputHint`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].amountInputHint`
                  )}
                  type="number"
                  InputProps={{
                    min: 0,
                    step: 0.1,
                  }}
                  onChange={(e) =>
                    this.setState({
                      new_service: {
                        ...new_service,
                        amount:
                          e.target.value.length > 0
                            ? Math.round(parseFloat(e.target.value).toFixed(2))
                            : null,
                      },
                    })
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-6">
                <span style={{ fontSize: "12px", opacity: "0.8" }}>
                  {this.context.state.currency
                    ? "(" + this.context.state.currency + ")"
                    : "(₺)"}{" "}
                  Bazında
                </span>
                <Switch
                  checked={this.state.moneyOrPercent ? true : false}
                  onChange={() => {
                    this.setState({
                      moneyOrPercent: !this.state.moneyOrPercent,
                      new_service: {
                        ...this.state.new_service,
                        bounty: 0,
                      },
                    });
                  }}
                  color="default"
                  inputProps={{
                    "aria-label": "checkbox with default color",
                  }}
                />
                <span style={{ fontSize: "12px", opacity: "0.8" }}>
                  Yüzde Bazında
                </span>
                <MaskedInput
                  label={
                    this.state.moneyOrPercent
                      ? this.context.t(`['services/detail'].bountyInputLabel`)
                      : `Prim Tutarı ${
                          this.context.state.currency
                            ? " (" + this.context.state.currency + ")"
                            : "(₺)"
                        }`
                  }
                  value={new_service.bounty ?? 0}
                  //helperText={this.context.t(`['services/detail'].bountyInputHint`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].bountyInputHint`
                  )}
                  mask={this.state.moneyOrPercent ? "99" : "99999999999"}
                  maskChar=""
                  onChange={(e) =>
                    this.setState({
                      new_service: {
                        ...new_service,
                        bounty: e.target.value ? parseInt(e.target.value) : 0,
                      },
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-6">
                <MaskedInput
                  label={this.context.t(`['services/detail'].pointInputLabel`)}
                  value={new_service.point ?? ""}
                  //helperText={this.context.t(`['services/detail'].pointInputHint`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].pointInputHint`
                  )}
                  mask="99999"
                  maskChar=""
                  onChange={(e) =>
                    this.setState({
                      new_service: {
                        ...new_service,
                        point: e.target.value ? parseInt(e.target.value) : null,
                      },
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-6">
                <MaskedInput
                  required
                  label={this.context.t(`['services/detail'].timeInputLabel`)}
                  value={new_service.process_time ?? ""}
                  //helperText={this.context.t(`['services/detail'].timeInputHint`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].timeInputHint`
                  )}
                  mask="999"
                  maskChar=""
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      new_service: {
                        ...new_service,
                        process_time: e.target.value
                          ? parseInt(e.target.value)
                          : null,
                      },
                    })
                  }
                />
              </Column>
            </Grid>

            <Grid>
              <Column className="xs-12 sm-12 md-6">
                <MaskedInput
                  label={this.context.t(`['services/detail'].repeatInputLabel`)}
                  value={new_service.repeat ?? ""}
                  //helperText={this.context.t(`['services/detail'].repeatInputHint`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].repeatInputHint`
                  )}
                  mask="999"
                  maskChar=""
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      new_service: {
                        ...new_service,
                        repeat: e.target.value
                          ? parseInt(e.target.value)
                          : null,
                      },
                    })
                  }
                />
              </Column>
              <Column className="xs-12 sm-12 md-6">
                <Select
                  label={this.context.t(`['services/detail'].urlInputLabel`)}
                  //helperText={this.context.t(`['services/detail'].urlInputHint`)}
                  hoverHelperText={this.context.t(
                    `['services/detail'].urlInputHint`
                  )}
                  items={[
                    {
                      label: this.context.t(`['services/detail'].urlInputTrue`),
                      value: true,
                    },
                    {
                      label: this.context.t(
                        `['services/detail'].urlInputFalse`
                      ),
                      value: false,
                    },
                  ]}
                  labelKey="label"
                  valueKey="value"
                  selected={`${new_service.show_at_url}`}
                  handler={(show_at_url) =>
                    this.setState({
                      new_service: {
                        ...new_service,
                        show_at_url: show_at_url === "true",
                      },
                    })
                  }
                />
              </Column>

              <Column className="xs-12 sm-12 md-6">
                <Input
                  label={this.context.t(
                    `['services/detail'].serviceDescriptionLabel`
                  )}
                  value={new_service.description ?? ""}
                  hoverHelperText={this.context.t(
                    `['services/detail'].serviceDescriptionHint`
                  )}
                  onChange={(e) =>
                    this.setState({
                      new_service: {
                        ...new_service,
                        description: e.target.value,
                      },
                    })
                  }
                />
              </Column>
            </Grid>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-3">
            <StaffBox>
              <SectionTitle>
                {this.context.t(`['services/detail'].staffSectionTitle`)}
              </SectionTitle>
              <FormControlLabel
                checked={this.state.selecetAllEmployees}
                control={<Checkbox color="primary" size="small" />}
                label={this.context.t(`['services/detail'].selcetAllEmployees`)}
                onChange={(e) => {
                  // console.log(this.state.staffs);
                  if (e.target.checked) {
                    this.setState({
                      selecetAllEmployees: e.target.checked,
                      staff: {
                        ...Object.keys(this.state.staffs).map((key) => {
                          const staff = this.state.staffs[key];
                          staff.active = true;
                          return { [staff.id]: { ...staff } };
                        }),
                      },
                    });
                  } else {
                    this.setState({
                      selecetAllEmployees: e.target.checked,
                      staff: {
                        ...Object.keys(this.state.staffs).map((key) => {
                          const staff = this.state.staffs[key];
                          staff.active = false;
                          return { [staff.id]: { ...staff } };
                        }),
                      },
                    });
                  }
                  // console.log(this.state.staffs);
                }}
              />
              <StaffListBox>
                {Object.keys(this.state.staffs) &&
                  Object.keys(this.state.staffs).length > 0 &&
                  Object.keys(this.state.staffs).map((key, index) => (
                    <this.StaffCheckbox
                      key={index}
                      _key={key}
                      staff={this.state.staffs[key]}
                    />
                  ))}
              </StaffListBox>
            </StaffBox>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12">
            <Button
              icon="check"
              title={this.context.t(`['services/detail'].createButtonTitle`)}
              textColor="white"
              backgroundColor="primary"
              fullWidth
              disabled={this.state.isInsertServiceButtonClicked}
              onClick={() => {
                this.setState({
                  isInsertServiceButtonClicked: true,
                });
                setTimeout(() => {
                  this.setState({ isInsertServiceButtonClicked: false });
                }, 1000);
                this.insertService();
              }}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }

  validateService = () => {
    const { new_service } = this.state;

    return Validate([
      {
        field: "Hizmet Kategorisi",
        value: this.state.selectedCategory,
        condition: Object,
      },
      {
        field: "Hizmet Adı",
        value: this.state.selectedServiceName,
        condition: Object,
      },
      /* {
        field: "Hizmet Adı",
        value: new_service.name,
        condition: /.+/
      }, */
      {
        field: "Hizmet Ücreti",
        value: new_service.amount,
        condition: Number,
      },
      {
        field: "İşlem Süresi",
        value: new_service.process_time,
        condition: Number,
      },
    ]);
  };

  StaffCheckbox = ({ staff, _key }) => (
    <ButtonBase
      style={{ width: "100%", marginBottom: 4 }}
      onClick={() =>
        this.setState({
          staffs: {
            ...this.state.staffs,
            [_key]: {
              ...this.state.staffs[_key],
              active: !this.state.staffs[_key].active,
            },
          },
        })
      }
    >
      <StaffButtonContainer
        className={staff.active ? "fc-blue bg-blue-opacity" : ""}
        style={{
          borderRadius: 5,
          border: "1px solid",
          borderColor: staff.active ? "rgba(49, 137, 236, 0.1)" : "transparent",
        }}
      >
        <i className="material-icons">
          {staff.active ? "check_box" : "check_box_outline_blank"}
        </i>
        <p style={{ width: "250px" }}>
          {staff.name} {staff.surname}
        </p>
        <StaffPhotoContainer>
          <StaffPhoto
            src={
              staff.detail.profile_photo
                ? `${this.context.api_endpoint}/${this.context.state.company_id}/${staff.detail.profile_photo}`.replace(
                    "api/v1/",
                    ""
                  )
                : require("../../../assets/images/profile_photo.svg")
            }
            alt={`${staff.name} ${staff.surname}`}
          />
        </StaffPhotoContainer>
      </StaffButtonContainer>
    </ButtonBase>
  );

  ServiceInformation = () => {
    const NewServiceDialog = this.RequestDialog;

    const requestDialogHandler = () => {
      this.setState({ openNewServiceDialog: true });
    };

    return (
      <ServiceInformationContainer>
        <p>
          {this.context.t(`[services/detail].serviceName`)}{" "}
          <span style={{ borderBottom: "1px solid black" }}>
            {this.context.t(`[services/detail].lookingFor`)}
          </span>{" "}
          {this.context.t(`[services/detail].ourList`)}{" "}
          <span className="button" onClick={requestDialogHandler}>
            {this.context.t(`[services/detail].createRequest`)}
          </span>
        </p>

        <NewServiceDialog />
      </ServiceInformationContainer>
    );
  };

  RequestDialog = () => {
    const [newServiceCategory, setNewServiceCategory] = useState(null);
    const [newServiceTitle, setNewServiceTitle] = useState(null);

    const closeDialogHandler = () => {
      this.setState({ openNewServiceDialog: false });
    };

    const cancelRequest = () => {
      this.setState({ openNewServiceDialog: false });
      setNewServiceTitle(null);
    };

    const makeNewServiceRequest = () => {
      if (newServiceTitle === null) {
        this.setState({ openNewServiceDialog: true });
        toast.error(
          this.context.t(`['services/detail'].newServiceRequestErrorToast`)
        );
      } else {
        // Axios
        // Close dialog after a success process.
        // Make newServiceCategory and newServiceTitle null.
        Axios.post(
          `${this.context.api_endpoint}/company/services/request/insert`,
          {
            name: newServiceTitle,
            categories_id: newServiceCategory ? newServiceCategory.id : null,
          }
        )
          .then(({ data }) => {
            toast.success(
              this.context.t(`['services/detail'].newServiceRequestToast`)
            );
            this.setState({ openNewServiceDialog: false });
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            } else {
              toast.warning(e.response.data.message);
            }
          })
          .finally(() => {
            setNewServiceCategory(null);
            setNewServiceTitle(null);
          });
      }
    };

    return (
      <AlertDialog
        title={this.context.t(
          `['services/detail'].newServiceRequestDialogTitle`
        )}
        buttons={[
          {
            title: this.context.t(
              `['services/detail'].cancelNewServiceRequest`
            ),
            icon: "close",
            backgroundColor: "white",
            textColor: "grey",
            onClick: () => {
              cancelRequest();
            },
          },
          {
            title: this.context.t(
              `['services/detail'].createNewServiceRequest`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              makeNewServiceRequest();
            },
          },
        ]}
        fullWidth
        disableBackdropClick={false}
        maxWidth={"sm"}
        open={this.state.openNewServiceDialog}
        closeHandler={closeDialogHandler}
        containerStyle={{ overflow: "visible" }}
        overflowContent={false}
      >
        <div>
          <AutocompleteInput
            required
            label={this.context.t(`['services/detail'].serviceCategoryInput`)}
            //helperText={this.context.t(`['services/detail'].serviceCategoryHint`)}
            hoverHelperText={this.context.t(
              `['services/detail'].newServiceCategoryHint`
            )}
            labelKey="name"
            valueKey="id"
            returnFullObject
            onRemove={async () => {
              setNewServiceCategory(null);
            }}
            selected={newServiceCategory || null}
            selectedHandler={async (selected_category) =>
              setNewServiceCategory(selected_category)
            }
            asyncDataService={async (keyword) =>
              this.getServiceCategories(keyword)
            }
          />

          <Input
            required
            label={this.context.t(`['services/detail'].newServiceInputTitle`)}
            value={newServiceTitle !== null ? newServiceTitle : ""}
            onChange={(e) => setNewServiceTitle(e.target.value)}
          />
        </div>
      </AlertDialog>
    );
  };
}

export default ServicesAdd;

const StaffBox = styled.div`
  width: 100%;
  padding: 12px; 
  box-sizing: border-box; 
  border-radius: 5px; 
  margin-bottom: 24px;
  box-shadow 0 2px 12px rgba(0,0,0,0.04);
`;

const StaffListBox = styled.div`
  width: 100%;
  height: 300px;
  padding-right: 8px;
  box-sizing: border-box;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    transition: all ease 0.35s;
  }

  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #d0d0d0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
    transition: all ease 0.35s;
  }
`;

const StaffButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 12px;

  box-sizing: border-box;
  text-align: left;

  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
`;

const StaffPhotoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StaffPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  object-fit: cover;
`;

const SectionTitle = styled.h3`
  font-size: 15px;
  padding: 0;
  margin: 0 0 8px;
`;

const ServiceInformationContainer = styled.div`
  width: 100%;
  background: #fff4e5;
  padding: 10px;
  boxsizing: border-box;
  color: #995a1f;
  border: 1px solid #ccc;

  .button {
    color: #3189ec;
    font-weight: bold;
    cursor: pointer;
  }
`;
