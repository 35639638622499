import React from "react";

export const Grid = (props) => {
  return (
    <div
      id={props.id}
      style={props.style ? props.style : null}
      className={`row${props.className ? ` ${props.className}` : ""}`}
    >
      {props.children}
    </div>
  );
};

export const Container = (props) => {
  return (
    <div className={"container"} style={props.style}>
      {props.children}
    </div>
  );
};

export const Column = (props) => {
  return (
    <div
      className={`col${props.className ? ` ${props.className}` : ""}`}
      style={props.style ? props.style : {}}
      ref={props.ref}
    >
      {props.children}
    </div>
  );
};
