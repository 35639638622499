import React from "react";
import { SvgIcon } from "@material-ui/core";

function LocalMall() {
  return (
    <SvgIcon
      width="28"
      height="36"
      viewBox="0 0 28 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.86">
        <path d="M12.762 0.724072C11.3166 0.980743 9.9922 1.69605 8.98499 2.76408C8.35347 3.41651 7.85708 4.18727 7.52432 5.03212C7.19157 5.87696 7.02902 6.7792 7.046 7.68705V8.40107H5.618C4.71672 8.28009 3.79973 8.42538 2.98 8.81907C2.16768 9.29604 1.56335 10.0598 1.286 10.9601C1.2 11.2481 0.269999 30.1831 0.269999 31.5741C0.265107 31.9392 0.298623 32.3039 0.369997 32.6621C0.545948 33.2713 0.882284 33.8222 1.34387 34.257C1.80545 34.6918 2.37534 34.9948 2.994 35.1341C3.535 35.2491 24.394 35.2491 24.935 35.1341C25.5547 34.9971 26.1259 34.695 26.5878 34.2598C27.0498 33.8246 27.3854 33.2725 27.559 32.6621C27.6304 32.3039 27.6639 31.9392 27.659 31.5741C27.659 30.1831 26.729 11.2471 26.643 10.9591C26.3656 10.0588 25.7613 9.29503 24.949 8.81807C24.1313 8.42579 23.217 8.28053 22.318 8.40007H20.907L20.864 7.40507C20.8603 6.07324 20.4531 4.77376 19.696 3.67805C18.9439 2.57388 17.8909 1.70867 16.6618 1.18507C15.4328 0.661466 14.0793 0.501472 12.762 0.724072ZM14.939 3.03108C15.6037 3.18205 16.2274 3.4764 16.7665 3.89354C17.3056 4.31068 17.747 4.84052 18.06 5.44606C18.4223 6.152 18.6008 6.93788 18.579 7.73106V8.40107H9.352V7.73106C9.32946 7.09399 9.43881 6.45914 9.67319 5.86631C9.90757 5.27349 10.2619 4.73548 10.714 4.28605C11.2558 3.73854 11.9268 3.33645 12.6651 3.11692C13.4034 2.89739 14.1851 2.86752 14.938 3.03007L14.939 3.03108ZM23.646 10.7361C23.7927 10.7803 23.9286 10.8544 24.0453 10.9537C24.162 11.053 24.2569 11.1753 24.324 11.3131C24.41 11.4861 24.524 13.3821 24.901 21.2601C25.153 26.6161 25.348 31.2721 25.341 31.6111C25.319 32.3251 25.204 32.5341 24.736 32.7641C24.448 32.9081 24.325 32.9081 13.967 32.9081C3.609 32.9081 3.486 32.9081 3.198 32.7641C2.722 32.5261 2.614 32.3241 2.593 31.5751C2.586 31.2151 2.78 26.5751 3.033 21.2601C3.545 10.5851 3.48699 11.2341 4.01299 10.9091L4.272 10.7431L11.772 10.7071C15.895 10.6851 20.191 10.6641 21.323 10.6571C22.0979 10.6013 22.8766 10.6278 23.646 10.7361Z" />
        <path d="M7.70825 13.302C7.14625 13.648 7.05926 14.196 7.40826 15.263C7.71796 16.2798 8.28976 17.1971 9.06625 17.923C9.94793 18.8664 11.0893 19.5275 12.3462 19.823C13.2911 20.0122 14.2614 20.0389 15.2153 19.902C16.2882 19.7126 17.3015 19.2733 18.1731 18.6196C19.0447 17.9658 19.7501 17.116 20.2323 16.139C20.5701 15.5176 20.7623 14.8275 20.7943 14.121C20.7659 13.8453 20.6325 13.5912 20.4217 13.4113C20.2109 13.2314 19.9389 13.1396 19.6623 13.155C19.3837 13.1455 19.1115 13.2398 18.8985 13.4195C18.6855 13.5993 18.5468 13.8518 18.5093 14.128C18.3538 14.7564 18.074 15.3474 17.6866 15.8661C17.2991 16.3847 16.8118 16.8206 16.2533 17.148C15.5516 17.5352 14.7624 17.7359 13.961 17.7309C13.1596 17.7258 12.3729 17.5151 11.6763 17.119C11.034 16.7024 10.4823 16.1606 10.0543 15.526C9.85559 15.1576 9.69155 14.7716 9.56425 14.373C9.34825 13.681 9.18226 13.429 8.80026 13.234C8.44158 13.1114 8.04896 13.1359 7.70825 13.302Z" />
      </g>
    </SvgIcon>
  );
}

export default LocalMall;
