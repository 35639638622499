import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import { toast } from "react-toastify";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { Alert } from "@material-ui/lab";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import styled from "styled-components";
import AllServicesDailog from "../../../components/Dialogs/AllServicesDialog";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "../../../assets/images/loader.gif";
import { Search } from "@material-ui/icons";

const ServicesIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [newServiceCategory, setNewServiceCategory] = useState(null);
  const [newServiceTitle, setNewServiceTitle] = useState(null);
  const [openNewServiceDialog, setOpenNewServiceDialog] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);

  const [loaded, setLoaded] = useState(false);

  const [filter_expanded, setFilterExpanded] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const [pagination, setPagination] = useState({
    total_page: null,
    page: null,
    onChange: (page) => getServices(page),
  });
  const [services, setServices] = useState([]);
  const [searched_services, setSearchedServices] = useState([]);

  const [delete_confirm, setDeleteConfirm] = useState(false);
  const [delete_id, setDeleteId] = useState(null);

  const [open_information_dialog, setOpenInformationDialog] = useState(false);
  const [open_delete_all_dialog, setOpenDeleteAllDialog] = useState(false);

  const [open_all_services_dialog, setOpenAllServicesDialog] = useState(false);

  const [selectedServices, setSelectedServices] = useState([]);

  const closeDialogHandler = () => {
    setOpenNewServiceDialog(false);
  };

  const cancelRequest = () => {
    setOpenNewServiceDialog(false);
    setNewServiceTitle(null);
    setNewServiceCategory(null);
  };

  const makeNewServiceRequest = () => {
    if (newServiceTitle === null) {
      setOpenNewServiceDialog(true);
      toast.error(context.t(`['services/detail'].newServiceRequestErrorToast`));
    } else {
      // Axios
      // Close dialog after a success process.
      // Make newServiceCategory and newServiceTitle null.
      Axios.post(`${context.api_endpoint}/company/services/request/insert`, {
        name: newServiceTitle,
        category: newServiceCategory,
      })
        .then(({ data }) => {
          toast.success(
            context.t(`['services/detail'].newServiceRequestToast`)
          );
          setOpenNewServiceDialog(false);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }
  };

  const getServiceCategories = async (key) => {
    key.length > 0
      ? await Axios.get(
          `${context.api_endpoint}/company/services/category/list/all`,
          {
            params: {
              name: key === " " ? "" : key,
            },
          }
        )
          .then(({ data }) => {
            setServiceCategories([...data.data]);
            // this.setState({ serviceCategories: [...data.data] });
          })
          .catch((e) => {
            if (e.response.status === 401) {
              toast.error(e.response.data.message);
            } else {
              toast.warning(e.response.data.message);
            }
          })
      : setServiceCategories([]);

    return serviceCategories;
  };

  const getServices = (page) => {
    const urlPage = parseInt(
      new URLSearchParams(window.location.search).get("page")
    );
    // console.log(urlPage);
    Axios.get(`${context.api_endpoint}/company/services`, {
      params: {
        page: page ? page : urlPage ? urlPage : 1,
      },
    })
      .then(async ({ data }) => {
        // console.log(data)
        setLoaded(true);
        setServices(data.data.records);
        setPagination({
          ...pagination,
          page: data.data.page,
          total_page: data.data.records.length,
        });
        setOpenAllServicesDialog(
          data.data.records.length < 3 && data.data.page === 1 ? true : false
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong!");
        setLoaded(true);
      });
  };

  const getCategories = useCallback(() => {
    Axios.get(`${context.api_endpoint}/company/services/with/category/all`)
      .then(async ({ data }) => {
        // console.log(data);
        setLoaded(true);
        setServices([...data.data.filter((m) => m.services.length !== 0)]);
        setSearchedServices([
          ...data.data.filter((m) => m.services.length !== 0),
        ]);
      })
      .catch((e) => {
        setLoaded(true);
        if (e.response.data.code === 401) {
          toast.warn(e.response.data.message);
          history.push("/wallet");
        }
      });
  }, [services, searched_services, loaded]);

  const searchServicesByKey = (key) => {
    let searchedServices = services.filter((category) =>
      category.services.find((service) =>
        service.name.toLowerCase().includes(key.toLowerCase())
      )
    );

    searchedServices.forEach((category) => {
      category.services = [
        ...category.services.filter((service) =>
          service.name.toLowerCase().includes(key.toLowerCase())
        ),
      ];
    });

    setSearchedServices([...searchedServices]);
  };

  const deteleServiceByID = (id) => {
    Axios.delete(`${context.api_endpoint}/company/service/delete/${id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`services.deleteToast`));
          getCategories(1);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const deleteSelectedServices = () => {
    setLoaded(false);
    Axios.post(`${context.api_endpoint}/company/service/delete/multiple`, {
      services_id: [...selectedServices],
    })
      .then(async (response) => {
        setLoaded(true);
        if (response.status === 200) {
          toast.success(context.t(`services.deleteAll.deleteAllServicesToast`));
          setSelectedServices([]);
          getCategories(1);
        }
      })
      .catch((e) => {
        setLoaded(true);
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const deleteAllServices = () => {
    Axios.post(
      `${context.api_endpoint}/company/service/delete/multiple`, // It will change.
      {
        services_id: [-1],
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          toast.success(context.t(`services.deleteAll.deleteAllServices`));
          setSelectedServices([]);
          getCategories(1);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const ServiceCategoryTab = () => {
    const [tab, setTab] = useState(0);
    return (
      <AppBar position="sticky" color="default">
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            setTab(newValue);
            document.getElementById("services-tab").scrollTo({
              top:
                document.getElementById(
                  searched_services[newValue].name.replace(" ", "-")
                ).offsetTop - 80,
              behavior: "smooth",
            });
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {searched_services.length > 0 &&
            searched_services.map((service) => (
              <Tab key={service.id} label={service.name} />
            ))}
        </Tabs>
      </AppBar>
    );
  };

  const InformationDialog = () => {
    return (
      <AlertDialog
        title="UYARI"
        open={open_information_dialog}
        fullWidth={true}
        buttons={[
          {
            title: context.t(
              `services.addAllService.informationDialog.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary",
            textColor: "white",
          },
        ]}
        closeHandler={() => {
          setOpenInformationDialog(false);
        }}
      >
        <p>
          {context.t(
            `services.addAllService.informationDialog.addServiceDescription`
          )}
        </p>
      </AlertDialog>
    );
  };

  const DeleteServicesDialog = () => {
    return (
      <AlertDialog
        title={context.t(
          `${
            selectedServices.length > 0
              ? "services.deleteSelectedServices.title"
              : "services.deleteAll.title"
          }`
        )}
        open={open_delete_all_dialog}
        closeHandler={() => setOpenDeleteAllDialog(false)}
        buttons={[
          {
            title: context.t(`services.deleteAll.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () =>
              selectedServices.length > 0
                ? deleteSelectedServices()
                : deleteAllServices(),
          },
          {
            title: context.t(`services.deleteAll.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>
            {context.t(
              `${
                selectedServices.length > 0
                  ? "services.deleteSelectedServices.alertBoldText"
                  : "services.deleteAll.alertBoldText"
              }`
            )}
          </b>
          <br />
          {context.t(
            `${
              selectedServices.length > 0
                ? "services.deleteSelectedServices.alertText"
                : "services.deleteAll.alertText"
            }`
          )}
        </Alert>
      </AlertDialog>
    );
  };

  const unlisten = history.listen((location, action) => {
    pagination.page !== null && action === "POP" && getCategories();
  });

  useEffect(() => {
    getCategories();
    return () => {
      unlisten();
    };
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="services">
      <AlertDialog
        title={context.t(`['services/detail'].newServiceRequestDialogTitle`)}
        buttons={[
          {
            title: context.t(`['services/detail'].cancelNewServiceRequest`),
            icon: "close",
            backgroundColor: "white",
            textColor: "grey",
            onClick: () => {
              cancelRequest();
            },
          },
          {
            title: context.t(`['services/detail'].createNewServiceRequest`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              makeNewServiceRequest();
            },
          },
        ]}
        fullWidth
        disableBackdropClick={false}
        maxWidth={"sm"}
        open={openNewServiceDialog}
        closeHandler={closeDialogHandler}
        containerStyle={{ overflow: "visible" }}
        overflowContent={false}
      >
        <div>
          <AutocompleteInput
            required
            label={context.t(`['services/detail'].serviceCategoryInput`)}
            hoverHelperText={context.t(
              `['services/detail'].newServiceCategoryHint`
            )}
            labelKey="name"
            valueKey="id"
            returnFullObject
            onRemove={async () => {
              setNewServiceCategory(null);
            }}
            selected={newServiceCategory || null}
            selectedHandler={async (selected_category) =>
              setNewServiceCategory(selected_category)
            }
            asyncDataService={async (keyword) => getServiceCategories(keyword)}
          />
          <Input
            required
            label={context.t(`['services/detail'].newServiceInputTitle`)}
            value={newServiceTitle !== null ? newServiceTitle : ""}
            onChange={(e) => setNewServiceTitle(e.target.value)}
          />
        </div>
      </AlertDialog>

      {services && (
        <AllServicesDailog
          open={open_all_services_dialog ? true : false}
          closeHandler={(state) => {
            state && getCategories();
            setOpenInformationDialog(state);
            setOpenAllServicesDialog(false);
          }}
        />
      )}
      <Grid className="mb-2">
        <Column className="xs-12 sm-12 md-6">
          <Input
            className="mb-0"
            InputProps={{
              startAdornment: (
                <div style={{ marginTop: "10px" }}>
                  {" "}
                  <Search />
                </div>
              ),
            }}
            placeholder={context.t(`services.filter.inputLabel`)}
            onChange={(e) => {
              const { value } = e.target;
              if (typingTimeout) clearTimeout(typingTimeout);
              setLoaded(true);
              value
                ? setTypingTimeout(
                    setTimeout(() => searchServicesByKey(value), 500)
                  )
                : getCategories();
            }}
          />
        </Column>
        <Column className="xs-12 sm-12 md-6">
          <ProcessContainer>
            <Button
              title={context.t(`[services/detail].newCreateRequest`)}
              backgroundColor="primary"
              textColor="white"
              icon="add"
              onClick={() => {
                setOpenNewServiceDialog(true);
              }}
            />
            <Button
              title={context.t(`services.addAllService.addButtonTitle`)}
              backgroundColor="primary"
              textColor="white"
              icon="add"
              onClick={() => {
                setOpenAllServicesDialog(true);
              }}
            />
            <DeleteServicesDialog />
            <Button
              disabled={selectedServices.length === 0}
              title={context.t(
                `services.deleteSelectedServices.deleteSelectedButtonTitle`
              )}
              backgroundColor="red"
              textColor="white"
              icon="delete_forever"
              onClick={() => setOpenDeleteAllDialog(true)}
            />
          </ProcessContainer>
        </Column>
      </Grid>
      <Grid>
        <Column className="xs-12 sm-12">
          {loaded ? (
            <CategoryTabContainer id="services-tab">
              {searched_services.length > 0 && <ServiceCategoryTab />}
              {searched_services.length > 0 ? (
                searched_services.map((service) => (
                  <div key={service.id} className="mt-1">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        gap: "10px",
                      }}
                    >
                      <Checkbox
                        style={{ margin: "0px", padding: "0px" }}
                        checked={service.services.every((m) =>
                          selectedServices.includes(m.id)
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedServices([
                              ...selectedServices,
                              ...service.services
                                .filter((e) => !selectedServices.includes(e.id))
                                .map((m) => m.id),
                            ]);
                          } else {
                            setSelectedServices(
                              selectedServices.filter(
                                (id) =>
                                  !service.services
                                    .map((m) => m.id)
                                    .includes(id)
                              )
                            );
                          }
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                      <h3
                        style={{
                          display: "fkex",
                          alignItems: "center",
                          height: "20px",
                        }}
                        id={service.name.replace(" ", "-")}
                      >
                        {service.name}
                      </h3>
                    </div>

                    <Table
                      isExist={false}
                      headings={{
                        name: {
                          label: context.t(`services.headings.name`),
                          sortable: {
                            0: [{ field: "`services`.`name`", order: "ASC" }],
                            1: [{ field: "`services`.`name`", order: "DESC" }],
                          },
                        },
                        process_time: {
                          label: context.t(`services.headings.processTime`),
                          suffix: "dk",
                          sortable: {
                            0: [
                              {
                                field: "`services`.`process_time`",
                                order: "ASC",
                              },
                            ],
                            1: [
                              {
                                field: "`services`.`process_time`",
                                order: "DESC",
                              },
                            ],
                          },
                        },
                        bounty: {
                          label:
                            `Prim Tutarı ${
                              context.state.currency
                                ? "(" + context.state.currency + ")"
                                : "(₺)"
                            }` +
                            " / " +
                            context.t(`services.headings.bounty`),
                          prefix: "%",
                          amount: context.state.currency
                            ? context.state.currency
                            : "₺",
                          sortable: {
                            0: [{ field: "`services`.`bounty`", order: "ASC" }],
                            1: [
                              { field: "`services`.`bounty`", order: "DESC" },
                            ],
                          },
                        },
                        amount: {
                          label: context.t(`services.headings.amount`),
                          suffix: context.state.currency
                            ? context.state.currency
                            : "₺",
                          sortable: {
                            0: [{ field: "`services`.`amount`", order: "ASC" }],
                            1: [
                              { field: "`services`.`amount`", order: "DESC" },
                            ],
                          },
                        },
                        show_at_url: {
                          label: context.t(`services.headings.showAtUrl`),
                          sortable: {
                            0: [
                              {
                                field: "`services`.`show_at_url`",
                                order: "ASC",
                              },
                            ],
                            1: [
                              {
                                field: "`services`.`show_at_url`",
                                order: "DESC",
                              },
                            ],
                          },
                        },
                        _: {
                          label: context.t(`component.actionHeadingText`),
                        },
                      }}
                      rows={service.services ?? []}
                      loaded={loaded}
                      replacements={{
                        show_at_url: {
                          true: context.t(
                            `services.replacements.showAtUrlTrue`
                          ),
                          false: context.t(
                            `services.replacements.showAtUrlFalse`
                          ),
                        },
                      }}
                      pagination={!filter_expanded ? pagination : undefined}
                      checkedCheckboxes={selectedServices}
                      checkboxOnChange={(row, state) => {
                        console.log(state);
                        if (state) {
                          if (!selectedServices.includes(row.id)) {
                            setSelectedServices([...selectedServices, row.id]);
                          }
                        } else {
                          setSelectedServices([
                            ...selectedServices.filter((id) => id !== row.id),
                          ]);
                        }
                      }}
                      buttons={[
                        {
                          title: context.t(`component.detailEditButtonTitle`),
                          icon: "launch",
                          textColor: "primary",
                          transitionEffect: true,
                          pushEffect: true,
                          onClick: (row) =>
                            history.push(`/services/detail/${row.id}`),
                        },
                        {
                          title: context.t(`services.deleteButtonTitle`),
                          icon: "delete_forever",
                          transitionEffect: true,
                          textColor: "red",
                          onClick: (row) => {
                            setDeleteId(row.id);
                            setDeleteConfirm(true);
                          },
                        },
                      ]}
                    />
                  </div>
                ))
              ) : (
                <NoDataContainer>
                  <img
                    src={require("../../../assets/images/undraw/no_data_table.svg")}
                    alt={context.t(`services.dataNotFound`)}
                  />
                  <span>{context.t(`services.dataNotFound`)}</span>
                </NoDataContainer>
              )}
            </CategoryTabContainer>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={Loader} width="100" height="100" alt="loading" />
            </div>
          )}

          <AlertDialog
            title={context.t(`services.delete.title`)}
            open={delete_confirm}
            closeHandler={() => setDeleteConfirm(false)}
            buttons={[
              {
                title: context.t(`services.delete.confirmButtonTitle`),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                timer: 5,
                onClick: () => deteleServiceByID(delete_id),
              },
              {
                title: context.t(`services.delete.discardButtonTitle`),
                icon: "close",
                textColor: "grey",
              },
            ]}
          >
            <Alert severity="warning">
              <b>{context.t(`services.delete.alertBoldText`)}</b>
              <br />
              {context.t(`services.delete.alertText`)}
            </Alert>
          </AlertDialog>
        </Column>
      </Grid>

      <InformationDialog />
    </AuthContainer>
  );
};

export default ServicesIndex;

const ProcessContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  @media screen and (max-width: 750px) {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    align-items: stretch;
  }
`;

const CategoryTabContainer = styled.div`
  height: 600px;
  position: relative;
  overflow: auto;
`;

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  img {
    height: 160px;
    object-fit: contain;
    margin-bottom: 16px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    color: #a0a0a0;
  }
`;
