import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AppContext from "./context/store";
import PanelScaffold from "./components/Scaffold/PanelScaffold";
import ScrollToTop from "./components/Navigation/ScrollToTop";

//Pages
import LoginIndex from "./views/Unauthenticated/Login";
import RegisterIndex from "./views/Unauthenticated/Register";
import ForgotPassIndex from "./views/Unauthenticated/ForgotPass";

import DashboardIndex from "./views/Authenticated/Dashboard/Index";

import ReceiptIndex from "./views/Authenticated/Receipt/Index";
import AddReceipt from "./views/Authenticated/Receipt/RecordAdd";
import CostumerAdd from "./views/Authenticated/Receipt/NewAdd";
import SingleReceipt from "./views/Authenticated/Receipt/Single";

import PackageIndex from "./views/Authenticated/Packages/Index";
import PackageDetail from "./views/Authenticated/Packages/Detail";
import PackageAdd from "./views/Authenticated/Packages/Add";
import PackageSalesIndex from "./views/Authenticated/Packages/Sales/Index";
import PackageSalesAdd from "./views/Authenticated/Packages/Sales/Add";

import AppointmentsTableIndex from "./views/Authenticated/Appointments/TableIndex";
import OnlineAppointments from "./views/Authenticated/Appointments/Online";
import AddAppointment from "./views/Authenticated/Appointments/Add";
import AppointmentFilter from "./views/Authenticated/Appointments/Filter";

import CustomersIndex from "./views/Authenticated/Customers/Index";
import PassiveCustomersIndex from "./views/Authenticated/Customers/PassiveIndex";
import CustomersRisky from "./views/Authenticated/Customers/Risky";
import AddCustomer from "./views/Authenticated/Customers/Add";
import AddBulkCustomers from "./views/Authenticated/Customers/AddBulk";
import SingleCustomer from "./views/Authenticated/Customers/Single";
import CustomersRating from "./views/Authenticated/Customers/Rating";

import IncomeTransactions from "./views/Authenticated/Accounting/IncomeTransactions";
import ExpenseTransactions from "./views/Authenticated/Accounting/ExpenseTransactions";
import ExpenseTypes from "./views/Authenticated/Accounting/ExpenseTypes";
import DebtTransactions from "./views/Authenticated/Accounting/DebtTransactions";
import BountyTransactions from "./views/Authenticated/Accounting/Bounty";

import EmployeesIndex from "./views/Authenticated/Employees/Index";
import AddEmployee from "./views/Authenticated/Employees/Add";
import SingleEmployee from "./views/Authenticated/Employees/Single";

import ProfileIndex from "./views/Authenticated/Profile/Index";

import ServicesIndex from "./views/Authenticated/Services/Index";
import SingleService from "./views/Authenticated/Services/Single";
import AddService from "./views/Authenticated/Services/Add";
import Requests from "./views/Authenticated/Services/Requests";

import ProductsIndex from "./views/Authenticated/Products/Index";
import AddProduct from "./views/Authenticated/Products/Add";
import ProductsDetail from "./views/Authenticated/Products/Detail";

import SmsIndex from "./views/Authenticated/Sms/Index";
import SendSelective from "./views/Authenticated/Sms/Send/Selective";
import SendFilter from "./views/Authenticated/Sms/Send/Filter";
import SendGroup from "./views/Authenticated/Sms/Send/Group";

import SmsSettings from "./views/Authenticated/Sms/Settings";
import WPSettings from "./views/Authenticated/Sms/WPSettings";
import Blacklist from "./views/Authenticated/Sms/Blacklist";
import Template from "./views/Authenticated/Sms/Send/Template";

import SettingsIndex from "./views/Authenticated/Settings/Index";
import PasswordSettings from "./views/Authenticated/Settings/Password";
import WorkHourSettings from "./views/Authenticated/Settings/Hours";
import PlatformSettings from "./views/Authenticated/Settings/PlatformSettings";

import LogsIndex from "./views/Authenticated/Settings/Logs";
import AgreementIndex from "./views/Authenticated/Settings/Agreement/Index";
import FeedbackIndex from "./views/Authenticated/Feedback/Index";

import BusinessWebsite from "./views/Unauthenticated/BusinessWebsite/NewIndex_new";
import AppointmentRating from "./views/Unauthenticated/AppointmentRating/Index";

import BranchesIndex from "./views/Authenticated/Branches/Index";
import BranchInPanelAdd from "./views/Authenticated/Branches/InPanelAdd";

import CompanyReports from "./views/Authenticated/Reports/Company";
import ServiceReports from "./views/Authenticated/Reports/Service";
import StaffReports from "./views/Authenticated/Reports/Staff";
import CustomerReports from "./views/Authenticated/Reports/Customer";
import StockReports from "./views/Authenticated/Reports/Stock";
import AppointmentReports from "./views/Authenticated/Reports/Appointment";

import TodoIndex from "./views/Authenticated/Todo/Index";
import WalletIndex from "./views/Authenticated/Wallet/Index";

import NotFound from "./views/Authenticated/NotFound";
import PaymentNotify from "./views/Authenticated/PaymentNotify/Index";

import PlatformPhoto from "./views/Authenticated/Gallery/PlatformPhoto";
import GalleryIndex from "./views/Authenticated/Gallery/Gallery";
import ServiceGallery from "./views/Authenticated/Gallery/ServiceGallery";
import LiveSupport from "./views/Authenticated/Support/LiveSupport";
import Bounty from "./views/Authenticated/Services/Bounty";

import Unbooked from "./views/Authenticated/Packages/Unbooked";
import Referance from "./views/Authenticated/Settings/Referance";
import Credit from "./views/Authenticated/Accounting/Credit";
import AddCredit from "./views/Authenticated/Accounting/AddCredit";
import Debit from "./views/Authenticated/Accounting/Debit";
import AddDebit from "./views/Authenticated/Accounting/AddDebit";
import CreditDetail from "./views/Authenticated/Accounting/CreditDetail";
import DebitDetail from "./views/Authenticated/Accounting/DebitDetail";
import AppointmentConfirmation from "./views/Unauthenticated/AppointmentConfirmation";

import Campaigns from "./views/Unauthenticated/Campaigns/index";
import CampaignsAll from "./views/Unauthenticated/CampaignsAll/index";
import Renewal from "./views/Unauthenticated/Renewal/Renewal";
import OneSignal from "react-onesignal";

//Router
const AppRouter = () => {
  const context = useContext(AppContext);

  const AuthControlRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        context.state.is_authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );

  // useEffect(() => {
  //   if (
  //     context.state.company_branch_selected === true ||
  //     context.state.user.permission !== 1
  //   ) {
  //     if (typeof window !== "undefined") {
  //       OneSignal.init({
  //         appId: "5f199283-d5b5-4c8d-92ff-1fa3d5d1573e",
  //         notifyButton: {
  //           enable: true,
  //         },
  //         allowLocalhostAsSecureOrigin: true,
  //       });
  //       OneSignal.login(`${context.state.user.id}`, null)
  //         .then(() => {
  //           console.log("User logged in successfully with external ID.");
  //         })
  //         .catch((error) => {
  //           console.error("Error logging in user:", error);
  //         });
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   const initializeOneSignal = async () => {
  //     if (window.OneSignal) {
  //       await window.OneSignal.init({
  //         appId: "5f199283-d5b5-4c8d-92ff-1fa3d5d1573e",
  //       });
  //       console.log("OneSignal initialized successfully.");
  //       alert("OneSignal is initialized and ready for push notifications.");
  //     } else {
  //       window.OneSignalDeferred = window.OneSignalDeferred || [];
  //       window.OneSignalDeferred.push(async function (OneSignal) {
  //         await OneSignal.init({
  //           appId: "5f199283-d5b5-4c8d-92ff-1fa3d5d1573e",
  //         });
  //         console.log("OneSignal initialized successfully.");
  //         alert("OneSignal is initialized and ready for push notifications.");
  //       });
  //     }
  //   };

  //   initializeOneSignal();
  // }, []);

  const PanelRoutesJSX = (
    <Switch>
      <Route path={"/dashboard"} component={DashboardIndex} exact />

      <Route path={"/appointments"} component={AppointmentsTableIndex} exact />
      <Route
        path={"/appointments/online"}
        component={OnlineAppointments}
        exact
      />
      <Route
        path={"/appointments/filter"}
        component={AppointmentFilter}
        exact
      />
      <Route path={"/appointments/add"} component={AddAppointment} exact />

      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/receipts"} component={ReceiptIndex} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/receipts/add"} component={AddReceipt} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/receipts/add/costumeradd"}
          component={CostumerAdd}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/receipts/detail/:id"} component={SingleReceipt} exact />
      )}

      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/packages"}
          render={() => <Redirect to="/packages/sales" />}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/packages/list"} component={PackageIndex} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/packages/detail/:id"} component={PackageDetail} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/packages/add"} component={PackageAdd} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/packages/sales"} component={PackageSalesIndex} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/packages/sales/add"} component={PackageSalesAdd} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/packages/unbooked"} component={Unbooked} exact />
      )}

      <Route path={"/customers"} component={CustomersIndex} exact />
      <Route path={"/customers/risky"} component={CustomersRisky} exact />
      <Route
        path={"/customers/passive"}
        component={PassiveCustomersIndex}
        exact
      />
      <Route path={"/customers/add"} component={AddCustomer} exact />
      <Route path={"/customers/add/bulk"} component={AddBulkCustomers} exact />
      <Route path={"/customers/detail/:id"} component={SingleCustomer} exact />
      <Route path={"/customers/rating"} component={CustomersRating} exact />

      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/accounting"}
          render={() => <Redirect to="/accounting/income" />}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/accounting/income"}
          component={IncomeTransactions}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/accounting/expense"}
          component={ExpenseTransactions}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/accounting/expense/types"}
          component={ExpenseTypes}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/accounting/debt"} component={DebtTransactions} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/accounting/bounty"}
          component={BountyTransactions}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/accounting/credit"} component={Credit} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/accounting/credit/add"} component={AddCredit} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/accounting/credit/detail/:id"}
          component={CreditDetail}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/accounting/debit"} component={Debit} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/accounting/debit/add"} component={AddDebit} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/accounting/debit/detail/:id"}
          component={DebitDetail}
          exact
        />
      )}

      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/reports"}
          render={() => <Redirect to="/reports/company" />}
          exact
        />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/reports/company"} component={CompanyReports} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/reports/service"} component={ServiceReports} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/reports/stock"} component={StockReports} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/reports/staff"} component={StaffReports} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/reports/customer"} component={CustomerReports} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route
          path={"/reports/appointment"}
          component={AppointmentReports}
          exact
        />
      )}

      <Route path={"/services"} component={ServicesIndex} exact />
      <Route path={"/services/add"} component={AddService} exact />
      <Route path={"/services/detail/:id"} component={SingleService} exact />
      <Route path={"/services/requests"} component={Requests} exact />
      <Route path={"/services/bounty"} component={Bounty} exact />

      <Route path={"/sms"} component={SendGroup} exact />
      <Route path={"/sms/send/selective"} component={SendSelective} exact />
      <Route path={"/sms/send/filter"} component={SendFilter} exact />
      <Route path={"/sms/send/group"} component={SendGroup} exact />
      <Route path={"/sms/settings"} component={SmsSettings} exact />
      <Route path={"/sms/settings/wp"} component={WPSettings} exact />
      <Route path={"/sms/reports"} component={SmsIndex} />
      <Route path={"/sms/template"} component={Template} />
      <Route path={"/sms/blacklist"} component={Blacklist} />
      <Route path={"/sms"} component={SendGroup} exact />
      <Route path={"/sms/send/selective"} component={SendSelective} exact />
      <Route path={"/sms/send/filter"} component={SendFilter} exact />
      <Route path={"/sms/send/group"} component={SendGroup} exact />
      <Route path={"/sms/reports"} component={SmsIndex} />
      <Route path={"/sms/blacklist"} component={Blacklist} />

      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/products"} component={ProductsIndex} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/products/detail/:id"} component={ProductsDetail} exact />
      )}
      {context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
        <Route path={"/products/add"} component={AddProduct} exact />
      )}

      <Route path={"/profile"} component={ProfileIndex} exact />
      <Route path={"/feedback"} component={FeedbackIndex} exact />

      <Route path={"/todos"} component={TodoIndex} exact />

      <Route path={"/wallet"} component={WalletIndex} exact />
      <Route path={"/payment-notify"} component={PaymentNotify} exact />

      <Route path={"/settings"} component={SettingsIndex} exact />
      <Route
        path={"/settings/password"}
        component={PasswordSettings}
        exacbnet
      />
      <Route path={"/settings/hours"} component={WorkHourSettings} exact />

      <Route
        path={"/settings/agreement/index"}
        component={AgreementIndex}
        exact
      />
      <Route
        path={"/settings/platform-settings"}
        component={PlatformSettings}
        exact
      />
      <Route path={"/settings/logs"} component={LogsIndex} exact />
      <Route path={"/settings/give-referance"} component={Referance} exact />

      <Route path={"/settings/branch/add"} component={BranchInPanelAdd} exact />
      <Route path={"/settings/employees"} component={EmployeesIndex} exact />
      <Route path={"/settings/sms"} component={SmsSettings} exact />
      <Route path={"/settings/wp"} component={WPSettings} exact />
      <Route path={"/settings/template"} component={Template} />
      <Route path={"/settings/gallery"} component={PlatformPhoto} exact />
      <Route
        path={"/settings/enterprise-gallery"}
        component={GalleryIndex}
        exact
      />
      <Route
        path={"/settings/service-gallery"}
        component={ServiceGallery}
        exact
      />
      <Route
        path={"/settings/agreement/index"}
        component={AgreementIndex}
        exact
      />
      <Route path={"/settings/employees/add"} component={AddEmployee} exact />
      <Route
        path={"/settings/employees/detail/:id"}
        component={SingleEmployee}
        exact
      />

      <Route path={"/support/live-support"} component={LiveSupport} />

      <Route component={NotFound} />
    </Switch>
  );

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop>
        <Switch>
          <AuthControlRoute path={"/login"} component={LoginIndex} exact />
          <AuthControlRoute
            path={"/register"}
            component={RegisterIndex}
            exact
          />
          <AuthControlRoute
            path={"/forgot-pass"}
            component={ForgotPassIndex}
            exact
          />

          <Route path={"/_login"} component={LoginIndex} exact />
          <Route path={"/isletme/:id"} component={BusinessWebsite} exact />
          <Route path={"/anket/:id"} component={AppointmentRating} exact />
          <Route path={"/first/:token"} component={Campaigns} exact />
          <Route
            path={"/en-ucuz-guzellik-merkezi-programi-fiyatlari"}
            component={CampaignsAll}
            exact
          />
          <Route path={"/renewal/:token"} component={Renewal} exact />
          <Route
            path={"/onay/:apid/:coid"}
            component={AppointmentConfirmation}
            exact
          />

          <Route path={"/"} render={() => <Redirect to="/login" />} exact />

          {context.state.is_authenticated === true ? (
            <Route path={"/"}>
              {context.state.company_branch_selected === true ||
              context.state.user.permission !== 1 ? (
                <PanelScaffold logo={context.app.LOGO}>
                  {PanelRoutesJSX}
                </PanelScaffold>
              ) : (
                <Switch>
                  <Route path={"/branches"} component={BranchesIndex} exact />
                  <Route
                    path={"/"}
                    render={() => <Redirect to="/branches" />}
                  />
                </Switch>
              )}
            </Route>
          ) : (
            <Route path={"/"} render={() => <Redirect to="/login" />} />
          )}

          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default AppRouter;
