const CalculateMessageCount = (message, messageType) => {
  if (messageType == 1) {
    let length = message.length + 5;

    for (let i = 0; i < length; i++) {
      if (
        message.charAt(i) === "€" ||
        message.charAt(i) === "{" ||
        message.charAt(i) === "}" ||
        message.charAt(i) === "[" ||
        message.charAt(i) === "]" ||
        message.charAt(i) === "~" ||
        message.charAt(i) === "^" ||
        message.charAt(i) === "\\" ||
        message.charAt(i) === "|" ||
        message.charAt(i) === "ç" ||
        message.charAt(i) === "Ç" ||
        message.charAt(i) === "ş" ||
        message.charAt(i) === "ğ" ||
        message.charAt(i) === "ı" ||
        message.charAt(i) === "Ş" ||
        message.charAt(i) === "İ" ||
        message.charAt(i) === "\r" ||
        message.charAt(i) === "\n" ||
        message.charAt(i) === "Ğ"
      ) {
        length = length + 1;
      }
    }

    return length < 919;
  } else if (messageType == 2) {
    let length = message.length + 5;

    for (let i = 0; i < length; i++) {
      if (
        message.charAt(i) === "€" ||
        message.charAt(i) === "{" ||
        message.charAt(i) === "}" ||
        message.charAt(i) === "[" ||
        message.charAt(i) === "]" ||
        message.charAt(i) === "~" ||
        message.charAt(i) === "^" ||
        message.charAt(i) === "\\" ||
        message.charAt(i) === "|" ||
        message.charAt(i) === "ç" ||
        message.charAt(i) === "Ç" ||
        message.charAt(i) === "ş" ||
        message.charAt(i) === "ğ" ||
        message.charAt(i) === "ı" ||
        message.charAt(i) === "Ş" ||
        message.charAt(i) === "İ" ||
        message.charAt(i) === "\r" ||
        message.charAt(i) === "\n" ||
        message.charAt(i) === "Ğ"
      ) {
        length = length + 1;
      }

      return length < 450;
    }
  } else if (messageType == 3) {
    let length = message.length + 5;

    for (let i = 0; i < length; i++) {
      if (
        message.charAt(i) === "€" ||
        message.charAt(i) === "{" ||
        message.charAt(i) === "}" ||
        message.charAt(i) === "[" ||
        message.charAt(i) === "]" ||
        message.charAt(i) === "~" ||
        message.charAt(i) === "^" ||
        message.charAt(i) === "\\" ||
        message.charAt(i) === "|" ||
        message.charAt(i) === "ç" ||
        message.charAt(i) === "Ç" ||
        message.charAt(i) === "ş" ||
        message.charAt(i) === "ğ" ||
        message.charAt(i) === "ı" ||
        message.charAt(i) === "Ş" ||
        message.charAt(i) === "İ" ||
        message.charAt(i) === "\r" ||
        message.charAt(i) === "\n" ||
        message.charAt(i) === "Ğ"
      ) {
        length = length + 1;
      }
    }

    return length < 2000;
  }
};

export default CalculateMessageCount;
