import React, { useContext, useState, useEffect } from "react";
import AppContext from "../context/store";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../assets/style/component/Table.scss";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

const EmpoolesPackagesTable = ({
  containerHeight,
  disableHeadings,
  rows,
  loaded,
  pagination,
  noDataText,
  showAlwaysPagination,
  packets,
  updateState,
}) => {
  const context = useContext(AppContext);

  return (
    <div>
      <div style={{ overflowY: "auto", maxHeight: containerHeight ?? "auto" }}>
        <table className="table-container" border={0}>
          {disableHeadings !== true && <thead></thead>}

          <tbody style={{ display: "flex", flexWrap: "wrap" }}>
            {rows.map((row, index) => (
              <div
                key={index}
                style={{
                  flex: "0 0 48%",
                  margin: "1%",
                  boxSizing: "border-box",
                  height: "45px",
                  color: `${packets.includes(row.id) ? "#3189ec" : "black"}`,
                  borderRadius: "5px",
                  cursor: "pointer",
                  border: `1px solid ${
                    packets.includes(row.id) ? "#3189ec" : "#DCDCDC"
                  }`,
                }}
                onClick={() => {
                  if (!packets.includes(row.id)) {
                    updateState([...packets, row.id]);
                  } else {
                    updateState([...packets.filter((m) => m !== row.id)]);
                  }
                }}
              >
                <div
                  style={{
                    width: "98%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {packets.includes(row.id) ? (
                    <CheckIcon
                      fontSize="small"
                      style={{ paddingRight: "5px" }}
                    />
                  ) : (
                    <CloseIcon
                      fontSize="small"
                      style={{ paddingRight: "5px" }}
                    />
                  )}

                  {row.name}
                </div>
              </div>
            ))}
          </tbody>
        </table>
      </div>

      {loaded === true && rows?.length === 0 && (
        <>
          <NoDataContainer>
            <img
              src={require("../assets/images/undraw/no_data_table.svg")}
              alt="Veri bulunamadı!"
            />
            <span>
              {noDataText
                ? noDataText
                : context.t(`sms.bottom_table_titles.data_not_found`)}
            </span>
          </NoDataContainer>
        </>
      )}
      {/* Pagination Clear And Count Prev Next Added */}
      {(showAlwaysPagination ||
        (rows?.length > 0 && pagination && pagination.total_page)) && (
        <PaginationContainer>
          <div className="pagination p7">
            <ul>
              <button
                disabled={pagination.page < 2}
                onClick={() => {
                  window.history.pushState(
                    { path: "" },
                    "",
                    "?page=" + pagination.page--
                  );
                  pagination.page && pagination.onChange(pagination.page);
                }}
              >
                <li>
                  <ArrowBackIosIcon className="icon-back" fontSize="small" />
                </li>
              </button>
              <button
                disabled={rows?.length < 10}
                onClick={() => {
                  window.history.pushState(
                    { path: "" },
                    "",
                    "?page=" + pagination.page++
                  );
                  pagination.page && pagination.onChange(pagination.page);
                }}
              >
                <li>
                  <ArrowForwardIosIcon fontSize="small" />
                </li>
              </button>
            </ul>
          </div>
        </PaginationContainer>
      )}
    </div>
  );
};

export default React.memo(EmpoolesPackagesTable);

export const SkeletonLine = styled.div`
  margin: 10px 0;
  height: 24px;
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  img {
    height: 160px;
    object-fit: contain;
    margin-bottom: 16px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    color: #a0a0a0;
  }
`;
