import {
  COLUMN_WIDTH_12,
  COLUMN_WIDTH_11,
  COLUMN_WIDTH_10,
  COLUMN_WIDTH_9,
  COLUMN_WIDTH_8,
  COLUMN_WIDTH_7,
  COLUMN_WIDTH_6,
  COLUMN_WIDTH_5,
  COLUMN_WIDTH_4,
  COLUMN_WIDTH_3,
  COLUMN_WIDTH_2,
  COLUMN_WIDTH_1,
  COLUMN_WIDTH,
  SMALL_COLUMN_WIDTH,
} from "../views/Authenticated/Appointments/TableIndex";
const moment = require("moment");

const AppointmentCalendar = (
  appointment,
  shift,
  appointments,
  forGroup,
  largeTableRow,
  staffCount,
  clock_off,
  hover
) => {
  const shift_start = shift
    ? moment(`1970-01-01 ${shift.start_hour}`).tz("Europe/Istanbul")
    : null;
  const shift_end = shift
    ? moment(`1970-01-01 ${shift.end_hour}`).tz("Europe/Istanbul")
    : null;

  if (shift !== undefined) {
    if (parseInt(shift.start_hour) > parseInt(shift.end_hour)) {
      shift_end.add(1, "days");
    }
  }

  const process_start = appointment
    ? moment(appointment.appointment_start_date).tz("Europe/Istanbul")
    : null;
  const process_end = appointment
    ? moment(appointment.appointment_end_date).tz("Europe/Istanbul")
    : null;

  //TODO: Saat Kapatma için kullanılacak
  const process_start_off = appointment
    ? appointment.all_hours_closed === false
      ? moment(appointment.start_hour).tz("Europe/Istanbul")
      : moment(
          moment(shift_start)
            .tz("Europe/Istanbul")
            .format("YYYY-MM-DDTHH:mm:ssZ")
        )
    : null;
  const process_end_off = appointment
    ? appointment.all_hours_closed === false
      ? moment(appointment.end_hour).tz("Europe/Istanbul")
      : moment(
          moment(shift_end).tz("Europe/Istanbul").format("YYYY-MM-DDTHH:mm:ssZ")
        )
    : null;

  const process_diff =
    process_start && process_end
      ? parseFloat(moment.duration(process_end.diff(process_start)).asMinutes())
      : null;

  const shift_diff =
    shift_start && shift_end
      ? parseFloat(moment.duration(shift_end.diff(shift_start)).asMinutes())
      : null;

  //TODO: Saat Kapatma için kullanılacak
  const process_diff_off =
    process_start_off && process_end_off
      ? parseFloat(
          moment.duration(process_end_off.diff(process_start_off)).asMinutes()
        )
      : null;

  const shift_diff_per_slice = shift
    ? parseInt(Math.ceil(shift_diff / shift.slice))
    : null;

  // const shift_diff_off =
  //   shift_start && shift_end
  //     ? parseFloat(moment.duration(shift_end.diff(process_end_off)).asMinutes())
  //     : null;

  /**
   * Şuanı gösteren kırmızı çizginin yukarıdan ne kadar aşağıda olacağını hesaplayan fonksiyon.
   */

  const getNowIndicatorTopMargin = () =>
    (parseFloat(
      moment
        .duration(
          moment(
            `1970-01-01 ${moment(new Date())
              .tz("Europe/Istanbul")
              .format("HH:mm")}`
          ).diff(shift_start)
        )
        .asMinutes()
    ) /
      (shift_diff_per_slice * shift.slice)) *
    100;
  /**
   * Kartın süre bazlı tablo kapsayıcısı içerisinde ne kadar büyüklükte olacağını hesaplayan fonksiyon.
   */
  const getCardHeightPercent = () => {
    return clock_off === true
      ? (process_diff_off / shift_diff) * 100
      : (process_diff / shift_diff) * 100;
  };

  /**
   * Kartın tüm tablo kapsayıcısı içerisinde ne yükseklikte olacağını hesaplayan fonksiyon.
   */
  const getCardTopMargin = () => {
    return clock_off === true
      ? (parseFloat(
          moment
            .duration(
              moment(`1970-01-01 ${process_start_off.format("HH:mm")}`)
                .tz("Europe/Istanbul")
                .diff(shift_start)
            )
            .asMinutes()
        ) /
          (shift_diff_per_slice * shift.slice)) *
          100
      : hover !== true
      ? moment(process_start).tz("Europe/Istanbul").format("HH:mm") <
        shift.start_hour
        ? (parseFloat(
            moment
              .duration(
                moment(`1970-01-02 ${process_start.format("HH:mm")}`)
                  .tz("Europe/Istanbul")
                  .diff(shift_start)
              )
              .asMinutes()
          ) /
            (shift_diff_per_slice * shift.slice)) *
          100
        : (parseFloat(
            moment
              .duration(
                moment(`1970-01-01 ${process_start.format("HH:mm")}`)
                  .tz("Europe/Istanbul")
                  .diff(shift_start)
              )
              .asMinutes()
          ) /
            (shift_diff_per_slice * shift.slice)) *
          100
      : moment(shift_end).tz("Europe/Istanbul").format("HH") -
          moment(process_end).tz("Europe/Istanbul").format("HH") >
          2 || hover === true
      ? moment(process_start).tz("Europe/Istanbul").format("HH:mm") <
        shift.start_hour
        ? (parseFloat(
            moment
              .duration(
                moment(`1970-01-02 ${process_start.format("HH:mm")}`)
                  .tz("Europe/Istanbul")
                  .diff(shift_start)
              )
              .asMinutes()
          ) /
            (shift_diff_per_slice * shift.slice)) *
          100
        : (parseFloat(
            moment
              .duration(
                moment(`1970-01-01 ${process_start.format("HH:mm")}`)
                  .tz("Europe/Istanbul")
                  .diff(shift_start)
              )
              .asMinutes()
          ) /
            (shift_diff_per_slice * shift.slice)) *
          100
      : 85;
  };

  /**
   * Kartların çakışma durumuna karşın iç içe geçirecek görünümü hesaplayan fonksiyon.
   */
  const getCardWidthAndLeftMargin = () => {
    let indent = 0;

    const ROW_WIDTH = largeTableRow
      ? SMALL_COLUMN_WIDTH
      : staffCount === 1
      ? COLUMN_WIDTH_1
      : forGroup.week === true
      ? COLUMN_WIDTH_7
      : COLUMN_WIDTH;

    if (clock_off === true) {
      appointments.map((item) =>
        (process_start_off.isSameOrAfter(item.start_hour) &&
          process_start_off.isSameOrBefore(item.end_hour)) ||
        (process_start_off.isAfter(item.start_hour) &&
          process_start_off.isBefore(item.end_hour))
          ? (indent += 1)
          : undefined
      );
    } else {
      appointments.map((item) =>
        (process_start.isSameOrAfter(item.appointment_start_date) &&
          process_start.isSameOrBefore(item.appointment_end_date)) ||
        (process_start.isAfter(item.appointment_start_date) &&
          process_start.isBefore(item.appointment_end_date))
          ? (indent += 1)
          : undefined
      );
    }

    const isGroup = forGroup.groupCount > 1;
    const findColumnPaddingLeft =
      forGroup.staffIndexOf * ROW_WIDTH + forGroup.staffIndexOf;

    let offsetX = findColumnPaddingLeft;
    let width = ROW_WIDTH - indent * 12;

    if (isGroup) {
      width = (ROW_WIDTH - indent * forGroup.groupCount) / forGroup.groupCount;
      offsetX =
        findColumnPaddingLeft + width * forGroup.appointmentIndexFromGroup;
    }

    let sizeWidth = ((indent >= 1 && width) || ROW_WIDTH) + "px";

    return {
      width: sizeWidth,
      left: `${offsetX}px`,
    };
  };

  /**
   * Kartın arkaplanını adisyonu açıldıysa yeşil, eğer tarihi geçtiyse kırmızı, bekleyen durumdaysa kırmızı görünümde ayarlayan fonksiyon.
   */
  const getCardBackgroundColor = () => {
    if (appointment.customer_state === 0)
      // Geldiyse
      return "#CBECC9"; //Yeşil

    if (appointment.customer_state === 1)
      // Gelmediyse
      return "#ED8286"; //Kırmızı

    if (appointment.customer_state === 3)
      // Teyit Edildiyse
      return "#D6FAFA"; //Mavi

    if (appointment.customer_state === 4)
      // İşlemdeyse
      return "#EDDEFC"; // Mor

    // Bekleniyor
    return "#FFDDB0"; //Turuncu
  };

  /**
   * Kartın text color'unu adisyonu açıldıysa yeşil, eğer tarihi geçtiyse kırmızı, bekleyen durumdaysa kırmızı görünümde ayarlayan fonksiyon.
   */
  const getCardTextColor = () => {
    if (appointment.customer_state === 0)
      // Geldiyse
      return "#528859"; //Yeşil

    if (appointment.customer_state === 1)
      // Gelmediyse
      return "#9A2020"; //Kırmızı

    if (appointment.customer_state === 3)
      // Teyit Edildiyse
      return "#0b8bc1"; //Mavi

    if (appointment.customer_state === 4)
      // İşlemdeyse
      return "#9e8c99"; // Mor

    // Bekleniyor
    return "#995A1F"; //Turuncu
  };

  /**
   * Bütün hesaplamalar sonucu kart stilini obje olarak döndüren fonksiyon.
   */
  const getStyle = () => {
    if (forGroup.week === true) {
      return {
        height: `${getCardHeightPercent()}%`,
        top: `${getCardTopMargin()}%`,
        ...getCardWidthAndLeftMargin(),
      };
    } else {
      return {
        height: `${getCardHeightPercent()}%`,
        top: `${getCardTopMargin()}%`,
      };
    }
  };

  return {
    getNowIndicatorTopMargin,

    getCardHeightPercent,
    getCardTopMargin,
    getCardWidthAndLeftMargin,
    getCardBackgroundColor,
    getCardTextColor,

    getStyle,
  };
};

export default AppointmentCalendar;
