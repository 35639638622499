import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import { PieChart, PieSeries, Legend } from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import AppContext from "../../context/store";

am4core.useTheme(am4themes_animated);

const SemiCirclePie = (props) => {
  const context = useContext(AppContext);

  const [uniqueID] = useState(
    Date.now() * Math.round(Math.random() * 100000000)
  );

  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create(`CHART_${uniqueID}`, PieChart);
    am4core.options.minPolylineStep = 5;
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.data = props.data ? props.data : [];
    chart.radius = am4core.percent(96);
    chart.innerRadius = am4core.percent(props.innerRadius ?? 32);
    chart.startAngle = 180;
    chart.endAngle = 360;
    chart.svgContainer.autoResize = false;
    chart.svgContainer.measure();

    let series = chart.series.push(new PieSeries());
    const { dataFields, tooltip, slices, colors } = series;

    dataFields.value = props.valueKey;
    dataFields.category = props.labelKey;

    let shadow = tooltip.background.filters.getIndex(0);
    shadow.color = am4core.color("rgba(0,0,0,0)");

    slices.template.cornerRadius = 10;
    slices.template.innerCornerRadius = 7;
    slices.template.draggable = true;
    slices.template.inert = true;

    slices.template.states.getKey("hover").properties.scale = 1;
    slices.template.states.getKey("active").properties.shiftRadius = 0;

    const colorListArr = [];
    props.colors &&
      props.colors.length > 0 &&
      props.colors.map((color) => colorListArr.push(am4core.color(color)));

    colors.list =
      colorListArr.length > 0
        ? [...colorListArr]
        : [
            am4core.color("#004880"),
            am4core.color("#0a5fa1"),
            am4core.color("#0c77c9"),
            am4core.color("#60b1f0"),
            am4core.color("#77bcf2"),
          ];

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    chart.legend = new Legend();
    chart.legend.marginTop = "30px";
    chart.legend.width = am4core.percent(100);
    chart.legend.valueLabels.template.text = props.labelText
      ? "{value.value}" +
        (context.state.currency ? context.state.currency : "₺")
      : "{value.value}";

    [...document.querySelectorAll('g[stroke-width="1.7999999999999998"]')].map(
      (element) => (element.style.display = "none")
    );
    setTimeout(() => {
      [...document.querySelectorAll('rect[width="66"]')].map(
        (element) => (element.style.display = "none")
      );
    }, 800);

    chartRef.current = chart;
    return () => {
      chart.dispose();
    };
  });
  return (
    <>
      {props.data.reduce((total = 0, data) => total + data.count, 0) > 0 ? (
        <div
          id={`CHART_${uniqueID}`}
          className={props.className ?? "mb-3"}
          style={
            props.containerStyle
              ? props.containerStyle
              : { width: "100%", height: "220px" }
          }
        ></div>
      ) : (
        <div
          className={props.className ?? "mb-3"}
          style={
            props.containerStyle
              ? props.containerStyle
              : {
                  width: "100%",
                  height: "220px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
          }
        >
          {context.t(`component.semiCirclePie.noDisplayChartDescription`)}
        </div>
      )}
    </>
  );
};

export default React.memo(SemiCirclePie);
