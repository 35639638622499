import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import FCLabel from "../../../theme/FCLabel";
import { FormControlLabel, Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import { flushSync } from "react-dom";

class Unbooked extends React.Component {
  static contextType = AppContext;

  state = {
    delete_id: null,
    delete_confirm: false,

    filter_expanded: false,
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),

    customers: [],
    selected_customer: null,

    loaded: false,
    pagination: {
      total_page: null,
      page: 1,
      onChange: (page) => this.getOpenedReceipts(page),
    },
    data: [],
  };

  getOpenedReceipts = async (page, customer) => {
    const urlPage = parseInt(
      new URLSearchParams(this.props.location.search).get("page")
    );
    const { filter_expanded, start, end } = this.state;
    await Axios.get(
      `${this.context.api_endpoint}/company/appointments/transaction`,
      filter_expanded
        ? {
            params: {
              page: page ? page : urlPage ? urlPage : 1,
              ...{
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
                isbetween: true,
                customerid: customer ? customer.id : null,
              },
            },
          }
        : {
            params: {
              page: page ? page : urlPage ? urlPage : 1,
            },
          }
    )
      .then((response) => {
        const { data } = response;
        this.setState({
          loaded: true,
          data: [
            ...data.data.map((record) => {
              return {
                ...record,
                process_date: moment(record.process_date).format("DD/MM/YYYY"),
                custname: record.receipt.customer.full_name,
                info: record.receipt.info ? record.receipt.info : "",
                created_at: moment(record.created_at)
                  .local()
                  .format("DD/MM/YYYY HH:mm"),
              };
            }),
          ],
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page,
            total_page: data.data.length,
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getCustomers = async (key) => {
    await Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(async ({ data }) => {
      await this.setState({ customers: [...data.data.records] });
    });

    return this.state.customers;
  };

  componentDidMount() {
    this.getOpenedReceipts();
    // this.getCustomers();

    this.unlisten = this.props.history.listen((location, action) => {
      this.state.pagination.page !== null &&
        action === "POP" &&
        this.getOpenedReceipts();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[0, 1]}
        limited_permission="sales"
      >
        <Grid className="mb-2">
          <Column className={`xs-12 sm-12`}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={this.state.filter_expanded}
                  onChange={async (e) => {
                    await this.setState({
                      loaded: false,
                      filter_expanded: e.target.checked,
                    });
                    await this.getOpenedReceipts();
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{this.context.t(`receipts.filter.title`)}</span>
                </FCLabel>
              }
            />
          </Column>
          {this.state.filter_expanded && (
            <>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <DatePickerInput
                  className="mb-0"
                  label={this.context.t(`receipts.filter.startInputLabel`)}
                  value={new Date(moment(this.state.start).toISOString())}
                  maxDate={this.state.end}
                  onChange={async (start) => {
                    await this.setState({
                      loaded: false,
                      start,
                      selected_filter_picker: null,
                    });
                    await this.getOpenedReceipts();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <DatePickerInput
                  className="mb-0"
                  label={this.context.t(`receipts.filter.endInputLabel`)}
                  value={this.state.end}
                  minDate={new Date(moment(this.state.start).toISOString())}
                  onChange={async (end) => {
                    await this.setState({
                      loaded: false,
                      end,
                      selected_filter_picker: null,
                    });
                    await this.getOpenedReceipts();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-4">
                <AutocompleteInput
                  label={this.context.t(
                    `[receipts/add].searchCustomerInputLabel`
                  )}
                  labelKey="full_name"
                  valueKey="id"
                  returnFullObject
                  onRemove={() => {
                    this.setState({
                      selected_customer: null,
                    });
                    this.getOpenedReceipts();
                  }}
                  selected={this.state.selected_customer || null}
                  selectedHandler={(selected_customer) => {
                    flushSync(() => {
                      this.setState({
                        selected_customer: selected_customer,
                      });
                    });
                    this.getOpenedReceipts(this.state.page, selected_customer);
                  }}
                  asyncDataService={async (keyword) =>
                    await this.getCustomers(keyword)
                  }
                />
              </Column>
            </>
          )}
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12">
            <Table
              refreshAction={() =>
                this.getOpenedReceipts(this.state.pagination.page)
              }
              headings={{
                id: {
                  label: this.context.t(`receipts.headings.id`),
                  style: { width: 60 },
                },
                created_at: {
                  label: this.context.t(`receipts.headings.createdAt`),
                  style: { width: 200 },
                  sortable: {
                    0: [{ field: "`receipts`.`created_at`", order: "ASC" }],
                    1: [{ field: "`receipts`.`created_at`", order: "DESC" }],
                  },
                },
                process_date: {
                  label: this.context.t(`receipts.headings.processDate`),
                  style: { width: 200 },
                },
                custname: {
                  label: this.context.t(`receipts.headings.customerFullName`),
                  // with_photo: "customer.profile_photo",
                  style: { width: 300 },
                  limited_line: 1,
                  sortable: {
                    0: [
                      { field: "`customers`.`name`", order: "ASC" },
                      { field: "`customers`.`surname`", order: "ASC" },
                    ],
                    1: [
                      { field: "`customers`.`name`", order: "DESC" },
                      { field: "`customers`.`surname`", order: "DESC" },
                    ],
                  },
                },
                info: {
                  label: this.context.t(`receipts.headings.info`),
                  style: { width: 400 },
                  limited_line: 2,
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={this.state.data}
              loaded={this.state.loaded}
              replacements={{
                custname: {
                  null: this.context.t(
                    `receipts.replacements.undefinedCustomer`
                  ),
                  "": this.context.t(`receipts.replacements.undefinedCustomer`),
                },
              }}
              pagination={this.state.pagination}
              buttons={[
                {
                  title: this.context.t(`receipts.showButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  onClick: (row) =>
                    this.props.history.push(
                      `/receipts/detail/${row.receipt_id}`
                    ),
                },
              ]}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }
}

export default Unbooked;
