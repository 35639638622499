import React from 'react';
import styled from 'styled-components';
import { Grid, Column } from '../theme/Grid';


const AuthorityNotFound = () => {
  return (
    <Grid>
      <Column className="xs-12 sm-12">
        <ContainerAuthority>
          <DetailImage src={require("../assets/images/manypixels/authority.svg")} />
          <InfoCaption>Erişim yetkiniz yok!</InfoCaption>
          <InfoText>
            <b>Bu sayfaya erişim yetkiniz bulunmamaktadır.<br /> Bir problem olduğunu düşünüyorsanız bize iletişim kanallarımız üzerinden ulaşablirsiniz.</b>
          </InfoText>
        </ContainerAuthority>
      </Column>
    </Grid>
  )
}

export default AuthorityNotFound;


const ContainerAuthority = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const DetailImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 32px;
`;

const InfoCaption = styled.h2`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
`;

const InfoText = styled.p`
  font-size: 14px;
  font-weight: 300;
  padding: 0;
  margin: 0 0 16px;

  b {
    font-weight: 500;
  }
`;
