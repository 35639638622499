import React, { useRef, useContext, useState, useEffect } from "react";
import AppContext from "../../../../context/store";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import {
  QuestionAnswer,
  Telegram,
  WhatsApp,
  Message,
} from "@material-ui/icons";

function ChatBox() {
  const context = useContext(AppContext);
  const tawkMessengerRef = useRef();
  const [whatsAppMessage, setWhatsAppMessage] = useState("");

  useEffect(() => {
    setWhatsAppMessage(
      `https://api.whatsapp.com/send?phone=902127060510&text=Merhabalar,%20size%20kampanya%20sayfanız%20%C3%BCzerinden%20ula%C5%9F%C4%B1yorum.`
    );
    console.log(context);
  }, []);

  return (
    <div>
      <nav
        style={{
          position: "fixed",
          bottom: "15%",
          right: "12%",
          zIndex: 99999,
        }}
      >
        <input
          type="checkbox"
          href="#"
          class="menu-open"
          name="menu-open"
          id="menu-open"
          style={{ display: "none" }}
        />

        <label class="menu-open-button" for="menu-open">
          <QuestionAnswer color="secondary" fontSize="large" />
        </label>

        <a
          href="https://t.me/salonsupport"
          target="_target"
          class="menu-item blue"
        >
          <Telegram fontSize="large" />
        </a>

        <a href={whatsAppMessage} target="_blank" class="menu-item green">
          <WhatsApp fontSize="large" />
        </a>

        <a href="javascript:void(Tawk_API.toggle())" class="menu-item red">
          <Message fontSize="large" />
          <TawkMessengerReact
            propertyId="5eb2d120a1bad90e54a240b4"
            widgetId="default"
            ref={tawkMessengerRef}
          />
        </a>
      </nav>
    </div>
  );
}

export default ChatBox;
