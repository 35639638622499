import React, { Component } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";

import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import { toast } from "react-toastify";
import Select from "../../../theme/CustomMUI/Select";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import styled from "styled-components";
import { DateRange } from "@material-ui/icons";
import RemainingDebt from "../../../assets/images/svg/remaining_debt.svg";

import { withStyles } from "@material-ui/styles";
import CardSection from "../../../components/Cards/CardSection";

import Loader from "../../../assets/images/loader.gif";

const styles = (theme) => ({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgba(249, 186, 132, 0.21) !important",
    },
  },
});
class IncomeTransactions extends Component {
  static contextType = AppContext;

  state = {
    loaded: false,
    filter: {
      start_date: this.props.location.state
        ? `${this.props.location.state?.detail.start.split("/")[2]}-${
            this.props.location.state?.detail.start.split("/")[1]
          }-${this.props.location.state?.detail.start.split("/")[0]}`
        : moment().subtract(1, "days").format("YYYY-MM-DD"),
      end_date: this.props.location.state
        ? `${this.props.location.state?.detail.end.split("/")[2]}-${
            this.props.location.state?.detail.end.split("/")[1]
          }-${this.props.location.state?.detail.end.split("/")[0]}`
        : moment().format("YYYY-MM-DD"),
      payment_type: null,
    },
    customers: [],
    selected_customer: null,
    reportsDate: {
      between: {
        start: this.props.location.state
          ? `${this.props.location.state?.detail.start.split("/")[0]}/${
              this.props.location.state?.detail.start.split("/")[1]
            }/${this.props.location.state?.detail.start.split("/")[2]}`
          : moment().subtract(1, "days").format("DD/MM/YYYY"),
        end: this.props.location.state
          ? `${this.props.location.state?.detail.end.split("/")[0]}/${
              this.props.location.state?.detail.end.split("/")[1]
            }/${this.props.location.state?.detail.end.split("/")[2]}`
          : moment().format("DD/MM/YYYY"),
      },
    },
    filter_modal_open: false,
    readyToUseFilter: null,
    income_transactions: {
      data: [],
      pagination: {
        page: 1,
        total_page: 1,
        onChange: (page) => this.getIncomeData(page),
      },
    },
    filterPicker: [
      {
        id: 1,
        label: "Bugün",
        offset: 1,
      },
      {
        id: 2,
        label: "Bu Hafta",
        offset: 3,
      },
      {
        id: 3,
        label: "Bu Ay",
        offset: 4,
      },
    ],

    selected_filter_picker: this.props.location.state
      ? null
      : {
          id: 1,
          label: "Bugün",
          offset: 1,
        },

    total_income: null,

    comingData: this.props.location.state ? this.props.location.state : null,
  };

  getIncomeData = (page = 1) => {
    this.setState({ loaded: false });
    const { filter, selected_filter_picker, selected_customer } = this.state;
    Axios.get(
      `${this.context.api_endpoint}/company/accounting/incomes`,
      selected_filter_picker === null
        ? {
            params: {
              page: page ? page : 1,
              start: moment(filter.start_date).format("YYYY-MM-DD"),
              end: moment(filter.end_date).format("YYYY-MM-DD"),
              customerid: selected_customer ? selected_customer.id : null,
              paymenttype: filter.payment_type
                ? filter.payment_type !== "null"
                  ? filter.payment_type
                  : null
                : null,
              isbetween: true,
            },
          }
        : {
            params: {
              page: page ? page : 1,
              isbetween: false,
              offset: selected_filter_picker.offset,
              customerid: selected_customer ? selected_customer.id : null,
              paymenttype: filter.payment_type
                ? filter.payment_type !== "null"
                  ? filter.payment_type
                  : null
                : null,
            },
          }
    )
      .then(({ data }) => {
        this.setState({
          loaded: true,
          reportsDate: {
            between: {
              start: moment(data.data.start_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
              end: moment(data.data.end_time.split("T")[0])
                .local()
                .format("DD/MM/YYYY"),
            },
          },
          income_transactions: {
            ...this.state.income_transactions,
            data: [
              ...data.data.records.map((item) => {
                return {
                  ...item,
                  amount: item.amount.toFixed(2),
                  payment_date: moment(item.payment_date)
                    .tz("Europe/Istanbul")
                    .format("LL"),
                };
              }),
            ],
            // pagination: {
            //   ...this.state.income_transactions.pagination,
            //   page: data.data.page,
            //   total_page: data.data.records.length,
            // },
          },
          total_income:
            data.data.total_income +
            (this.context.state.currency ? this.context.state.currency : "₺"),
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO: search for customer by name
  getCustomers = (key) => {
    Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    })
      .then(async ({ data }) => {
        await this.setState({ customers: [...data.data.records] });
      })
      .catch((e) => {
        if (e.response.data.code === 401) {
          this.props.history.push("/wallet");
        }
      });

    return this.state.customers;
  };

  componentDidMount() {
    this.getIncomeData();
    this.getCustomers();
    this.unlisten = this.props.history.listen((location, action) => {
      this.state.income_transactions.page !== null &&
        action === "POP" &&
        this.getIncomeData();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { selected_filter_picker, classes } = this.props;
    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[1]}
        limited_permission="income_expense"
      >
        <Grid>
          <Column
            className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
            style={{ borderBottom: "1px solid #b2b2b2" }}
          >
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ height: "63px", display: "flex", alignItems: "center" }}
            >
              <ShortButtonContainer>
                <ShortButton
                  onClick={async () => {
                    await this.setState({
                      selected_filter_picker: {
                        id: 1,
                        label: "Bugün",
                        offset: 1,
                      },
                    });
                    await this.getIncomeData();
                  }}
                  style={{
                    backgroundColor: `${
                      this.state.selected_filter_picker?.id === 1
                        ? "rgb(49, 169, 243, 0.14)"
                        : ""
                    }`,
                    border: "1px solid rgb(91, 187, 245, 0.41)",
                    color: "rgb(49, 169, 243)",
                  }}
                >
                  Bugün
                </ShortButton>
                <ShortButton
                  onClick={async () => {
                    await this.setState({
                      selected_filter_picker: {
                        id: 2,
                        label: "Bu Hafta",
                        offset: 3,
                      },
                    });
                    await this.getIncomeData();
                  }}
                  style={{
                    backgroundColor: `${
                      this.state.selected_filter_picker?.id === 2
                        ? "rgb(29, 139, 62, 0.21)"
                        : ""
                    }`,
                    border: "1px solid rgb(58, 154, 87, 0.35)",
                    color: "rgb(29, 139, 62)",
                  }}
                >
                  Bu Hafta
                </ShortButton>
                <ShortButton
                  onClick={async () => {
                    await this.setState({
                      selected_filter_picker: {
                        id: 3,
                        label: "Bu Ay",
                        offset: 4,
                      },
                    });
                    await this.getIncomeData();
                  }}
                  style={{
                    backgroundColor: `${
                      this.state.selected_filter_picker?.id === 3
                        ? "rgb(254, 151, 151, 0.19)"
                        : ""
                    }`,
                    border: "1px solid rgb(185, 178, 178, 0.65)",
                    color: "rgb(254, 111, 111)",
                  }}
                >
                  Bu Ay
                </ShortButton>
              </ShortButtonContainer>
            </Column>
            <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ borderLeft: "1px solid #b2b2b2" }}
            >
              <FilterContainer>
                <FilterButton
                  onClick={() => {
                    this.setState({
                      filter_modal_open: true,
                      selected_filter_picker: null,
                      ready_to_use_filter: 1,
                      filter: {
                        start_date: moment()
                          .local()
                          .subtract(1, "days")
                          .toISOString(),
                        end_date: moment().local().toISOString(),
                      },
                      reportsDate: {
                        between: {
                          start: moment()
                            .local()
                            .subtract(1, "days")
                            .format("DD/MM/YYYY"),
                          end: moment().local().format("DD/MM/YYYY"),
                        },
                      },
                    });
                  }}
                >
                  {" "}
                  <DateRange />
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {this.state.reportsDate.between.start} -{" "}
                    {this.state.reportsDate.between.end}
                  </span>
                </FilterButton>

                <FilterButton
                  style={{
                    cursor: "auto",
                    backgroundColor: "rgba(208, 233, 233, 0.65)",
                    color: "#54AEAE",
                    border: "1px solid rgba(208, 233, 233, 0.8)",
                  }}
                >
                  <img
                    src={RemainingDebt}
                    alt="remaining_debt"
                    style={{ marginRight: "10px", color: "red" }}
                  />
                  Toplam Gelir{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                    }}
                  >
                    {`${parseInt(this.state.total_income)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}${
                      this.context.state.currency
                        ? this.context.state.currency
                        : "₺"
                    }`}
                  </span>
                </FilterButton>
              </FilterContainer>
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <Column className="xs-12 sm-12 md-12 lg-4">
              <Select
                label={this.context.t(`accounting.typeInputLabel`)}
                items={[
                  { id: "null", label: this.context.t(`accounting.typeALL`) },
                  { id: "0", label: this.context.t(`accounting.typeCASH`) },
                  { id: "1", label: this.context.t(`accounting.typeCARD`) },
                  { id: "2", label: this.context.t(`accounting.typeEFT`) },
                ]}
                labelKey="label"
                valueKey="id"
                selected={`${this.state.filter.payment_type}`}
                handler={async (payment_type) => {
                  await this.setState({
                    filter: {
                      ...this.state.filter,
                      payment_type: payment_type && payment_type,
                    },
                  });
                  await this.getIncomeData();
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <AutocompleteInput
                label={this.context.t(
                  `[receipts/add].searchCustomerInputLabel`
                )}
                labelKey="full_name"
                valueKey="id"
                returnFullObject
                onRemove={async () => {
                  await this.setState({
                    selected_customer: null,
                  });
                  this.getIncomeData();
                }}
                selected={this.state.selected_customer || null}
                selectedHandler={async (selected_customer) => {
                  await this.setState({
                    selected_customer: selected_customer,
                  });
                  setTimeout(async () => {
                    await this.getIncomeData();
                  }, 250);
                }}
                asyncDataService={async (keyword) => this.getCustomers(keyword)}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-4">
              <ExcelButton
                fullWidth={true}
                route="accounting/incomes"
                size="lg"
                requestData={
                  this.state.selected_filter_picker === null
                    ? {
                        start: moment(this.state.filter.start_date).format(
                          "YYYY-MM-DD"
                        ),
                        end: moment(this.state.filter.end_date).format(
                          "YYYY-MM-DD"
                        ),
                        customerid: this.state.selected_customer
                          ? this.state.selected_customer.id
                          : null,
                        paymenttype: this.state.filter.payment_type
                          ? this.state.filter.payment_type !== "null"
                            ? this.state.filter.payment_type
                            : null
                          : null,
                        isbetween: true,
                        is_excel: true,
                      }
                    : {
                        isbetween: false,
                        customerid: this.state.selected_customer
                          ? this.state.selected_customer.id
                          : null,
                        paymenttype: this.state.filter.payment_type
                          ? this.state.filter.payment_type !== "null"
                            ? this.state.filter.payment_type
                            : null
                          : null,
                        offset: this.state.selected_filter_picker.offset,
                        is_excel: true,
                      }
                }
              />
            </Column>
          </Column>
        </Grid>

        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <CardSection
              title={"Gelir İşlemleri"}
              className="no-gutters-all"
              includesColumn={false}
            >
              {this.state.loaded ? (
                <Table
                  refreshAction={() =>
                    this.getIncomeData(
                      this.state.income_transactions.pagination.page
                    )
                  }
                  loaded={this.state.loaded}
                  headings={{
                    payment_date: {
                      label: this.context.t(
                        `['accounting/income'].headings.createdAt`
                      ),
                      sortable: {
                        0: [
                          {
                            field: "`receipt_payments`.`payment_date`",
                            order: "ASC",
                          },
                        ],
                        1: [
                          {
                            field: "`receipt_payments`.`payment_date`",
                            order: "DESC",
                          },
                        ],
                      },
                    },
                    customer_name: {
                      label: this.context.t(
                        `['accounting/income'].headings.customerFullName`
                      ),
                      sortable: {
                        0: [
                          { field: "`customers`.`name`", order: "ASC" },
                          { field: "`customers`.`surname`", order: "ASC" },
                        ],
                        1: [
                          { field: "`customers`.`name`", order: "DESC" },
                          { field: "`customers`.`surname`", order: "DESC" },
                        ],
                      },
                    },
                    amount: {
                      label: this.context.t(
                        `['accounting/income'].headings.amount`
                      ),
                      suffix: this.context.state.currency
                        ? this.context.state.currency
                        : "₺",
                      sortable: {
                        0: [
                          {
                            field: "`receipt_payments`.`amount`",
                            order: "ASC",
                          },
                        ],
                        1: [
                          {
                            field: "`receipt_payments`.`amount`",
                            order: "DESC",
                          },
                        ],
                      },
                    },
                    payment_type: {
                      label: this.context.t(
                        `['accounting/income'].headings.type`
                      ),
                    },
                    _: { label: this.context.t(`component.actionHeadingText`) },
                  }}
                  rows={this.state.income_transactions.data}
                  replacements={{
                    payment_type: {
                      0: this.context.t(`accounting.typeCASH`),
                      1: this.context.t(`accounting.typeCARD`),
                      2: this.context.t(`accounting.typeEFT`),
                    },
                  }}
                  pagination={this.state.income_transactions.pagination}
                  buttons={[
                    {
                      title: this.context.t(`accounting.showOnReceipt`),
                      icon: "launch",
                      transitionEffect: true,
                      onClick: (row) =>
                        this.props.history.push(
                          `/receipts/detail/${row.receipt_id}`
                        ),
                    },
                  ]}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img src={Loader} width="100" height="100" alt="loading" />
                </div>
              )}
            </CardSection>
          </Column>
        </Grid>

        <AlertDialog
          title={<h3 style={{ textAlign: "center" }}>Zaman Aralığı Seçiniz</h3>}
          disableBackdropClick={false}
          open={this.state.filter_modal_open}
          maxWidth="sm"
          closeHandler={() => {
            this.setState({
              filter_modal_open: false,
            });
          }}
          buttons={[
            {
              title: "Onayla",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: async () => {
                await this.getIncomeData();
              },
            },
            {
              title: "İptal",
              icon: "close",
              textColor: "grey",
              onClick: () => {
                this.setState({
                  readyToUseFilter: null,
                  selected_filter_picker: {
                    id: 1,
                    label: "Bugün",
                    offset: 1,
                  },
                });
              },
            },
          ]}
        >
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
              <Column className="xs-12 sm-12 md-12 lg-6">
                <ShortButtonContainer
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <ShortButton
                    style={{
                      border: "1px solid rgba(249,186,132, 0.51)",
                      color: "rgb(219,146,92)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 1
                          ? "rgba(249,186,132, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 1,
                        filter: {
                          start_date: moment()
                            .local()
                            .subtract(1, "days")
                            .toISOString(),
                          end_date: moment().local().toISOString(),
                        },
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(1, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Dün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(91, 187, 245, 0.41)",
                      color: "rgb(49, 169, 243)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 2
                          ? "rgb(49, 169, 243, 0.14)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 2,
                        filter: {
                          start_date: moment()
                            .local()
                            .subtract(7, "days")
                            .toISOString(),
                          end_date: moment().local().toISOString(),
                        },
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(7, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Son 7 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(58, 154, 87, 0.35)",
                      color: "rgb(29, 139, 62)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 3
                          ? "rgb(29, 139, 62, 0.21)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 3,
                        filter: {
                          start_date: moment()
                            .local()
                            .subtract(15, "days")
                            .toISOString(),
                          end_date: moment().local().toISOString(),
                        },
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(15, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Son 15 gün
                  </ShortButton>
                  <ShortButton
                    style={{
                      border: "1px solid rgb(185, 178, 178, 0.65)",
                      color: "rgb(254, 111, 111)",
                      backgroundColor: `${
                        this.state.readyToUseFilter === 4
                          ? "rgb(254, 151, 151, 0.19)"
                          : ""
                      }`,
                    }}
                    onClick={() => {
                      this.setState({
                        readyToUseFilter: 4,
                        filter: {
                          start_date: moment()
                            .local()
                            .subtract(30, "days")
                            .toISOString(),
                          end_date: moment().local().toISOString(),
                        },
                        reportsDate: {
                          between: {
                            start: moment()
                              .local()
                              .subtract(30, "days")
                              .format("DD/MM/YYYY"),
                            end: moment().local().format("DD/MM/YYYY"),
                          },
                        },
                      });
                    }}
                  >
                    Son 30 gün
                  </ShortButton>
                </ShortButtonContainer>
              </Column>

              <Column
                className="xs-12 sm-12 md-12 lg-6"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #b2b2b2",
                    height: "100%",
                  }}
                >
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={this.context.t(`reports.startInputLabel`)}
                      value={new Date(this.state.filter.start_date)}
                      maxDate={new Date(this.state.filter.end_date)}
                      onChange={async (date) => {
                        this.setState({
                          readyToUseFilter: null,
                          filter: {
                            ...this.state.filter,
                            start_date: moment(date).local().toISOString(),
                          },
                          reportsDate: {
                            ...this.state.reportsDate,
                            between: {
                              ...this.state.reportsDate.between,
                              start: moment(date).local().format("DD/MM/YYYY"),
                            },
                          },
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12">
                    <DatePickerInput
                      label={this.context.t(`reports.endInputLabel`)}
                      value={
                        new Date(
                          String(this.state.filter.end_date).split("T")[0]
                        )
                      }
                      minDate={this.state.filter.start_date}
                      onChange={async (date) => {
                        this.setState({
                          readyToUseFilter: null,
                          filter: {
                            ...this.state.filter,
                            end_date: moment(date).local().toISOString(),
                          },
                          reportsDate: {
                            ...this.state.reportsDate,
                            between: {
                              ...this.state.reportsDate.between,
                              end: moment(date).local().format("DD/MM/YYYY"),
                            },
                          },
                        });
                      }}
                    />
                  </Column>
                </div>
              </Column>
            </Column>
          </Grid>
        </AlertDialog>
      </AuthContainer>
    );
  }
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 10px;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
`;

export default withStyles(styles)(IncomeTransactions);
