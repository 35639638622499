import React, { useState } from "react";
import { ImYoutube } from "react-icons/im";
import Dialog from "@material-ui/core/Dialog";
import YouTube from "react-youtube";
import { IoMdClose } from "react-icons/io";
import youtube_image from "../assets/images/youtube_image.jpg";

function YoutubeTutorial(props) {
  const [youtube, setYoutube] = useState(false);

  const opts = {
    height: "360",
    width: "600",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div>
      <img
        onClick={() => setYoutube(true)}
        src={youtube_image}
        style={{ width: "80px", objectFit: "contain", cursor: "pointer" }}
      />
      <Dialog open={youtube} onClose={() => setYoutube(false)}>
        <div className="youtube_tutorial_alert_wrapper">
          <div className="youtube_tutorial_alert_close">
            <IoMdClose
              onClick={() => setYoutube(false)}
              style={{
                cursor: "pointer",
                marginRight: "2px",
                marginTop: "2px",
              }}
              size={"23px"}
              color="white"
            />
          </div>
          <div className="youtube_tutorial_alert_video">
            <YouTube videoId={props.videoId} opts={opts} />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default YoutubeTutorial;
