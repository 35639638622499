import React from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";

const _type_model = {
  custom_expense_1: null,
  custom_expense_2: null,
  custom_expense_3: null,
  custom_expense_4: null,
  custom_expense_5: null,
  custom_expense_6: null,
  custom_expense_7: null,
  custom_expense_8: null,
  custom_expense_9: null,
  custom_expense_10: null,
  custom_expense_11: null,
  custom_expense_12: null,
  custom_expense_13: null,
  custom_expense_14: null,
  custom_expense_15: null,
  custom_expense_16: null,
  custom_expense_17: null,
  custom_expense_18: null,
  custom_expense_19: null,
  custom_expense_20: null,
  custom_expense_21: null,
  custom_expense_22: null,
  custom_expense_23: null,
  custom_expense_24: null,
  custom_expense_25: null,
  custom_expense_26: null,
  custom_expense_27: null,
  custom_expense_28: null,
  custom_expense_29: null,
  custom_expense_30: null,
  custom_expense_31: null,
  custom_expense_32: null,
  custom_expense_33: null,
  custom_expense_34: null,
  custom_expense_35: null,
  custom_expense_36: null,
  custom_expense_37: null,
  custom_expense_38: null,
  custom_expense_39: null,
  custom_expense_40: null,
  custom_expense_41: null,
  custom_expense_42: null,
  custom_expense_43: null,
  custom_expense_44: null,
  custom_expense_45: null,
  custom_expense_46: null,
  custom_expense_47: null,
  custom_expense_48: null,
  custom_expense_49: null,
  custom_expense_50: null,
};

class ExpenseTypes extends React.Component {
  static contextType = AppContext;

  state = {
    types: { ..._type_model },
    income_types: { ..._type_model }, // İşlem yapma üzerinde sunucudan gelen yanıta göre işlem yapıyor
    page: 1,
  };

  getExpenseTypes = () => {
    Axios.get(`${this.context.api_endpoint}/company/expense/categories`)
      .then(({ data }) => {
        const types =
          data.data.name !== "" && data.data.name !== "{}"
            ? JSON.parse(data.data.name)
            : { ..._type_model };
        this.setState({
          types: { ...this.state.types, ...types },
          income_types: { ...this.state.income_types, ...types },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  insertExpenseTypes = () => {
    Axios.post(`${this.context.api_endpoint}/company/expense/category/insert`, {
      name: { ...this.state.types },
    })
      .then((response) => {
        response.status === 201 &&
          toast.success(
            this.context.t(`['accounting/expense/types'].insertToast`)
          );
        this.getExpenseTypes();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  updateExpenseTypes = () => {
    Axios.put(`${this.context.api_endpoint}/company/expense/category/update`, {
      name: JSON.stringify({ ...this.state.types }),
    })
      .then((response) => {
        response.status === 200 &&
          toast.success(
            this.context.t(`['accounting/expense/types'].insertToast`)
          );
        this.getExpenseTypes();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  doAction = () => {
    const { income_types } = this.state;

    //Kayıt Varlık Kontrolüne Göre İşlem
    let count = Object.keys(income_types).filter(
      (key) => income_types[key] !== null
    ).length;

    count > 0 ? this.updateExpenseTypes() : this.insertExpenseTypes();
  };

  componentDidMount() {
    this.getExpenseTypes();
  }

  getEndNumber = () => {
    switch (this.state.page) {
      case 1:
        return 20;
      // break;
      case 2:
        return 28;
      // break;
      case 3:
        return 36;
      // break;
      case 4:
        return 44;
      // break;
      default:
        return 50;
    }
  };

  render() {
    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[1]}
        limited_permission="income_expense"
      >
        <Grid alignItems="center" justify="center">
          <Column
            className="xs-12 sm-12 mb-3"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Alert severity="info" style={{ width: "100%" }}>
              <b>
                {this.context.t(`['accounting/expense/types'].alertBoldText`)}
              </b>
              <br />
              {this.context.t(`['accounting/expense/types'].alertText`)}
            </Alert>
          </Column>

          {Object.keys(this.state.types)
            .sort((a, b) => {
              const a_arr = a.split("_");
              const b_arr = b.split("_");
              return parseInt(a_arr[2]) > parseInt(b_arr[2]) ? 1 : -1;
            })
            .slice(0, this.getEndNumber())
            .map((key, index) => (
              <Column className="xs-12 sm-12 md-6 lg-4 xl-3">
                <Input
                  key={index}
                  label={this.context.t(
                    `['accounting/expense/types'].typeInputLabel`,
                    {
                      i: index + 1,
                    }
                  )}
                  value={this.state.types[key] ? this.state.types[key] : ""}
                  onChange={(e) =>
                    this.setState({
                      types: {
                        ...this.state.types,
                        [key]: e.target.value,
                      },
                    })
                  }
                />
              </Column>
            ))}

          {this.state.page < 5 ? (
            <Column
              className="xs-12 sm-12 md-12 lg-12 mb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                title={this.context.t(
                  `['accounting/expense/types'].moreButtonTitle`
                )}
                backgroundColor="primary"
                textColor="white"
                onClick={() =>
                  this.setState({
                    ...this.state,
                    page: this.state.page + 1,
                  })
                }
              />
            </Column>
          ) : null}

          <Column className="xs-12 sm-12 ">
            <Button
              icon="check"
              title={this.context.t(
                `['accounting/expense/types'].saveChangesButtonTitle`
              )}
              backgroundColor="primary"
              textColor="white"
              fullWidth={true}
              onClick={() => this.doAction()}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }
}

export default ExpenseTypes;
