import React from "react";
import { SvgIcon } from "@material-ui/core";

function Home() {
  return (
    <SvgIcon
      width="35"
      height="35"
      viewBox="0 0 35 35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.6594 0.266064C15.7744 0.511064 16.0194 0.286061 8.17236 8.11906C4.09936 12.1921 0.672359 15.6591 0.564359 15.8191C0.155544 16.4341 -0.0279714 17.1716 0.0449336 17.9064C0.117839 18.6413 0.442676 19.3284 0.964359 19.8511C1.27051 20.1572 1.63503 20.3989 2.0363 20.5615C2.43757 20.7242 2.86742 20.8046 3.30036 20.7981H3.76336V26.2471C3.76336 29.9321 3.79036 31.8051 3.83836 32.0471C3.98873 32.6843 4.29629 33.2738 4.73298 33.7617C5.16967 34.2495 5.72159 34.6203 6.33836 34.8401C6.78836 34.9971 6.82236 34.9971 10.1114 34.9971C13.3534 34.9971 13.4284 34.9971 13.6114 34.8541C13.7336 34.7591 13.8434 34.6493 13.9384 34.5271C14.0814 34.3431 14.0814 34.2551 14.1154 29.7931L14.1494 25.2431L14.3494 24.9091C14.4952 24.6914 14.6851 24.5067 14.9068 24.367C15.1285 24.2273 15.377 24.1357 15.6364 24.0981C15.8274 24.0711 16.7804 24.0641 17.7544 24.0781L19.5254 24.0981L19.8864 24.2981C20.2164 24.4782 20.4847 24.7535 20.6564 25.0881C20.8076 26.6542 20.8507 28.229 20.7854 29.8011C20.8194 34.2561 20.8194 34.3441 20.9624 34.5281C21.0573 34.6503 21.1672 34.7601 21.2894 34.8551C21.4734 34.9981 21.5484 34.9981 24.7894 34.9981C28.0794 34.9981 28.1134 34.9981 28.5624 34.8411C29.1791 34.6213 29.731 34.2505 30.1677 33.7627C30.6044 33.2748 30.912 32.6853 31.0624 32.0481C31.1104 31.8031 31.1374 29.9301 31.1374 26.2481V20.7991H31.6004C32.375 20.8124 33.1284 20.5454 33.7217 20.0473C34.3151 19.5491 34.7084 18.8533 34.8294 18.0881C34.9271 17.4328 34.825 16.7634 34.5364 16.1671C34.3364 15.7581 33.7464 15.1451 26.9214 8.31407C22.8554 4.24107 19.3814 0.814078 19.2044 0.699078C18.9882 0.56332 18.7603 0.447174 18.5234 0.352063C17.9194 0.165585 17.2779 0.13599 16.6594 0.266064Z" />
    </SvgIcon>
  );
}

export default Home;
