import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import AppContext from '../../context/store';
import { Column, Grid } from '../../theme/Grid';

const SenderFetchAlert = () => {
  const context = useContext(AppContext);

  if (context.state.company_sms_settings_loaded === false) {
    return (
      <Grid>
        <Column className="xs-12 sm-12 mb-3">
          <Alert severity="info">
            <b>SMS gönderici bilgileriniz alınıyor...</b><br />
            Bu işlem biraz sürebilir, lütfen bekleyiniz.
          </Alert>
        </Column>
      </Grid>
    );
  }

  return <></>
};

export default SenderFetchAlert;