import React from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import RoundedRow from "../../../components/Table/RoundedRow";
import { Alarm, AlarmOff, AlarmOn, EventNote } from "@material-ui/icons";

class OnlineAppointments extends React.Component {
  static contextType = AppContext;

  state = {
    delete_confirm: false,
    delete_id: null,

    loaded: false,

    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getAppointmentRequests(page),
    },
    data: [],
    checkConfirmData: [],

    dialCode: null,
    phone: null,
    add_blockList_popup: false,
  };

  getAppointmentRequests = (page) => {
    Axios.get(`${this.context.api_endpoint}/company/appointments/requests`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        // console.log(data)
        this.setState({
          loaded: true,
          pagination: {
            ...this.state.pagination,
            page: data.data.page,
            total_page: data.data.records.length,
          },
          data: [
            ...data.data.records.map((item) => ({
              ...item,
              customer_statics: {
                ...item.customer_statics,
                sumCol: (
                  <>
                    <RoundedRow color="blue">
                      <EventNote />{" "}
                      {item.customer_statics.success +
                        item.customer_statics.wait +
                        item.customer_statics.fail}
                    </RoundedRow>
                    <RoundedRow color="green">
                      <AlarmOn /> {item.customer_statics.success}
                    </RoundedRow>
                    <RoundedRow color="orange">
                      <Alarm /> {item.customer_statics.wait}
                    </RoundedRow>
                    <RoundedRow color="red">
                      <AlarmOff /> {item.customer_statics.fail}
                    </RoundedRow>
                  </>
                ),
              },
              appointment_start_date: moment(item.appointment_start_date)
                .tz("Europe/Istanbul")
                .format("DD/MM/YYYY HH:mm"),
            })),
          ].filter((item) => item !== undefined),
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  approveSingleAppointmentRequest = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/appointment/request/approve`,
      {
        request_id: id,
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`['appointments/online'].approveToast`));
          this.getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  rejectSingleAppointmentRequest = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/appointment/request/${id}/cancel`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`['appointments/online'].rejectToast`));
          this.getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  addBlacklist = () => {
    Axios.post(`${this.context.api_endpoint}/company/shortcode/add/blacklist`, {
      phone: this.state.phone ? this.state.phone : null,
      dialCode: this.state.dialCode ? this.state.dialCode : null,
    })
      .then(() => {})
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        this.setState({
          dialCode: null,
          phone: null,
        });
      });
  };

  componentDidMount() {
    this.getAppointmentRequests();
  }

  render() {
    return (
      <AuthContainer limited_permission="appointment">
        <Grid>
          <Column className="xs-12 sm-12">
            {this.context.state.user.permission !== 1 && (
              <Alert className="mb-3" severity="info">
                {this.context.t(`['appointments/online'].alertText`)}
              </Alert>
            )}
            <Table
              refreshAction={() =>
                this.getAppointmentRequests(this.state.pagination.page)
              }
              loaded={this.state.loaded}
              headings={{
                appointment_start_date: {
                  label: this.context.t(
                    `['appointments/online'].headings.appointmentStartDate`
                  ),
                  style: { width: 150 },
                },
                customer_full_name: {
                  label: this.context.t(
                    `['appointments/online'].headings.customerFullName`
                  ),
                  //with_photo: "customer.photo",
                  limited_line: 3,
                  style: { width: "200px" },
                },
                customer_phone: {
                  label: this.context.t(
                    `['appointments/online'].headings.customerPhone`
                  ),
                },
                // "customer_type": {
                //   label: this.context.t(`['appointments/online'].headings.customerType`),
                //  },
                "staff.full_name": {
                  label: this.context.t(
                    `['appointments/online'].headings.staffFullName`
                  ),
                  with_photo: "staff.detail.profile_photo",
                  limited_line: 1,
                },
                "service.name": {
                  label: this.context.t(
                    `['appointments/online'].headings.serviceName`
                  ),
                  limited_line: 1,
                },
                note: {
                  label: this.context.t(
                    `['appointments/online'].headings.note`
                  ),
                  limited_line: 1,
                },
                "customer_statics.sumCol": {
                  label: this.context.t(`dashboard.stats.appointment.heading`),
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={this.state.data}
              replacements={this.state.replacements}
              pagination={this.state.pagination}
              buttons={[
                {
                  /* title: this.context.t(
                    `['appointments/online'].approveButtonTitle`
                  ) */
                  icon: "check",
                  textColor: "green",
                  transitionEffect: true,
                  pushEffect: true,
                  disabled: this.context.state.user.permission === 0,

                  onClick: (row) => {
                    this.setState({
                      checkConfirmData: [
                        ...this.state.checkConfirmData,
                        row.id,
                      ],
                    });
                    if (!this.state.checkConfirmData.includes(row.id)) {
                      this.approveSingleAppointmentRequest(row.id);
                    }
                  },
                },
                {
                  /*  title: this.context.t(
                    `['appointments/online'].rejectButtonTitle`
                  ), */
                  icon: "close",
                  textColor: "red",
                  transitionEffect: true,
                  pushEffect: true,
                  disabled: this.context.state.user.permission === 0,
                  onClick: (row) =>
                    this.setState({
                      delete_id: row.id,
                      delete_confirm: true,
                    }),
                },
                {
                  icon: "block",
                  textColor: "black",
                  transitionEffect: true,
                  pushEffect: true,
                  disabled: this.context.state.user.permission === 0,
                  onClick: (row) => {
                    this.setState({
                      dialCode: row?.dialCode,
                      phone: row?.customer_phone,
                      add_blockList_popup: true,
                      delete_id: row.id,
                    });
                  },
                },
              ]}
            />

            <AlertDialog
              title={this.context.t(
                `['appointments/online'].rejectConfirm.title`
              )}
              open={this.state.delete_confirm}
              closeHandler={() => this.setState({ delete_confirm: false })}
              buttons={[
                {
                  title: this.context.t(
                    `['appointments/online'].rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () =>
                    this.rejectSingleAppointmentRequest(this.state.delete_id),
                },
                {
                  title: this.context.t(
                    `['appointments/online'].rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {this.context.t(
                    `['appointments/online'].rejectConfirm.alertBoldText`
                  )}
                </b>
                <br />
                {this.context.t(
                  `['appointments/online'].rejectConfirm.alertText`
                )}
              </Alert>
            </AlertDialog>

            <AlertDialog
              title={
                <h5>
                  {this.context.t(
                    `['appointments/online'].addToNumberBlackList`
                  )}{" "}
                  ({this.state.phone})
                </h5>
              }
              open={this.state.add_blockList_popup}
              closeHandler={() => this.setState({ add_blockList_popup: false })}
              buttons={[
                {
                  title: this.context.t(
                    `['appointments/online'].rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () => {
                    this.addBlacklist();
                    this.rejectSingleAppointmentRequest(this.state.delete_id);
                  },
                },
                {
                  title: this.context.t(
                    `['appointments/online'].rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                {this.context.t(`['appointments/online'].blackListWarning`)}
              </Alert>
            </AlertDialog>
          </Column>
        </Grid>
      </AuthContainer>
    );
  }
}

export default OnlineAppointments;
