import { all, call, put, takeEvery } from "redux-saga/effects";
import { api_endpoint } from "../../../../redux/store";
import { showReceipt } from "./reducer";
import { ReceiptTypes } from "./types";

import Axios from "axios";

function* showReceiptHandler(data) {
  try {
    const response = yield call(() =>
      Axios.get(`${api_endpoint}/company/receipts/opened`, {
        params: data.payload.params,
      })
    );
    yield put(showReceipt(response.data.data));
  } catch (error) {
    console.log("err", error);
  }
}

export function* showReceiptSaga() {
  yield all([takeEvery(ReceiptTypes.SHOW_RECEIPT, showReceiptHandler)]);
}
