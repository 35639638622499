import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import AppContext from "../../context/store";
// import Button from '../../theme/Button';
import moment from "moment";
import Axios from "axios";
// import { contextType } from "react-copy-to-clipboard";
import AppointmentNotification from "../Dialogs/AppointmentNotification";
import { flushSync } from "react-dom";

class NotificationsView extends Component {
  static contextType = AppContext;
  state = {
    notifications: [],
  };

  setReadNotifications = () => {
    Axios.post(`${this.context.api_endpoint}/company/notification/read`, {
      notification_id: [
        ...this.state.notifications
          .filter((notif) => notif.is_read === false)
          .map((notif) => notif.id),
      ],
    }).then(async (response) => {
      if (response.status === 201) {
        await this.setState({
          notifications: [
            ...this.state.notifications.map((notif) => ({
              ...notif,
              is_read: true,
            })),
          ],
        });

        await this.props.unreadCountHandler(0);
      }
    });
  };

  setAppointmentsReadNotifications = () => {
    Axios.post(`${this.context.api_endpoint}/company/notification/read`, {
      notification_id: [
        ...this.state.notifications
          .filter(
            (notif) =>
              notif.description ===
                "Yeni bir randevunuz oluştu. İyi çalışmalar dileriz." &&
              notif.is_read === false
          )
          .map((notif) => notif.id),
      ],
    }).then(async (response) => {
      if (response.status === 201) {
        await this.setState({
          notifications: [
            ...this.state.notifications.map((notif) => ({
              ...notif,
            })),
          ],
        });

        await this.props.unreadCountHandler(
          this.state.notifications.filter(
            (notification) =>
              notification.description !==
                "Yeni bir randevunuz oluştu. İyi çalışmalar dileriz." &&
              notification.is_read === false
          ).length
        );
      }
    });
  };

  endpointGenerator = () => {
    return this.context.api_endpoint
      .replace(/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/, "")
      .replace(/(\/api\/v1)/, "");
  };

  conn = new WebSocket(
    `wss://${this.endpointGenerator()}/ws?id=${
      this.context.state.company_id * 924
    }`
  );
  componentDidMount() {
    this.conn.onmessage = async (e) => {
      const notifications = e.data.split("\n");
      flushSync(() => {
        this.setState({
          notifications: [
            ...notifications
              .map((notif) => JSON.parse(notif))
              .filter(
                (notif) =>
                  notif.type !== 2 &&
                  notif.description ===
                    "Yeni bir randevunuz oluştu. İyi çalışmalar dileriz."
              ),
            ...this.state.notifications,
          ],
        });
      });
      await this.props.unreadCountHandler(
        this.state.notifications.filter((notif) => notif.is_read === false)
          .length
      );

      let appointmentNotification = false;

      this.state.notifications.forEach((notification) => {
        if (
          notification.description ===
            "Yeni bir randevunuz oluştu. İyi çalışmalar dileriz." &&
          notification.is_read === false
        ) {
          appointmentNotification = true;
        }
      });

      appointmentNotification &&
        this.context.dispatch({
          type: "SET_APPOINTMENT_NOTIFICATION_DIALOG",
          payload: { open_appointment_notificaiton_dialog: true },
        });
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state && this.props.state === true)
      this.setReadNotifications();
  }

  render() {
    const { notifications } = this.state;
    return (
      <BoxPopContainer
        className={this.props.state ? "show" : "hide"}
        onMouseOver={this.props.stopPropagation}
        onClick={this.props.stopPropagation}
      >
        <AppointmentNotification
          readNotifications={this.setAppointmentsReadNotifications}
        />
        <BoxPopInner>
          <BoxPopCaption>
            {this.context.t(`scaffold.notificationViewHeading`)}
          </BoxPopCaption>
          {notifications.length > 0 ? (
            <ListOverflow>
              {notifications.map((notification, index) => (
                <NotificationCard key={index}>
                  {/*<pre>{JSON.stringify(notification, 0, 2)}</pre>*/}
                  <p>{notification.description ?? ""}</p>
                  <span className="sub" style={{ display: "flex" }}>
                    {moment(notification.created_at)
                      .local()
                      .format("DD/MM/YYYY HH:mm")}
                  </span>
                </NotificationCard>
              ))}
            </ListOverflow>
          ) : (
            <NoDataContainer>
              <img
                src={require("../../assets/images/manypixels/click_action.svg")}
                alt="Malesef bulunamadı!"
              />
              <p>{this.context.t("scaffold.notFoundNotification")}</p>
            </NoDataContainer>
          )}

          {/*<Button
            className="mt-1"
            icon="label_off"
            title="Tümünü Okundu İşaretle"
            textColor="primary"
            size="sm"
            fullWidth
          />*/}
        </BoxPopInner>
      </BoxPopContainer>
    );
  }
}

export default withRouter(NotificationsView);

const BoxPopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;

  position: fixed;
  z-index: 10000000000000;
  right: 168px;
  top: 66px;

  width: 380px;
  max-width: 80vw;

  background: transparent;
  opacity: 0;

  &.show {
    opacity: 1;
    visibility: visible;
    transition: backdrop-filter 0s, all ease 0.25s;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.25s;
  }

  @media only screen and (max-width: 576px) {
    right: 20px !important;
    max-width: calc(100vw - 40px) !important;
  }
`;

const BoxPopCaption = styled.h4`
  font-weight: 600;
  letter-spacing: 0.25px;
  letter-spacing: 0.75px;
  font-size: 11px;
  color: #aaa;
  padding: 8px 0;
  margin: 0;
`;

const BoxPopInner = styled.div`
  width: 100%;
  padding: 8px 16px 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
`;

const ListOverflow = styled.div`
  max-height: 40vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    transition: all ease 0.35s;
  }

  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #d0d0d0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
    transition: all ease 0.35s;
  }
`;

const NotificationCard = styled.div`
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.025);
  border: 1px solid #fafafa;
  border-radius: 5px;
  margin: 0 0 8px;

  transition: all ease 0.5s;

  p {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    font-weight: 500;
    margin: 0 0 2px;
    white-space: pre-wrap;
  }
  span.sub {
    font-size: 12px;
    margin-right: 16px;
    display: inline-block;
    color: #606060;
  }

  span.leading {
    font-size: 11px;
    font-weight: 600;
    color: #606060;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;
