import React, { useContext } from "react";
import AppContext from "../../context/store";
import styled from "styled-components";
import { ButtonBase } from "@material-ui/core";

import moment from "moment";
import "moment/locale/tr";
import "moment/locale/en-gb";

const LanguageButton = ({ lang, heading }) => {
  const context = useContext(AppContext);
  // return <></>
  return (
    <FlagContainer>
      <ButtonBase
        style={{ fontFamily: "Inter, sans-serif !important;" }}
        onClick={() => {
          context.dispatch({
            type: "CHANGE_DEFAULT_LANGUAGE",
            payload: { default_locale: lang },
          });

          moment.locale(lang);
          window.location.reload();
        }}
      >
        <Flag src={require(`../../assets/images/flags/${lang}.svg`)} />
        <p>{heading}</p>
      </ButtonBase>
    </FlagContainer>
  );
};

export default LanguageButton;

const FlagContainer = styled.div`
  display: inline-flex;
  margin-bottom: 8px;

  &:nth-last-child(1) {
    margin: 0;
  }

  button {
    font-family: "Inter", sans-serif !important;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const Flag = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-right: 8px;

  & + p {
    font-size: 12px !important;
    color: #303030;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
`;
