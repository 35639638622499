import React, { useState } from "react";
import YouTube from "react-youtube";
import { Grid, Column } from "../../../../theme/Grid";
import video1 from "../../../../assets/images/Thumbnail_1.png";
import video2 from "../../../../assets/images/Thumbnail_2.png";
import video3 from "../../../../assets/images/Thumbnail_3.png";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import { useMediaQuery } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { FaPlayCircle } from "react-icons/fa";
import styles from "./Videos.module.css";

const Videos = () => {
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [videoId, setVideoId] = useState(null);
  const [open, setOpen] = useState(false);

  const opts = {
    height: "390",
    width: fullScreen ? "320" : "560",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div
      className="container"
      style={{ marginTop: "150px", marginBottom: "100px" }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "36px",
                fontWeight: 700,
                lineHeight: "1.2",
              }}
            >
              Salon Randevu ile{" "}
              <span style={{ color: "rgb(213, 10, 88)" }}>
                1 Adım Önde Olun!
              </span>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                textAlign: "center",
                opacity: "0.6",
                fontSize: "16px",
                lineHeight: "1.2",
                marginTop: "10px",
                fontWeight: 500,
              }}
            >
              Kullanıcılarımızın Salon Randevu hakkındaki görüşlerini dinleyin!
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-6 lg-4 xl-4 mt-2 ">
            <div
              className={styles.videosWrapper}
              onClick={() => {
                setVideoId("AyBtndhV29M");
                setOpen(true);
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FaPlayCircle
                size={70}
                color="white"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "42%",
                  boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.2)",
                  cursor: "pointer",
                }}
              />
              <img
                style={{ width: "100%", borderRadius: "18px" }}
                src={video1}
              />
            </div>
          </Column>
          <Column className="xs-12 sm-12 md-6 lg-4 xl-4 mt-2 ">
            <div
              className={styles.videosWrapper}
              onClick={() => {
                setVideoId("cUPpSoVDvdM");
                setOpen(true);
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FaPlayCircle
                size={70}
                color="white"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "42%",
                  boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.2)",
                  cursor: "pointer",
                }}
              />
              <img
                style={{ width: "100%", borderRadius: "18px" }}
                src={video2}
              />
            </div>
          </Column>
          <Column className="xs-12 sm-12 md-6 lg-4 xl-4 mt-2 ">
            <div
              className={styles.videosWrapper}
              onClick={() => {
                setVideoId("fwnOwfuKETQ");
                setOpen(true);
              }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <FaPlayCircle
                size={70}
                color="white"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "42%",
                  boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.2)",
                  cursor: "pointer",
                }}
              />
              <img
                style={{ width: "100%", borderRadius: "18px" }}
                src={video3}
              />
            </div>
          </Column>
        </Grid>
      </div>
      <AlertDialog
        maxWidth="sm"
        backgroundColor="black"
        style={{ padding: "0px", margin: "0px" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div>
          <div
            onClick={() => setOpen(false)}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              color: "white",
              cursor: "pointer",
            }}
          >
            <AiOutlineClose size={22} />
          </div>
          <YouTube style={{ width: "100%" }} videoId={videoId} opts={opts} />
        </div>
      </AlertDialog>
    </div>
  );
};

export default Videos;
