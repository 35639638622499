import React from "react";
import "../assets/style/component/Button.scss";
import { ButtonBase } from "@material-ui/core";

const Button = (props) => {
  const button_disabled_status = (() => {
    if (props.disabled) {
      if (typeof props.disabled === "function") {
        return props.disabled();
      } else {
        return props.disabled;
      }
    } else {
      return false;
    }
  })();

  return (
    <ButtonBase
      disabled={button_disabled_status}
      type={props.type ? props.type : "button"}
      onClick={
        props.pushEffect === true
          ? () => setTimeout(props.onClick, 200)
          : props.onClick
      }
      style={props.style}
      className={`btn${props.className ? ` ${props.className}` : ""}${
        props.size ? ` btn-${props.size}` : ` btn-sm`
      }${props.fullWidth === true ? ` full-width` : ``}${
        props.textColor ? ` fc-${props.textColor}` : " fc-primary"
      }${
        props.backgroundColor
          ? ` bg-${props.backgroundColor}`
          : " bg-transparent"
      }${props.outlined === true ? ` border` : ""}${
        button_disabled_status ? " btn-disabled" : ""
      }${props.transitionEffect === true ? ` btn-transition-effect` : ""}`}
    >
      <div
        className={"button-container"}
        style={props.containerStyle ? props.containerStyle : {}}
      >
        {props.icon && (
          <i
            className="material-icons"
            style={{
              marginRight: props.title ? 4 : 0,
              fontSize: props.iconSize ? props.iconSize : 17,
              color: props.iconColor ? props.iconColor : "",
            }}
          >
            {props.icon}
          </i>
        )}

        {props.iconComponent && (
          <props.iconComponent
            style={{
              marginRight: props.title ? 4 : 0,
              height: props.iconSize ?? 17,
              color: props.iconColor ? props.iconColor : "",
            }}
          />
        )}
        {props.title && !props.title.includes("WhatsApp") && (
          <span
            className={props.limitedLine ? props.limitedLine : "limited-line-3"}
            style={{ lineHeight: 1.4 }}
          >
            {props.title}
          </span>
        )}
        {props.title && props.title.includes("WhatsApp") && (
          <>
            <span
              className={
                props.limitedLine ? props.limitedLine : "limited-line-3"
              }
              style={{
                lineHeight: 1.4,
                color: props.title.includes("WhatsApp") ? "" : "",
              }}
            >
              {props.title.split(" ").filter((m) => m !== "WhatsApp")[0]}
            </span>
            <span
              className={
                props.limitedLine ? props.limitedLine : "limited-line-3"
              }
              style={{
                lineHeight: 1.4,
                color: props.title.includes("WhatsApp") ? "green" : "",
                marginLeft: "5px",
              }}
            >
              {props.title
                .split(" ")
                .filter((m) => m === "WhatsApp")
                .join(" ")}
            </span>
            <span
              className={
                props.limitedLine ? props.limitedLine : "limited-line-3"
              }
              style={{
                lineHeight: 1.4,
                color: props.title.includes("WhatsApp") ? "" : "",
                marginLeft: "5px",
              }}
            >
              {props.title.split(" ").filter((m) => m !== "WhatsApp")[1]}
            </span>
          </>
        )}
      </div>
    </ButtonBase> // color: props.title.includes("WhatsApp") ? "green" : "",
  );
};

export default React.memo(Button);
