/* eslint-disable */
import React, { Component, useEffect, useState, useRef } from "react";
import AppContext from "../../../context/store";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import Button from "../../../theme/Button";

import Input from "../../../theme/CustomMUI/Input";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Select from "../../../theme/CustomMUI/Select";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";

import moment from "moment";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";
import { getCities } from "../../../functions/CityDistrict";
import getDayIndex from "../../../functions/GetDayIndex";
import { toast } from "react-toastify";
import {
  Grid,
  Container,
  // CircularProgress,
  ButtonBase,
  Dialog,
  // makeStyles,
  // TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { StorefrontOutlined, AccountCircleOutlined } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
// import LanguageButton from "../../../components/Buttons/LanguageButton";
// import { LanguagesContainer } from "../../../components/Scaffold/TranslateView";

// import querystring from "querystring";
// import Validate from "../../../functions/Validate";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Column } from "../../../theme/Grid";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/List";
import GroupIcon from "@material-ui/icons/Group";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { flushSync } from "react-dom";
import LoaderGif from "../../../assets/images/loader.gif";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class BusinessWebsite extends Component {
  static contextType = AppContext;

  state = {
    TAB_TYPE: 0,
    loaded: false,

    instagram: { loaded: false, photos: [] },
    comments: { records: [] },
    business: {
      id: null,
      name: null,
      mail: null,
      phone: null,
      province: null,
      district: null,
      address: null,
      map: null,
      shift: {
        start_hour: "00:00",
        end_hour: "23:59",
        slice: 20,
      },
      staffs: [],
      services: [],
      show_amount_on_appointment: null,
      show_customer_comments_at_url: null,
      rating_avg: null,
      staff_rating_avg: null,
      services_categories: [],
      services_gallery: [],
      show_case: null,
      services_gender: null,
      slug: null,
    },

    //İstek atarken kullanılacak şablon
    appointment: {
      customer_name: null,
      customer_surname: null,
      customer_phone: null,
      customer_email: null,
      staff_id: null,
      service_id: null,
      date: moment(new Date()).format("DD/MM/YYYY"),
      time: null,
      note: null,
    },
    appointment_check_modal: false,

    create_appointment_modal: false,

    selected_category: null,

    open_map_dialog: false,

    open_appointemt_remaining_error_dialog: false,
    appointment_error_message: null,
  };

  getBusiness = () => {
    const { params } = this.props.match;
    Axios.get(`${this.context.api_endpoint}/request/index/${params.id}`)
      .then(async (response) => {
        const { data } = response;
        response.status === 201 &&
          flushSync(() => {
            this.setState({
              loaded: true,
              business: {
                ...this.state.business,
                ...data.data,
                instagram:
                  data.data.instagram !== "" && data.data.instagram !== null
                    ? data.data.instagram
                    : this.context.app.INSTAGRAM,
                province: data.data.province
                  ? getCities().filter(
                      (city) => city.id === data.data.province
                    )[0].name
                  : "Bulunamadı",
                shift: data.data.hours,
                staffs: data.data.staffs ? [...data.data.staffs] : [],
                services: [
                  ...data.data.services.map((service) => ({
                    ...service,
                    staffs: [
                      ...service.staffs
                        .map((staff) =>
                          staff.staff !== null ? { ...staff.staff } : undefined
                        )
                        .filter((item) => item !== undefined),
                    ],
                  })),
                ],
                rating_avg: data.data.rating_avg,
                staff_rating_avg: data.data.staff_rating_avg,
                slug: data.data.slug,
                services_categories: [...data.data.services_categories],
                services_gallery: [...data.data.services_gallery],
              },
              selected_category: data.data.services_categories[0],
              appointment: {
                ...this.state.appointment,
                date: moment(new Date()).format("DD/MM/YYYY"),
              },
            });
          });
      })
      .then((data) => {
        document.location.href = `https://salonrandevu.com/isletme/${this.state.business.slug}i`;
        //this.getStaffs()
      });
  };

  getStaffs = () => {
    const { business } = this.state;
    const { params } = this.props.match;
    Axios.get(
      `${this.context.api_endpoint}/request/index/${params.id}/staffs`
    ).then(({ data }) =>
      this.setState({ business: { ...business, staffs: [...data.data] } })
    );
  };

  componentDidMount() {
    this.getBusiness();
  }

  render() {
    return null;
    // console.log(this.state.selected_service);
    const Loader = this._LoaderBuilder;
    const { business, loaded } = this.state;
    const BusinessHeader = this._BusinessHeaderBuilder;
    const GalleryCardBuilder = this._GalleryCardBuilder;
    const ServiceCategoryGallery = this._ServiceCategoryGallery;
    const AboutCard = this._AboutCardBuilder;
    const CommentCard = this._CommentsCardBuilder;
    const AppointmentCard = this._AppointmentCardBuilder;
    const ServicesCard = this._ServicesCardBuilder;
    const StaffsCard = this._StaffsCardBuilder;
    const AppointmentButtonCard = this._AppointmentButtonCardBuilder;
    const AppointmentDialog = this._AppointmentDialog;
    const MapDialog = this._MapDialog;
    const AppointmentErrorDialog = this._AppointentErrorDialog;
    const InstagramCard = this._InstagramCardBuiler;
    const FacebookCard = this._FacebookCardBuilder;

    return (
      <SiteContainer>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BusinessHeader />
            </Grid>
            {business.services_gallery.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ServiceCategoryGallery />
              </Grid>
            )}
            {business.services_categories.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={9}>
                <ServicesCard />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={business.services_categories.length > 0 ? 3 : 12}
            >
              <AboutCard />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StaffsCard />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CommentCard />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <InstagramCard />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <FacebookCard />
            </Grid>
          </Grid>
          <AppointmentDialog />
          <MapDialog />
          <AppointmentErrorDialog />
        </Container>
      </SiteContainer>
    );
  }

  /**
   * İstekten cevap gelene kadar olan süreçte ilgili alanda loader gösteren builder.
   */
  _LoaderBuilder = () => {
    return (
      <LoaderContainer>
        <img src={LoaderGif} width="100" height="100" alt="loading" />
      </LoaderContainer>
    );
  };

  /**
   * İşletme bilgisi altında tab navigator oluşturan ve
   * ilgili tab'in component'ini sayfaya dahil eden builderfonksiyonudur..
   */
  _TabNavigatorBuilder = () => {
    const { business } = this.state;

    const Tab = this._NavigatorSingleTabBuilder;

    // Components
    const AppointmentCard = this._AppointmentCardBuilder;
    const ServicesCard = this._ServicesCardBuilder;
    const StaffsCard = this._StaffsCardBuilder;
    const CommentsCard = this._CommentsCardBuilder;

    // Navigator Settings
    const TabNavigator = [
      {
        title: this.context.t(`businessURL.appointment.tab`),
        component: <AppointmentCard />,
      },
      {
        title: this.context.t(`businessURL.services.tab`),
        component: <ServicesCard />,
      },
      {
        title: this.context.t(`businessURL.staffs.tab`),
        component: <StaffsCard />,
      },
      business.show_customer_comments_at_url
        ? {
            title: this.context.t(`businessURL.comments.tab`),
            component: <CommentsCard />,
          }
        : undefined,
    ];

    return (
      <>
        <TNavigator className="mt-3">
          {TabNavigator.filter((_tab) => _tab !== undefined).map(
            (_tab, index) => (
              <Tab
                key={index}
                title={_tab.title}
                control={this.state.TAB_TYPE === index ? "active" : ""}
                onClick={() => this.setState({ TAB_TYPE: index })}
              />
            )
          )}
        </TNavigator>
        {TabNavigator[this.state.TAB_TYPE].component}
      </>
    );
  };

  /**
   * _TabNavigatorBuilder içerisinde kullanılacak olan tekli tab buttonun builder fonksiyonudur.
   */
  _NavigatorSingleTabBuilder = ({ title, control, onClick }) => {
    return (
      <TNavigatorTab>
        <ButtonBase className={control} onClick={onClick}>
          {title}
        </ButtonBase>
      </TNavigatorTab>
    );
  };

  /**
   * İşletme adı, il/ilçe ve Hemen Ara buttonlarının bulunduğu kart görünümünün builder fonksiyonudur.
   */
  _BusinessHeaderBuilder = () => {
    const { business } = this.state;
    const { params } = this.props.match;

    const smallScreen = useMediaQuery("(max-width: 960px)");

    const gender_icons = [
      require("../../../assets/images/female.png"),
      require("../../../assets/images/male.png"),
      require("../../../assets/images/male_female.png"),
    ];
    const gender_text = ["Kadın", "Erkek", "Unisex"];

    const StoreInfoCard = (
      <div className="info-card">
        <p>
          <FlashOnIcon /> <span>Anında Randevu</span>
        </p>
        {business.services_gender !== null && (
          <p>
            <img
              src={gender_icons[business.services_gender]}
              alt=""
              style={{ width: "18px", height: "18px", marginRight: "5px" }}
            />{" "}
            <span>{gender_text[business.services_gender]}</span>
          </p>
        )}
        <p>
          <MailOutlineIcon /> <span>SMS Bildirimi</span>
        </p>
      </div>
    );

    return (
      <Card>
        <Grid container spacing={0}>
          <Grid item md={5} lg={5} style={{ order: smallScreen ? 2 : 1 }}>
            <BusinessHeadingTitle>
              <BusinessHeading>
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontStyle: "normal",
                  }}
                  className="limited-line-2"
                >
                  {business.name}
                </h1>
                <span
                  onClick={() => {
                    this.setState({ open_map_dialog: true });
                  }}
                >
                  <LocationOnIcon />
                  {business.district} / {business.province}
                </span>
              </BusinessHeading>
              {StoreInfoCard}
              {this._AppointmentButtonCardBuilder()}
            </BusinessHeadingTitle>
          </Grid>
          <Grid item md={7} lg={7} style={{ order: smallScreen ? 1 : 2 }}>
            {this._GalleryCardBuilder()}
          </Grid>
        </Grid>
      </Card>
    );
  };

  _GalleryCardBuilder = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    const [activeStep, setActiveStep] = useState(0);

    const { params } = this.props.match;
    const { business } = this.state;

    const images =
      business.gallery && business.gallery.length > 0
        ? business.gallery.map(
            ({ photo }, index) =>
              `${this.context.api_endpoint.replace("api/v1", "")}${params.id}/${
                photo.path
              }`
          )
        : [];

    const handleStepChange = (step) => {
      setActiveStep(step);
    };
    return business.gallery && business.gallery.length > 0 ? (
      <div>
        <AutoPlaySwipeableViews
          axis={"x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {business.gallery.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 10 ? (
                <LeadingPhoto
                  src={`${this.context.api_endpoint.replace("api/v1", "")}${
                    params.id
                  }/${business.gallery[index].photo.path}`}
                  alt={""}
                  onClick={async () => {
                    /* await setPhotoIndex(index);
                                        await setModalOpen(true); */
                  }}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        {modalOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setModalOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </div>
    ) : (
      <LeadingPhoto
        src={require("../../../assets/images/no_photo.png")}
        alt={""}
      />
    );
  };

  _ServiceCategoryGallery = () => {
    const { business } = this.state;
    const { params } = this.props.match;

    const [modalOpen, setModalOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const images =
      business.services_gallery && business.services_gallery.length > 0
        ? business.services_gallery.map(
            ({ photo }, index) =>
              `${this.context.api_endpoint.replace("api/v1", "")}${params.id}/${
                photo.path
              }`
          )
        : [];

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <Card>
        <SectionHeading>
          <h2>{this.context.t(`businessURL.servicesGallery.title`)}</h2>
        </SectionHeading>
        <Carousel responsive={responsive}>
          {business.services_gallery.map(({ photo }, index) => (
            <ServicesGalleryContainer
              key={photo.id}
              src={`${this.context.api_endpoint.replace("api/v1", "")}${
                params.id
              }/${photo.path}`}
              onClick={async () => {
                await setPhotoIndex(index);
                await setModalOpen(true);
              }}
            />
          ))}
        </Carousel>

        {modalOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setModalOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </Card>
    );
  };

  _AppointmentButtonCardBuilder = () => {
    const { business } = this.state;
    return (
      <Grid item>
        <Button
          textColor="white"
          backgroundColor="red"
          title={this.context.t("businessURL.appointment.appointment_button")}
          //fullWidth={true}
          onClick={() => {
            this.setState({ create_appointment_modal: true });
          }}
        />
      </Grid>
    );
  };

  _NewAppointmentCardBuilder = () => {
    const {
      business,
      appointment,
      appointment_check_modal,
      hours_expanded,
      selected_service,
      __service,
    } = this.state;

    const { params } = this.props.match;

    const mdScreen = useMediaQuery("(max-width: 960px)");

    const getUnavailableHours = () => {
      const { params } = this.props.match;
      const { appointment } = this.state;

      const date_arr = appointment.date.split("/");
      const date = new Date(
        `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}T00:00:00`
      );

      Axios.get(
        `${this.context.api_endpoint}/request/${params.id}/staff/active`,
        {
          params: {
            staff_id: appointment.staff_id,
            day_index: getDayIndex(moment(date).format("YYYY-MM-DD")),
            date: moment(date).format("YYYY-MM-DD"),
          },
        }
      ).then(({ data }) => {
        this.setState({
          hours_expanded: true,
          unavailable_hours: [
            ...DisabledAppointmentsGenerator(
              {
                ...data.data,
                single: data.data.single !== null ? data.data.single : [],
                between:
                  data.data.between !== null
                    ? data.data.between.map((item) => {
                        return {
                          ...item,
                          start_hour: moment(`1970-01-01 ${item.start_hour}`)
                            .local()
                            .subtract(
                              this.state.selected_service.process_time - 1,
                              "minute"
                            )
                            .format("HH:mm"),
                        };
                      })
                    : [],
              },
              this.state.business.shift.slice
            ),
          ],
        });
      });
    };

    const makeAppointmentRequest = () => {
      const { params } = this.props.match;
      const { appointment } = this.state;

      const date_arr = appointment.date.split("/");
      const time_arr = appointment.time.split(":");

      Axios.post(`${this.context.api_endpoint}/make/appointment/request`, {
        ...this.state.appointment,
        company_id: parseInt(params.id),
        staff_id: parseInt(appointment.staff_id),
        service_id: parseInt(appointment.service_id),
        date: `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}T${time_arr[0]}:${time_arr[1]}:00Z`,
        __service: null,
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              this.context.t(`businessURL.appointment.insertToast`)
            );
            this.setState({
              hours_expanded: false,
              selected_service: null,
              appointment: {
                customer_name: null,
                customer_surname: null,
                customer_phone: null,
                company_id: params.id,
                staff_id: null,
                service_id: null,
                __service: null,
                __staff: null,
                date: moment(new Date()).format("DD/MM/YYYY"),
                time: null,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response.data.status === 404) {
            this.setState({
              open_appointemt_remaining_error_dialog: true,
              appointment_error_message: error.response.data.message,
            });
          }
        });
    };

    const [step, setStep] = useState(0);

    const [errorTextStep, setErrorTextStep] = useState(null);

    const errorTexts = [
      "Lütfen bir hizmet seçiniz.",
      "Lütfen bir personel seçiniz.",
      "Lütfen bir saat seçiniz.",
      "Lütfen isminizi giriniz.",
      "Lütfen soyisminizi giriniz.",
      "Lütfen telefon numaranızı giriniz.",
    ];

    const nextButtonHandler = () => {
      if (step === 0 && appointment.service_id) {
        setStep(1);
        document.getElementById("buttons").scrollLeft += 135;
      } else if (step === 0 && appointment.service_id === null) {
        setErrorTextStep(0);
      }
      if (step === 1 && appointment.staff_id) {
        setStep(2);
        document.getElementById("buttons").scrollLeft += 135;
      } else if (step === 1 && appointment.staff_id === null) {
        setErrorTextStep(1);
      }
      if (step === 2 && appointment.date && appointment.time) {
        setStep(3);
        document.getElementById("buttons").scrollLeft += 135;
      } else if (step === 2 && appointment.time === null) {
        setErrorTextStep(2);
      }
    };

    const appointmentButtonHandler = () => {
      if (
        appointment.customer_name === null ||
        appointment.customer_name === "" ||
        appointment.customer_surname === null ||
        appointment.customer_surname === "" ||
        appointment.customer_phone === null ||
        appointment.customer_phone === ""
      ) {
        setErrorTextStep(4);
      } else {
        makeAppointmentRequest();
      }
    };

    const [currentDate, setCurrentDate] = useState(0); // 0 - today |  1 - tomorrow | 2- next day

    const [timeAccordionExpanded, setTimeAccordionExpanded] = useState(false); // Time selection accordion expanded

    const dateRef = useRef();

    useEffect(() => {
      this.setState({
        hours_expanded: false,
        appointment: {
          ...appointment,
          date: moment(new Date()).format("DD/MM/YYYY"),
        },
      });
    }, []);

    return (
      <Grid container spacing={2} className="mt-3">
        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={8}
          className={`${mdScreen ? "mb-3" : null}`}
        >
          <AppointmentStepContainer>
            <div className="step-buttons-container">
              {step > 0 && (
                <button
                  className="back-button"
                  onClick={() => {
                    setStep((prevState) => prevState - 1);
                    document.getElementById("buttons").scrollLeft -= 135;
                  }}
                >
                  Geri
                </button>
              )}
              {step < 3 && mdScreen && (
                <button className="forward-button" onClick={nextButtonHandler}>
                  İleri
                </button>
              )}
            </div>
            <div className="button-group" id="buttons">
              <button
                onClick={() => {
                  setStep(0);
                }}
              >
                <ListIcon /> Hizmet
              </button>
              <button
                className={appointment.service_id ? "" : "next-button"}
                onClick={() => {
                  if (appointment.service_id) {
                    setStep(1);
                  }
                }}
              >
                <GroupIcon /> Personel
              </button>
              <button
                className={
                  !appointment.service_id
                    ? "inactive-button"
                    : appointment.staff_id
                    ? ""
                    : "next-button"
                }
                onClick={() => {
                  if (appointment.staff_id) {
                    setStep(2);
                  }
                }}
              >
                <QueryBuilderIcon /> Tarih ve Saat
              </button>
              <button
                className={
                  !appointment.staff_id
                    ? "inactive-button"
                    : appointment.date && appointment.time
                    ? ""
                    : "next-button"
                }
                onClick={() => {
                  if (appointment.date && appointment.time) {
                    setStep(3);
                  }
                }}
              >
                <FormatAlignLeftIcon /> Kişisel Bilgiler
              </button>
            </div>
            {step === 0 && (
              <div className="step-container">
                <div className="heading-container">
                  <ListIcon style={{ fontSize: 30 }} />
                  <h2>Hizmet Seçimi</h2>
                </div>
                <Grid item xs={12} sm={12} md={12}>
                  <AutocompleteInput
                    label={this.context.t(
                      `businessURL.appointment.serviceInputLabel`
                    )}
                    placeholder={this.context.t(
                      `businessURL.appointment.serviceInputHint`
                    )}
                    helperText={this.context.t(
                      `businessURL.appointment.serviceInputHint`
                    )}
                    style={{ color: "red" }}
                    selected={selected_service || null}
                    labelKey="name"
                    valueKey="id"
                    value={
                      this.state.appointment.__service
                        ? this.state.appointment.__service.name.toUpperCase()
                        : null
                    }
                    returnFullObject
                    options={[
                      ...business.services.map((item) => ({
                        ...item,
                        name: `${item.name} ${
                          business.show_amount_on_appointment
                            ? `(${item.amount.toFixed(2)}₺)`
                            : ""
                        }`,
                      })),
                    ]}
                    onRemove={() => {
                      this.setState({
                        hours_expanded: false,
                        selected_service: null,
                        appointment: {
                          ...appointment,
                          __service: null,
                          service_id: null,
                          staff_id: null,
                          date: null,
                          time: null,
                        },
                      });
                    }}
                    selectedHandler={(service) => {
                      setTimeAccordionExpanded(false);
                      this.setState({
                        hours_expanded: false,
                        selected_service: service,
                        appointment: {
                          ...appointment,
                          staff_id:
                            service.id === appointment.service_id
                              ? appointment.staff_id
                              : service.staffs.find(
                                  (_staff) => _staff.id === appointment.staff_id
                                )
                              ? service.staffs.find(
                                  (_staff) => _staff.id === appointment.staff_id
                                ).id
                              : null,
                          __service: service,
                          service_id: service.id,
                          time: null,
                          date: moment(new Date()).format("DD/MM/YYYY"),
                        },
                      });
                    }}
                    asyncDataService={(keyword) => {
                      const data = [
                        ...business.services.map((item) => ({
                          ...item,
                          name: `${item.name} ${
                            business.show_amount_on_appointment
                              ? `(${item.amount.toFixed(2)}₺)`
                              : ""
                          }`,
                        })),
                      ];

                      return keyword
                        ? data.filter((item) =>
                            item.name
                              .toLocaleLowerCase()
                              .includes(keyword.toLocaleLowerCase())
                          )
                        : data;
                    }}
                  />
                  {errorTextStep === 0 && appointment.service_id === null && (
                    <p className="error-text">{errorTexts[errorTextStep]}</p>
                  )}
                </Grid>
              </div>
            )}
            {step === 1 && (
              <div className="step-container">
                <div className="heading-container">
                  <GroupIcon style={{ fontSize: 30 }} />
                  <h2>Personel Seçimi</h2>
                </div>
                <Grid item xs={12} sm={12} md={12}>
                  <Select
                    required
                    label={this.context.t(
                      `businessURL.appointment.staffInputLabel`
                    )}
                    helperText={this.context.t(
                      `businessURL.appointment.staffInputCompanyHint`
                    )}
                    items={
                      appointment.__service
                        ? business.services.find(
                            (item) => item.id === appointment.__service.id
                          ).staffs
                        : []
                    }
                    selected={
                      appointment.staff_id !== null ? appointment.staff_id : ""
                    }
                    labelKey="full_name"
                    valueKey="id"
                    returnFullObject
                    handler={(selected_employee) => {
                      setTimeAccordionExpanded(false);
                      this.setState({
                        hours_expanded: false,
                        selected_employee,
                        appointment: {
                          ...appointment,
                          staff_id: selected_employee.id,
                          __staff: selected_employee,
                          time: null,
                          date: moment(new Date()).format("DD/MM/YYYY"),
                        },
                      });
                    }}
                  />
                  {errorTextStep === 1 && appointment.staff_id === null && (
                    <p className="error-text">{errorTexts[errorTextStep]}</p>
                  )}
                </Grid>
              </div>
            )}
            {step === 2 && (
              <div className="step-container">
                <div className="heading-container">
                  <GroupIcon style={{ fontSize: 30 }} />
                  <h2>Tarih ve Saat Seçimi</h2>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {appointment.date ??
                            moment(
                              new Date().setDate(new Date().getDate())
                            ).format("DD/MM/YYYY")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "stretch",
                          }}
                        >
                          <div className="date-button-container">
                            <button
                              className={`date-button ${
                                currentDate === 0 ? "active" : null
                              }`}
                              onClick={async () => {
                                await this.setState({
                                  appointment: {
                                    ...appointment,
                                    date: moment(new Date()).format(
                                      "DD/MM/YYYY"
                                    ),
                                    time: null,
                                  },
                                });
                                getUnavailableHours();
                                setCurrentDate(0);
                              }}
                            >
                              Bugün
                            </button>
                            <button
                              ref={dateRef}
                              className={`date-button ${
                                currentDate === 1 ? "active" : null
                              }`}
                              onClick={async () => {
                                await this.setState({
                                  hours_expanded: false,
                                  appointment: {
                                    ...appointment,
                                    date: moment(
                                      new Date().setDate(
                                        new Date().getDate() + 1
                                      )
                                    ).format("DD/MM/YYYY"),
                                    time: null,
                                  },
                                });
                                getUnavailableHours();
                                setCurrentDate(1);
                              }}
                            >
                              Yarın
                            </button>
                            <button
                              className={`date-button ${
                                currentDate === 2 ? "active" : null
                              }`}
                              onClick={() => {
                                setCurrentDate(2);
                              }}
                            >
                              İleri Bir Tarih
                            </button>
                          </div>
                          <div>
                            {currentDate === 2 && (
                              <DatePickerInput
                                required
                                label={this.context.t(
                                  `businessURL.appointment.dateInputLabel`
                                )}
                                minDate={new Date()}
                                maxDate={new Date().setDate(
                                  new Date().getDate() + 30
                                )}
                                onChange={async (date) => {
                                  await this.setState({
                                    hours_expanded: false,
                                    appointment: {
                                      ...appointment,
                                      date: moment(date).format("DD/MM/YYYY"),
                                      time: null,
                                    },
                                  });
                                  getUnavailableHours();
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Accordion
                      expanded={timeAccordionExpanded}
                      onClick={() => {
                        setTimeAccordionExpanded(!timeAccordionExpanded);
                        if (appointment.date) {
                          getUnavailableHours();
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {appointment.time ?? "Bir saat seçiniz"}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {hours_expanded && (
                          <div style={{ height: "250px", overflowY: "scroll" }}>
                            <Grid container>
                              {[...BetweenTimeGenerator(business.shift)].map(
                                (item, index) => {
                                  const time_arr = item.split(":");
                                  const date_arr = appointment.date.split("/");
                                  let x = this.context.actions.DateFormatter(
                                    `${date_arr[2]}-${date_arr[1]}-${date_arr[0]} ${time_arr[0]}:${time_arr[1]}:00`
                                  );

                                  return (
                                    <Grid
                                      item
                                      key={index}
                                      xs={6}
                                      sm={6}
                                      md={5}
                                      lg={4}
                                    >
                                      <Button
                                        title={item}
                                        fullWidth={true}
                                        textColor={
                                          this.state.unavailable_hours.includes(
                                            item
                                          ) || x < new Date()
                                            ? "red"
                                            : "green"
                                        }
                                        disabled={
                                          this.state.unavailable_hours.includes(
                                            item
                                          ) || x < new Date()
                                        }
                                        onClick={() => {
                                          setTimeAccordionExpanded(false);
                                          this.setState({
                                            appointment_check_modal: true,
                                            appointment: {
                                              ...appointment,
                                              time: item,
                                            },
                                          });
                                        }}
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                            </Grid>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {errorTextStep === 2 && appointment.time === null && (
                      <p className="error-text">{errorTexts[errorTextStep]}</p>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
            {step === 3 && (
              <div className="step-container">
                <div className="heading-container">
                  <FormatAlignLeftIcon style={{ fontSize: 30 }} />
                  <h2>Kişisel Bilgiler</h2>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      required
                      label={this.context.t(
                        `businessURL.appointment.nameInputLabel`
                      )}
                      value={
                        appointment.customer_name !== null
                          ? appointment.customer_name
                          : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          appointment: {
                            ...appointment,
                            customer_name: e.target.value,
                          },
                        })
                      }
                    />
                    {errorTextStep === 4 &&
                      appointment.customer_name === null && (
                        <p className="error-text">{errorTexts[3]}</p>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Input
                      required
                      label={this.context.t(
                        `businessURL.appointment.surnameInputLabel`
                      )}
                      value={
                        appointment.customer_surname !== null
                          ? appointment.customer_surname
                          : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          appointment: {
                            ...appointment,
                            customer_surname: e.target.value,
                          },
                        })
                      }
                    />
                    {errorTextStep === 4 &&
                      appointment.customer_surname === null && (
                        <p className="error-text">{errorTexts[4]}</p>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MaskedInput
                      required
                      label={this.context.t(
                        `businessURL.appointment.phoneInputLabel`
                      )}
                      maskChar={""}
                      mask="999 999 9999"
                      startAdornment="0"
                      placeholder="500 000 0000"
                      value={
                        appointment.customer_phone !== null
                          ? appointment.customer_phone
                          : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          appointment: {
                            ...appointment,
                            customer_phone:
                              e.target.value.substring(0, 1) === "5" &&
                              e.target.value.replace(/\s/g, ""),
                          },
                        })
                      }
                    />
                    {errorTextStep === 4 &&
                      appointment.customer_phone === null && (
                        <p className="error-text">{errorTexts[5]}</p>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Input
                      label={"Email"}
                      value={
                        appointment.customer_email !== null
                          ? appointment.customer_email
                          : ""
                      }
                      onChange={(e) =>
                        this.setState({
                          appointment: {
                            ...appointment,
                            customer_email: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Input
                    className="mt-2 mb-2"
                    label={this.context.t(
                      `businessURL.appointment.check.noteInputLabel`
                    )}
                    multiline
                    rows={5}
                    helperText={`${
                      appointment.note ? appointment.note.length : 0
                    }/255`}
                    onChange={(e) =>
                      this.setState({
                        appointment: {
                          ...appointment,
                          note:
                            e.target.value.length <= 255
                              ? e.target.value
                              : null,
                        },
                      })
                    }
                  />
                </Grid>
              </div>
            )}
            {step < 3 && !mdScreen && (
              <button className="forward-button" onClick={nextButtonHandler}>
                İleri
              </button>
            )}
            {step === 3 && (
              <button
                className={"appointment-button"}
                onClick={appointmentButtonHandler}
              >
                Randevu Al
              </button>
            )}
          </AppointmentStepContainer>
        </Grid>

        {appointment.service_id && (
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <AppointmentInfoCard>
              <div className="heading">
                <img
                  className="img-box"
                  src={
                    business.show_case
                      ? `${this.context.api_endpoint.replace("api/v1", "")}${
                          params.id
                        }/${business.show_case.path}`
                      : null
                  }
                  alt=""
                />
                <h3>{business.name}</h3>
                <p>{business.address}</p>
                <div className="divider"></div>
              </div>
              {appointment.service_id && (
                <div className="other-infos">
                  <h3>Hizmet</h3>
                  <p>{this.state.appointment.__service.name}</p>
                  <div className="divider"></div>
                </div>
              )}

              {appointment.staff_id && (
                <div className="other-infos">
                  <h3>Personel</h3>
                  <p>
                    {this.state.appointment.__staff.name}{" "}
                    {this.state.appointment.__staff.surname}
                  </p>
                  <div className="divider"></div>
                </div>
              )}

              {appointment.date && appointment.time && (
                <div className="other-infos">
                  <h3>Zaman</h3>
                  <p>{this.state.appointment.date}</p>
                  <p>{this.state.appointment.time}</p>
                  <div className="divider"></div>
                </div>
              )}

              {appointment.service_id && (
                <div className="total-container">
                  <h3>Toplam</h3>
                  <p className="amount">
                    {appointment.__service.amount} <span>TL</span>
                  </p>
                </div>
              )}
            </AppointmentInfoCard>
          </Grid>
        )}
      </Grid>
    );
  };

  _AppointmentCardBuilder = () => {
    const {
      business,
      appointment,
      appointment_check_modal,
      hours_expanded,
      selected_service,
      __service,
    } = this.state;

    const getUnavailableHours = () => {
      const { params } = this.props.match;
      const { appointment } = this.state;

      const date_arr = appointment.date.split("/");
      const date = new Date(
        `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}T00:00:00`
      );

      Axios.get(
        `${this.context.api_endpoint}/request/${params.id}/staff/active`,
        {
          params: {
            staff_id: appointment.staff_id,
            day_index: getDayIndex(moment(date).format("YYYY-MM-DD")),
            date: moment(date).format("YYYY-MM-DD"),
          },
        }
      ).then(({ data }) => {
        this.setState({
          hours_expanded: true,
          unavailable_hours: [
            ...DisabledAppointmentsGenerator(
              {
                ...data.data,
                single: data.data.single !== null ? data.data.single : [],
                between:
                  data.data.between !== null
                    ? data.data.between.map((item) => {
                        return {
                          ...item,
                          start_hour: moment(`1970-01-01 ${item.start_hour}`)
                            .local()
                            .subtract(
                              this.state.business.__service.process_time - 1,
                              "minute"
                            )
                            .format("HH:mm"),
                        };
                      })
                    : [],
              },
              this.state.business.shift.slice
            ),
          ],
        });
      });
    };

    const makeAppointmentRequest = () => {
      const { params } = this.props.match;
      const { appointment } = this.state;

      const date_arr = appointment.date.split("/");
      const time_arr = appointment.time.split(":");

      Axios.post(`${this.context.api_endpoint}/make/appointment/request`, {
        ...this.state.appointment,
        company_id: parseInt(params.id),
        staff_id: parseInt(appointment.staff_id),
        service_id: parseInt(appointment.service_id),
        date: `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}T${time_arr[0]}:${time_arr[1]}:00Z`,
        __service: null,
      }).then((response) => {
        if (response.status === 201) {
          toast.success(this.context.t(`businessURL.appointment.insertToast`));
          this.setState({
            hours_expanded: false,
            appointment: {
              customer_name: null,
              customer_surname: null,
              customer_phone: null,
              company_id: params.id,
              staff_id: null,
              service_id: null,
              date: moment(new Date()).format("DD/MM/YYYY"),
              time: null,
            },
          });
        }
      });
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.appointment.title`)}</h2>
        </SectionHeading>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Input
              required
              label={this.context.t(`businessURL.appointment.nameInputLabel`)}
              value={
                appointment.customer_name !== null
                  ? appointment.customer_name
                  : ""
              }
              onChange={(e) =>
                this.setState({
                  appointment: {
                    ...appointment,
                    customer_name: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Input
              required
              label={this.context.t(
                `businessURL.appointment.surnameInputLabel`
              )}
              value={
                appointment.customer_surname !== null
                  ? appointment.customer_surname
                  : ""
              }
              onChange={(e) =>
                this.setState({
                  appointment: {
                    ...appointment,
                    customer_surname: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <MaskedInput
              required
              label={this.context.t(`businessURL.appointment.phoneInputLabel`)}
              maskChar={""}
              mask="999 999 9999"
              startAdornment="0"
              placeholder="500 000 0000"
              value={
                appointment.customer_phone !== null
                  ? appointment.customer_phone
                  : ""
              }
              onChange={(e) =>
                this.setState({
                  appointment: {
                    ...appointment,
                    customer_phone:
                      e.target.value.substring(0, 1) === "5" &&
                      e.target.value.replace(/\s/g, ""),
                  },
                })
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <AutocompleteInput
              label={this.context.t(
                `businessURL.appointment.serviceInputLabel`
              )}
              placeholder={this.context.t(
                `businessURL.appointment.serviceInputHint`
              )}
              helperText={this.context.t(
                `businessURL.appointment.serviceInputHint`
              )}
              selected={selected_service || null}
              labelKey="name"
              valueKey="id"
              returnFullObject
              options={[
                ...business.services.map((item) => ({
                  ...item,
                  name: `${item.name} ${
                    business.show_amount_on_appointment
                      ? `(${item.amount.toFixed(2)}₺)`
                      : ""
                  }`,
                })),
              ]}
              onRemove={() => {
                this.setState({
                  hours_expanded: false,
                  selected_service: null,
                  appointment: {
                    ...appointment,
                    __service: null,
                    service_id: null,
                    staff_id: null,
                  },
                });
              }}
              selectedHandler={(service) =>
                this.setState({
                  hours_expanded: false,
                  selected_service: service,
                  appointment: {
                    ...appointment,
                    __service: service,
                    service_id: service.id,
                    staff_id: null,
                  },
                })
              }
              asyncDataService={(keyword) => {
                const data = [
                  ...business.services.map((item) => ({
                    ...item,
                    name: `${item.name} ${
                      business.show_amount_on_appointment
                        ? `(${item.amount.toFixed(2)}₺)`
                        : ""
                    }`,
                  })),
                ];

                return keyword
                  ? data.filter((item) =>
                      item.name
                        .toLocaleLowerCase()
                        .includes(keyword.toLocaleLowerCase())
                    )
                  : data;
              }}
            />
            {/*
                <Select
                  required
                  label="Hizmet Seçimi"
                  helperText="Hizmetler arasından seçim yapınız"
                  items={[...business.services.map(item => ({
                    ...item,
                    name: `${item.name} ${business.show_amount_on_appointment ? `(${item.amount.toFixed(2)}₺)` : ''}`
                  }))]}
                  selected={appointment.service_id !== null ? appointment.service_id : ''}
                  labelKey="name"
                  valueKey="id"
                  returnFullObject
                  handler={service => this.setState({
                    hours_expanded: false,
                    selected_service: service,
                    appointment: {
                      ...appointment,
                      __service: service,
                      service_id: service.id,
                      staff_id: null
                    }
                  })}
                />
                */}
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Select
              required
              label={this.context.t(`businessURL.appointment.staffInputLabel`)}
              helperText={this.context.t(
                `businessURL.appointment.staffInputHint`
              )}
              items={selected_service ? selected_service.staffs : []}
              selected={
                appointment.staff_id !== null ? appointment.staff_id : ""
              }
              labelKey="full_name"
              valueKey="id"
              returnFullObject
              handler={(selected_employee) =>
                this.setState({
                  hours_expanded: false,
                  selected_employee,
                  appointment: {
                    ...appointment,
                    staff_id: selected_employee.id,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <DatePickerInput
              required
              label={this.context.t(`businessURL.appointment.dateInputLabel`)}
              minDate={new Date()}
              maxDate={new Date().setDate(new Date().getDate() + 30)}
              helperText={this.context.t(
                `businessURL.appointment.dateInputHint`
              )}
              onChange={(date) =>
                this.setState({
                  hours_expanded: false,
                  appointment: {
                    ...appointment,
                    date: moment(date).format("DD/MM/YYYY"),
                  },
                })
              }
            />
          </Grid>
        </Grid>

        <Button
          disabled={
            business.shift.slice === "0" ||
            appointment.customer_name === null ||
            appointment.customer_last_name === null ||
            appointment.customer_phone === null ||
            appointment.customer_phone.length !== 10 ||
            appointment.staff_id === null ||
            appointment.staff_id === null ||
            appointment.service_id === null
          }
          icon="search"
          title={this.context.t(
            `businessURL.appointment.availableHoursButtonTitle`
          )}
          backgroundColor="primary"
          textColor="white"
          fullWidth={true}
          onClick={() => getUnavailableHours()}
        />

        {hours_expanded === true && (
          <Grid container spacing={2} className="mt-4">
            {[...BetweenTimeGenerator(business.shift)].map((item, index) => {
              const time_arr = item.split(":");
              const date_arr = appointment.date.split("/");
              let x = this.context.actions.DateFormatter(
                `${date_arr[2]}-${date_arr[1]}-${date_arr[0]} ${time_arr[0]}:${time_arr[1]}:00`
              );

              return (
                <Grid item key={index} xs={6} sm={6} md={3} lg={2}>
                  <Button
                    title={item}
                    fullWidth={true}
                    textColor={
                      this.state.unavailable_hours.includes(item) ||
                      x < new Date()
                        ? "red"
                        : "green"
                    }
                    disabled={
                      this.state.unavailable_hours.includes(item) ||
                      x < new Date()
                    }
                    onClick={() =>
                      this.setState({
                        appointment_check_modal: true,
                        appointment: {
                          ...appointment,
                          time: item,
                        },
                      })
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        )}

        <AlertDialog
          title={this.context.t(`businessURL.appointment.check.title`)}
          open={appointment_check_modal}
          closeHandler={() => this.setState({ appointment_check_modal: false })}
          buttons={[
            {
              title: this.context.t(
                `businessURL.appointment.check.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => makeAppointmentRequest(),
            },
            {
              title: this.context.t(
                `businessURL.appointment.check.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
            },
          ]}
        >
          <Alert severity="warning">
            <b>
              {this.context.t(`businessURL.appointment.check.alertBoldText`, {
                name: appointment.customer_name,
                surname: appointment.customer_surname,
              })}
            </b>
            ,<br />
            {this.context.t(`businessURL.appointment.check.alertText`, {
              date: appointment.date,
              time: appointment.time,
            })}
            {appointment.__service &&
              business.show_amount_on_appointment === true && (
                <>
                  <p className="mt-3">
                    {this.context.t(`businessURL.appointment.check.priceText`, {
                      service: appointment.__service.name,
                      amount: appointment.__service.amount,
                    })}
                  </p>
                  <p className="mt-3">
                    <b>
                      {this.context.t(
                        `businessURL.appointment.check.priceBoldText`
                      )}
                    </b>
                  </p>
                </>
              )}
          </Alert>
          <Input
            className="mt-2 mb-2"
            label={this.context.t(
              `businessURL.appointment.check.noteInputLabel`
            )}
            multiline
            rows={5}
            helperText={this.context.t(
              `businessURL.appointment.check.noteInputHint`,
              {
                char: appointment.note ? appointment.note.length : 0,
              }
            )}
            onChange={(e) =>
              this.setState({
                appointment: {
                  ...appointment,
                  note: e.target.value.length <= 255 ? e.target.value : null,
                },
              })
            }
          />
        </AlertDialog>
      </Card>
    );
  };

  _StaffsCardBuilder = () => {
    const { business } = this.state;
    const { params } = this.props.match;
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <Card>
        <SectionHeading>
          <h2>{this.context.t(`businessURL.staffs.title`)}</h2>
        </SectionHeading>
        <Carousel responsive={responsive}>
          {[...this.state.business.staffs].map((staff, index) => {
            return (
              <StaffContainer>
                <SelectableStaffContainer>
                  <SelectableStaffPhoto
                    src={
                      staff.detail.profile_photo
                        ? `${this.context.api_endpoint.replace("api/v1", "")}${
                            params.id
                          }/${staff.detail.profile_photo}`
                        : require("../../../assets/images/profile_photo.svg")
                    }
                    onClick={async () => {
                      this.setState((prevState) => ({
                        selected_service: null,
                        appointment: {
                          ...prevState.appointment,
                          staff_id: staff.id,
                          __staff: staff,
                          __service: null,
                          service_id: null,
                          time: null,
                        },
                        create_appointment_modal: true,
                      }));
                    }}
                  />
                  <div className="select-staff-content">SEÇ</div>
                </SelectableStaffContainer>
                <span>
                  {staff.name} {staff.surname}
                </span>
              </StaffContainer>
            );
          })}
        </Carousel>
      </Card>
    );
  };

  _ServicesCardBuilder = () => {
    const { business, appointment } = this.state;

    const mobileServicesWidth = useMediaQuery("(max-width: 650px)");

    const mobileScreenServicesContent = (
      <Grid spacing={0}>
        {[...business.services_categories].map((category, index) => (
          <Accordion key={category.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{category.name}</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              {category.services.map((service, index) => (
                <MobileServiceInfoContainer key={service.id}>
                  <p>{service.name}</p>
                  <div className="flexBox">
                    <span style={{ marginRight: "10px" }}>
                      {service.amount}₺
                    </span>
                    <Button
                      style={{
                        width: "auto",
                        height: "auto",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                      size="small"
                      textColor="white"
                      backgroundColor="red"
                      title={"Hizmeti Seç"}
                      onClick={() => {
                        this.setState({
                          selected_service: service,
                          appointment: {
                            ...appointment,
                            service_id: service.id,
                            __service: service,
                            staff_id: null,
                            __staff: null,
                          },
                          create_appointment_modal: true,
                        });
                      }}
                    />
                  </div>
                  <div className="divider"></div>
                </MobileServiceInfoContainer>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    );

    const largeScreenServicesContent = (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <div>
            {[...business.services_categories].map((category, index) => (
              <ServiceItemContainer
                className={
                  this.state.selected_category.id === category.id
                    ? "active"
                    : ""
                }
                key={category.id}
                onClick={() => {
                  this.setState({ selected_category: category });
                }}
              >
                {category.name}
              </ServiceItemContainer>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <ServicesContainer>
            {this.state.selected_category &&
              this.state.selected_category.services.map((service, index) => (
                <ServiceInfoContainer>
                  <div style={{ marginLeft: "20px", width: "50%" }}>
                    <p style={{ width: "100%" }}>
                      {service.name}
                      {" | " + service.process_time + " dk"}
                    </p>
                  </div>
                  <div>
                    <span style={{ marginRight: "25px", fontSize: "18px" }}>
                      {service.amount}₺
                    </span>
                    <Button
                      textColor="white"
                      size="small"
                      backgroundColor="red"
                      title={"Hizmeti Seç"}
                      onClick={() => {
                        this.setState({
                          selected_service: service,
                          appointment: {
                            ...appointment,
                            service_id: service.id,
                            __service: service,
                            staff_id: null,
                            __staff: null,
                          },
                          create_appointment_modal: true,
                        });
                      }}
                    />
                  </div>
                </ServiceInfoContainer>
              ))}
          </ServicesContainer>
        </Grid>
      </Grid>
    );

    return (
      <Card
        className="mt-2"
        style={{ backgroundColor: "#FBFBFB", padding: "24px" }}
      >
        <SectionHeading>
          <h2>{this.context.t(`businessURL.services.title`)}</h2>
        </SectionHeading>

        {mobileServicesWidth
          ? mobileScreenServicesContent
          : largeScreenServicesContent}
      </Card>
    );
  };

  _CommentsCardBuilder = () => {
    const { comments } = this.state;
    const { params } = this.props.match;

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const mobileWidth = useMediaQuery("(max-width: 600px)");

    React.useEffect(() => getComments(), []);

    const getComments = (page = 1) => {
      Axios.get(
        `${this.context.api_endpoint}/request/index/${params.id}/comments`,
        { params: { page } }
      ).then(({ data }) => this.setState({ comments: { ...data.data } }));
    };

    return (
      <Card className="mt-2">
        <SectionHeading>
          <h2>
            {this.context.t(`businessURL.comments.title`)} (
            {comments.total_record})
          </h2>
        </SectionHeading>

        <Grid container style={{ marginBottom: "20px" }}>
          <CommentInfoContainer>
            <CommentInfoSideContainer>
              <StorefrontOutlined
                style={{
                  fontSize: mobileWidth ? "40px" : "60px",
                  color: "#CC3370",
                }}
              />
              <h5>Salon Değerlendirmesi</h5>
              <Rating
                readOnly
                size={mobileWidth ? "small" : "medium"}
                style={{ display: "flex" }}
                precision={0.5}
                max={5}
                value={this.state.business.rating_avg}
              />
              <h5>5.0 üzerinden</h5>
            </CommentInfoSideContainer>
            {!mobileWidth && (
              <div
                style={{
                  width: "2px",
                  height: "70%",
                  alignSelf: "center",
                  backgroundColor: "#E8E8E8",
                }}
              ></div>
            )}
            {mobileWidth && (
              <div
                style={{
                  width: "90%",
                  height: "2px",
                  alignSelf: "center",
                  backgroundColor: "#E8E8E8",
                }}
              ></div>
            )}
            <CommentInfoSideContainer>
              <AccountCircleOutlined
                style={{
                  fontSize: mobileWidth ? "40px" : "60px",
                  color: "#CC3370",
                }}
              />
              <h5>Personel Değerlendirmesi</h5>
              <Rating
                readOnly
                size={mobileWidth ? "small" : "medium"}
                style={{ display: "flex" }}
                precision={0.5}
                max={5}
                value={this.state.business.staff_rating_avg}
              />
              <h5>5.0 üzerinden</h5>
            </CommentInfoSideContainer>
          </CommentInfoContainer>
        </Grid>

        {[...comments.records].map((comment, index) => (
          <SingleRowItem key={index}>
            <Grid container>
              <Grid item xs={12} sm={11}>
                <CommentsContainer>
                  <UserInfoContainer>
                    <CommentUserPhoto
                      src={require("../../../assets/images/profile_photo.svg")}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "15px",
                      }}
                    >
                      <p>**** ****</p>
                      <p
                        style={{
                          fontFamily: "'Roboto', sans-serif",
                          fontWeight: "400",
                          color: "#707070",
                        }}
                      >
                        {new Date(comment.updated_at).getDay()}{" "}
                        {months[new Date(comment.updated_at).getMonth()]}{" "}
                        {new Date(comment.updated_at).getFullYear()}
                      </p>
                      <p>{comment.description}</p>
                    </div>
                  </UserInfoContainer>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <StorefrontOutlined style={{ height: 20 }} />
                    <Rating
                      readOnly
                      size={mobileWidth ? "small" : "medium"}
                      style={{ display: "flex" }}
                      precision={0.5}
                      max={5}
                      value={comment.company_star.toFixed(2)}
                    />
                    <AccountCircleOutlined style={{ height: 20 }} />
                    <Rating
                      readOnly
                      size={mobileWidth ? "small" : "medium"}
                      style={{ display: "flex" }}
                      precision={0.5}
                      max={5}
                      value={comment.staff_star.toFixed(2)}
                    />
                  </div>
                </CommentsContainer>
              </Grid>
            </Grid>
          </SingleRowItem>
        ))}

        <PaginationContainer>
          <Pagination
            count={comments.total_page}
            onChange={(_, value) =>
              comments.page !== value && getComments(value)
            }
          />
        </PaginationContainer>
      </Card>
    );
  };

  _InstagramCardBuiler = () => {
    const { business } = this.state;
    const { params } = this.props.match;

    return (
      <Card className="mt-3">
        <SectionHeading>
          <h2>Instagram'da Takip Edin!</h2>
          <SocialProfileLink
            href={`https://instagram.com/${business.instagram}`}
            target="_blank"
          >
            <span>
              {this.context.t(`businessURL.instagramText`, {
                username: business.instagram ?? "salonrandevu",
              })}
            </span>
            <i className="material-icons">launch</i>
          </SocialProfileLink>
        </SectionHeading>
        {/*  <img
          src={
            business.show_case
              ? `${this.context.api_endpoint.replace("api/v1", "")}${
                  params.id
                }/${business.show_case.path}`
              : null
          }
          alt=""
        /> */}
      </Card>
    );
  };

  _FacebookCardBuilder = () => {
    const { business } = this.state;

    return (
      <Card className="mt-3">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.facebookHeading`)}</h2>
          <SocialProfileLink
            href={`https://facebook.com/${
              business.facebook ?? "salonrandevucom"
            }`}
            target="_blank"
          >
            <span>
              {this.context.t(`businessURL.facebookText`, {
                username: business.facebook ?? "salonrandevucom",
              })}
            </span>
            <i className="material-icons">launch</i>
          </SocialProfileLink>
        </SectionHeading>
        <iframe
          style={{ width: "100%", minHeight: 181, border: 0 }}
          src={`https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/${
            business.facebook ?? "salonrandevucom"
          }/&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media`}
        ></iframe>
      </Card>
    );
  };

  _AboutCardBuilder = () => {
    const { business } = this.state;
    return (
      <AboutCardContainer className="mt-2">
        <SectionHeading>
          <h2>{this.context.t(`businessURL.aboutHeading`)}</h2>
        </SectionHeading>

        {/* business.map !== null && business.map !== "" &&
            <Grid item xs={12} sm={12}>
              <iframe title={Date.now()} src={`https://maps.google.com/maps?q=@${business.map}&zoom=14&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="240" frameBorder={0} style={{ border: 0 }} aria-hidden="false" />
            </Grid> */}
        <Grid item xs={12} sm={12} className="mt-3">
          <div className="mb-1">
            <AboutItemContainer>
              <LocationOnIcon style={{ marginRight: "5px" }} />
              {business.address ?? "-"}
            </AboutItemContainer>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className="mt-3">
            <AboutItemContainer>
              <MailOutlineIcon style={{ marginRight: "5px" }} />
              {business.mail ?? "-"}
            </AboutItemContainer>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} className="mt-3">
          <AboutItemContainer>
            <PhoneIcon style={{ marginRight: "5px" }} />0{business.phone ?? "-"}
          </AboutItemContainer>
        </Grid>
        <Grid item xs={12} sm={12} className="mt-3">
          <AboutItemContainer>
            <QueryBuilderIcon style={{ marginRight: "5px" }} />
            {business.shift.start_hour} - {business.shift.end_hour}
          </AboutItemContainer>
        </Grid>
      </AboutCardContainer>
    );
  };

  _AppointmentDialog = () => {
    const AppointmentCard = this._NewAppointmentCardBuilder;
    return (
      <Dialog
        onClose={() => {
          this.setState({ create_appointment_modal: false });
        }}
        open={this.state.create_appointment_modal}
        fullWidth={true}
        maxWidth="md"
      >
        <MuiDialogTitle
          disableTypography
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">Randevu Al</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              this.setState({ create_appointment_modal: false });
            }}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <AppointmentCard />
        </MuiDialogContent>
      </Dialog>
    );
  };

  _MapDialog = () => {
    const { business } = this.state;
    return (
      <Dialog
        onClose={() => {
          this.setState({ open_map_dialog: false });
        }}
        open={this.state.open_map_dialog}
        fullWidth={true}
        maxWidth="md"
      >
        <MuiDialogTitle
          disableTypography
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">İşletmenin Konumu</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              this.setState({ open_map_dialog: false });
            }}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          {business.map !== null && business.map !== "" && (
            <Grid item xs={12} sm={12}>
              <iframe
                title={Date.now()}
                src={`https://maps.google.com/maps?q=@${business.map}&zoom=14&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                width="100%"
                height="300"
                frameBorder={0}
                style={{ border: 0 }}
                aria-hidden="false"
              />
            </Grid>
          )}
        </MuiDialogContent>
      </Dialog>
    );
  };

  _AppointentErrorDialog = () => {
    return (
      <AlertDialog
        title={""}
        open={this.state.open_appointemt_remaining_error_dialog}
        fullWidth
        textType
        disableBackdropClick={false}
        closeHandler={() =>
          this.setState({ open_appointemt_remaining_error_dialog: false })
        }
        buttons={[
          {
            //icon: "okay",
            title: "Kapat",
            textColor: "white",
            backgroundColor: "primary",
          },
        ]}
      >
        <p>{this.state.appointment_error_message}</p>
      </AlertDialog>
    );
  };
}

export default BusinessWebsite;

const AppointmentStepContainer = styled.div`
  display: flex;
  flex-direction: column;

  .step-buttons-container {
    display: flex;
    flex-direction: row;
  }

  button.back-button,
  button.appointment-button,
  button.forward-button,
  button.inactive-appointment-button {
    width: 150px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #cc3370;
    margin-right: 10px;
    cursor: pointer;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  button.inactive-appointment-button {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .error-text {
    color: red;
  }

  div.button-group {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-start;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    button {
      padding: 10px;
      border: none;
      border-radius: 5px;
      background-color: #cc3370;
      margin-right: 10px;
      cursor: pointer;
      color: white;
      display: flex;
      align-items: center;
      scroll-snap-align: center;
      min-width: 135px !important;
    }

    button.next-button {
      opacity: 0.7;
    }

    button.inactive-button {
      background-color: #eed9e2;
      color: #cc3370;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: row;
      overflow-x: auto;
      align-items: stretch;
      button {
        width: 100%;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }

  div.step-container {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background-color: #fbfbfb;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media only screen and (max-width: 600px) {
      label {
        color: black;
      }

      .MuiInputBase-root {
        border: 1px solid #04040424;
      }
      .heading-container {
        display: none !important;
      }
    }

    .heading-container {
      display: flex;
      align-items: center;
      h2 {
        margin-left: 5px;
      }
    }
  }

  div.date-button-container {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .date-button {
      background-color: transparent;
      border: 2px solid #dfdfdf;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
    }

    button.active {
      border: 2px solid #cc3370;
      color: #cc3370;
    }
  }
`;

const AppointmentInfoCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #7a7a7a;

  .img-box {
    width: 100px;
    height: 100px;
    border: 1px solid #e8e8e8;
    transform: translateY(-50%);
  }

  div.heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Roboto", sans-serif;
  }

  @media only screen and (max-width: 600px) {
    div.heading {
      display: none !important;
    }
  }

  div.other-infos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: "Roboto", sans-serif;
  }

  div.total-container {
    width: 90%;
    display: flex;
    justify-content: space-between;

    p.amount {
      font-size: 24px;

      span {
        font-size: 16px;
      }
    }
  }

  h3 {
    font-weigth: 400;
  }

  div.divider {
    width: 90%;
    height: 1px;
    background-color: gray;

    p.amount {
      fonst-size: 24px;
    }
  }
`;

const AboutItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #707070;
  font-family: "Roboto", sans-serif;
`;

const ServiceItemMobileContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const AboutCardContainer = styled.div`
  background-color: #fbfbfb;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ServiceItemContainer = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 90%;
  font-family: "Roboto", sans-serif;
  font-weight: "300";
  color: #707070;

  &:hover {
    color: white;
    background-color: #cc3370;
  }

  &.active {
    color: white;
    background-color: #cc3370;
    font-weight: bold;
  }
`;

const ServicesContainer = styled.div`
  width: 100%;
`;

const ServiceInfoContainer = styled.div`
  box-sizing: border-box;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #707070;
  font-size: 16px;
  margin-bottom: 5px;
`;

const MobileServiceInfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #707070;
  font-size: 16px;
  margin-bottom: 5px;

  button {
    border: none;
    border-radius: 5px;
    background-color: #cc3370;
    color: white;
    padding: 5px;
  }

  .flexBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: gray;
  }
`;

const BusinessHeadingTitle = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: linear-gradient(to right, #fbfbfb, white);

  div.info-card {
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    font-weight: 300;

    p {
      display: flex;
      align-items: center;

      span: {
        margin-left: 10px;
      }
    }
  }
`;

const StaffContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SiteContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0;
  box-sizing: border-box;
  background: white;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TNavigator = styled.ul`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TNavigatorTab = styled.li`
  width: auto;
  flex: none;
  text-align: center;

  button {
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    text-decoration: none;
    display: block;
    padding: 16px;
    line-height: 26px;
    color: #303030;

    &.active {
      color: var(--primary);
      border-bottom: 2px solid var(--primary);
    }
  }
`;

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02);
`;

const BusinessHeading = styled.div`
  h1 {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 24px;

    & + span {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      color: #aaa;
      margin-top: 4px;
      cursor: pointer;
    }
  }
`;

const ServicesGalleryContainer = styled.img`
  width: 300px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const SelectableStaffPhoto = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 8px;
  cursor: pointer;
`;

const SelectableStaffContainer = styled.div`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  div.select-staff-content {
    width: 100%;
    font-size: 16px;
    background-color: red;
    color: white;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    z-index: 1000;
  }

  &:hover {
    border: 1px solid black;
  }
`;

const CommentUserPhoto = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 8px;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const LeadingPhoto = styled.img`
  width: 100%;
  height: 430px;
  object-fit: cobtain;
  border-radius: 5px;
`;

const SmallPhoto = styled.img`
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
`;

const SectionHeading = styled.h2`
  margin: 0 0 8px;

  h2 {
    font-size: 25px;
    font-weight: 300;
    line-height: 24px;
    padding: 0;
    margin: 0;
    font-family: "Roboto";
  }
`;

const InstagramPhotoSkeleton = styled.div`
  width: 100%;
  height: 105px;
  border-radius: 10px;
`;

const PhotoLink = styled.a`
  img {
    width: 100%;
    height: 105px;
    border-radius: 10px;
    object-fit: cover;
  }
`;

const SocialProfileLink = styled.a`
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  color: #7713b5;
  text-decoration: none;

  i {
    font-size: 15px;
  }
  b {
    font-weight: 600;
  }
`;

const CommentInfoContainer = styled.div`
  width: 100%;
  height: 200px;
  border: 1px solid #e8e8e8;
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    height: auto;
    padding: 15px;
  }
`;

const CommentInfoSideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #707070;
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: top;
`;

const SingleRowItem = styled.div`
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  color: #303030;
  border-bottom: 1px solid #efefef;

  &:nth-last-child(1) {
    border-bottom: 0;
    padding-bottom: 0;
  }
  &:nth-of-type(1) {
    padding-top: 0 !important;
  }

  span#heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    padding: 0;
  }

  span#subtext {
    display: flex;
    flex-direction: row;
    opacity: 0.6;
    margin-top: 0px;

    p {
      font-size: 13px;
      line-height: 1.614;
      font-weight: 500;
      margin: 0;
      padding: 0;
      margin-right: 16px;

      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
`;

const StaffPhoto = styled.img`
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 10px;
  background: #fafafa;
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SingleContainer = styled.div``;
