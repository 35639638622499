const get = require('lodash.get');
// eslint-disable-next-line
String.prototype.formatUnicorn = String.prototype.formatUnicorn ??
  function () {
    var str = this.toString();
    if (arguments.length) {
      var t = typeof arguments[0];
      var key;
      var args = ("string" === t || "number" === t)
        ? Array.prototype.slice.call(arguments)
        : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
      }
    }

    return str;
  };

const translator = (key, state, config, variables) => {
  const locale = config?.locale ?? state.default_locale;
  return variables
    ? get(state.locales?.[locale], key)?.formatUnicorn(variables)
    : get(state.locales?.[locale], key);
};

export default translator;