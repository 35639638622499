import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";

const CSwitch = (props) => {
  const CustomSwitch = withStyles({
    switchBase: {
      color: props.closeColor ?? "white",
      "&$checked": {
        color: "primary",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <div className={props.className ?? ""}>
      {props.title && <h4>{props.title}</h4>}
      {/* <FormControlLabel
        control={
          <Switch
            checked={props.checked}
            onChange={props.onChange}
            color={props.color ?? "primary"}
            disabled={props.disabled}
            name={props.name ?? "checkedA"}
          />
        }
        label={props.label}
      /> */}
      <div>
        <Grid component="label" container alignItems="center">
          {props.labelLeft && (
            <Grid item>
              <p style={{ fontSize: "15px", margin: "0px" }}>
                {props.labelLeft}
              </p>
            </Grid>
          )}
          <Grid item>
            <CustomSwitch
              checked={props.checked}
              onChange={props.onChange}
              color={props.color ?? "primary"}
              disabled={props.disabled}
              name={props.name ?? "checkedA"}
            />
          </Grid>
          <Grid item>
            <p style={{ fontSize: "15px", margin: "0px" }}>{props.label}</p>
          </Grid>
          {props.description && (
            <Grid item>
              <FormHelperText>{props.description}</FormHelperText>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default CSwitch;
