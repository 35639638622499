/* eslint-disable */
import React from "react";
import styled from "styled-components";
import AppContext from "../../../context/store";
import Validate from "../../../functions/Validate";

import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import Button from "../../../theme/Button";
import Table from "../../../theme/Table";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
// import TimeSelect from "../../../theme/TimeSelect";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import {
  AddBox,
  AddOutlined,
  AddShoppingCart,
  Layers,
  LibraryAdd,
  Person,
  Phone,
  ShoppingBasket,
  ThreeSixty,
  WhatsApp,
  Comment,
  AllInbox,
  Search,
  Backspace,
  Payment,
  Add,
  Clear,
  Note,
  Receipt,
  Sms,
} from "@material-ui/icons";
import { AiOutlineBarcode } from "react-icons/ai";
// import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment, TextField } from "@material-ui/core";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";
import getDayIndex from "../../../functions/GetDayIndex";
import ReactToPrint from "react-to-print";
import Loader from "../../../assets/images/loader.gif";
import { flushSync } from "react-dom";
import Cash from "../../../assets/images/svg/cash.svg";
import Credit from "../../../assets/images/svg/credit.svg";
import Transfer from "../../../assets/images/svg/transfer.svg";
import CustomerPoint from "../../../assets/images/svg/customer_point.svg";
import CashWhite from "../../../assets/images/svg/cash_white.svg";
import CreditWhite from "../../../assets/images/svg/credit_white.svg";
import CustomerPointWhite from "../../../assets/images/svg/customer_point_white.svg";
import TransferWhite from "../../../assets/images/svg/transfer_white.svg";
import RemainingDebt from "../../../assets/images/svg/remaining_debt.svg";
import RemainingDebtRed from "../../../assets/images/svg/remaining_debt_red.svg";
import ReceiptOrange from "../../../assets/images/svg/Receipt-orange.svg";
import PaymentGreen from "../../../assets/images/svg/payment-green.svg";
import Debt from "../../../assets/images/svg/debt.svg";
import Print from "../../../assets/images/svg/print.svg";
import StaffSelect from "../../../theme/CustomMUI/StaffSelect";
import { Tooltip } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import FullScreenDialog from "../../../theme/CustomMUI/FullScreenDialog";
import SemiCirclePie from "../../../components/Charts/SemiCirclePie";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import AddIcon from "@material-ui/icons/Add";
import BackupIcon from "@material-ui/icons/Backup";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Checkbox from "@material-ui/core/Checkbox";
import PackageTable from "../../../theme/PackageTable";
import SvgIcon from "@material-ui/core/SvgIcon";
import Waiting from "../../../assets/images/svg/waiting-receipt.svg";
import HomeChcek from "../../../assets/images/svg/home-check-receipt.svg";
import NotCome from "../../../assets/images/svg/notCome-receipt.svg";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import PackageSalesAdd from "../Packages/Sales/Add";
import { backgrounds } from "polished";
import { async } from "q";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
      },
    },
  },
});

const themeForCardDetail = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        zIndex: "999999999",
        width: "150px",
        backgroundColor: "rgba(10,10,10,0.7)",
      },
    },
  },
});

const themeForDatePicker = createTheme({
  typography: {
    fontSize: 11,
  },
});

const selectedmuiTheme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#3189ec",
          "&:hover": {
            backgroundColor: "#3189ec",
          },
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "rgba(13, 78, 108, 0.2)",
        },
      },
    },
  },
});

class SingleReceipt extends React.PureComponent {
  static contextType = AppContext;

  state = {
    loaded: false,
    customer: {},
    services: [],
    staffs: [],
    stock_items: [],
    selected_stock: null,

    save_button_disabled: false,

    created_at: null,
    is_package: null,

    customer_note: "",
    receipt_note: "",
    appointment_note: "",

    checkout_data_dialog: false,

    // debt Information
    receipt_sender: null,
    add_sms_dialog: false,
    alert_message_dialog: false,
    message: "",
    // not ekle pop-ıp
    note_add_pop_up: false,
    note_content: "",
    note_content_array: [],

    packet_detail_array: [],
    packet_detail_array1: [],

    customer_s: [
      {
        label: "Geldi",
        value: 0,
      },
      {
        label: "Gelmedi",
        value: 1,
      },
      {
        label: "Bekliyor",
        value: 2,
      },
    ],

    service_transaction_modal: false,
    service_transaction: {},
    _service_transaction: {
      service_id: null,
      staff_id: this.context.state.user.id,
      amount: null,
      customer_state: 0,
    },
    deleted_packages: [],
    selected_transaction_package_id: null,
    selected_package_name: null,
    package_delete_modal: false,
    print_dialog: false,

    addPayment_dialog: false,

    is_change: false,

    installment_dialog: false,
    selected_installment: null,
    selected_installment_status: null,
    installment_date: new Date(),

    selected_staff_services: [],

    product_transaction_modal: false,
    product_transaction: {},
    _product_transaction: {
      stock_item_id: null,
      staff_id: this.context.state.user.id,
      quantity: 1,
      amount: null,
    },

    __package: null,
    packages: [],
    package_sale_modal: false,
    new_package: {
      services: {},
    },
    total: 0,
    total_loader: false, // package sale

    // Appointments states
    unavailableData: {
      single: [],
      between: [],
      day_closed: false,
    },
    availableAppointmentExpanded: false,
    unavaliableHours: [],

    advanced_date: new Date(),
    advanced_type: 0,
    advanced_amount: 0,
    advanced_staff: null,

    hours: [...BetweenTimeGenerator(this.context.state.company_shift)],
    checkout_data_dialog: false,
    selected_staff: null,
    selected_service: null,
    selected_customer: null,
    customer_id: null,
    selected_description: "",
    selected_time: null,
    selected_date: new Date(),
    send_sms: true,
    note: null,
    checkout_data: {
      receipt_id: this.props.match.params.id,
      services: {}, // service_transaction{} objeleri pushlanacak.
      packages: {}, // service_transaction{} objeleri pushlanacak.
      sales: {}, // product_transaction{} objeleri pushlanacak.
      paids: {}, // {paid_amount, payment_type} objesi pushlanacak.
      groups: [],
      groupsAmount: {},
      installment: {}, // {paid_amount, payment_type} objesi pushlanacak.
    },

    installment_data: [],

    discount_applied: false,
    discount_target: "",
    discount_type: "TL",
    discount_modal: false,

    delete_confirm: false,
    delete_confirm1: false,

    packageChanges: false,
    /**
     * PDF states
     */
    contract_dialog: false,

    contract_tab: 0,

    // all pattern
    contract_patterns: [],
    // pattern for select
    contract_patterns_wo_clauses: [],

    // Sözleşme maddeleri
    clauses: [],
    // Seçilen sözleşme
    selected_pattern: {
      Clauses: [],
      Template: [],
    },

    // Sözleşme bilgileri
    contract_info_ful_name: "",
    contract_info_phone: "",
    contract_info_id_number: "",
    contract_info_tax_number: "",
    contract_info_address: "",

    // Ön izleme için açılan dialog
    selected_agreement_dialog_show: false,

    // yeni sözleşme olutşru ek butonları
    new_agreement_detail: false,

    // servislerin ve çalışanların listesi
    services_staffs_PDF: [],

    // PDF staff
    staffArr: [],

    // PDF services
    servicesArr: [],

    // customer agreement for receipt
    customer_agreements: [],
    // kayıtlı sözleşmeyi silmek için
    customer_agreement_delete_id: null,
    // silmek için açılan dialog
    delete_agreement_dialog: false,
    agreement_load: true,
    report2: true,
    menu_open_id: 0,
    // uploaded file
    file: null,
    agreement_customer_for_receipt: null,
    agreement_title: "",

    // sms onay dialogu
    sms_aproval_modal: false,
    // gönderilecek sms id,name,agreement name
    send_sms_id: null,
    send_sms_customer_name: null,
    send_sms_agreement_name: null,
    // Whatsapp onay dialogu
    wp_aproval_modal: false,
    // gönderilecek wp id
    send_wp_id: null,
    send_wp_customer_name: null,
    send_wp_agreement_name: null,
    // wp phone number
    send_wp_phone: null,
    send_wp_message: null,

    logo_src: null,

    pagination_agreement: {
      page: null,
      total_page: null,
      onChange: (page) => this.getAgreement(page, id),
    },

    send_to_signiture_aproval_modal: false,

    content_to_signiture: {
      receipt_id: null,
      title: null,
      name: null,
      company_name: null,
      company_info: null,
      address: null,
      nationality_id: null,
      tax_no: null,
      phone: null,
      date: null,
      clauses: [],
      show_service: false,
      show_amount: false,
      show_staff: false,
    },

    services_update_modal: false,
    services_update_date_modal: false,

    selected_package_transition_id: null,
    selected_package_transition_update_state: null,

    package_update_date: null,
    selected_package_update_date: null,

    deleted_packages_services_transaction_package_id: null,
    last_services_from_package_to_delete_modal: false,

    key_for_delete_services: null,

    sex: null,

    group_header_height: "6.5vh",

    // Detail dialog open-close
    services_detail_dialog_modal: false,
    package_detail_dialog_modal: false,
    product_detail_dialog_modal: false,
    payment_detail_dialog_modal: false,
    installment_detail_dialog_modal: false,

    services_by_selected_staff_show: false,
    services_by_selected_staff: [],

    products_by_selected_staff_show: false,
    products_by_selected_staff: [],

    //for appointment
    hoursData: {
      closed_hours: [],
      day_closed: false,
      free_hours: [],
      occupied_hours: [],
      appointment_hours: [],
      trans_hours: [],
    },

    // package dialog tab
    addPackage: false,

    // new package dialog modal
    new_package_sales_dialog_modal: false,

    // Seans notu
    session_note: {
      value: "",
      session_id: null,
      key: null,
    },
    openInput: false,
    barcodeValue: "",
    stock_item_value: null,
    payment_select_staff: {},
  };

  getCompanyHours = () => {
    Axios.get(`${this.context.api_endpoint}/company/hours`)
      .then(async ({ data }) => {
        await this.setState({
          hour_detail: {
            IsSet: data.data.IsSet,
            end_hour: data.data.end_hour,
            id: data.data.id,
            slice: data.data.slice,
            start_hour: data.data.start_hour,
          },
        });

        await this.setState({
          hours: [
            ...BetweenTimeGenerator({
              IsSet: data.data.IsSet,
              end_hour: data.data.end_hour,
              id: data.data.id,
              slice: data.data.slice,
              start_hour: data.data.start_hour,
            }),
          ],
        });
      })
      .catch((e) => console.log(e));
  };

  getCompanyHours = () => {
    Axios.get(`${this.context.api_endpoint}/company/hours`)
      .then(async ({ data }) => {
        await this.setState({
          hour_detail: {
            IsSet: data.data.IsSet,
            end_hour: data.data.end_hour,
            id: data.data.id,
            slice: data.data.slice,
            start_hour: data.data.start_hour,
          },
        });

        await this.setState({
          hours: [
            ...BetweenTimeGenerator({
              IsSet: data.data.IsSet,
              end_hour: data.data.end_hour,
              id: data.data.id,
              slice: data.data.slice,
              start_hour: data.data.start_hour,
            }),
          ],
        });
      })
      .catch((e) => console.log(e));
  };

  getCompanyHours = () => {
    Axios.get(`${this.context.api_endpoint}/company/hours`)
      .then(async ({ data }) => {
        await this.setState({
          hour_detail: {
            IsSet: data.data.IsSet,
            end_hour: data.data.end_hour,
            id: data.data.id,
            slice: data.data.slice,
            start_hour: data.data.start_hour,
          },
        });

        await this.setState({
          hours: [
            ...BetweenTimeGenerator({
              IsSet: data.data.IsSet,
              end_hour: data.data.end_hour,
              id: data.data.id,
              slice: data.data.slice,
              start_hour: data.data.start_hour,
            }),
          ],
        });
      })
      .catch((e) => console.log(e));
  };

  getCompanyHours = () => {
    Axios.get(`${this.context.api_endpoint}/company/hours`)
      .then(async ({ data }) => {
        await this.setState({
          hour_detail: {
            IsSet: data.data.IsSet,
            end_hour: data.data.end_hour,
            id: data.data.id,
            slice: data.data.slice,
            start_hour: data.data.start_hour,
          },
        });

        await this.setState({
          hours: [
            ...BetweenTimeGenerator({
              IsSet: data.data.IsSet,
              end_hour: data.data.end_hour,
              id: data.data.id,
              slice: data.data.slice,
              start_hour: data.data.start_hour,
            }),
          ],
        });
      })
      .catch((e) => console.log(e));
  };

  saveSingleTransactionNote = async () => {
    const { session_note, checkout_data } = this.state;

    await Axios.put(
      `${this.context.api_endpoint}/company/receipt/${parseInt(
        checkout_data.receipt_id
      )}/transaction/${session_note.session_id}/note`,
      {
        note: session_note.value,
      }
    )
      .then((data) => {
        if (data.data.status === 200) {
          toast.success("Not Başarıyla Kaydedildi");
          this.setState({
            checkout_data: {
              ...this.state.checkout_data,
              packages: {
                ...this.state.checkout_data.packages,
                [session_note.key]: {
                  ...this.state.checkout_data.packages[session_note.key],
                  session_note: session_note.value,
                },
              },
            },
          });
          setTimeout(() => {
            this.setState({
              session_note: {
                value: "",
                session_id: null,
                key: null,
              },
            });
          }, 1000);
        } else {
          toast.success(data.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  goBack = () => {
    this.setState({
      new_package_sales_dialog_modal: false,
    });
  };

  // for detail services filter by selected staff
  getServicesByStaffId = async () => {
    const { service_transaction } = this.state;
    let arr = [];
    await Axios.get(`${this.context.api_endpoint}/company/services/filter`, {
      params: {
        id: service_transaction.staff_id,
        key: "",
        paginate: 1,
      },
    })
      .then(({ data }) =>
        this.setState({
          services_by_selected_staff: [
            ...data.data.filter(
              (m) => m && m.is_passive !== undefined && m.is_passive === false
            ),
          ],
          services_by_selected_staff_show: true,
        })
      )
      .catch((e) => console.log(e));
  };

  getHeightForGruopHeader = () => {
    const head = document.querySelector(".group_header")?.clientHeight;

    this.setState({
      group_header_height: head === null ? "6.6vh" : `${head}px`,
    });
  };

  setTransictionsToDate = () => {
    this.setState({
      checkout_data: {
        ...this.state.checkout_data,
        groups: [
          ...this.state.checkout_data.groups.map((item, index) =>
            item.id === this.state.selected_package_transition_id
              ? {
                  ...item,
                  process_date: moment(
                    this.state.checkout_data.groups[index].process_date,
                    "YYYY-MM-DD"
                  )
                    .add(this.state.package_update_date, "d")
                    .format("YYYY-MM-DD"),
                }
              : item
          ),
        ],
        groupsAmount: Object.assign(
          {},
          Object.values(this.state.checkout_data.groupsAmount).map(
            (item, key) =>
              item.id === this.state.selected_package_transition_id
                ? {
                    ...item,
                    process_date: moment(
                      this.state.checkout_data.groupsAmount[key].process_date,
                      "YYYY-MM-DD"
                    )
                      .add(this.state.package_update_date, "d")
                      .format("YYYY-MM-DD"),
                  }
                : item
          )
        ),
        packages: Object.assign({}, [
          ...Object.values(this.state.checkout_data.packages).filter(
            (item) =>
              item.receipt_package_id !==
              this.state.selected_package_transition_id
          ),
          ...Object.values(this.state.checkout_data.packages)
            .filter(
              (item) =>
                item.receipt_package_id ===
                this.state.selected_package_transition_id
            )
            .map((item, key) => ({
              ...item,
              process_date: moment(
                this.state.checkout_data.packages[key].process_date,
                "YYYY-MM-DD"
              )
                .add(this.state.package_update_date, "d")
                .format("YYYY-MM-DD"),
            })),
        ]),
      },
    });
  };

  setTransictionsToState = () => {
    this.setState({
      checkout_data: {
        ...this.state.checkout_data,
        packages: Object.assign(
          {},
          Object.values(this.state.checkout_data.packages).map((item) =>
            item.receipt_package_id ===
            this.state.selected_package_transition_id
              ? {
                  ...item,
                  customer_state:
                    this.state.selected_package_transition_update_state,
                }
              : item
          )
        ),
      },
    });
  };

  sendAgreementToMobile = () => {
    const { content_to_signiture } = this.state;

    Axios.post(
      `${this.context.api_endpoint}/company/contract/template/pdf/draft`,
      {
        ...content_to_signiture,
      }
    )
      .then((data) => {
        if (data.data.status === 201) {
          toast.success("Sözleşme başarıyla gönderildi.");
        }
      })
      .catch((err) => {
        if (err.response.data.status === 404) {
          toast.error(err.response.data.message);
        }
      });
  };

  getLogoSrc = () => {
    Axios.get(`${this.context.api_endpoint}/company/message/credit`)
      .then(({ data }) => {
        this.setState({
          logo_src: data.data.settings.logo_src,
        });
      })
      .catch((e) => console.log(e));
  };

  // send sms to customer
  sendSMSAgreement = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/contract/template/pdf/sms`,
      {
        id: id,
        is_wp: 1,
      }
    )
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(data.data.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // send wp to customer
  sendWPAgreement = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/contract/template/pdf/sms`,
      {
        id: id,
        is_wp: 2,
      }
    )
      .then((data) => {
        window.open(
          `https://api.whatsapp.com/send?phone=${this.state.customer.phone}&text=${data.data.data}`,
          "_blank"
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  deleteAgreement = (id) => {
    Axios.delete(
      `${this.context.api_endpoint}/company/contract/template/pdf/${id}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          this.getCustomerAgreements(1, this.state.checkout_data.receipt_id);
        }
      })
      .catch((err) => {
        toast.error(
          "Beklenmeyen bir hata meydana geldi. Lütfen müşteri temsilciniz ile iletişime geçiniz."
        );
      });
  };

  uploadAgreement = async (e) => {
    if (e.target?.files) {
      if (e.target.files[0].size / 1024 / 1024 > 2) {
        return toast.error("Sözleşme boyutu 2 mb'dan fazla olmamalıdır.");
      } else {
        const Form = new FormData();
        toast.warning("Sözleşme hazırlanıyor...");
        await Form.append("uploadContractPdf", e.target.files[0], "Sözleşme");
        await Form.append("customer_id", this.state.customer_id);
        await Form.append("receipt_id", this.state.checkout_data.receipt_id);
        await Form.append("platform", 2);
        await Form.append("title", this.state.agreement_title);
        Axios.post(
          `${this.context.api_endpoint}/company/contract/template/pdf`,
          Form,
          {
            headers: { "Content-Type": undefined },
          }
        )
          .then(({ data }) => {
            if (data.status === 201) {
              toast.success("Sözleşme başarıyla yüklendi.");
              this.setState({
                contract_tab: 3,
                file: null,
                agreement_title: "",
              });
              this.getCustomerAgreements(
                1,
                this.state.checkout_data.receipt_id
              );
            }
          })
          .catch((err) => {
            toast.error(
              "Beklenmeyen bir hata oluştu. Lütfen müşteri temsilciniz ile iletişime geçiniz."
            );
          });
      }
    }
  };

  getCustomerAgreements = (page, id) => {
    this.setState({
      agreement_load: false,
    });
    Axios.get(
      `${
        this.context.api_endpoint
      }/company/contract/template/pdf?receipt_id=${id}&page=${page ? page : 1}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          this.setState({
            customer_agreements: data.data.pdfs.records.map((item) => {
              return {
                url: item.secure_url,
                name: `${item.customer_name} ${item.customer_surname}`,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                id: item.id,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                agreement_name: item.title,
                opened: item.is_connection_opened,
              };
            }),
            agreement_load: true,
            pagination_agreement: {
              ...this.state.pagination_agreement,
              page: data.data.pdfs.page,
              total_page: data.data.pdfs.records.length,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getAgreements = () => {
    Axios.get(`${this.context.api_endpoint}/company/contract/template`)
      .then((data) => {
        this.setState({
          contract_patterns: data.data.data.map((pat) =>
            pat.Clauses === null ? { Template: pat.Template, Clauses: [] } : pat
          ),
          contract_patterns_wo_clauses: [...data.data.data].map(
            (el) => el.Template
          ),
        });
      })
      .catch((err) => console.log(err));
  };

  getClauses = () => {
    Axios.get(`${this.context.api_endpoint}/company/contract/clause`)
      .then((data) => {
        this.setState({
          clauses: data.data.data,
        });
      })
      .catch((err) => console.log("hata:", err));
  };

  getSingleReceipt = async () => {
    const { params } = this.props.match;
    const { checkout_data } = this.state;

    await Axios.get(
      `${this.context.api_endpoint}/company/receipt/${params.id}`
    ).then(({ data: { data } }) => {
      // Taksitli ödemelerde eğer ödenememiş taksit varsa alert çıkartır.
      data.receipt.receipt_installments.forEach((element) => {
        const currentDate = new Date();
        const elementDate = new Date(element.payment_date);

        // Zaman farkını gün cinsine çevirme
        const timeDifferenceInDays = Math.floor(
          (currentDate - elementDate) / (1000 * 60 * 60 * 24)
        );

        if (timeDifferenceInDays > 0 && element.payment_status === 0) {
          toast.warn(this.context.t(`receipts.alert_receipt_installment`));
        }
      });

      this.setState({
        sex: data.receipt.customer.sex,
        agreement_customer_for_receipt: data.receipt.customer.full_name,
        loaded: true,
        customer_id: data.receipt.customer.id,
        selected_customer: data.receipt.customer.full_name,
        selected_description: data.receipt.customer.description,
        receipt_sender: data.receipt.company_settings.sender
          ? data.receipt.company_settings.sender
          : null,
        customer: { ...data.receipt.customer },

        contract_info_ful_name: data.receipt.customer.full_name,
        contract_info_phone: data.receipt.customer.phone,
        contract_info_id_number: data.receipt.customer.nationalityID,
        staffs: [
          ...data.staffs.map((staff) => {
            // staff.services.forEach((service, index) => {
            //   if (
            //     service.service.category_name !== null &&
            //     service.service.category_name.toLowerCase().includes("erkek")
            //   ) {
            //     service.service.name += " (E)";
            //   } else if (
            //     service.service.category_name !== null &&
            //     service.service.category_name.toLowerCase().includes("kadın")
            //   ) {
            //     service.service.name += " (K)";
            //   }
            // });
            return staff;
          }),
        ],
        services: [...data.services],
        stock_items: [...data.stock_items],

        created_at: data.receipt.created_at,
        is_package: data.receipt.is_package,

        customer_note: data.receipt.customer.description,
        receipt_note: data.receipt.note,
        appointment_note: data.receipt.appointment
          ? data.receipt.appointment.note
          : null,

        checkout_data: {
          ...checkout_data,
          paids: Object.assign(
            ...data.receipt.receipt_payments.map((item, index) => ({
              [index]: {
                payment_date: item.payment_date,
                payment_type: item.payment_type,
                staff_id: item?.staff_id,
                pay_amount:
                  item.payment_type === 3 ? item.amount * 100 : item.amount,
              },
            })),
            {}
          ),
          installment: Object.assign(
            ...data.receipt.receipt_installments.map((item, index) => ({
              [index]: {
                installment_date: item.payment_date,
                installment_type: item.payment_type,
                installment_amount:
                  item.payment_type === 3 ? item.amount * 100 : item.amount,
                installment_status: item.payment_status,
              },
            })),
            {}
          ),
          sales: Object.assign(
            ...data.receipt.receipt_sales.map((item, index) => ({
              [index]: {
                stock_item_id: item.stock_item_id,
                staff_id: item.staff_id,
                amount: item.amount,
                quantity: item.quantity,
                first_amount: item.amount,
                sold_date: item.sold_date ? item.sold_date : "",
                stock_item: {
                  is_passive: item.stock_item.is_passive,
                },
              },
            })),
            {}
          ),
          services: Object.assign(
            ...data.receipt.receipt_transactions
              .filter((item) => item.process_type !== 1)
              .map((item, index) => ({
                [index]: {
                  process_type: item.process_type,
                  process_date: item.process_date
                    ? item.process_date
                    : moment().format("YYYY-MM-DD"),
                  service_id: item.service_id,

                  staff_id: item.staff ? item.staff.id : null,
                  amount: item.amount,
                  first_amount: item.amount,
                  customer_state: item.customer_state,
                },
              })),
            this.state.checkout_data.services
          ),
          groups: data.receipt.receipt_packages
            ? [
                ...data.receipt.receipt_packages?.map((item, index) => ({
                  bounty: item.bounty,
                  id: item.id,
                  staff_id: item.staff_id ? item.staff_id : null,
                  amount: item.amount,
                  note: item.note ?? "",
                  package_name: item.package_name,
                  packet_id: item.packet_id,
                  process_date: item.process_date,
                  receipt_id: item.receipt_id,
                  receipt: item.receipt,
                  customer_state: item.customer_state,
                  is_passive: item.is_passive,
                  transaction_package_id: item.transaction_package_id,
                })),
              ]
            : [],
          groupsAmount: data.receipt.receipt_packages
            ? data.receipt.receipt_packages.length > 0 &&
              Object.assign(
                ...data.receipt.receipt_packages.map((item, index) => ({
                  [index]: {
                    bounty: item.bounty,
                    id: item.id,
                    first_amount: item.amount,
                    staff_id: item.staff_id ? item.staff_id : null,
                    amount: item.amount,
                    note: item.note ?? "",
                    package_name: item.package_name,
                    packet_id: item.packet_id,
                    process_date: item.process_date,
                    receipt_id: item.receipt_id,
                    receipt: item.receipt,
                    customer_state: item.customer_state,
                    transaction_package_id: item.transaction_package_id,
                  },
                }))
              )
            : {},
          packages: Object.assign(
            ...data.receipt.receipt_transactions
              .filter((item) => item.process_type === 1)
              .map((item, index) => ({
                [index]: {
                  process_type: item.process_type,
                  process_date: item.process_date,
                  service_id: item.service_id,
                  staff_id: item.staff ? item.staff.id : null,
                  amount: item.amount,
                  first_amount: item.amount,
                  customer_state: item.customer_state,
                  transaction_package_id: item.transaction_package_id,
                  receipt_package_id: item.receipt_package_id,
                  receipt_transaction_uniqe_id: item.id,
                  session_note: item.note,
                },
              })),
            {}
          ),
        },
      });
      setTimeout(() => {
        this.getHeightForGruopHeader();
      }, 500);
    });

    await Axios.get(`${this.context.api_endpoint}/company/packets/nop`).then(
      ({ data }) => {
        this.setState({
          packages: [
            ...data.data.packets.filter((m) => m.is_passive === false),
          ],
        });
      }
    );
  };

  // Tc No Validation
  validateTCKN = (tckn) => {
    // Kimlik numarası uzunluğu kontrolü
    if (tckn.length !== 11) {
      return false;
    }

    // İlk hane sıfır olamaz kontrolü
    if (tckn[0] === "0") {
      return false;
    }

    // 1. 3. 5. 7. ve 9. hanelerin toplamının 7 katı
    let oddSum = 0;
    for (let i = 0; i < 9; i += 2) {
      oddSum += parseInt(tckn[i], 10);
    }
    oddSum *= 7;

    // 2. 4. 6. ve 8. hanelerin toplamı
    let evenSum = 0;
    for (let i = 1; i < 8; i += 2) {
      evenSum += parseInt(tckn[i], 10);
    }

    // 1. 2. 3. 4. 5. 6. 7. 8. 9. ve 10. hanelerin toplamının mod 10'u
    const totalSum = oddSum - evenSum;
    const mod10 = totalSum % 10;

    // 11. hane kontrolü
    if (parseInt(tckn[9], 10) === mod10) {
      return true;
    }

    return false;
  };

  stockItemHandler = async () => {
    await Axios.post(`${this.context.api_endpoint}/company/stock/item`, {
      barcode: this.state.barcodeValue,
    })
      .then((data) => {
        this.setState({
          stock_item_value: data.data.data.name ? data.data.data.name : null,
          product_transaction: {
            ...this.state.product_transaction,
            stock_item_id: parseInt(data.data.data.id),
            amount: data.data.data.amount,
          },
        });
      })
      .catch((err) => {
        toast.error("Kayıt Bulunamadı");
      });
  };

  getStaff = () => {
    const { checkout_data } = this.state;

    let staffArr = [];

    Object.keys(checkout_data.services).filter(
      (key) => checkout_data.services[key] !== undefined
    ).length > 0 &&
      Object.keys(checkout_data.services).map(
        (key, index) =>
          checkout_data.services[key] !== undefined &&
          staffArr.push(
            this.state.staffs.filter(
              (item) => item.id === checkout_data.services[key].staff_id
            )[0].full_name
          )
      );

    Object.keys(checkout_data.packages).filter(
      (key) => checkout_data.packages[key] !== undefined
    ).length > 0 &&
      Object.keys(checkout_data.packages).map(
        (key, index) =>
          checkout_data.packages[key] !== undefined &&
          staffArr.push(
            this.state.staffs.filter(
              (item) => item.id === checkout_data.packages[key].staff_id
            )[0].full_name
          )
      );

    this.setState({
      staffArr: [...staffArr],
    });
  };

  getServices = () => {
    const { checkout_data } = this.state;

    let servicesArr = [];

    Object.keys(checkout_data.services).filter(
      (key) => checkout_data.services[key] !== undefined
    ).length > 0 &&
      Object.keys(checkout_data.services).map(
        (key, index) =>
          checkout_data.services[key] !== undefined &&
          servicesArr.push(
            this.state.services.filter(
              (item) => item.id === checkout_data.services[key].service_id
            )[0]?.name
          )
      );

    Object.keys(checkout_data.packages).filter(
      (key) => checkout_data.packages[key] !== undefined
    ).length > 0 &&
      Object.keys(checkout_data.packages).map(
        (key, index) =>
          checkout_data.packages[key] !== undefined &&
          servicesArr.push(
            this.state.services.filter(
              (item) => item.id === checkout_data.packages[key].service_id
            )[0].name
          )
      );

    this.setState({
      servicesArr: [...servicesArr],
    });
  };

  getStaffUnavailableHours = async () => {
    const { selected_staff, selected_date, selected_service } = this.state;

    await Axios.get(
      `${this.context.api_endpoint}/company/appointments/staff/available`,
      {
        params: {
          staff_id: parseInt(selected_staff),
          date: moment(selected_date).format("YYYY-MM-DD"),
          day_index: getDayIndex(moment(selected_date).format("YYYY-MM-DD")),
          process_time: parseInt(
            this.state.services.filter(
              (item) => item.id === selected_service
            )[0].process_time
          ),
        },
      }
    ).then(({ data }) => {
      this.setState({
        hoursData: {
          ...this.state.hoursData,
          occupied_hours: data.data.occupied_hours
            ? [
                ...data.data.occupied_hours?.map(
                  (item) =>
                    `${item.split("T")[1].split(":")[0]}:${
                      item.split("T")[1].split(":")[1]
                    }`
                ),
              ]
            : [],
          free_hours: data.data.free_hours
            ? [
                ...data.data.free_hours?.map(
                  (item) =>
                    `${item.split("T")[1].split(":")[0]}:${
                      item.split("T")[1].split(":")[1]
                    }`
                ),
              ]
            : [],
          closed_hours: data.data.closed_hours
            ? [
                ...data.data.closed_hours?.map(
                  (item) =>
                    `${item.split("T")[1].split(":")[0]}:${
                      item.split("T")[1].split(":")[1]
                    }`
                ),
              ]
            : [],
          day_closed: data.data.day_closed,
        },
        availableAppointmentExpanded: true,
        appointment_hours: data.data.app_overlap
          ? data.data.app_overlap
              .map((item) =>
                BetweenTimeGenerator({
                  slice: this.context.state.company_shift.slice,
                  end_hour: `${item.app_end.split("T")[1].split(":")[0]}:${
                    item.app_end.split("T")[1].split(":")[1]
                  }`,
                  start_hour: `${item.app_start.split("T")[1].split(":")[0]}:${
                    item.app_start.split("T")[1].split(":")[1]
                  }`,
                  isSet: 1,
                })
              )
              .flat()
          : [],
        trans_hours: data.data.closed_overlap
          ? data.data.closed_overlap
              .map((item) =>
                BetweenTimeGenerator({
                  slice: this.context.state.company_shift.slice,
                  end_hour: `${item.app_end.split("T")[1].split(":")[0]}:${
                    item.app_end.split("T")[1].split(":")[1]
                  }`,
                  start_hour: `${item.app_start.split("T")[1].split(":")[0]}:${
                    item.app_start.split("T")[1].split(":")[1]
                  }`,
                  isSet: 1,
                })
              )
              .flat()
          : [],
      });
    });
  };

  insertAppointment = () => {
    const {
      selected_date,
      selected_service,
      selected_staff,
      selected_customer,
      customer_id,
      selected_time,
      send_sms,
      note,
    } = this.state;

    let appointment_date = selected_date;
    let appointment_time = selected_time.split(":");
    appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${
      appointment_time[0]
    }:${appointment_time[1]}:00Z`;

    Axios.post(`${this.context.api_endpoint}/company/appointment/insert`, {
      staff_id: selected_staff,
      customer_id: customer_id,
      service_id: selected_service,
      receipt_id: parseInt(this.props.location.pathname.split("/")[3]),
      send_sms: selected_customer
        ? selected_customer.send_sms === false
          ? false
          : send_sms
        : send_sms,
      appointment_date,
      note,
    })
      .then((response) => {
        if (response.status === 201) {
          // this.props.history.push(
          //   `/appointments?date=${this.state.selected_date}`
          // );
          toast.success(this.context.t(`appointments.insertToast`));
          this.setState({
            checkout_data_dialog: false,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else if (e.response.status === 404) {
          this.setState({
            checkout_data_dialog: false,
          });
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    })
      .then(({ data }) => {
        arr = [...data.data.records];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  saveSingleReceipt = () => {
    const { params } = this.props.match;
    const { checkout_data, installment_data, save_button_disabled } =
      this.state;

    this.setState({
      save_button_disabled: true,
    });

    this.validatePointPaids() &&
      Axios.put(`${this.context.api_endpoint}/company/receipt/update`, {
        id: parseInt(params.id),
        customer_id: this.state.customer.id,
        is_paid:
          parseFloat(this.getAllTotalService()) -
            parseFloat(
              this.getTotalAmounts().installment_total +
                this.getTotalAmounts().paids
            ) <=
          0,
        total_paid: parseFloat(this.getTotalAmounts().paids),
        note: this.state.receipt_note,
        content: this.state.message ? this.state.message : "",
        receipt_packages:
          this.state.discount_target !== "services" &&
          this.state.discount_target !== "sales"
            ? [
                ...Object.keys(checkout_data.groups)
                  .filter((key) => checkout_data.groups[key] !== undefined)
                  .map((key) => ({
                    receipt_id: checkout_data.groups[key].receipt_id,
                    process_date: checkout_data.groups[key].process_date,
                    staff_id: checkout_data.groups[key].staff_id,
                    amount: checkout_data.groups[key].amount,
                    packet_id: checkout_data.groups[key].packet_id,
                    package_name: checkout_data.groups[key].package_name,
                    note: checkout_data.groups[key].note,
                    id: checkout_data.groups[key].id,
                    is_passive: checkout_data.groups[key].is_passive,
                    transaction_package_id:
                      checkout_data.groups[key].transaction_package_id,
                  })),
              ]
            : [],
        receipt_transactions: [
          ...Object.keys(checkout_data.services)
            .filter((key) => checkout_data.services[key] !== undefined)
            .map((key) => ({
              amount: checkout_data.services[key].amount,
              first_amount: checkout_data.services[key].first_amount,
              customer_state: checkout_data.services[key].customer_state,
              process_type: checkout_data.services[key].process_type,
              process_date: checkout_data.services[key].process_date,
              service_id: checkout_data.services[key].service_id,
              staff_id: checkout_data.services[key].staff_id,
              receipt_package_id:
                checkout_data.services[key].receipt_package_id,
              transaction_package_id:
                checkout_data.services[key].transaction_package_id,
            })),
          ...Object.keys(checkout_data.packages)
            .filter((key) => checkout_data.packages[key] !== undefined)
            .map((key) => ({
              amount: checkout_data.packages[key].amount,
              first_amount: checkout_data.packages[key].first_amount,
              customer_state: checkout_data.packages[key].customer_state,
              process_type: checkout_data.packages[key].process_type,
              process_date: checkout_data.packages[key].process_date,
              service_id: checkout_data.packages[key].service_id,
              staff_id: checkout_data.packages[key].staff_id,
              receipt_package_id:
                checkout_data.packages[key].receipt_package_id,
              transaction_package_id:
                checkout_data.packages[key].transaction_package_id,
              note: checkout_data.packages[key].session_note,
            })),
        ],
        receipt_sales: [
          ...Object.keys(checkout_data.sales)
            .filter((key) => checkout_data.sales[key] !== undefined)
            .map((key) => ({
              ...checkout_data.sales[key],
              sold_date: checkout_data.sales[key].sold_date,
            })),
        ],
        receipt_payments: [
          ...Object.keys(checkout_data.paids)
            .filter((key) => checkout_data.paids[key] !== undefined)
            .map((key) => ({
              ...checkout_data.paids[key],
              amount: checkout_data.paids[key].pay_amount,
              staff_id: checkout_data.paids[key].staff_id
                ? checkout_data.paids[key].staff_id
                : null,
              payment_date: `${moment(
                checkout_data.paids[key].payment_date
              ).format("YYYY-MM-DD")}T00:00:00.000Z`,
            })),
        ],
        deleted_packages: this.state.deleted_packages,
        receipt_installments: [
          ...Object.keys(checkout_data.installment)
            .filter((key) => checkout_data.installment[key] !== undefined)
            .map((key) => ({
              amount: checkout_data.installment[key].installment_amount,
              payment_date: `${moment(
                checkout_data.installment[key].installment_date
              ).format("YYYY-MM-DD")}T00:00:00.000Z`,
              payment_status: checkout_data.installment[key].installment_status,
              payment_type: checkout_data.installment[key].installment_type,
            })),
        ],
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              discount_target: "",
              service_transaction: {
                staff_id: null,
                service_id: null,
                amount: null,
                first_amount: null,
                process_date: null,
                process_type: null,
              },
              message: "",
              services_detail_dialog_modal: false,
              package_detail_dialog_modal: false,
              services_by_selected_staff_show: false,
            });
            this.setState({
              deleted_packages: [],
              deleted_packages_services_transaction_package_id: null,
              key_for_delete_services: null,
            });
            // 201 olacak
            if (response.data.data.warning_text !== "") {
              setTimeout(() => {
                toast.warning(response.data.data.warning_text);
              }, 750);
            }
            this.setState({
              alert_message_dialog: false,
              save_button_disabled: false,
            });
            toast.success(
              this.context.t(`['receipt/detail/id'].saveChangesToast`)
            );
            this.getSingleReceipt();
            flushSync(() => {
              this.setState({
                is_change: false,
                deleted_packages: [],
              });
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.setState({
              discount_target: "",
            });
            toast.error(e.response.data.message, {
              autoClose: 3000,
            });
            this.setState({
              save_button_disabled: false,
            });
          } else {
            this.setState({
              discount_target: "",
            });
            toast.warning(e.response.data.message);
            this.setState({
              save_button_disabled: false,
            });
          }
        });
  };

  deleteSingleReceipt = () => {
    const { params } = this.props.match;
    Axios.delete(
      `${this.context.api_endpoint}/company/receipt/${params.id}/delete`
    )
      .then((response) => {
        response.status === 200 &&
          toast.success("Adisyon ve ödemeler başarıyla silindi!");
        this.props.history.push("/receipts");
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        }
      });
  };

  searchServicesByKey = async (key) => {
    let arr = [];
    await Axios.get(`${this.context.api_endpoint}/company/services/filter`, {
      params: {
        id: this.state.service_transaction.staff_id
          ? this.state.service_transaction.staff_id
          : null,
        key: key === " " ? "" : key,
        paginate: 1,
      },
    })
      .then(({ data }) => {
        const services = data.data
          .filter(
            (m) => m && m.is_passive !== undefined && m.is_passive === false
          )
          .map((service) => {
            if (
              service.category_name !== null &&
              service.category_name.toLowerCase().includes("erkek")
            ) {
              service.name += " (E)";
            } else if (
              service.category_name !== null &&
              service.category_name.toLowerCase().includes("kadın")
            ) {
              service.name += " (K)";
            }
            return service;
          });
        arr = [...services];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  searchStockByKey = async (key) => {
    let arr = [];
    if (key === " ") {
      await Axios.get(`${this.context.api_endpoint}/company/stock/items/notpag`)
        .then(({ data }) => {
          arr = [...data.data].filter((item) => item.is_passive !== true);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
      return arr;
    } else {
      await Axios.get(`${this.context.api_endpoint}/company/stock/items`, {
        params: {
          stockname: key === " " ? "" : key,
          paginate: 1,
        },
      })
        .then(({ data }) => {
          arr = [...data.data.records].filter(
            (item) => item.is_passive !== true
          );
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
      return arr;
    }
  };

  componentDidMount = () => {
    this.getSingleReceipt();
    this.getLogoSrc();

    this.getCompanyHours();
  };

  Signature = (props) => {
    return (
      <SvgIcon
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 122.88 107.95"

        // version="1.0"
        // xmlns="http://www.w3.org/2000/svg"
        // width="512.000000pt"
        // height="512.000000pt"
        // viewBox="0 0 512.000000 512.000000"
        // preserveAspectRatio="xMidYMid meet"
      >
        <g>
          <path
            class="st0"
            d="M82.19,0c1.22-0.01,2.27,0.4,3.18,1.27l13.27,12.7c0.86,0.82,1.28,1.91,1.3,3.11 c0.02,1.22-0.34,2.33-1.18,3.18l-7.06,7.35L72.09,8.67l6.99-7.28C79.94,0.5,80.98,0.02,82.19,0L82.19,0z M20.08,68.29 c2.4-0.74,4.93,0.61,5.67,3c0.74,2.4-0.61,4.93-3,5.67c-6.98,2.14-11.66,6.28-13.21,10.17c-0.41,1.04-0.55,1.99-0.39,2.77 c0.12,0.59,0.48,1.13,1.07,1.56c2.55,1.86,8.04,2.34,17.26,0.1c5.46-1.33,11.31-3.13,16.49-4.73c5.29-1.63,9.91-3.06,13.12-3.66 c5.92-1.1,11.83-1.85,16.6-1.61c6.26,0.31,10.96,2.32,12.57,7.13c1.04,3.11,0.75,5.81,0.49,8.23c-0.09,0.87-0.18,1.68-0.14,2.21 c0,0.02,0.95-0.05,5.53-0.41c5.3-0.42,10.79-2.51,16.21-4.58c2.78-1.06,5.54-2.11,8.67-3.07c2.4-0.73,4.93,0.63,5.65,3.02 c0.73,2.4-0.63,4.93-3.02,5.65c-2.43,0.74-5.24,1.82-8.06,2.89c-6.04,2.3-12.14,4.63-18.74,5.15c-12.07,0.95-14.87-2.46-15.3-7.95 c-0.1-1.29,0.03-2.54,0.17-3.88c0.16-1.5,0.34-3.18-0.07-4.39c-0.18-0.54-1.89-0.82-4.39-0.94c-3.99-0.2-9.22,0.48-14.54,1.47 c-2.65,0.49-7.05,1.85-12.09,3.41c-5.27,1.63-11.22,3.46-17.02,4.87c-12.14,2.95-20.21,1.7-24.73-1.6c-2.54-1.85-4.05-4.27-4.63-7 c-0.54-2.54-0.23-5.28,0.85-8C3.56,77.63,10.33,71.27,20.08,68.29L20.08,68.29z M41.27,48.45L53.3,60.02l-14.71,3.95 c-0.53,0.1-0.74-0.11-0.65-0.6L41.27,48.45L41.27,48.45z M65.87,15.11l19.59,18.87L58.7,62.23l-23.98,6.43 c-0.86,0.17-1.2-0.17-1.06-0.98l5.43-24.31L65.87,15.11L65.87,15.11z"
          />
        </g>
      </SvgIcon>
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.unavailableData !== prevState.unavailableData) {
      this.setState({
        unavaliableHours: [
          ...DisabledAppointmentsGenerator(this.state.unavailableData),
        ],
      });
    }
  };

  render() {
    // const { service_transaction, product_transaction } = this.state;
    // console.log(this.state.checkout_data);

    const ReceiptHeading = this._ReceiptHeadingBuilder;

    const ServiceSales = this._ServiceSalesBuilder;
    const PackageSales = this._PackageSalesBuilder;
    const ProductSales = this._ProductSalesBuilder;
    const Payments = this._PaymentsBuilder;
    const AppNotes = this._AppNotesBuilder;
    const CusNotes = this._CusNotesBuilder;
    const PackageSummary = this._PackageSummaryBuilder;

    const ReceiptDeleteDialog = this._ReceiptDeleteDialogBuilder;
    const ServiceSalesDialog = this._ServiceSalesDialogBuilder;
    const PackageSalesDialog = this._PackageSalesDialogBuilder;
    const ProductSalesDialog = this._ProductSalesDialogBuilder;
    const DebtInformationDialog = this._DebtInformationDialogBuilder;
    const AutoAppointmentDialog = this._AutoAppointmentDialogBuilder;
    const DiscountDialog = this._DiscountDialogBuilder;

    const TypeServicesDetailDialog = this._TypeServicesDetailDialog;
    const TypePackageDetailDialog = this._TypePackageDetailDialog;
    const TypeProductDetailDialog = this._TypeProductDetailDialog;
    const TypePaymentDetailDialog = this._TypePaymentDetailDialog;

    return (
      <AuthContainer
        makePlanUpgrade={[2]}
        authorities={[0, 1]}
        limited_permission="sales"
      >
        <Grid className="no-gutters-row">
          <SaveChangesButton>
            <Button
              icon="check"
              iconSize="32"
              title={this.context.t(
                `['receipt/detail/id'].saveChangesButtonTitle`
              )}
              disabled={this.state.save_button_disabled}
              backgroundColor="save-opacity"
              textColor="green"
              outlined={true}
              size="lg"
              fullWidth
              onClick={() => {
                this.saveSingleReceipt();
                this.setState({ packageChanges: false });
              }}
            />
          </SaveChangesButton>
          <ReceiptHeading />

          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <ServiceSales />
            <PackageSales />
            <ProductSales />
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <Payments />
            <PackageSummary />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
            <Column className="xs-12 sm-12 md-4 lg-12">
              {this.state.alert_message_dialog === true ? (
                <Alert severity="warning">
                  <b>{this.context.t(`[receipt/detail/id].alertText`)}</b>
                </Alert>
              ) : (
                <></>
              )}
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <AppNotes />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
              <CusNotes />
            </Column>
          </Column>

          <Column className="xs-12 sm-12">
            <Button
              disabled={
                this.context.state.user.permission === 2
                  ? this.context.state.limited_permission.sales === 2
                    ? false
                    : true
                  : false
              }
              className="mt-4"
              icon="delete_forever"
              title={this.context.t(
                `['receipt/detail/id'].deleteReceiptButtonTitle`
              )}
              backgroundColor="red-opacity"
              textColor="red"
              fullWidth
              onClick={() => this.setState({ delete_confirm: true })}
            />
            <ReceiptDeleteDialog />
          </Column>
        </Grid>

        <ServiceSalesDialog />
        <PackageSalesDialog />
        <ProductSalesDialog />
        <DebtInformationDialog />
        <DiscountDialog />
        <AutoAppointmentDialog />
        <TypeServicesDetailDialog />
        <TypePackageDetailDialog />
        <TypeProductDetailDialog />
        <TypePaymentDetailDialog />
      </AuthContainer>
    );
  }

  /**
   * Adisyonun üst alanındaki müşteri bilgileri, değişiklikleri kaydet buttonu gibi componentlerin gösterildiği alan.
   */
  _ReceiptHeadingBuilder = () => {
    const {
      contract_tab,
      new_agreement_detail,
      file,
      checkout_data,
      print_dialog,
    } = this.state;
    const ContractDialogEntrance = this._ContractDialogEntrance;
    const ContractDialogCreateNewContract =
      this._ContractDialogCreateNewContract;
    const ContractDialogUploadContract = this._ContractDialogUploadContract;
    const ContractDialogContracts = this._ContractDialogContracts;

    const printRef = React.useRef();
    return (
      <Grid className="no-gutters-row ml-3 mr-3">
        {print_dialog === true ? (
          <div ref={printRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px 70px",
              }}
            >
              <h2
                style={{
                  margin: "40px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {this.context.state.company_branch_first.name}
              </h2>
              <div
                style={{
                  borderBottom: "2px solid #b2b2b2",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ width: "33%", textAlign: "center" }}>
                  Adisyon No: {this.state.checkout_data.receipt_id}
                </p>
                <p style={{ width: "33%", textAlign: "center" }}>
                  {this.state.customer.full_name}
                </p>
                <p style={{ width: "33%", textAlign: "center" }}>
                  {moment().format("DD-MM-YYYY")}
                </p>
              </div>
              {/* Hizmetler */}
              {(Object.keys(checkout_data.services).filter(
                (key) => checkout_data.services[key] !== undefined
              ).length > 0 ||
                Object.keys(checkout_data.packages).filter(
                  (key) => checkout_data.packages[key] !== undefined
                ).length > 0) && (
                <>
                  <div style={{ width: "100%" }}>
                    <h3 style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                      Hizmetler
                    </h3>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px solid #b2b2b2",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <h4>Personel</h4>
                    <h4>Hizmet</h4>
                    <h4>Ücret</h4>
                  </div>
                </>
              )}
              {Object.keys(checkout_data.services).filter(
                (key) => checkout_data.services[key] !== undefined
              ).length > 0 &&
                Object.keys(checkout_data.services).map(
                  (key, index) =>
                    checkout_data.services[key] !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                        key={index}
                      >
                        {/* Çalışan */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.staffs.filter(
                              (item) =>
                                item.id === checkout_data.services[key].staff_id
                            )[0].full_name
                          }
                        </p>
                        {/* Hizmet */}

                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.services.filter(
                              (item) =>
                                item.id ===
                                checkout_data.services[key].service_id
                            )[0].name
                          }
                        </p>
                        {/* Ücret */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.services[key].amount}
                          {this.context.state.currency
                            ? this.context.state.currency
                            : "₺"}
                        </p>
                      </div>
                    )
                )}
              {/* Paket Hizmetleri */}
              {Object.keys(checkout_data.packages).filter(
                (key) => checkout_data.packages[key] !== undefined
              ).length > 0 &&
                Object.keys(checkout_data.packages).map(
                  (key, index) =>
                    checkout_data.packages[key] !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                        key={index}
                      >
                        {/* Çalışan */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.staffs.filter(
                              (item) =>
                                item.id === checkout_data.packages[key].staff_id
                            )[0].full_name
                          }
                        </p>

                        {/* Hizmet */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.services.filter(
                              (item) =>
                                item.id ===
                                checkout_data.packages[key].service_id
                            )[0].name
                          }
                        </p>

                        {/* Ücret */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.packages[key].amount}
                          {this.context.state.currency
                            ? this.context.state.currency
                            : "₺"}
                        </p>
                      </div>
                    )
                )}
              {/* Ürünler */}
              {Object.keys(checkout_data.sales).filter(
                (key) => checkout_data.sales[key] !== undefined
              ).length > 0 && (
                <>
                  <div style={{ width: "100%" }}>
                    <h3 style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                      Ürünler
                    </h3>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #b2b2b2",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <h4>Ürün</h4>
                    <h4>Adet</h4>
                    <h4>Ücret</h4>
                  </div>
                </>
              )}
              {Object.keys(checkout_data.sales).filter(
                (key) => checkout_data.sales[key] !== undefined
              ).length > 0 &&
                Object.keys(checkout_data.sales).map(
                  (key, index) =>
                    checkout_data.sales[key] !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                        key={index}
                      >
                        {/* Ürün */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.stock_items.filter(
                              (item) =>
                                item.id ===
                                checkout_data.sales[key].stock_item_id
                            )[0].name
                          }
                        </p>

                        {/* Adet */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.sales[key].quantity}
                        </p>

                        {/* Ücret */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.sales[key].amount}
                          {this.context.state.currency
                            ? this.context.state.currency
                            : "₺"}
                        </p>
                      </div>
                    )
                )}
              {/* Adisyon Özeti */}
              <div
                style={{
                  width: "100%",
                  marginTop: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    Adisyon Toplamı:
                  </span>
                  <p>
                    {(
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map((key) => checkout_data.sales[key])
                        .map((item) => item.stock_item_id)
                        .map((item, index) => {
                          return (
                            this.state.stock_items.filter(
                              (stockItems) => stockItems.id === item
                            )[0].amount * checkout_data.sales[index].quantity
                          );
                        })
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0)}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    İndirim:
                  </span>
                  <p>
                    {(
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map((key) => checkout_data.sales[key])
                        .map((item) => item.stock_item_id)
                        .map((item, index) => {
                          return (
                            this.state.stock_items.filter(
                              (stockItems) => stockItems.id === item
                            )[0].amount * checkout_data.sales[index].quantity
                          );
                        })
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0) -
                      (
                        Object.keys(checkout_data.packages)
                          .map((key) => checkout_data.packages[key].amount)
                          .reduce((acc, cur) => acc + cur, 0) +
                        Object.keys(checkout_data.services)
                          .map((key) => checkout_data.services[key].amount)
                          .reduce((acc, cur) => acc + cur, 0) +
                        Object.keys(checkout_data.sales)
                          .map(
                            (key) =>
                              checkout_data.sales[key].amount *
                              checkout_data.sales[key].quantity
                          )
                          .reduce((acc, cur) => acc + cur, 0)
                      ).toFixed(0)}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    Net Toplam:
                  </span>
                  <p>
                    {(
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map(
                          (key) =>
                            checkout_data.sales[key].amount *
                            checkout_data.sales[key].quantity
                        )
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0)}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    Ödenen Tutar:
                  </span>
                  <p>
                    {(
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map(
                          (key) =>
                            checkout_data.sales[key].amount *
                            checkout_data.sales[key].quantity
                        )
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0) -
                      parseFloat(this.getAllTotalService()) -
                      parseFloat(
                        this.getTotalAmounts().installment_total +
                          this.getTotalAmounts().paids
                      ) *
                        -1}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    Kalan Tutar:
                  </span>
                  <p>
                    {parseFloat(this.getAllTotalService()) -
                      parseFloat(
                        this.getTotalAmounts().installment_total +
                          this.getTotalAmounts().paids
                      )}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <Column className="xs-12 sm-12 md-4 lg-6">
          <Column className="xs-12 sm-12 md-4 lg-3 mb-3">
            <ReceiptProfile
              style={{
                cursor: "pointer",
                border: `1px solid ${
                  this.state.sex === 0 ? "#8c0fb4" : "#3189ec"
                }`,
                backgroundColor: `${
                  this.state.sex === 0
                    ? "rgba(140,15,180, 0.1)"
                    : "rgba(49,137,236,0.08)"
                }`,
              }}
              onClick={() =>
                this.props.history.push(
                  `/customers/detail/${this.state.customer.id}`
                )
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Person
                  style={{
                    marginRight: "5px",
                    color: `${this.state.sex === 0 ? "#8c0fb4" : "#3189ec"}`,
                  }}
                />
                <div
                  style={{
                    marginTop: "5px",
                    fontSize: `${
                      this.state.customer.full_name?.length > 15
                        ? "8px"
                        : "12px"
                    }`,
                  }}
                >
                  {this.state.customer.full_name || "Tanımlanmamış Müşteri"}
                </div>
              </div>
              <div>
                <Phone style={{ marginRight: "5px", color: "#b2b2b2" }} />
                <>{this.state.customer.phone || null}</>
              </div>
            </ReceiptProfile>
          </Column>

          {/* <Column className="xs-12 sm-12 md-4 lg-3">
            <Button
              icon="print"
              fullWidth
              size="lg"
              title={"Yazdır"}
              backgroundColor="teal-opacity"
              textColor="teal"
              outlined={true}
            />
          </Column> */}

          <Column className="xs-12 sm-12 md-4 lg-3">
            <div
              onMouseUp={async () => {
                await this.setState({
                  print_dialog: true,
                });
              }}
            >
              <ReactToPrint
                trigger={() => (
                  <Button
                    icon="print"
                    fullWidth
                    size="lg"
                    title={"Yazdır"}
                    backgroundColor="teal-opacity"
                    textColor="teal"
                    outlined={true}
                  />
                )}
                content={() => printRef.current}
                onAfterPrint={async () => {
                  await this.setState({
                    print_dialog: false,
                  });
                }}
              />
            </div>
          </Column>

          <Column className="xs-12 sm-12 md-4 lg-3">
            <Button
              icon="contacts"
              fullWidth
              size="lg"
              outlined={true}
              title={"Sözleşme"}
              backgroundColor="orange-opacity"
              textColor="orange"
              onClick={() => {
                //
                if (this.context.state.company_license.accounting === 3) {
                  if (this.state.is_change) {
                    toast.warning(
                      "Yapmış olduğunuz değişiklikleri lütfen kaydediniz."
                    );
                  } else {
                    this.setState({
                      contract_dialog: true,
                    });
                    this.getAgreements();
                    this.getClauses();
                  }
                } else {
                  toast.warn(
                    "Diamond lisans paketi için olan bu özelliği kullanabilmek için Diamond lisans paketini satın almanız gerekmektedir."
                  );
                }
              }}
            />
          </Column>

          <Column className="xs-12 sm-12 md-4 lg-3">
            <CustomerBoxDiv
              style={{
                border: "1px solid #3189ec",
                backgroundColor: "rgba(49,137,236,0.08)",
              }}
            >
              <img
                src={CustomerPoint}
                alt="customer_point"
                style={{ height: "70%" }}
              />
              <CustomerPointBox>
                <span>
                  {this.state.customer.point !== null &&
                  this.state.customer.point !== undefined
                    ? `${this.state.customer.point}`
                    : "0"}{" "}
                  {this.context.t(`['receipt/detail/id'].point`)}
                  <b>
                    ~
                    {this.state.customer.point !== null &&
                    this.state.customer.point !== undefined
                      ? `${this.state.customer.point / 100}`
                      : "0"}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </b>
                </span>
                {/* <h3>{this.context.t(`['receipt/detail/id'].customerPoint`)}</h3> */}
              </CustomerPointBox>
            </CustomerBoxDiv>
          </Column>
        </Column>

        <Column className="xs-12 sm-12 md-4 lg-6">
          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PaymentSection
              style={{
                backgroundColor: "rgb(255, 144, 22, 0.14)",
                border: "1px solid #ff9016",
              }}
            >
              <img src={ReceiptOrange} alt="receipt" />
              <SubTotalText
                style={{
                  color: "#ff9016",
                }}
              >
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                  }}
                >
                  {this.getAllTotalService().toFixed(0)}
                  {this.context.state.currency
                    ? this.context.state.currency
                    : "₺"}
                </p>
                <span>
                  {this.context.t(`['receipt/detail/id'].payments.totalAmount`)}
                </span>
              </SubTotalText>
            </PaymentSection>
          </Column>

          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PaymentSection
              style={{
                backgroundColor: "rgb(29, 139, 62, 0.21)",
                border: "1px solid rgb(58, 154, 87, 0.35)",
              }}
            >
              <img src={PaymentGreen} alt="payment" />
              <SubTotalText
                style={{
                  color: "#1D8B3E",
                }}
              >
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                  }}
                >
                  {parseFloat(
                    this.getTotalAmounts().installment_total +
                      this.getTotalAmounts().paids
                  )}
                  {this.context.state.currency
                    ? this.context.state.currency
                    : "₺"}
                </p>
                <span>
                  {this.context.t(
                    `['receipt/detail/id'].payments.totalPaidAmount`
                  )}
                </span>
              </SubTotalText>
            </PaymentSection>
          </Column>

          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PaymentSection
              style={{
                backgroundColor: "rgb(254, 151, 151, 0.19)",
                border: "1px solid rgb(185, 178, 178, 0.65)",
              }}
            >
              <img src={Debt} alt="debt" />
              <SubTotalText
                style={{
                  color: `${
                    parseFloat(this.getAllTotalService()).toFixed(0) -
                      parseFloat(
                        this.getTotalAmounts().installment_total +
                          this.getTotalAmounts().paids
                      ).toFixed(0) >
                    0
                      ? "#FC8E8E"
                      : "grey"
                  }`,
                }}
              >
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                  }}
                >
                  {parseFloat(this.getAllTotalService()).toFixed(0) -
                    parseFloat(
                      this.getTotalAmounts().installment_total +
                        this.getTotalAmounts().paids
                    ).toFixed(0)}
                  {this.context.state.currency
                    ? this.context.state.currency
                    : "₺"}
                </p>
                <span>
                  {this.context.t(
                    `['receipt/detail/id'].payments.totalDebtAmount`
                  )}
                </span>
              </SubTotalText>
            </PaymentSection>
          </Column>
          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PaymentSection
              style={{
                backgroundColor: "rgba(49,137,236,0.08)",
                border: "1px solid rgb(185, 178, 178, 0.65)",
              }}
            >
              <Button
                icon="storage"
                size="lg"
                title={"Adisyon işlem kayıtlarına git"}
                onClick={() => {
                  console.log(this.state.checkout_data.receipt_id);
                  this.context.dispatch({
                    type: "LOG_TYPE",
                    payload: { log_type: 2 },
                  });
                  this.context.dispatch({
                    type: "LOG_ID",
                    payload: {
                      log_id: Number(this.state.checkout_data.receipt_id),
                    },
                  });
                  this.props.history.push("/settings/logs");
                }}
              />
            </PaymentSection>
          </Column>
        </Column>

        {/* Sözleşme için açılan pop-up */}
        <AlertDialog
          title={""}
          open={this.state.contract_dialog}
          maxWidth={contract_tab === 3 || file ? "md" : "sm"}
          fullWidth
          disableBackdropClick={false}
          closeHandler={() => {
            this.setState({
              contract_dialog: false,
              contract_info_id_number: "",
              contract_info_tax_number: "",
              contract_info_address: "",
            });
          }}
        >
          {contract_tab !== 0 && (
            <ArrowBackIosIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  contract_tab: 0,
                  selected_pattern: {
                    Clauses: [],
                    Template: [],
                  },
                  new_agreement_detail: false,
                  file: null,
                });
              }}
            />
          )}
          {contract_tab === 0 && <ContractDialogEntrance />}
          {contract_tab === 1 && <ContractDialogCreateNewContract />}
          {contract_tab === 2 && <ContractDialogUploadContract />}
          {contract_tab === 3 && <ContractDialogContracts />}

          {new_agreement_detail ? (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div
                style={{
                  width: "35%",
                }}
              >
                <Button
                  fullWidth
                  title="Önizleme"
                  textColor="white"
                  backgroundColor="orange"
                  onClick={() => {
                    if (
                      this.state.selected_pattern.Template.nationality_id &&
                      this.state.contract_info_id_number !== "" &&
                      !this.validateTCKN(this.state.contract_info_id_number)
                    ) {
                      toast.error("Geçersiz T.C. Kimlik Numarası");
                    } else {
                      this.setState({
                        selected_agreement_dialog_show: true,
                      });
                      this.getServices();
                      this.getStaff();
                    }
                  }}
                />
              </div>
              <div
                style={{
                  width: "35%",
                }}
              >
                <Button
                  fullWidth
                  outlined
                  icon="clear"
                  title={contract_tab === 3 ? "Kapat" : "İptal"}
                  textColor="#ccc"
                  onClick={() => {
                    this.setState({
                      contract_dialog: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        contract_tab: 0,
                        selected_pattern: {
                          Clauses: [],
                          Template: [],
                        },
                        new_agreement_detail: false,
                        // contract_info_id_number: "",
                        contract_info_tax_number: "",
                        contract_info_address: "",
                        file: null,
                        agreement_title: "",
                      });
                    }, 500);
                  }}
                />
              </div>{" "}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "35%" }}>
                <Button
                  title={contract_tab === 3 ? "Kapat" : "İptal"}
                  fullWidth
                  outlined
                  icon="clear"
                  textColor="#ccc"
                  onClick={() => {
                    this.setState({
                      contract_dialog: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        contract_tab: 0,
                        selected_pattern: {
                          Clauses: [],
                          Template: [],
                        },
                        new_agreement_detail: false,
                        // contract_info_id_number: "",
                        contract_info_tax_number: "",
                        contract_info_address: "",
                      });
                    }, 500);
                  }}
                />
              </div>
            </div>
          )}
        </AlertDialog>
      </Grid>
    );
  };

  /**
   * Hizmet satışının yapıldığı, verilerin düzenlendiği alan.
   */
  _ServiceSalesBuilder = () => {
    const { checkout_data } = this.state;

    let total_mount = 0;
    const rounded_amount = Math.round(this.getTotalAmounts().services);

    let amountData = Object.keys(checkout_data.services).map((key) =>
      checkout_data.services[key] !== undefined
        ? checkout_data.services[key].amount !== NaN
          ? checkout_data.services[key].amount
          : 0
        : 0
    );

    for (let i = 0; i < amountData.length - 1; i++) {
      total_mount += Math.round(amountData[i]);
    }

    for (let j = 0; j < amountData.length; j++) {
      amountData[j] = Math.round(amountData[j]);
      if (amountData[amountData.length - 1]) {
        amountData[amountData.length - 1] = rounded_amount - total_mount;
      }
    }

    React.useEffect(() => {
      Object.keys(checkout_data.services).map((key) => {
        checkout_data.services[key] !== undefined
          ? checkout_data.services[key].amount !== NaN
            ? (checkout_data.services[key].amount = Math.round(amountData[key]))
            : 0
          : 0;
      }),
        flushSync();
    }, [checkout_data]);

    const inputRef = React.useRef();

    return (
      <SectionBox style={{ overflowX: "hidden" }}>
        <SectionHeading
          style={{
            backgroundColor: "rgba(13, 78, 108, 0.04)",
            color: "#3189ec",
            border: "1px solid #3189ec",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="detail-search"
            style={{
              backgroundColor: "#3189ec",
            }}
            onClick={() => {
              this.setState({ services_detail_dialog_modal: true });
              console.log(123);
            }}
          >
            <Search style={{ color: "white", fontSize: "20px" }} />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Layers />
            <h3 style={{ marginLeft: "15px" }}>
              {this.context.t(
                `['receipt/detail/id'].serviceTransactions.title`
              )}
            </h3>
          </div>
          <div>
            <AddBox
              style={{ cursor: "pointer", fontSize: "36px" }}
              onClick={async () =>
                await this.setState({
                  service_transaction: {
                    ...this.state._service_transaction,
                    process_date: moment().format("YYYY-MM-DD"),
                  },
                  selected_staff_services: [
                    ...this.state.staffs
                      .find(
                        (staff) =>
                          staff.id === this.state._service_transaction.staff_id
                      )
                      .services.map((service) => service.service),
                  ],
                  service_transaction_modal: true,
                })
              }
            />
          </div>
        </SectionHeading>
        <ListSection className="no-scroll-bar">
          {
            //Hizmet Listesi
            Object.keys(checkout_data.services).filter(
              (key) => checkout_data.services[key] !== undefined
            ).length > 0 ? (
              Object.keys(checkout_data.services).map(
                (key, index) =>
                  checkout_data.services[key] !== undefined &&
                  checkout_data.services[key].process_type !== 1 && (
                    <Grid className="no-gutters-row" key={index}>
                      <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                        <MuiThemeProvider theme={themeForDatePicker}>
                          <DatePickerInput
                            className="mb-1"
                            label={this.context.t(
                              `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                            )}
                            error={false}
                            value={
                              moment(
                                checkout_data.services[key].process_date
                              ).format("YYYY-MM-DD") === "0001-01-01"
                                ? null
                                : moment(
                                    checkout_data.services[key].process_date
                                  )
                            }
                            onChange={(process_date) => {
                              this.setState({
                                checkout_data: {
                                  ...checkout_data,
                                  services: {
                                    ...checkout_data.services,
                                    [key]: {
                                      ...checkout_data.services[key],
                                      process_date:
                                        moment(process_date).format(
                                          "YYYY-MM-DD"
                                        ),
                                    },
                                  },
                                },
                                is_change: true,
                              });
                            }}
                          />
                        </MuiThemeProvider>
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-3">
                        <Select
                          className="mb-1"
                          label={this.context.t(
                            `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                          )}
                          items={this.state.staffs
                            .filter(
                              (staff) =>
                                staff.id ===
                                checkout_data.services[key].staff_id
                            )[0]
                            .services.map((service) => service.service)}
                          selected={
                            checkout_data.services[key].service_id
                              ? this.state.services.filter(
                                  (item) =>
                                    item.id ===
                                    checkout_data.services[key].service_id
                                ).length
                                ? checkout_data.services[key].service_id
                                : "Silinmiş Hizmet"
                              : "Silinmiş Hizmet"
                          }
                          labelKey="name"
                          valueKey="id"
                          title={
                            this.state.services.filter(
                              (item) =>
                                item.id ===
                                checkout_data.services[key].service_id
                            ).length > 0
                              ? this.state.services.filter(
                                  (item) =>
                                    item.id ===
                                    checkout_data.services[key].service_id
                                )[0].name
                              : "Silinmiş Hizmet"
                          }
                          handler={(service_id) =>
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                services: {
                                  ...checkout_data.services,
                                  [key]: {
                                    ...checkout_data.services[key],
                                    service_id: parseInt(service_id),
                                    process_type: 2,
                                  },
                                },
                              },
                              is_change: true,
                            })
                          }
                        />
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-2">
                        <StaffSelect
                          className="mb-1"
                          disabled={this.context.state.user.permission === 0}
                          label={this.context.t(
                            `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                          )}
                          items={this.state.staffs}
                          selected={
                            checkout_data.services[key].staff_id
                              ? checkout_data.services[key].staff_id
                              : ""
                          }
                          labelKey="full_name"
                          valueKey="id"
                          handler={(staff_id) =>
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                services: {
                                  ...checkout_data.services,
                                  [key]: {
                                    ...checkout_data.services[key],
                                    staff_id: parseInt(staff_id),
                                    process_type: 2,
                                  },
                                },
                              },
                              is_change: true,
                            })
                          }
                        />
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-2">
                        <Select
                          className="mb-1"
                          disabled={this.context.state.user.permission === 0}
                          label={"Durumu"}
                          items={this.state.customer_s}
                          selected={
                            checkout_data.services[key].customer_state !== null
                              ? `${checkout_data.services[key].customer_state}`
                              : ""
                          }
                          labelKey="label"
                          valueKey="value"
                          handler={(customer_state) => {
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                services: {
                                  ...checkout_data.services,
                                  [key]: {
                                    ...checkout_data.services[key],
                                    customer_state: parseInt(customer_state),
                                    process_type: 2,
                                  },
                                },
                              },
                              is_change: true,
                            });
                          }}
                        />
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-2">
                        <MaskedInput
                          className="mb-1"
                          label={
                            this.context.t(
                              `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                            ) +
                            (this.context.state.currency
                              ? " (" + this.context.state.currency + ")"
                              : "(₺)")
                          }
                          mask="9999999999999"
                          maskChar=""
                          value={
                            checkout_data.services[key].amount !== null
                              ? checkout_data.services[key].amount
                              : ""
                          }
                          onChange={(e) =>
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                services: {
                                  ...checkout_data.services,
                                  [key]: {
                                    ...checkout_data.services[key],
                                    amount: /[0-9,.]+/.test(e.target.value)
                                      ? Math.round(parseFloat(e.target.value))
                                      : null,
                                    process_type: 2,
                                  },
                                },
                              },
                              is_change: true,
                            })
                          }
                        />
                      </Column>
                    </Grid>
                  )
              )
            ) : (
              <NoDataContainer style={{ height: "80%" }}>
                <img
                  src={require("../../../assets/images/no-data-img.png")}
                  alt="Malesef bulunamadı!"
                />
                <p>
                  {this.context.t(
                    `['receipt/detail/id'].serviceTransactions.noDataText`
                  )}
                </p>
              </NoDataContainer>
            )
          }
        </ListSection>
        {Object.keys(checkout_data.services).filter(
          (key) => checkout_data.services[key] !== undefined
        ).length > 0 && (
          <SubTotalText className="mt-2">
            <CustomerBoxDiv>
              <Button
                style={{
                  border: "1px solid rgba(255, 127, 80, 0.53)",
                }}
                disabled={
                  Object.keys(checkout_data.services).filter(
                    (key) => checkout_data.services[key] !== undefined
                  ).length === 0
                }
                className="mr-2"
                icon="emoji_symbols"
                title={this.context.t(
                  `['receipt/detail/id'].applyDiscountButtonTitle`
                )}
                backgroundColor="orange-opacity"
                textColor="orange"
                onClick={() => {
                  if (
                    Object.keys(checkout_data.paids).filter(
                      (key) => checkout_data.paids[key] !== undefined
                    ).length === 0 &&
                    Object.keys(checkout_data.installment).filter(
                      (key) => checkout_data.installment[key] !== undefined
                    ).length === 0
                  ) {
                    this.setState(
                      {
                        discount_target: "services",
                      },
                      () =>
                        this.setState({
                          discount_value: null,
                          discount_type: "TL",
                          discount_modal: true,
                        })
                    );
                  } else {
                    toast.warning(
                      this.context.t(
                        `['receipt/detail/id'].discountBeforePaymentToast`
                      )
                    );
                  }
                }}
              />
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RemainingDebtRed} alt="remaining_debt_red" />
                <CustomerPointBox>
                  {this.getTotalAmounts().services !=
                  this.getTotalAmounts()._fservices ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{ textDecoration: "line-through" }}
                        className="fc-grey mr-1"
                      >
                        {this.getTotalAmounts()._fservices.toFixed(0)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                      <p>
                        {this.getTotalAmounts().services.toFixed(0)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                    </span>
                  ) : (
                    <span>
                      <p>
                        {Math.round(this.getTotalAmounts().services)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                    </span>
                  )}
                  <h3>
                    {this.context.t(
                      `['receipt/detail/id'].serviceTransactions.totalAmount`
                    )}
                  </h3>
                </CustomerPointBox>
              </div>
            </CustomerBoxDiv>
          </SubTotalText>
        )}
        {this.state.print_dialog === true ? (
          <div ref={inputRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px 70px",
              }}
            >
              <h2
                style={{
                  margin: "40px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {this.context.state.company_branch_first.name}
              </h2>
              <div
                style={{
                  borderBottom: "2px solid #b2b2b2",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ width: "33%", textAlign: "center" }}>
                  Adisyon No: {this.state.checkout_data.receipt_id}
                </p>
                <p style={{ width: "33%", textAlign: "center" }}>
                  {this.state.customer.full_name}
                </p>
                <p style={{ width: "33%", textAlign: "center" }}>
                  {moment().format("DD-MM-YYYY")}
                </p>
              </div>
              {/* Hizmetler */}
              {(Object.keys(checkout_data.services).filter(
                (key) => checkout_data.services[key] !== undefined
              ).length > 0 ||
                Object.keys(checkout_data.packages).filter(
                  (key) => checkout_data.packages[key] !== undefined
                ).length > 0) && (
                <>
                  <div style={{ width: "100%" }}>
                    <h3 style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                      Hizmetler
                    </h3>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px solid #b2b2b2",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <h4>Personel</h4>
                    <h4>Hizmet</h4>
                    <h4>Ücret</h4>
                  </div>
                </>
              )}
              {Object.keys(checkout_data.services).filter(
                (key) => checkout_data.services[key] !== undefined
              ).length > 0 &&
                Object.keys(checkout_data.services).map(
                  (key, index) =>
                    checkout_data.services[key] !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                        key={index}
                      >
                        {/* Çalışan */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.staffs.filter(
                              (item) =>
                                item.id === checkout_data.services[key].staff_id
                            )[0].full_name
                          }
                        </p>
                        {/* Hizmet */}

                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.services.filter(
                              (item) =>
                                item.id ===
                                checkout_data.services[key].service_id
                            )[0].name
                          }
                        </p>
                        {/* Ücret */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.services[key].amount}
                          {this.context.state.currency
                            ? this.context.state.currency
                            : "₺"}
                        </p>
                      </div>
                    )
                )}
              {/* Paket Hizmetleri */}
              {Object.keys(checkout_data.packages).filter(
                (key) => checkout_data.packages[key] !== undefined
              ).length > 0 &&
                Object.keys(checkout_data.packages).map(
                  (key, index) =>
                    checkout_data.packages[key] !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                        key={index}
                      >
                        {/* Çalışan */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.staffs.filter(
                              (item) =>
                                item.id === checkout_data.packages[key].staff_id
                            )[0].full_name
                          }
                        </p>

                        {/* Hizmet */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.services.filter(
                              (item) =>
                                item.id ===
                                checkout_data.packages[key].service_id
                            )[0].name
                          }
                        </p>

                        {/* Ücret */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.packages[key].amount}
                          {this.context.state.currency
                            ? this.context.state.currency
                            : "₺"}
                        </p>
                      </div>
                    )
                )}
              {/* Ürünler */}
              {Object.keys(checkout_data.sales).filter(
                (key) => checkout_data.sales[key] !== undefined
              ).length > 0 && (
                <>
                  <div style={{ width: "100%" }}>
                    <h3 style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                      Ürünler
                    </h3>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #b2b2b2",
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <h4>Ürün</h4>
                    <h4>Adet</h4>
                    <h4>Ücret</h4>
                  </div>
                </>
              )}
              {Object.keys(checkout_data.sales).filter(
                (key) => checkout_data.sales[key] !== undefined
              ).length > 0 &&
                Object.keys(checkout_data.sales).map(
                  (key, index) =>
                    checkout_data.sales[key] !== undefined && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                        key={index}
                      >
                        {/* Ürün */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.stock_items.filter(
                              (item) =>
                                item.id ===
                                checkout_data.sales[key].stock_item_id
                            )[0].name
                          }
                        </p>

                        {/* Adet */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.sales[key].quantity}
                        </p>

                        {/* Ücret */}
                        <p
                          style={{
                            width: "33%",
                            textAlign: "center",
                          }}
                        >
                          {this.state.checkout_data.sales[key].amount}
                          {this.context.state.currency
                            ? this.context.state.currency
                            : "₺"}
                        </p>
                      </div>
                    )
                )}
              {/* Adisyon Özeti */}
              <div
                style={{
                  width: "100%",
                  marginTop: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    Adisyon Toplamı:
                  </span>
                  <p>
                    {(
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map((key) => checkout_data.sales[key])
                        .map((item) => item.stock_item_id)
                        .map((item, index) => {
                          return (
                            this.state.stock_items.filter(
                              (stockItems) => stockItems.id === item
                            )[0].amount * checkout_data.sales[index].quantity
                          );
                        })
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0)}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    İndirim:
                  </span>
                  <p>
                    {(
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key])
                        .map((item) => item.service_id)
                        .map((item) => {
                          return this.state.services.filter(
                            (service) => service.id === item
                          )[0].amount;
                        })
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map((key) => checkout_data.sales[key])
                        .map((item) => item.stock_item_id)
                        .map((item, index) => {
                          return (
                            this.state.stock_items.filter(
                              (stockItems) => stockItems.id === item
                            )[0].amount * checkout_data.sales[index].quantity
                          );
                        })
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0) -
                      (
                        Object.keys(checkout_data.packages)
                          .map((key) => checkout_data.packages[key].amount)
                          .reduce((acc, cur) => acc + cur, 0) +
                        Object.keys(checkout_data.services)
                          .map((key) => checkout_data.services[key].amount)
                          .reduce((acc, cur) => acc + cur, 0) +
                        Object.keys(checkout_data.sales)
                          .map(
                            (key) =>
                              checkout_data.sales[key].amount *
                              checkout_data.sales[key].quantity
                          )
                          .reduce((acc, cur) => acc + cur, 0)
                      ).toFixed(0)}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    Net Toplam:
                  </span>
                  <p>
                    {(
                      Object.keys(checkout_data.packages)
                        .map((key) => checkout_data.packages[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.services)
                        .map((key) => checkout_data.services[key].amount)
                        .reduce((acc, cur) => acc + cur, 0) +
                      Object.keys(checkout_data.sales)
                        .map(
                          (key) =>
                            checkout_data.sales[key].amount *
                            checkout_data.sales[key].quantity
                        )
                        .reduce((acc, cur) => acc + cur, 0)
                    ).toFixed(0)}
                    {this.context.state.currency
                      ? this.context.state.currency
                      : "₺"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </SectionBox>
    );
  };

  /**
   * Paket satışının yapıldığı, verilerin düzenlendiği alan.
   */
  _PackageSalesBuilder = () => {
    const { checkout_data, packageChanges } = this.state;
    let total_mount = 0;
    const rounded_amount = this.getTotalAmounts().packages;

    let amountData = Object.keys(checkout_data.packages).map((key) =>
      checkout_data.packages[key] !== undefined
        ? checkout_data.packages[key].amount !== NaN
          ? checkout_data.packages[key].amount
          : 0
        : 0
    );

    for (let i = 0; i < amountData.length - 1; i++) {
      total_mount += amountData[i];
    }

    for (let j = 0; j < amountData.length; j++) {
      amountData[j] = amountData[j];
      if (amountData[amountData.length - 1]) {
        amountData[amountData.length - 1] = rounded_amount - total_mount;
      }
    }

    // React.useEffect(() => {
    //   Object.keys(checkout_data.packages).map((key) => {
    //     checkout_data.packages[key] !== undefined
    //       ? checkout_data.packages[key].amount !== NaN
    //         ? (checkout_data.packages[key].amount = Math.round(amountData[key]))
    //         : 0
    //       : 0;
    //   }),
    //     flushSync();
    // }, [checkout_data]);
    const inputRef = React.useRef();
    return (
      <SectionBox style={{ overflowX: "hidden" }}>
        <SectionHeading
          style={{
            backgroundColor: "rgba(140,15,180, 0.1)",
            color: "#8c0fb4",
            border: "1px solid #8c0fb4",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div className="detail-search" style={{ backgroundColor: "#8c0fb4" }}>
            <Search
              style={{ color: "white", fontSize: "20px" }}
              onClick={() =>
                this.setState({
                  package_detail_dialog_modal: true,
                })
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ShoppingBasket />
            <h3 style={{ marginLeft: "15px" }}>
              {this.context.t(
                `['receipt/detail/id'].serviceTransactions.packageTitle`
              )}
            </h3>
          </div>
          <div>
            <AddBox
              style={{ cursor: "pointer", fontSize: "36px" }}
              onClick={() =>
                this.setState({
                  package_sale_modal: true,
                })
              }
            />
          </div>
        </SectionHeading>

        <ListSection className="no-scroll-bar">
          {checkout_data.groups
            .filter((el) => el !== undefined)
            .map((data, indexGroup) => (
              <ReportsContainer
                key={indexGroup}
                style={{
                  padding: "1.5em 0 0 0",
                  transition: "all 0.3s ease",
                  height: "auto",
                }}
              >
                <Column
                  className="sx-12 sm-12 md-12 lg-12 group_header"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <MuiThemeProvider theme={themeForDatePicker}>
                      <DatePickerInput
                        className="mb-1"
                        label={this.context.t(
                          `['receipt/detail/id'].serviceTransactions.packageDateInputLabel`
                        )}
                        error={false}
                        value={data.process_date}
                        onChange={(date) => {
                          this.setState({
                            selected_package_update_date:
                              moment(date).format("YYYY-MM-DD"),
                            selected_package_transition_id: data.id,
                            services_update_date_modal: true,
                            package_update_date: moment(date).diff(
                              data.process_date,
                              "days"
                            ),
                          });
                        }}
                      />
                    </MuiThemeProvider>
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <ReportsHeader
                      onClick={() => {
                        if (
                          this.state.packet_detail_array1.includes(indexGroup)
                        ) {
                          this.setState({
                            packet_detail_array1:
                              this.state.packet_detail_array1.filter(
                                (index) => index !== indexGroup
                              ),
                          });
                        } else {
                          this.setState({
                            packet_detail_array1: [
                              ...this.state.packet_detail_array1,
                              indexGroup,
                            ],
                          });
                        }
                      }}
                    >
                      <p
                        style={{ color: data.is_passive ? "red" : "black" }}
                      >{`${data.package_name}`}</p>
                      <p style={{ color: data.is_passive ? "red" : "black" }}>
                        &nbsp;
                        {`(${
                          Object.keys(checkout_data.packages)
                            .filter(
                              (key) => checkout_data.packages[key] !== undefined
                            )
                            .filter(
                              (key) =>
                                checkout_data.packages[key]
                                  .receipt_package_id === data.id
                            ).length
                        })`}
                      </p>
                    </ReportsHeader>
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <StaffSelect
                      className="mb-1"
                      disabled={this.context.state.user.permission === 0}
                      label={this.context.t(
                        `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                      )}
                      items={this.state.staffs}
                      selected={data.staff_id ? data.staff_id : ""}
                      labelKey="full_name"
                      valueKey="id"
                      handler={(staff_id) => {
                        this.setState({
                          checkout_data: {
                            ...checkout_data,
                            groups: [
                              ...this.state.checkout_data.groups.map((item) =>
                                item.id === data.id
                                  ? { ...item, staff_id: parseInt(staff_id) }
                                  : { ...item }
                              ),
                            ],
                            groupsAmount: Object.assign(
                              {},
                              Object.values(
                                this.state.checkout_data.groupsAmount
                              ).map((item, index) =>
                                item.id === data.id
                                  ? {
                                      ...item,
                                      staff_id: parseInt(staff_id),
                                    }
                                  : { ...item }
                              )
                            ),
                            packages: Object.assign({}, [
                              ...Object.values(
                                this.state.checkout_data.packages
                              ).filter(
                                (item) => item.receipt_package_id !== data.id
                              ),
                              ...Object.values(
                                this.state.checkout_data.packages
                              )
                                .filter(
                                  (item) => item.receipt_package_id === data.id
                                )
                                .map((item, key) => ({
                                  ...item,
                                  staff_id: parseInt(staff_id),
                                })),
                            ]),
                          },
                          is_change: true,
                          packageChanges: true,
                        });
                      }}
                    />
                  </Column>

                  <Column
                    className="xs-12 sm-12 md-12 lg-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <InfoCard
                      style={{
                        backgroundColor: "rgba(249,186,132, 0.21)",
                        border: "1px solid rgba(249,186,132, 0.51)",
                        color: "rgb(219,146,92)",
                      }}
                      onClick={async () => {
                        await this.setState({
                          selected_package_transition_id: data.id,
                          selected_package_transition_update_state: 2,
                          services_update_modal: true,
                        });
                      }}
                    >
                      <InfoCardText>
                        <span>
                          {
                            Object.keys(checkout_data.packages)
                              .filter(
                                (key) =>
                                  checkout_data.packages[key] !== undefined
                              )
                              .filter(
                                (key) =>
                                  checkout_data.packages[key]
                                    .receipt_package_id === data.id &&
                                  checkout_data.packages[key].customer_state ===
                                    2
                              ).length
                          }
                        </span>
                        <img src={Waiting} alt="waiting" />
                      </InfoCardText>
                    </InfoCard>

                    <InfoCard
                      style={{
                        backgroundColor: "rgb(29, 139, 62, 0.21)",
                        border: "1px solid rgb(58, 154, 87, 0.35)",
                        color: "rgb(29, 139, 62)",
                      }}
                      onClick={async () => {
                        await this.setState({
                          selected_package_transition_id: data.id,
                          selected_package_transition_update_state: 0,
                          services_update_modal: true,
                        });
                      }}
                    >
                      <InfoCardText>
                        <span>
                          {
                            Object.keys(checkout_data.packages)
                              .filter(
                                (key) =>
                                  checkout_data.packages[key] !== undefined
                              )
                              .filter(
                                (key) =>
                                  checkout_data.packages[key]
                                    .receipt_package_id === data.id &&
                                  checkout_data.packages[key].customer_state ===
                                    0
                              ).length
                          }
                        </span>
                        <img src={HomeChcek} alt="home-check" />
                      </InfoCardText>
                    </InfoCard>

                    <InfoCard
                      style={{
                        backgroundColor: "rgb(254, 151, 151, 0.19)",
                        border: "1px solid rgb(185, 178, 178, 0.65)",
                        color: "rgb(254, 111, 111)",
                      }}
                      onClick={async () => {
                        await this.setState({
                          selected_package_transition_id: data.id,
                          selected_package_transition_update_state: 1,
                          services_update_modal: true,
                        });
                      }}
                    >
                      <InfoCardText>
                        <span>
                          {
                            Object.keys(checkout_data.packages)
                              .filter(
                                (key) =>
                                  checkout_data.packages[key] !== undefined
                              )
                              .filter(
                                (key) =>
                                  checkout_data.packages[key]
                                    .receipt_package_id === data.id &&
                                  checkout_data.packages[key].customer_state ===
                                    1
                              ).length
                          }
                        </span>
                        <img src={NotCome} alt="note-come" />
                      </InfoCardText>
                    </InfoCard>
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <MaskedInput
                      className="mb-1"
                      label={
                        this.context.t(
                          `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                        ) +
                        (this.context.state.currency
                          ? " (" + this.context.state.currency + ")"
                          : "(₺)")
                      }
                      // this.context.state.currency ? this.context.state.currency : "₺"
                      mask="9999999999999"
                      maskChar=""
                      value={Math.round(
                        checkout_data.groupsAmount[indexGroup].amount !== null
                          ? checkout_data.groupsAmount[indexGroup].amount
                          : ""
                      )}
                      onChange={(e) => {
                        let dividedBy = Object.values(
                          this.state.checkout_data.packages
                        ).filter(
                          (el) => el.receipt_package_id === data.id
                        ).length;

                        this.setState({
                          checkout_data: {
                            ...checkout_data,
                            groupsAmount: {
                              ...checkout_data.groupsAmount,
                              [indexGroup]: {
                                ...checkout_data.groupsAmount[indexGroup],
                                amount: /[0-9,.]+/.test(e.target.value)
                                  ? Math.round(parseFloat(e.target.value))
                                  : null,
                              },
                            },
                            groups: checkout_data.groups.map((item) =>
                              item.id !== checkout_data.groups[indexGroup].id
                                ? item
                                : {
                                    ...item,
                                    amount: /[0-9,.]+/.test(e.target.value)
                                      ? Math.round(parseFloat(e.target.value))
                                      : null,
                                  }
                            ),
                            packages: {
                              ...Object.assign({}, [
                                ...Object.values(
                                  this.state.checkout_data.packages
                                )
                                  .filter(
                                    (el) => el.receipt_package_id === data.id
                                  )
                                  .map((item) => ({
                                    ...item,
                                    amount: e.target.value / dividedBy,
                                  })),
                                ...Object.values(
                                  this.state.checkout_data.packages
                                ).filter(
                                  (el) => el.receipt_package_id !== data.id
                                ),
                              ]),
                            },
                          },
                          is_change: true,
                        });
                      }}
                    />
                  </Column>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (
                        this.state.packet_detail_array1.includes(indexGroup)
                      ) {
                        this.setState({
                          packet_detail_array1:
                            this.state.packet_detail_array1.filter(
                              (index) => index !== indexGroup
                            ),
                        });
                      } else {
                        this.setState({
                          packet_detail_array1: [
                            ...this.state.packet_detail_array1,
                            indexGroup,
                          ],
                        });
                      }
                    }}
                  >
                    {this.state.packet_detail_array1.includes(indexGroup) ? (
                      <ExpandLessIcon color="secondary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )}
                  </div>
                </Column>

                {this.state.packet_detail_array1.includes(indexGroup) && (
                  <Column className="xs-12 sm-12 md-12 lg-12 mx-2">
                    {
                      //Paket Listesi
                      Object.keys(checkout_data.packages).filter(
                        (key) => checkout_data.packages[key] !== undefined
                      ).length > 0 ? (
                        Object.keys(checkout_data.packages)
                          .filter(
                            (key) =>
                              checkout_data.packages[key]
                                .transaction_package_id ===
                                data.transaction_package_id &&
                              checkout_data.packages[key].receipt_package_id ===
                                data.id
                          )
                          .map(
                            (key, indexSession) =>
                              checkout_data.packages[key].process_type ===
                                1 && (
                                <Grid
                                  className="no-gutters-row"
                                  key={indexSession}
                                >
                                  {checkout_data.packages[key].process_date &&
                                    this.state.is_package && (
                                      <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                                        <MuiThemeProvider
                                          theme={themeForDatePicker}
                                        >
                                          <DatePickerInput
                                            className="mb-1"
                                            label={`${this.context.t(
                                              `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                            )} / ${indexSession + 1} Seans`}
                                            error={false}
                                            value={
                                              moment(
                                                checkout_data.packages[key]
                                                  .process_date
                                              ).format("YYYY-MM-DD") ===
                                              "0001-01-01"
                                                ? null
                                                : moment(
                                                    checkout_data.packages[key]
                                                      .process_date
                                                  )
                                            }
                                            // disablePast
                                            onChange={(process_date) => {
                                              this.setState({
                                                checkout_data: {
                                                  ...checkout_data,
                                                  packages: {
                                                    ...checkout_data.packages,
                                                    [key]: {
                                                      ...checkout_data.packages[
                                                        key
                                                      ],
                                                      process_date:
                                                        moment(
                                                          process_date
                                                        ).format("YYYY-MM-DD"),
                                                    },
                                                  },
                                                },
                                                is_change: true,
                                                packageChanges: true,
                                              });
                                            }}
                                          />
                                        </MuiThemeProvider>
                                      </Column>
                                    )}
                                  <Column className="xs-12 sm-12 md-12 lg-4">
                                    <Select
                                      className="mb-1"
                                      label={this.context.t(
                                        `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                                      )}
                                      title={
                                        this.state.services.filter(
                                          (item) =>
                                            item.id ===
                                            checkout_data.packages[key]
                                              .service_id
                                        ).length > 0
                                          ? this.state.services.filter(
                                              (item) =>
                                                item.id ===
                                                checkout_data.packages[key]
                                                  .service_id
                                            )[0].name
                                          : "Silinmiş Hizmet"
                                      }
                                      items={this.state.staffs
                                        .filter(
                                          (staff) =>
                                            staff.id ===
                                            checkout_data.packages[key].staff_id
                                        )[0]
                                        .services.map(
                                          (service) => service?.service
                                        )}
                                      selected={
                                        checkout_data.packages[key].service_id
                                          ? this.state.services.filter(
                                              (item) =>
                                                item.id ===
                                                checkout_data.packages[key]
                                                  .service_id
                                            ).length
                                            ? checkout_data.packages[key]
                                                .service_id
                                            : "Silinmiş Hizmet"
                                          : "Silinmiş Hizmet"
                                      }
                                      labelKey="name"
                                      valueKey="id"
                                      handler={(service_id) =>
                                        this.setState({
                                          checkout_data: {
                                            ...checkout_data,
                                            packages: {
                                              ...checkout_data.packages,
                                              [key]: {
                                                ...checkout_data.packages[key],
                                                service_id:
                                                  parseInt(service_id),
                                              },
                                            },
                                          },
                                          is_change: true,
                                          packageChanges: true,
                                        })
                                      }
                                    />
                                  </Column>

                                  <Column className="xs-12 sm-12 md-12 lg-3">
                                    <StaffSelect
                                      className="mb-1"
                                      disabled={
                                        this.context.state.user.permission === 0
                                      }
                                      label={this.context.t(
                                        `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                                      )}
                                      items={this.state.staffs}
                                      selected={
                                        checkout_data.packages[key].staff_id
                                          ? checkout_data.packages[key].staff_id
                                          : ""
                                      }
                                      labelKey="full_name"
                                      valueKey="id"
                                      handler={(staff_id) =>
                                        this.setState({
                                          checkout_data: {
                                            ...checkout_data,
                                            packages: {
                                              ...checkout_data.packages,
                                              [key]: {
                                                ...checkout_data.packages[key],
                                                staff_id: parseInt(staff_id),
                                              },
                                            },
                                          },
                                          is_change: true,
                                          packageChanges: true,
                                        })
                                      }
                                    />
                                  </Column>

                                  <Column className="xs-12 sm-12 md-12 lg-2">
                                    <Select
                                      className="mb-1"
                                      label={"Durumu"}
                                      items={this.state.customer_s}
                                      selected={
                                        checkout_data.packages[key]
                                          .customer_state !== null
                                          ? `${checkout_data.packages[key].customer_state}`
                                          : ""
                                      }
                                      labelKey="label"
                                      valueKey="value"
                                      handler={(customer_state) => {
                                        this.setState({
                                          checkout_data: {
                                            ...checkout_data,
                                            packages: {
                                              ...checkout_data.packages,
                                              [key]: {
                                                ...checkout_data.packages[key],
                                                customer_state:
                                                  parseInt(customer_state),
                                              },
                                            },
                                          },
                                          is_change: true,
                                        });
                                      }}
                                    />
                                  </Column>

                                  {checkout_data.packages[key].process_date &&
                                    this.state.is_package && (
                                      <Column className="xs-12 sm-12 md-12 lg-1 mt-1">
                                        <Button
                                          icon="event"
                                          iconSize={21}
                                          textColor="blue"
                                          size="lg"
                                          fullWidth
                                          onClick={() => {
                                            if (packageChanges) {
                                              toast.warning(
                                                "Randevu almadan önce lütfen yaptığınız değişiklikleri kaydedin."
                                              );
                                            } else {
                                              this.setState({
                                                checkout_data_dialog: true,
                                                selected_time:
                                                  moment().hour() +
                                                  ":" +
                                                  moment().minutes(),
                                                selected_date: new Date(
                                                  checkout_data.packages[
                                                    key
                                                  ].process_date
                                                ).toISOString(),
                                                selected_service:
                                                  checkout_data.packages[key]
                                                    .service_id,
                                                selected_staff:
                                                  checkout_data.packages[key]
                                                    .staff_id,
                                                is_change: true,
                                              });
                                            }
                                          }}
                                        />
                                      </Column>
                                    )}
                                </Grid>
                              )
                          )
                      ) : (
                        <NoDataContainer style={{ height: "70%" }}>
                          <img
                            src={require("../../../assets/images/manypixels/click_action.svg")}
                            alt="Malesef bulunamadı!"
                          />
                          <p>
                            {this.context.t(
                              `['receipt/detail/id'].serviceTransactions.noDataPackageText`
                            )}
                          </p>
                        </NoDataContainer>
                      )
                    }
                  </Column>
                )}
              </ReportsContainer>
            ))}

          {checkout_data.groups.length === 0 ? (
            <NoDataContainer style={{ height: "80%" }}>
              <img
                src={require("../../../assets/images/no-data-img.png")}
                alt="Malesef bulunamadı!"
              />
              <p>
                {this.context.t(
                  `['receipt/detail/id'].serviceTransactions.noDataPackageText`
                )}
              </p>
            </NoDataContainer>
          ) : (
            <></>
          )}
        </ListSection>

        {Object.values(checkout_data.packages).length > 0 && (
          <SubTotalText className="mt-2">
            <CustomerBoxDiv>
              <Button
                style={{
                  border: "1px solid rgba(255, 127, 80, 0.53)",
                }}
                disabled={
                  Object.keys(checkout_data.packages).filter(
                    (key) => checkout_data.packages[key] !== undefined
                  ).length === 0
                }
                className="mr-2"
                icon="emoji_symbols"
                title={this.context.t(
                  `['receipt/detail/id'].applyDiscountButtonTitle`
                )}
                backgroundColor="orange-opacity"
                textColor="orange"
                onClick={() => {
                  if (
                    Object.keys(checkout_data.paids).filter(
                      (key) => checkout_data.paids[key] !== undefined
                    ).length === 0 &&
                    Object.keys(checkout_data.installment).filter(
                      (key) => checkout_data.installment[key] !== undefined
                    ).length === 0
                  ) {
                    this.setState(
                      {
                        discount_target: "groupsAmount",
                      },
                      () =>
                        this.setState({
                          discount_value: null,
                          discount_type: "TL",
                          discount_modal: true,
                        })
                    );
                  } else {
                    toast.warning(
                      this.context.t(
                        `['receipt/detail/id'].discountBeforePaymentToast`
                      )
                    );
                  }
                }}
              />
              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RemainingDebtRed} alt="remaining_debt_red" />
                <CustomerPointBox>
                  {this.getTotalAmounts().groupsAmount !==
                  this.getTotalAmounts()._fgroups ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{ textDecoration: "line-through" }}
                        className="fc-grey mr-1"
                      >
                        {Math.round(this.getTotalAmounts()._fgroups)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                      <p>
                        {Math.round(this.getTotalAmounts().groupsAmount)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                    </span>
                  ) : (
                    <span>
                      <p>
                        {this.getTotalAmounts().groupsAmount.toFixed(0)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                    </span>
                  )}
                  <h3>
                    {this.context.t(
                      `['receipt/detail/id'].serviceTransactions.packageTotalAmount`
                    )}
                  </h3>
                </CustomerPointBox>
              </div>
            </CustomerBoxDiv>
          </SubTotalText>
        )}
        <AlertDialog
          closeHandler={() => {
            this.setState({
              services_update_modal: false,
            });
          }}
          open={this.state.services_update_modal}
          title="Paket Seans Güncelleme"
          buttons={[
            {
              title: "Onayla",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              icon: "check",
              onClick: async () => {
                await this.setTransictionsToState();
              },
            },
            {
              title: "Vazgeç",
              outlined: true,
              textColor: "#fff",
              icon: "clear",
              onClick: () => {
                this.setState({
                  selected_package_transition_update_state: null,
                  selected_package_transition_id: null,
                });
              },
            },
          ]}
        >
          <p>
            {`Paketteki bütün seanslar "${
              this.state.selected_package_transition_update_state === 0
                ? "Geldi"
                : this.state.selected_package_transition_update_state === 2
                ? '"Bekleniyor"'
                : '"Gelmedi"'
            }" olarak güncellenecektir. Onaylıyor musunuz?`}
            <br />
            <br />
            Not: Bu güncellemeden sonra değişiklikleri kaydetmeyi unutmayın.
          </p>
        </AlertDialog>

        <AlertDialog
          closeHandler={() => {
            this.setState({
              services_update_date_modal: false,
            });
          }}
          open={this.state.services_update_date_modal}
          title="Paket Tarih Güncelleme"
          buttons={[
            {
              title: "Onayla",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              icon: "check",
              onClick: async () => {
                await this.setTransictionsToDate();
              },
            },
            {
              title: "Vazgeç",
              outlined: true,
              textColor: "#fff",
              icon: "clear",
              onClick: () => {
                this.setState({
                  package_update_date: null,
                  selected_package_transition_id: null,
                  selected_package_update_date: null,
                });
              },
            },
          ]}
        >
          Paketteki bütün seansların tarihleri, tarih aralığına göre
          güncellenecektir. Onaylıyor musunuz?
          <br />
          Not: Bu güncellemeden sonra değişiklikleri kaydetmeyi unutmayın.
        </AlertDialog>

        <AlertDialog
          closeHandler={() => {
            this.setState({
              package_delete_modal: false,
            });
          }}
          open={this.state.package_delete_modal}
          title="Paket Silme"
          buttons={[
            {
              title: "Onayla",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              icon: "check",
              onClick: async () => {
                await this.setState({
                  checkout_data: {
                    ...this.state.checkout_data,
                    packages: Object.assign(
                      {},
                      Object.values(this.state.checkout_data.packages).filter(
                        (item) =>
                          item.receipt_package_id !==
                          this.state.delete_package_id
                      )
                    ),
                    groups: this.state.checkout_data.groups.filter(
                      (item) => item.id !== this.state.delete_package_id
                    ),
                  },
                });
              },
            },
            {
              title: "Vazgeç",
              outlined: true,
              textColor: "#fff",
              icon: "clear",
            },
          ]}
        >
          Paket ve içerisinde bulunan hizmetler silinecektir.Onaylıyor musunuz?
          <br />
          Not: Bu güncellemeden sonra değişiklikleri kaydetmeyi unutmayın.
        </AlertDialog>

        <AlertDialog
          closeHandler={() => {
            this.setState({
              last_services_from_package_to_delete_modal: false,
            });
          }}
          open={this.state.last_services_from_package_to_delete_modal}
          title="Paket Silme"
          buttons={[
            {
              title: "Onayla",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              icon: "check",
              onClick: async () => {
                // delete checkout_data.packages[
                //   this.state.key_for_delete_services
                // ];
                await this.setState({
                  checkout_data: {
                    ...this.state.checkout_data,
                    groups: this.state.checkout_data.groups.filter(
                      (item) => item.id !== this.state.delete_package_id
                    ),
                    packages: Object.assign(
                      {},
                      Object.values(this.state.checkout_data.packages).filter(
                        (item) =>
                          item.receipt_package_id !==
                          this.state.delete_package_id
                      )
                    ),
                  },
                });

                await this.setState({
                  checkout_data: {
                    ...checkout_data,
                  },
                  is_change: true,
                });
                // console.log("checkoutdata:", this.state.checkout_data);

                setTimeout(() => {
                  // this.saveSingleReceipt();
                  this.setState({
                    deleted_packages: [],
                    deleted_packages_services_transaction_package_id: null,
                  });
                }, 250);
              },
            },
            {
              title: "Vazgeç",
              outlined: true,
              textColor: "#fff",
              icon: "clear",
              onClick: () => {},
            },
          ]}
        >
          Silmeyi onaylamanız halinde paket ve içerisinde bulunan
          hizmet/hizmetler silinecektir. Onaylıyor musunuz?
          <br />
          Not: Bu güncellemeden sonra değişiklikleri kaydetmeyi unutmayın.
        </AlertDialog>
      </SectionBox>
    );
  };

  //** */ Ürün satışının yapıldığı, verilerin düzenlendiği alan.

  _ProductSalesBuilder = () => {
    const { checkout_data } = this.state;

    return (
      <SectionBox style={{ overflowX: "hidden" }}>
        <SectionHeading
          style={{
            backgroundColor: "rgba(49, 169, 243, 0.24)",
            color: "#3189ec",
            border: "1px solid #3189ec",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            className="detail-search"
            style={{
              backgroundColor: "#3189ec",
            }}
            onClick={() =>
              this.setState({
                product_detail_dialog_modal: true,
              })
            }
          >
            <Search style={{ color: "white", fontSize: "20px" }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <AddShoppingCart />
            <h3 style={{ marginLeft: "15px" }}>
              {this.context.t(`['receipt/detail/id'].stockSales.title`)}
            </h3>
          </div>
          <div>
            <AddBox
              style={{ cursor: "pointer", fontSize: "36px" }}
              onClick={() =>
                this.setState({
                  product_transaction: { ...this.state._product_transaction },
                  product_transaction_modal: true,
                  is_change: true,
                })
              }
            />
          </div>
        </SectionHeading>

        <ListSection className="no-scroll-bar">
          {Object.keys(checkout_data.sales).filter(
            (key) => checkout_data.sales[key] !== undefined
          ).length ? (
            Object.keys(checkout_data.sales).map(
              (key) =>
                checkout_data.sales[key] !== undefined && (
                  <Grid className="no-gutters-row">
                    <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                      <MuiThemeProvider theme={themeForDatePicker}>
                        <DatePickerInput
                          className="mb-1"
                          label={this.context.t(
                            `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                          )}
                          error={false}
                          value={
                            moment(checkout_data.sales[key].sold_date).format(
                              "YYYY-MM-DD"
                            ) === "0001-01-01"
                              ? null
                              : moment(checkout_data.sales[key].sold_date)
                          }
                          //  disabled={true}
                          onChange={(process_date) => {
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                sales: {
                                  ...checkout_data.sales,
                                  [key]: {
                                    ...checkout_data.sales[key],
                                    sold_date:
                                      moment(process_date).toISOString(),
                                  },
                                },
                              },
                              is_change: true,
                            });
                          }}
                        />
                      </MuiThemeProvider>
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-3">
                      <Input
                        className="mb-1"
                        readOnly
                        label={this.context.t(
                          `['receipt/detail/id'].stockSales.nameInputLabel`
                        )}
                        inputProps={{
                          style: {
                            color: `${
                              checkout_data.sales[key].stock_item?.is_passive
                                ? "red"
                                : "black"
                            }`,
                          },
                        }}
                        value={
                          checkout_data.sales[key].stock_item_id
                            ? this.state.stock_items.filter(
                                (item) =>
                                  item.id ===
                                  checkout_data.sales[key].stock_item_id
                              ).length
                              ? this.state.stock_items.filter(
                                  (item) =>
                                    item.id ===
                                    checkout_data.sales[key].stock_item_id
                                )[0].name
                              : this.context.t(
                                  `['receipt/detail/id'].stockSales.deletedName`
                                )
                            : this.context.t(
                                `['receipt/detail/id'].stockSales.deletedName`
                              )
                        }
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-2">
                      <StaffSelect
                        className="mb-2"
                        disabled={this.context.state.user.permission === 0}
                        label={this.context.t(
                          `['receipt/detail/id'].stockSales.staffInputLabel`
                        )}
                        items={this.state.staffs}
                        selected={
                          checkout_data.sales[key].staff_id
                            ? checkout_data.sales[key].staff_id
                            : null
                        }
                        labelKey="full_name"
                        valueKey="id"
                        handler={(staff_id) =>
                          this.setState({
                            checkout_data: {
                              ...checkout_data,
                              sales: {
                                ...checkout_data.sales,
                                [key]: {
                                  ...checkout_data.sales[key],
                                  staff_id: parseInt(staff_id),
                                },
                              },
                            },
                            is_change: true,
                          })
                        }
                      />
                    </Column>

                    {/* INPUTCHECK */}
                    <Column className="xs-12 sm-12 md-12 lg-2">
                      <Input
                        className="mb-2"
                        label={this.context.t(
                          `['receipt/detail/id'].stockSales.countInputLabel`
                        )}
                        value={checkout_data.sales[key].quantity}
                        onChange={(e) =>
                          this.setState({
                            checkout_data: {
                              ...checkout_data,
                              sales: {
                                ...checkout_data.sales,
                                [key]: {
                                  ...checkout_data.sales[key],
                                  quantity:
                                    e.target.value &&
                                    /[0-9]+/.test(e.target.value)
                                      ? parseInt(e.target.value)
                                      : 0,
                                },
                              },
                            },
                            is_change: true,
                          })
                        }
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-2">
                      <Input
                        readOnly
                        className="mb-2"
                        type="number"
                        label={
                          this.context.t(
                            `['receipt/detail/id'].stockSales.amountInputLabel`
                          ) +
                          (this.context.state.currency
                            ? " (" + this.context.state.currency + ")"
                            : "(₺)")
                        }
                        value={checkout_data.sales[key].amount}
                        onChange={(e) =>
                          this.setState({
                            checkout_data: {
                              ...checkout_data,
                              sales: {
                                ...checkout_data.sales,
                                [key]: {
                                  ...checkout_data.sales[key],
                                  amount:
                                    e.target.value &&
                                    /[0-9,.]+/.test(e.target.value)
                                      ? parseFloat(e.target.value)
                                      : 0,
                                },
                              },
                            },
                            is_change: true,
                          })
                        }
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-1">
                      <Button
                        icon="delete_forever"
                        iconSize={21}
                        textColor="red"
                        size="lg"
                        fullWidth
                        onClick={() =>
                          this.setState({
                            checkout_data: {
                              ...checkout_data,
                              sales: {
                                ...checkout_data.sales,
                                [key]: undefined,
                              },
                            },
                            is_change: true,
                          })
                        }
                      />
                    </Column>
                  </Grid>
                )
            )
          ) : (
            <NoDataContainer style={{ height: "80%" }}>
              <img
                src={require("../../../assets/images/no-data-img.png")}
                alt="Malesef bulunamadı!"
              />
              <p>
                {this.context.t(`['receipt/detail/id'].stockSales.noDataText`)}
              </p>
            </NoDataContainer>
          )}
        </ListSection>

        {Object.keys(checkout_data.sales).filter(
          (key) => checkout_data.sales[key] !== undefined
        ).length > 0 && (
          <SubTotalText className="mt-2">
            <CustomerBoxDiv>
              <Button
                style={{
                  border: "1px solid rgba(255, 127, 80, 0.53)",
                }}
                disabled={
                  Object.keys(checkout_data.sales).filter(
                    (key) => checkout_data.sales[key] !== undefined
                  ).length === 0
                }
                className="mr-2"
                icon="emoji_symbols"
                title={this.context.t(
                  `['receipt/detail/id'].applyDiscountButtonTitle`
                )}
                backgroundColor="orange-opacity"
                textColor="orange"
                onClick={async () => {
                  if (
                    Object.keys(checkout_data.paids).filter(
                      (key) => checkout_data.paids[key] !== undefined
                    ).length === 0 &&
                    Object.keys(checkout_data.installment).filter(
                      (key) => checkout_data.installment[key] !== undefined
                    ).length === 0
                  ) {
                    this.setState({
                      discount_target: "sales",
                    });
                    this.setState({
                      discount_value: null,
                      discount_type: "TL",
                      discount_modal: true,
                    });
                  } else {
                    toast.warning(
                      this.context.t(
                        `['receipt/detail/id'].discountBeforePaymentToast`
                      )
                    );
                  }
                }}
              />

              <div
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RemainingDebtRed} alt="remaining_debt_red" />
                <CustomerPointBox>
                  {this.getTotalAmounts().sales !=
                  this.getTotalAmounts()._fsales ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{ textDecoration: "line-through" }}
                        className="fc-grey mr-1"
                      >
                        {this.getTotalAmounts()._fsales.toFixed(0)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                      <p>
                        {this.getTotalAmounts().sales.toFixed(0)}
                        {this.context.state.currency
                          ? this.context.state.currency
                          : "₺"}
                      </p>
                    </span>
                  ) : (
                    <p>
                      {this.getTotalAmounts().sales.toFixed(0)}
                      {this.context.state.currency
                        ? this.context.state.currency
                        : "₺"}
                    </p>
                  )}
                  <h3>
                    {this.context.t(
                      `['receipt/detail/id'].stockSales.totalAmount`
                    )}
                  </h3>
                </CustomerPointBox>
              </div>
            </CustomerBoxDiv>
          </SubTotalText>
        )}
      </SectionBox>
    );
  };

  /**
   * Adisyona ödeme yöntemlerinin eklendiği, düzenlendiği alan.
   */
  _PaymentsBuilder = () => {
    const {
      checkout_data,
      advanced_date,
      advanced_amount,
      advanced_staff,
      selected_installment,
      selected_installment_status,
      advanced_type,
      installment_date,
      installment_data,
    } = this.state;

    let total_mount = 0;
    const rounded_amount = Math.round(this.getTotalAmounts().installment);

    let amountData = Object.keys(checkout_data.installment).map((key) =>
      checkout_data.installment[key] !== undefined
        ? checkout_data.installment[key].installment_amount !== NaN
          ? checkout_data.installment[key].installment_amount
          : 0
        : 0
    );

    for (let i = 0; i < amountData.length - 1; i++) {
      total_mount += Math.round(amountData[i]);
    }

    for (let j = 0; j < amountData.length; j++) {
      amountData[j] = Math.round(amountData[j]);
      if (amountData[amountData.length - 1]) {
        amountData[amountData.length - 1] = rounded_amount - total_mount;
      }
    }

    React.useEffect(() => {
      Object.keys(checkout_data.installment).map((key) => {
        checkout_data.installment[key] !== undefined
          ? checkout_data.installment[key].installment_amount !== NaN
            ? (checkout_data.installment[key].installment_amount = Math.round(
                amountData[key]
              ))
            : 0
          : 0;
      }),
        flushSync();
    }, [checkout_data]);

    return (
      <>
        <SectionBox style={{ overflowX: "hidden" }}>
          <SectionHeading
            style={{
              backgroundColor: "rgba(29,139,62, 0.08)",
              color: "green",
              border: "1px solid green",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              onClick={() =>
                this.setState({
                  payment_detail_dialog_modal: true,
                })
              }
              className="detail-search"
              style={{ backgroundColor: "green" }}
            >
              <Search style={{ color: "white", fontSize: "20px" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Payment />
              <h3 style={{ marginLeft: "15px" }}>
                {this.context.t(`['receipt/detail/id'].payments.title`)}
              </h3>
            </div>
            <div>
              <AddBox
                style={{ cursor: "pointer", fontSize: "36px" }}
                onClick={() =>
                  this.setState({
                    addPayment_dialog: true,
                    advanced_amount: this.getPaidsDiffTotal(),
                    is_change: true,
                  })
                }
              />
            </div>
          </SectionHeading>

          <AlertDialog
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "5px",
                }}
              >
                <AddOutlined
                  style={{
                    color: "#6097D2",
                  }}
                />
                <span
                  style={{
                    color: "#6097D2",
                  }}
                >
                  {this.context.t(`['receipt/detail/id'].addPaymentTitle`)}
                </span>
              </div>
            }
            open={this.state.addPayment_dialog}
            fullWidth
            maxWidth="xs"
            closeHandler={() => this.setState({ addPayment_dialog: false })}
            buttons={[
              {
                title: this.context.t(
                  `appointments.create.completePaymentButtonTitle`
                ),
                icon: "check",
                backgroundColor: "green",
                style: {
                  height: "56px",
                },
                textColor: "white",
                onClick: () => {
                  this.setState({
                    checkout_data: {
                      ...checkout_data,
                      paids: {
                        ...checkout_data.paids,
                        [Object.keys(checkout_data.paids).length + 1]: {
                          payment_date: advanced_date,
                          payment_type: advanced_type,
                          pay_amount: parseFloat(advanced_amount),
                          staff_id: advanced_staff?.id,
                        },
                      },
                    },
                    is_change: true,
                  });
                  this.setState({
                    addPayment_dialog: false,
                    advanced_amount: null,
                    advanced_date: new Date(),
                    advanced_type: 0,
                    advanced_staff: null,
                    payment_select_staff: {},
                  });
                },
              },
              {
                title: this.context.t(`appointments.create.discardButtonTitle`),
                icon: "close",
                backgroundColor: "white",
                style: {
                  border: "1px solid #666666",
                  height: "56px",
                },
                textColor: "black",
                onClick: () => {
                  this.setState({
                    addPayment_dialog: false,
                    payment_select_staff: {},
                    advanced_staff: null,
                  });
                },
              },
            ]}
          >
            <>
              <Column>
                <span
                  style={{
                    marginBottom: "10px",
                    marginLeft: "5px",
                    fontSize: "18px",
                  }}
                >
                  Ödeme Tarihi *
                </span>
                <DatePickerInput
                  // label={this.context.t(
                  //   `['receipt/detail/id'].payments.dateInputLabel`
                  // )}
                  value={moment(advanced_date).local().toISOString()}
                  onChange={(payment_date) => {
                    this.setState({
                      advanced_date: payment_date,
                    });
                  }}
                />
              </Column>
              <Column>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <AdvancedType
                    style={{
                      backgroundColor:
                        advanced_type == 0 ? "#78B8FF" : "#FFFFFF",
                      filter:
                        advanced_type == 0
                          ? "drop-shadow(0px 3px 6px #78B8FF)"
                          : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                      border:
                        advanced_type == 0
                          ? "1px solid #6BB0FE"
                          : "1px solid #b8b8b8",
                    }}
                    onClick={() => this.setState({ advanced_type: 0 })}
                  >
                    {advanced_type == 0 ? (
                      <img src={CashWhite} alt="cash_white" />
                    ) : (
                      <img src={Cash} alt="cash" />
                    )}
                    <span
                      style={{
                        color: advanced_type == 0 ? "#FFFFFF" : "#000000",
                      }}
                    >
                      Nakit
                    </span>
                  </AdvancedType>
                  <AdvancedType
                    style={{
                      marginLeft: "10px",
                      backgroundColor:
                        advanced_type == 2 ? "#78B8FF" : "#FFFFFF",
                      filter:
                        advanced_type == 2
                          ? "drop-shadow(0px 3px 6px #78B8FF)"
                          : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                      border:
                        advanced_type == 2
                          ? "1px solid #6BB0FE"
                          : "1px solid #b8b8b8",
                    }}
                    onClick={() =>
                      this.setState({ advanced_type: 2, is_change: true })
                    }
                  >
                    {advanced_type == 2 ? (
                      <img src={TransferWhite} alt="transfer_white" />
                    ) : (
                      <img src={Transfer} alt="transfer" />
                    )}
                    <span
                      style={{
                        color: advanced_type == 2 ? "#FFFFFF" : "#000000",
                      }}
                    >
                      Havale/EFT
                    </span>
                  </AdvancedType>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <AdvancedType
                    style={{
                      backgroundColor:
                        advanced_type == 1 ? "#78B8FF" : "#FFFFFF",
                      filter:
                        advanced_type == 1
                          ? "drop-shadow(0px 3px 6px #78B8FF)"
                          : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                      border:
                        advanced_type == 1
                          ? "1px solid #6BB0FE"
                          : "1px solid #b8b8b8",
                    }}
                    onClick={() =>
                      this.setState({ advanced_type: 1, is_change: true })
                    }
                  >
                    {advanced_type == 1 ? (
                      <img src={CreditWhite} alt="credit_white" />
                    ) : (
                      <img src={Credit} alt="credit" />
                    )}
                    <span
                      style={{
                        color: advanced_type == 1 ? "#FFFFFF" : "#000000",
                      }}
                    >
                      Kredi Kartı/Banka Kartı
                    </span>
                  </AdvancedType>
                  <AdvancedType
                    style={{
                      marginLeft: "10px",
                      backgroundColor:
                        advanced_type == 3 ? "#78B8FF" : "#FFFFFF",
                      filter:
                        advanced_type == 3
                          ? "drop-shadow(0px 3px 6px #78B8FF)"
                          : "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.149))",
                      border:
                        advanced_type == 3
                          ? "1px solid #6BB0FE"
                          : "1px solid #b8b8b8",
                    }}
                    onClick={() =>
                      this.setState({ advanced_type: 3, is_change: true })
                    }
                  >
                    {advanced_type == 3 ? (
                      <img
                        src={CustomerPointWhite}
                        alt="customer_point_white"
                      />
                    ) : (
                      <img src={CustomerPoint} alt="customer_point" />
                    )}
                    <span
                      style={{
                        color: advanced_type == 3 ? "#FFFFFF" : "#000000",
                      }}
                    >
                      Müşteri Puanı
                    </span>
                  </AdvancedType>
                </div>
              </Column>
              <Column>
                <StaffSelect
                  disabled={this.context.state.user.permission === 0}
                  label="Personel Seçimi (Opsiyonel)"
                  items={this.state.staffs}
                  selected={this.state.payment_select_staff?.staff_id}
                  labelKey="full_name"
                  valueKey="id"
                  returnFullObject
                  handler={(staff) =>
                    this.setState({
                      advanced_staff: staff,
                      is_change: true,
                      payment_select_staff: {
                        ...this.state.payment_select_staff,
                        staff_id: parseInt(staff.id),
                      },
                    })
                  }
                />
              </Column>
              <Column>
                <span
                  style={{
                    marginBottom: "10px",
                    marginLeft: "5px",
                    fontSize: "18px",
                  }}
                >
                  Ödeme Tutarı *
                </span>
                <MaskedInput
                  // label={"Ödeme Tutarı"}
                  mask="9999999999999"
                  maskChar=""
                  value={advanced_amount ? advanced_amount : ""}
                  onChange={(e) => {
                    this.setState({
                      advanced_amount: e.target.value,
                      is_change: true,
                    });
                  }}
                />
              </Column>
            </>
          </AlertDialog>
          <ListSection className="no-scroll-bar">
            {Object.keys(checkout_data.paids).filter(
              (key) => checkout_data.paids[key] !== undefined
            ).length > 0 ? (
              Object.keys(checkout_data.paids).map(
                (key, index) =>
                  checkout_data.paids[key] !== undefined && (
                    <Grid className="no-gutters-row" key={index}>
                      <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                        <MuiThemeProvider theme={themeForDatePicker}>
                          <DatePickerInput
                            label={this.context.t(
                              `['receipt/detail/id'].payments.dateInputLabel`
                            )}
                            value={
                              new Date(
                                moment(checkout_data.paids[key].payment_date)
                                  .local()
                                  .toISOString()
                              )
                            }
                            onChange={(payment_date) => {
                              this.setState({
                                checkout_data: {
                                  ...checkout_data,
                                  paids: {
                                    ...checkout_data.paids,
                                    [key]: {
                                      ...checkout_data.paids[key],
                                      payment_date,
                                    },
                                  },
                                },
                                is_change: true,
                              });
                            }}
                          />
                        </MuiThemeProvider>
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-3">
                        <Select
                          label={this.context.t(
                            `['receipt/detail/id'].payments.typeInputLabel`
                          )}
                          items={[
                            {
                              label: this.context.t(
                                `['receipt/detail/id'].payments.typeCASH`
                              ),
                              value: "0",
                            },
                            {
                              label: this.context.t(
                                `['receipt/detail/id'].payments.typeCARD`
                              ),
                              value: "1",
                            },
                            {
                              label: this.context.t(
                                `['receipt/detail/id'].payments.typeEFT`
                              ),
                              value: "2",
                            },
                            {
                              label: this.context.t(
                                `['receipt/detail/id'].payments.typePOINT`
                              ),
                              value: "3",
                            },
                          ]}
                          selected={`${checkout_data.paids[key].payment_type}`}
                          labelKey="label"
                          valueKey="value"
                          handler={(payment_type) =>
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                paids: {
                                  ...checkout_data.paids,
                                  [key]: {
                                    ...checkout_data.paids[key],
                                    payment_type: parseInt(payment_type),
                                  },
                                },
                              },
                              is_change: true,
                            })
                          }
                        />
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-3">
                        {/* Select Staff For Paids */}

                        <StaffSelect
                          label="Personel"
                          items={this.state.staffs}
                          selected={
                            checkout_data.paids[key]?.staff_id
                              ? checkout_data.paids[key]?.staff_id
                              : ""
                          }
                          labelKey="full_name"
                          valueKey="id"
                          handler={(staff_id) =>
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                paids: {
                                  ...checkout_data.paids,
                                  [key]: {
                                    ...checkout_data.paids[key],
                                    staff_id: parseInt(staff_id),
                                  },
                                },
                              },
                              is_change: true,
                            })
                          }
                        />
                      </Column>

                      <Column className="xs-12 sm-12 md-12 lg-3">
                        <MaskedInput
                          label={
                            checkout_data.paids[key].payment_type === 3
                              ? this.context.t(
                                  `['receipt/detail/id'].payments.pointInputLabel`
                                )
                              : this.context.t(
                                  `['receipt/detail/id'].payments.amountInputLabel`
                                ) +
                                (this.context.state.currency
                                  ? "(" + this.context.state.currency + ")"
                                  : "₺")
                          }
                          mask="9999999999999"
                          maskChar=""
                          value={
                            checkout_data.paids[key].pay_amount !== null
                              ? parseFloat(checkout_data.paids[key].pay_amount)
                              : ""
                          }
                          onChange={(e) => {
                            // console.log(e.target.value)
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                paids: {
                                  ...checkout_data.paids,
                                  [key]: {
                                    ...checkout_data.paids[key],
                                    pay_amount:
                                      e.target.value &&
                                      /[0-9,.]+/.test(e.target.value)
                                        ? parseFloat(e.target.value)
                                        : null,
                                  },
                                },
                              },
                              is_change: true,
                            });
                          }}
                        />
                      </Column>

                      {/* <Column className="xs-12 sm-12 md-12 lg-1">
                        <Button
                          icon="delete_forever"
                          iconSize={21}
                          textColor="red"
                          size="lg"
                          fullWidth
                          onClick={() =>
                            this.setState({
                              checkout_data: {
                                ...checkout_data,
                                paids: {
                                  ...checkout_data.paids,
                                  [key]: undefined,
                                },
                              },
                              is_change: true,
                            })
                          }
                        />
                      </Column> */}
                    </Grid>
                  )
              )
            ) : (
              <NoDataContainer style={{ height: "90%" }}>
                <img
                  style={{ height: "80%" }}
                  src={require("../../../assets/images/manypixels/online_payment_.svg")}
                  alt="Malesef bulunamadı!"
                />
                <p>
                  {this.context.t(`['receipt/detail/id'].payments.noDataText`)}
                </p>
              </NoDataContainer>
            )}
          </ListSection>
        </SectionBox>

        {/* İnstallMent */}
        <SectionBox style={{ overflowX: "hidden" }}>
          <SectionHeading
            style={{
              backgroundColor: "rgba(255,0,0, 0.08)",
              color: "red",
              border: "1px solid red",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              className="detail-search"
              style={{
                backgroundColor: "red",
              }}
              onClick={() =>
                this.setState({
                  payment_detail_dialog_modal: true,
                })
              }
            >
              <Search style={{ color: "white", fontSize: "20px" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Payment />
              <h3 style={{ marginLeft: "15px" }}>
                {" "}
                {this.context.t(`['receipt/detail/id'].installment.title`)}
              </h3>
            </div>
            <div>
              <AddBox
                style={{ cursor: "pointer", fontSize: "36px" }}
                onClick={() =>
                  this.setState({
                    installment_dialog: true,
                  })
                }
              />
            </div>
          </SectionHeading>
          <AlertDialog
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <span
                  style={{
                    width: "120%",
                    marginTop: "15px",
                  }}
                >
                  {this.context.t(`['receipt/detail/id'].installmentProcess`)}
                </span>
                <CustomerBoxDiv>
                  {parseFloat(this.getAllTotalService()) -
                    parseFloat(
                      this.getTotalAmounts().installment_total +
                        this.getTotalAmounts().paids
                    ) ===
                  0 ? (
                    <img src={RemainingDebt} alt="remaining_debt" />
                  ) : (
                    <img src={RemainingDebtRed} alt="remaining_debt_red" />
                  )}
                  <CustomerPointBox>
                    <span>
                      {this.getInstallmentDiffTotal(1)}{" "}
                      {this.context.state.currency
                        ? this.context.state.currency
                        : "₺"}
                    </span>
                    <h3>
                      {this.context.t(`['receipt/detail/id'].remainingDebt`)}
                    </h3>
                  </CustomerPointBox>
                </CustomerBoxDiv>
              </div>
            }
            open={this.state.installment_dialog}
            closeHandler={() => this.setState({ installment_dialog: false })}
            fullWidth
            maxWidth="xs"
            buttons={[
              {
                title: this.context.t(
                  `appointments.create.completeButtonTitle`
                ),
                icon: "check",
                backgroundColor: "green",
                style: {
                  height: "56px",
                },
                textColor: "white",
                onClick: () => {
                  this.setState({
                    save_button_disabled: true,
                  });
                  for (var i = 0; i < selected_installment; i++) {
                    installment_data.push({
                      installment_date:
                        i === 0
                          ? moment(installment_date).format("YYYY-MM-DD")
                          : moment(installment_date)
                              .add(i, "months")
                              .format("YYYY-MM-DD"),
                      installment_type: 0,
                      installment_amount:
                        this.getInstallmentDiffTotal(selected_installment),
                      installment_status: 0,
                    });
                  }
                  setTimeout(() => {
                    this.setState({
                      checkout_data: {
                        ...checkout_data,
                        installment: {
                          ...[
                            ...Object.keys(checkout_data.installment).map(
                              (key) => checkout_data.installment[key]
                            ),
                            ...installment_data,
                          ],
                        },
                      },
                      is_change: true,
                    });
                  }, 1000);
                  setTimeout(() => {
                    this.setState({
                      installment_dialog: false,
                      is_change: true,
                      installment_data: [],
                    });
                    this.saveSingleReceipt();
                  }, 1500);
                  // setTimeout(() => {
                  //   window.location.reload();
                  // }, 300);
                },
              },
              {
                title: this.context.t(`appointments.create.discardButtonTitle`),
                icon: "close",
                backgroundColor: "white",
                style: {
                  border: "1px solid #666666",
                  height: "56px",
                },
                textColor: "black",
                onClick: () => this.setState({ installment_dialog: false }),
              },
            ]}
          >
            <>
              <Column>
                <span
                  style={{
                    marginBottom: "10px",
                    marginLeft: "5px",
                    fontSize: "18px",
                  }}
                >
                  Ödeme Tarihi *
                </span>
                <DatePickerInput
                  // label={this.context.t(
                  //   `['receipt/detail/id'].payments.dateInputLabel`
                  // )}
                  value={moment(installment_date).local().toISOString()}
                  onChange={(payment_date) => {
                    this.setState({
                      installment_date: payment_date,
                      is_change: true,
                    });
                  }}
                />
              </Column>
              <Column>
                <span
                  style={{
                    marginBottom: "10px",
                    marginLeft: "5px",
                    fontSize: "18px",
                  }}
                >
                  Taksit Sayısı *
                </span>
                <Select
                  // label={this.context.t(
                  //   `['receipt/detail/id'].installmentCountInputLabel`
                  // )}
                  labelKey="label"
                  valueKey="value"
                  items={[
                    { value: 2, label: "2 Taksit" },
                    { value: 3, label: "3 Taksit" },
                    { value: 4, label: "4 Taksit" },
                    { value: 5, label: "5 Taksit" },
                    { value: 6, label: "6 Taksit" },
                    { value: 7, label: "7 Taksit" },
                    { value: 8, label: "8 Taksit" },
                    { value: 9, label: "9 Taksit" },
                    { value: 10, label: "10 Taksit" },
                    { value: 11, label: "11 Taksit" },
                    { value: 12, label: "12 Taksit" },
                  ]}
                  selected={selected_installment ? selected_installment : null}
                  handler={(installment) => {
                    this.setState({
                      selected_installment: installment,
                      is_change: true,
                    });
                  }}
                />
              </Column>
              {this.state.selected_installment && (
                <Column>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p style={{ marginRight: "5px" }}>Ödemeler:</p>
                    <p>
                      {Math.round(
                        this.getInstallmentDiffTotal(selected_installment)
                      )}
                      {this.context.state.currency
                        ? this.context.state.currency
                        : "₺"}{" "}
                      x {this.state.selected_installment}
                    </p>
                  </div>
                </Column>
              )}
            </>
          </AlertDialog>
          <ListSection className="no-scroll-bar">
            {Object.values(checkout_data.installment).length > 0 ? (
              <>
                {Object.values(checkout_data.installment)
                  .filter((item) => item !== undefined)
                  .map((installment, index) => {
                    return (
                      <Grid className="no-gutters-row" key={index}>
                        <Column className="xs-12 sm-12 md-12 mt-1 lg-4">
                          <MuiThemeProvider theme={themeForDatePicker}>
                            <DatePickerInput
                              label={`${this.context.t(
                                `['receipt/detail/id'].payments.dateInputLabel`
                              )} / ${index + 1} Taksit`}
                              value={
                                new Date(
                                  moment(installment.installment_date)
                                    .local()
                                    .toISOString()
                                )
                              }
                              onChange={(installment_date) => {
                                // installment_data[index].installment_date =
                                //   installment_date;
                                this.setState({
                                  checkout_data: {
                                    ...this.state.checkout_data,
                                    installment: {
                                      ...[
                                        ...Object.keys(
                                          checkout_data.installment
                                        ).map(
                                          (key) =>
                                            checkout_data.installment[key]
                                        ),
                                      ],
                                      [index]: {
                                        ...this.state.checkout_data.installment[
                                          index
                                        ],
                                        installment_date:
                                          moment(installment_date).format(
                                            "YYYY-MM-DD"
                                          ),
                                      },
                                    },
                                  },
                                });
                              }}
                            />
                          </MuiThemeProvider>
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-3">
                          <Select
                            label={"Ödeme Durumu"}
                            items={[
                              {
                                label: "Bekleniyor",
                                value: "0",
                              },
                              {
                                label: "Ödendi",
                                value: "1",
                              },
                              {
                                label: "Ödenmedi",
                                value: "2",
                              },
                            ]}
                            selected={`${
                              installment.installment_status
                                ? installment.installment_status
                                : 0
                            }`}
                            labelKey="label"
                            valueKey="value"
                            handler={(installment_status) => {
                              // installment_data[index].installment_status =
                              //   parseInt(installment_status);
                              // this.setState({
                              //   selected_installment_status:
                              //     parseInt(installment_status),
                              // });
                              this.setState({
                                checkout_data: {
                                  ...this.state.checkout_data,
                                  installment: {
                                    ...[
                                      ...Object.keys(
                                        checkout_data.installment
                                      ).map(
                                        (key) => checkout_data.installment[key]
                                      ),
                                    ],
                                    [index]: {
                                      ...this.state.checkout_data.installment[
                                        index
                                      ],
                                      installment_status:
                                        parseInt(installment_status),
                                    },
                                  },
                                },
                              });
                            }}
                          />
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-3">
                          <Select
                            label={this.context.t(
                              `['receipt/detail/id'].payments.typeInputLabel`
                            )}
                            items={[
                              {
                                label: this.context.t(
                                  `['receipt/detail/id'].payments.typeCASH`
                                ),
                                value: "0",
                              },
                              {
                                label: this.context.t(
                                  `['receipt/detail/id'].payments.typeCARD`
                                ),
                                value: "1",
                              },
                              {
                                label: this.context.t(
                                  `['receipt/detail/id'].payments.typeEFT`
                                ),
                                value: "2",
                              },
                              // {
                              //   label: this.context.t(
                              //     `['receipt/detail/id'].payments.typePOINT`
                              //   ),
                              //   value: "3",
                              // },
                            ]}
                            selected={`${installment.installment_type}`}
                            labelKey="label"
                            valueKey="value"
                            handler={(installment_type) => {
                              // installment_data[index].installment_type =
                              //   parseInt(installment_type);
                              this.setState({
                                checkout_data: {
                                  ...this.state.checkout_data,
                                  installment: {
                                    ...[
                                      ...Object.keys(
                                        checkout_data.installment
                                      ).map(
                                        (key) => checkout_data.installment[key]
                                      ),
                                    ],
                                    [index]: {
                                      ...this.state.checkout_data.installment[
                                        index
                                      ],
                                      installment_type:
                                        parseInt(installment_type),
                                    },
                                  },
                                },
                              });
                            }}
                          />
                        </Column>

                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <MaskedInput
                            label={
                              installment.installment_type === 3
                                ? this.context.t(
                                    `['receipt/detail/id'].payments.pointInputLabel`
                                  )
                                : this.context.t(
                                    `['receipt/detail/id'].payments.amountInputLabel`
                                  ) +
                                  (this.context.state.currency
                                    ? "(" + this.context.state.currency + ")"
                                    : "₺")
                            }
                            value={
                              installment.installment_amount !== null
                                ? parseFloat(
                                    installment.installment_amount
                                  ).toFixed(0)
                                : ""
                            }
                            onChange={(e) => {
                              // installment_data[index].amount =
                              //   e.target.value && /[0-9,.]+/.test(e.target.value)
                              //     ? parseFloat(e.target.value)
                              //     : null;
                              this.setState({
                                checkout_data: {
                                  ...this.state.checkout_data,
                                  installment: {
                                    ...[
                                      ...Object.keys(
                                        checkout_data.installment
                                      ).map(
                                        (key) => checkout_data.installment[key]
                                      ),
                                    ],
                                    [index]: {
                                      ...this.state.checkout_data.installment[
                                        index
                                      ],
                                      installment_amount:
                                        e.target.value &&
                                        /[0-9,.]+/.test(e.target.value)
                                          ? parseFloat(e.target.value)
                                          : null,
                                    },
                                  },
                                },
                              });
                            }}
                          />
                        </Column>
                      </Grid>
                    );
                  })}
              </>
            ) : (
              <>
                {Object.keys(checkout_data.installment).filter(
                  (key) => checkout_data.installment[key] !== undefined
                ).length > 0 ? (
                  Object.keys(checkout_data.installment).map(
                    (key, index) =>
                      checkout_data.installment[key] !== undefined && (
                        <Grid className="no-gutters-row" key={index}>
                          <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                            <MuiThemeProvider theme={themeForDatePicker}>
                              <DatePickerInput
                                label={`${this.context.t(
                                  `['receipt/detail/id'].payments.dateInputLabel`
                                )} / ${index + 1}. taksit`}
                                value={
                                  new Date(
                                    moment(
                                      checkout_data.installment[key]
                                        .installment_date
                                    )
                                      .local()
                                      .toISOString()
                                  )
                                }
                                onChange={(installment_date) => {
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      installment: {
                                        ...checkout_data.installment,
                                        [key]: {
                                          ...checkout_data.installment[key],
                                          installment_date,
                                        },
                                      },
                                    },
                                    is_change: true,
                                  });
                                }}
                              />
                            </MuiThemeProvider>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <Select
                              label={"Ödeme Durumu"}
                              items={[
                                {
                                  label: "Bekleniyor",
                                  value: "0",
                                },
                                {
                                  label: "Ödendi",
                                  value: "1",
                                },
                                {
                                  label: "Ödenmedi",
                                  value: "2",
                                },
                              ]}
                              selected={`${checkout_data.installment[key].installment_status}`}
                              labelKey="label"
                              valueKey="value"
                              handler={(installment_status) =>
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    installment: {
                                      ...checkout_data.installment,
                                      [key]: {
                                        ...checkout_data.installment[key],
                                        installment_status:
                                          parseInt(installment_status),
                                      },
                                    },
                                  },
                                  is_change: true,
                                  selected_installment_status:
                                    parseInt(installment_status),
                                })
                              }
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <Select
                              label={this.context.t(
                                `['receipt/detail/id'].payments.typeInputLabel`
                              )}
                              items={[
                                {
                                  label: this.context.t(
                                    `['receipt/detail/id'].payments.typeCASH`
                                  ),
                                  value: "0",
                                },
                                {
                                  label: this.context.t(
                                    `['receipt/detail/id'].payments.typeCARD`
                                  ),
                                  value: "1",
                                },
                                {
                                  label: this.context.t(
                                    `['receipt/detail/id'].payments.typeEFT`
                                  ),
                                  value: "2",
                                },
                                // {
                                //   label: this.context.t(
                                //     `['receipt/detail/id'].payments.typePOINT`
                                //   ),
                                //   value: "3",
                                // },
                              ]}
                              selected={`${checkout_data.installment[key].installment_type}`}
                              labelKey="label"
                              valueKey="value"
                              handler={(installment_type) =>
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    installment: {
                                      ...checkout_data.installment,
                                      [key]: {
                                        ...checkout_data.installment[key],
                                        installment_type:
                                          parseInt(installment_type),
                                      },
                                    },
                                  },
                                  is_change: true,
                                })
                              }
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <MaskedInput
                              label={
                                checkout_data.installment[key]
                                  .installment_type === 3
                                  ? this.context.t(
                                      `['receipt/detail/id'].payments.pointInputLabel`
                                    )
                                  : this.context.t(
                                      `['receipt/detail/id'].payments.amountInputLabel`
                                    ) +
                                    (this.context.state.currency
                                      ? "(" + this.context.state.currency + ")"
                                      : "₺")
                              }
                              mask="9999999999999"
                              maskChar=""
                              value={
                                checkout_data.installment[key]
                                  .installment_amount
                                  ? parseFloat(
                                      checkout_data.installment[key]
                                        .installment_amount
                                    )
                                  : ""
                              }
                              onChange={(e) => {
                                // console.log(e.target.value)
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    installment: {
                                      ...checkout_data.installment,
                                      [key]: {
                                        ...checkout_data.installment[key],
                                        installment_amount:
                                          e.target.value &&
                                          /[0-9,.]+/.test(e.target.value)
                                            ? parseFloat(e.target.value)
                                            : null,
                                      },
                                    },
                                  },
                                  is_change: true,
                                });
                              }}
                            />
                          </Column>
                        </Grid>
                      )
                  )
                ) : (
                  <NoDataContainer style={{ height: "90%" }}>
                    <img
                      style={{ height: "80%" }}
                      src={require("../../../assets/images/manypixels/online_payment_.svg")}
                      alt="Malesef bulunamadı!"
                    />
                    <p>
                      {this.context.t(
                        `['receipt/detail/id'].payments.noDataText`
                      )}
                    </p>
                  </NoDataContainer>
                )}
              </>
            )}
          </ListSection>
        </SectionBox>
      </>
    );
  };

  // Randevu notu gösterildiği düzenlendiği alan.

  _AppNotesBuilder = () => {
    return (
      <SectionBox style={{ height: "auto" }}>
        <Grid className="no-gutters-row">
          <Column
            className={`sx-12 sm-12 md-12 lg-${
              this.state.receipt_sender !== null ? "7" : "12"
            }`}
          >
            <SectionHeading
              style={{
                backgroundColor: "rgba(140,15,180, 0.1)",
                color: "#8c0fb4",
                border: "1px solid #8c0fb4",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Comment />
                <h3 style={{ marginLeft: "15px" }}>
                  {this.context.t(`['receipt/detail/id'].notes.customer`)}
                </h3>
              </div>
            </SectionHeading>
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-5">
            {this.state.receipt_sender !== null ? (
              <SectionHeading
                onClick={() =>
                  this.setState({
                    add_sms_dialog: true,
                    message:
                      // "Sayın |ad soyad|, |ödenen tutar| ₺ ödemeniz için teşekkür ederiz. Kalan borcunuz |kalan borc| ₺'dir. Sağlıklı günler dileriz.",
                      ` Sayın |ad soyad|, |ödenen tutar| ${
                        this.context.state.currency
                          ? this.context.state.currency
                          : "₺"
                      } ödemeniz için teşekkür ederiz. Kalan borcunuz |kalan borc| ${
                        this.context.state.currency
                          ? this.context.state.currency
                          : "₺"
                      }'dir. Sağlıklı günler dileriz.`,
                  })
                }
                style={{
                  cursor: "pointer",
                  backgroundColor: "rgba(255,165,0, 0.1)",
                  color: "orange",
                  border: "1px solid orange",
                  padding: "10px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Sms />
                  <h3 style={{ marginLeft: "15px" }}>
                    {this.context.t(
                      `['receipt/detail/id'].sms.addSmsButtonTitle`
                    )}
                  </h3>
                </div>
              </SectionHeading>
            ) : (
              <></>
            )}
          </Column>
          <Column className="xs-12 sm-auto">
            <Input
              disabled
              multiline
              rows={8}
              // label={this.context.t(`['receipt/detail/id'].notes.customer`)}
              value={this.state.customer_note ?? null}
            />
          </Column>
        </Grid>
      </SectionBox>
    );
  };

  // Müşteri notlarının gösterildiği düzenlendiği alan.

  _CusNotesBuilder = () => {
    return (
      <SectionBox style={{ height: "auto" }}>
        <Grid className="no-gutters-row">
          <Column className="sx-12 sm-12 md-12 lg-12">
            <SectionHeading
              style={{
                backgroundColor: "rgba(140,15,180, 0.1)",
                color: "#8c0fb4",
                border: "1px solid #8c0fb4",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Receipt />
                <h3 style={{ marginLeft: "15px" }}>
                  {this.context.t(`['receipt/detail/id'].notes.receipt`)}
                </h3>
              </div>
            </SectionHeading>
          </Column>
          <Column className="xs-12 sm-auto">
            <Input
              // label={this.context.t(`['receipt/detail/id'].notes.receipt`)}
              multiline
              inputProps={{ maxLength: 255 }}
              rows={7}
              value={this.state.receipt_note ?? null}
              onChange={(e) =>
                this.setState({
                  receipt_note: e.target.value,
                  is_change: true,
                })
              }
              helperText={`Karakter limitiniz ${
                255 - parseInt(this.state.receipt_note.length)
              }`}
            />
          </Column>
        </Grid>

        {/* {this.state.appointment_note && (
          <Column className="xs-12 sm-auto">
            <Input
              disabled
              multiline
              rows={3}
              label={this.context.t(`['receipt/detail/id'].notes.appointment`)}
              value={this.state.appointment_note ?? null}
            />
          </Column>
        )} */}
      </SectionBox>
    );
  };

  // Otomayik Randevu oluşturmak için açılan dialog.

  _AutoAppointmentDialogBuilder = () => {
    return (
      <FullScreenDialog
        className="appointment-add-dialog"
        maxWidth="md"
        fullWidth
        receiptOk={true}
        open={this.state.checkout_data_dialog}
        onClose={() =>
          this.setState({
            checkout_data_dialog: false,
            availableAppointmentExpanded: false,
          })
        }
        title={this.context.t(`appointments.create.title`)}
        // buttons={[
        //   {
        //     title: this.context.t(`appointments.create.completeButtonTitle`),
        //     icon: "check",
        //     backgroundColor: "primary-opacity",
        //     textColor: "primary",
        //     disabledHandleClose: true,
        //     onClick: () => {
        //       setTimeout(() => {
        //         this.insertAppointment();
        //       }, 1000);
        //     },
        //   },
        //   {
        //     title: this.context.t(`appointments.create.discardButtonTitle`),
        //     icon: "close",
        //     textColor: "grey",
        //     onClick: () => {
        //       this.setState({ availableAppointmentExpanded: false });
        //     },
        //   },
        // ]}
      >
        <Grid style={{ marginTop: "20px" }}>
          <Column className="xs-6 sm-6">
            <Column className="xs-12 sm-12 md-auto">
              <div>
                <h5
                  style={{
                    background: "#eff6fe",
                    color: "#4293ed",
                    padding: "15px",
                    fontSize: "20px",
                  }}
                >
                  {moment(this.state.selected_date).format("DD MMMM dddd YYYY")}
                </h5>
              </div>
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <Input
                className="mb-1"
                readOnly
                label={this.context.t(
                  `appointments.create.selectedStaffInputLabel`
                )}
                value={
                  this.state.selected_staff
                    ? this.state.staffs.filter(
                        (staff) => staff.id === this.state.selected_staff
                      ).length
                      ? this.state.staffs.filter(
                          (staff) => staff.id === this.state.selected_staff
                        )[0].full_name
                      : "Silinmiş Hizmet"
                    : "Silinmiş Hizmet"
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <Input
                className="mb-1"
                readOnly
                label={this.context.t(
                  `appointments.create.selectedServiceInputLabel`
                )}
                value={
                  this.state.selected_service
                    ? this.state.services.filter(
                        (service) => service.id === this.state.selected_service
                      ).length
                      ? this.state.services.filter(
                          (service) =>
                            service.id === this.state.selected_service
                        )[0].name
                      : "Silinmiş Hizmet"
                    : "Silinmiş Hizmet"
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <Input
                className="mb-1"
                readOnly
                label={this.context.t(
                  `appointments.create.selectedCustomerInputLabel`
                )}
                value={this.state.selected_customer}
              />
            </Column>
          </Column>
          <Column className="xs-6 sm-6">
            <Column className="xs-12 sm-12 md-auto">
              <CSwitch
                label={this.context.t(`appointments.create.sendSmsInputLabel`)}
                checked={this.state.send_sms}
                color="primary"
                disabled={
                  this.state.selected_customer &&
                  this.state.selected_customer.send_sms === false
                }
                onChange={(checked) => {
                  this.setState({ send_sms: checked.target.checked });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <Input
                className="mt-2 mb-1"
                readOnly
                label={this.context.t(`appointments.create.description`)}
                value={this.state.selected_description}
              />
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <Input
                label={this.context.t(`appointments.create.noteInputLabel`)}
                multiline
                rows={1}
                onChange={(e) =>
                  this.setState({
                    note: e.target.value,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-auto">
              <Button
                icon="event"
                backgroundColor="primary"
                fullWidth
                textColor="white"
                title={this.context.t(
                  `['appointments/add'].availableHoursButtonTitle`
                )}
                onClick={() => {
                  this.getStaffUnavailableHours();
                  this.setState({
                    selected_time: null,
                  });
                }}
              />
            </Column>
          </Column>
        </Grid>

        {/* Time Select */}
        {this.state.availableAppointmentExpanded === true ? (
          <>
            <Grid className="p-2" style={{ width: "fit-content" }}>
              {BetweenTimeGenerator(this.context.state.company_shift).map(
                (item, index) => {
                  const time = item.split(":");
                  const { selected_date } = this.state;
                  const x = new Date(selected_date).setHours(
                    time[0],
                    time[1],
                    0,
                    0
                  );
                  return (
                    <div
                      key={index}
                      className="col xs-1 sm-1 md-1 lg-1 xl-1 mb-1 mx-3"
                      style={{
                        cursor: `${
                          this.state.hoursData.day_closed ||
                          this.state.hoursData.closed_hours.includes(item)
                            ? this.state.hoursData.trans_hours.includes(item)
                              ? "not-allowed"
                              : "pointer"
                            : "pointer"
                        }`,
                        margin: "5px 3px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "30px",
                        maxWidth: "120px",
                        borderRadius: "20px",
                        border: `${
                          this.state.hoursData.day_closed ||
                          this.state.hoursData.closed_hours.includes(item)
                            ? this.state.hoursData.trans_hours.includes(item)
                              ? "1px solid gray"
                              : "1px solid purple"
                            : this.state.hoursData.occupied_hours.includes(item)
                            ? this.state.hoursData.appointment_hours.includes(
                                item
                              )
                              ? "1px solid red"
                              : "1px solid purple"
                            : "1px solid green"
                        }`,
                        backgroundColor: `${
                          (this.state.hoursData.day_closed ||
                            this.state.hoursData.closed_hours.includes(item)) &&
                          this.state.selected_time === item
                            ? this.state.hoursData.trans_hours.includes(item)
                              ? "gray"
                              : "purple"
                            : this.state.hoursData.occupied_hours.includes(
                                item
                              ) && this.state.selected_time === item
                            ? this.state.hoursData.appointment_hours.includes(
                                item
                              )
                              ? "red"
                              : "purple"
                            : this.state.selected_time === item
                            ? "green"
                            : "white"
                        }`,
                        color: `${
                          this.state.selected_time === item
                            ? "white"
                            : this.state.hoursData.day_closed ||
                              this.state.hoursData.closed_hours.includes(item)
                            ? this.state.hoursData.trans_hours.includes(item)
                              ? "gray"
                              : "purple"
                            : this.state.hoursData.occupied_hours.includes(item)
                            ? this.state.hoursData.appointment_hours.includes(
                                item
                              )
                              ? "red"
                              : "purple"
                            : "green"
                        }`,
                      }}
                      onClick={() => {
                        if (item.split(":")[0] < 6) {
                          this.setState({
                            adj_selected_date: moment(
                              this.state.selected_date
                            ).add(1, "day"),
                          });
                        } else {
                          this.setState({
                            adj_selected_date: this.state.selected_date,
                          });
                        }
                        if (
                          !(
                            this.state.hoursData.day_closed ||
                            (this.state.hoursData.closed_hours.includes(item) &&
                              this.state.hoursData.trans_hours.includes(item))
                          )
                        ) {
                          this.setState({
                            selected_time: item,
                          });
                        }
                      }}
                    >
                      {item}
                    </div>
                  );
                }
              )}
            </Grid>
            <Grid>
              <MuiThemeProvider theme={theme}>
                <Tooltip
                  style={{ backgroundColor: "white", marginLeft: "30px" }}
                  title={
                    <Column
                      style={{ backgroundColor: "white" }}
                      className="xs-12 sm-12 p-2"
                    >
                      <Column className="xs-12 sm-12">
                        <ColorMean>
                          <div
                            className="color"
                            style={{ background: "green" }}
                          >
                            <div className="overlay" />
                          </div>
                          <span>
                            Randevu alımına açık boş saatler yeşil renk ile
                            gösterilir.
                          </span>
                        </ColorMean>
                      </Column>
                      <Column className="xs-12 sm-12 mt-2">
                        <ColorMean>
                          <div className="color" style={{ background: "red" }}>
                            <div className="overlay" />
                          </div>
                          <span>
                            Daha önce randevu oluşturulmuş ya da personel
                            detayından personel saatleri kapatılmış saatler
                            kırmızı renk ile gösterilir.
                          </span>
                        </ColorMean>
                      </Column>
                      <Column className="xs-12 sm-12 mt-2">
                        <ColorMean>
                          <div
                            className="color"
                            style={{ background: "orange" }}
                          >
                            <div className="overlay" />
                          </div>
                          <span>
                            Yetkili tarafından saat kapandığında turuncu renk
                            ile gösterilir
                          </span>
                        </ColorMean>
                      </Column>
                    </Column>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </MuiThemeProvider>
            </Grid>

            <Grid>
              {this.state.selected_time && (
                <Button
                  style={{ margin: "-20px auto 15px" }}
                  icon="check"
                  title={this.context.t(
                    `['appointments/add'].confirmButtonHoursTitle`
                  )}
                  backgroundColor="primary"
                  textColor="white"
                  onClick={() => {
                    this.setState({
                      availableAppointmentExpanded: false,
                    });
                    setTimeout(() => {
                      this.insertAppointment();
                    }, 500);
                  }}
                />
              )}
            </Grid>
          </>
        ) : (
          <></>
        )}
      </FullScreenDialog>
    );
  };

  // Paket özetinin gösterildiği alan.

  _PackageSummaryBuilder = () => {
    const { is_package } = this.state;

    return (
      is_package && (
        <SectionBox style={{ overflow: "hidden" }}>
          <SectionHeading
            style={{
              backgroundColor: "rgba(140,15,180, 0.1)",
              color: "#8c0fb4",
              border: "1px solid #8c0fb4",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <AllInbox />
              <h3 style={{ marginLeft: "15px" }}>
                {this.context.t(`['receipt/detail/id'].packageInner.title`)}
              </h3>
            </div>
          </SectionHeading>
          <div
            style={{
              marginBottom: "auto",
              overflow: "scroll",
            }}
            className="no-scroll-bar"
          >
            <PackageTable
              headings={{
                "service.name": {
                  label: this.context.t(
                    `['receipt/detail/id'].packageInner.headings.name`
                  ),
                },
                total: {
                  label: this.context.t(
                    `['receipt/detail/id'].packageInner.headings.count`
                  ),
                  rowStyleType: "primary",
                },
                completed: {
                  label: this.context.t(
                    `['receipt/detail/id'].packageInner.headings.completed`
                  ),
                  rowStyleType: "success",
                },
                pending: {
                  label: this.context.t(
                    `['receipt/detail/id'].packageInner.headings.pending`
                  ),
                  rowStyleType: "warn",
                },
              }}
              rows={this.packageSummaryGenerator()}
            />
          </div>
        </SectionBox>
      )
    );
  };

  // Servis satışını eklemek için açılan dialog.

  _ServiceSalesDialogBuilder = () => {
    const { service_transaction } = this.state;
    return (
      <AlertDialog
        containerStyle={{
          overflow: "visible",
        }}
        fullWidth
        maxWidth="sm"
        title={this.context.t(`['receipt/detail/id'].serviceTransaction.title`)}
        open={this.state.service_transaction_modal}
        closeHandler={() => this.setState({ service_transaction_modal: false })}
        buttons={[
          {
            title: this.context.t(
              `['receipt/detail/id'].serviceTransaction.addButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => this.addSingleServiceIntoCheckout(),
          },
          {
            title: this.context.t(
              `['receipt/detail/id'].serviceTransaction.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <StaffSelect
          disabled={this.context.state.user.permission === 0}
          label={this.context.t(
            `['receipt/detail/id'].serviceTransaction.staffInputLabel`
          )}
          items={this.state.staffs}
          selected={service_transaction.staff_id}
          labelKey="full_name"
          valueKey="id"
          returnFullObject
          handler={(staff) =>
            this.setState({
              // selected_staff_services: [
              //   ...staff.services.map((service) => service.service),
              // ],
              service_transaction: {
                ...service_transaction,
                staff_id: parseInt(staff.id),
              },
            })
          }
        />

        <AutocompleteInput
          label={this.context.t(
            `['receipt/detail/id'].serviceTransaction.serviceInputLabel`
          )}
          labelKey="name"
          showField="amount"
          style={{ zIndex: "9999" }}
          // opcStyle={{ marginTop: "-80px" }}
          valueKey="id"
          selected={
            this.state.selected_staff_services !== null
              ? this.state.selected_staff_services
              : ""
          }
          onRemove={() => {
            this.setState({ selected_staff_services: null });
            this.searchServicesByKey("");
          }}
          returnFullObject
          selectedHandler={(service) => {
            this.setState({
              service_transaction: {
                ...service_transaction,
                service_id: parseInt(service.id),
                amount: Math.round(service.amount),
                first_amount: Math.round(service.amount),
                process_date: moment().format("YYYY-MM-DD"),
                process_type: 2,
              },
              is_change: true,
            });
          }}
          asyncDataService={async (keyword) =>
            keyword.length > 0 ? this.searchServicesByKey(keyword) : []
          }
        />
      </AlertDialog>
    );
  };

  // Paket satışı eklemek için açılan dialog.

  _PackageSalesDialogBuilder = () => {
    const { packages, package_sale_modal, new_package, total_loader } =
      this.state;
    let typingTimeout = null;

    const fieldChanger = async (section, key, payload) => {
      const { new_package } = this.state;
      await this.setState({
        new_package: {
          ...new_package,
          [section]: {
            ...new_package[section],
            [key]: {
              ...new_package[section][key],
              ...payload,
            },
          },
        },
        is_change: true,
      });

      await this.setState({
        total: _total().amount,
      });
    };

    const _total = () => {
      const { new_package } = this.state;

      return {
        amount: Object.keys(new_package.services)
          .filter((key) => new_package.services[key] !== undefined)
          .map((key) =>
            new_package.services[key].amount !== null
              ? new_package.services[key].amount *
                new_package.services[key].period
              : 0
          )
          .reduce((a, b) => a + b)
          .toFixed(2),

        getRatioList: () => {
          const SingleRatio = {};
          Object.keys(new_package.services)
            .filter((key) => new_package.services[key] !== undefined)
            .map(
              (key) =>
                (SingleRatio[key] =
                  new_package.services[key].amount !== null
                    ? (new_package.services[key].amount *
                        new_package.services[key].period) /
                      _total().amount
                    : 0)
            );
          return SingleRatio;
        },
      };
    };

    const editTotal = () => {
      const { new_package, total } = this.state;

      // console.log(_total().getRatioList());

      if (total !== 0 || total !== null) {
        let services = { ...new_package.services };
        [...Object.keys(_total().getRatioList())].map(
          (key) =>
            (services = {
              ...services,
              [key]: {
                ...new_package.services[key],
                amount: parseFloat(
                  (
                    (total * _total().getRatioList()[key]) /
                    new_package.services[key].period
                  ).toFixed(2)
                ),
              },
            })
        );

        this.setState({
          total_loader: false,
          new_package: {
            ...new_package,
            services,
          },
        });
      }
    };

    const validatePackage = () => {
      const { new_package, selected_customer } = this.state;
      let undefined_service_count = 0;
      Object.keys(new_package.services).filter(
        (key) =>
          new_package.services[key] !== undefined &&
          new_package.services[key].service_id === null &&
          ++undefined_service_count
      );

      if (undefined_service_count > 0) {
        toast.warning(
          this.context.t(`['receipt/detail/id'].packageSale.servicePickToast`)
        );
        return false;
      }

      if (selected_customer === null) {
        toast.warning(
          this.context.t(`['receipt/detail/id'].packageSale.customerPickToast`)
        );
        return false;
      }

      return true;
    };

    return (
      <AlertDialog
        fullWidth
        maxWidth={this.state.__package ? "lg" : "sm"}
        title={this.context.t(`['receipt/detail/id'].packageSale.title`)}
        open={package_sale_modal}
        disableBackdropClick={false}
        closeHandler={() => this.setState({ package_sale_modal: false })}
      >
        <Select
          label={this.context.t(
            `['receipt/detail/id'].packageSale.packageNameInputLabel`
          )}
          items={[...packages]}
          selected={this.state.__package ? this.state.__package.id : null}
          labelKey="name"
          valueKey="id"
          returnFullObject
          handler={async (__package) => {
            flushSync(() => {
              this.setState({
                __package,
                new_package: {
                  services: {
                    ...Object.assign(
                      ...(__package.packet_details
                        ? __package.packet_details.map((item, index) => ({
                            [(index + 1) *
                            (Object.keys(new_package.services).length + 1)]: {
                              service_id: item.service.id,
                              staff_id: this.context.state.user.id,
                              packet_id: item.packet_id,
                              amount: item.amount,
                              period: item.period,
                              period_between: 7,
                              period_start: moment().format("YYYY-MM-DD"),
                              process_type: 1,
                              name: __package.name,
                            },
                          }))
                        : {}),
                      {}
                    ),
                  },
                },
              });
            });

            this.setState({
              total: _total().amount,
            });
          }}
        />
        {Object.keys(new_package.services).filter(
          (key) => new_package.services[key] !== undefined
        ).length > 0 ? (
          Object.keys(new_package.services)
            .filter((key) => new_package.services[key] !== undefined)
            .map((item, index) => (
              <Grid key={index} className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-2">
                  <Select
                    label={this.context.t(
                      `['receipt/detail/id'].packageSale.serviceInputLabel`
                    )}
                    items={this.state.staffs
                      .filter(
                        (staff) =>
                          staff.id === new_package.services[item].staff_id
                      )[0]
                      .services.map((service) => service.service)}
                    labelKey="name"
                    valueKey="id"
                    selected={
                      new_package.services[item].service_id
                        ? this.state.services.filter(
                            (ser) =>
                              ser.id === new_package.services[item].service_id
                          ).length
                          ? new_package.services[item].service_id
                          : "Silinmiş Hizmet"
                        : "Silinmiş Hizmet"
                    }
                    returnFullObject
                    handler={(service) =>
                      fieldChanger("services", item, {
                        service_id: parseInt(service.id),
                        amount: service.amount,
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                  <StaffSelect
                    label={this.context.t(
                      `['receipt/detail/id'].packageSale.staffInputLabel`
                    )}
                    items={[...this.state.staffs]}
                    labelKey="full_name"
                    valueKey="id"
                    selected={new_package.services[item].staff_id ?? null}
                    handler={(staff_id) =>
                      fieldChanger("services", item, {
                        staff_id: parseInt(staff_id),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                  <DatePickerInput
                    label={this.context.t(
                      `['receipt/detail/id'].packageSale.startInputLabel`
                    )}
                    error={false}
                    value={
                      new Date(moment(new_package.services[item].period_start))
                    }
                    onChange={(period_start) =>
                      fieldChanger("services", item, {
                        period_start: moment(period_start).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                  <Select
                    label={this.context.t(
                      `['receipt/detail/id'].packageSale.periodInputLabel`
                    )}
                    items={[...new Array(50).keys()].map((i) => ({
                      value: i + 1,
                    }))}
                    labelKey="value"
                    valueKey="value"
                    selected={new_package.services[item].period ?? null}
                    handler={(period) =>
                      fieldChanger("services", item, {
                        period: parseInt(period),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                  <Select
                    label={this.context.t(
                      `['receipt/detail/id'].packageSale.periodBetweenInputLabel`
                    )}
                    items={[...new Array(30).keys()].map((i) => ({
                      value: i + 1,
                      label: `${i + 1} Gün`,
                    }))}
                    labelKey="label"
                    valueKey="value"
                    selected={new_package.services[item].period_between ?? null}
                    handler={(period_between) =>
                      fieldChanger("services", item, {
                        period_between: parseInt(period_between),
                      })
                    }
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                  <MaskedInput
                    label={
                      this.context.t(
                        `['receipt/detail/id'].packageSale.periodAmountInputLabel`
                      ) +
                      (this.context.state.currency
                        ? " (" + this.context.state.currency + ")"
                        : "(₺)")
                    }
                    // mask="99,99"
                    maskChar=""
                    value={
                      new_package.services[item].amount
                        ? `${new_package.services[item].amount}`
                        : ""
                    }
                    onChange={(e) =>
                      fieldChanger("services", item, {
                        amount:
                          e.target.value && /[0-9,.]+/.test(e.target.value)
                            ? parseFloat(e.target.value)
                            : null,
                      })
                    }
                  />
                </Column>
                <Column>
                  <Button
                    icon="delete_forever"
                    iconSize={20}
                    backgroundColor="red-opacity"
                    textColor="red"
                    size="lg"
                    fullWidth
                    onClick={async () => {
                      delete new_package.services[item];
                      this.setState({
                        new_package: {
                          ...new_package,
                          services: {
                            ...new_package.services,
                          },
                        },
                        is_change: true,
                      });

                      this.setState({
                        total: _total().amount,
                      });
                    }}
                  />
                </Column>
              </Grid>
            ))
        ) : (
          <NoDataContainer style={{ height: "70%" }}>
            <img
              src={require("../../../assets/images/manypixels/click_action.svg")}
              alt="Malesef bulunamadı!"
            />
            <p>
              {this.context.t(`['receipt/detail/id'].packageSale.noDataText`)}
            </p>
          </NoDataContainer>
        )}
        {Object.keys(new_package.services).filter(
          (key) => new_package.services[key] !== undefined
        ).length > 0 && (
          <Grid className="no-gutters-row">
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
              <Input
                label={
                  this.context.t(
                    `['receipt/detail/id'].packageSale.totalAmount`
                  ) +
                  (this.context.state.currency
                    ? " (" + this.context.state.currency + ")"
                    : "(₺)")
                }
                // type="number"
                value={this.state.total}
                onChange={async (e) => {
                  this.setState({
                    total:
                      e.target.value && e.target.value !== "0"
                        ? parseFloat(e.target.value)
                        : 1,
                    total_loader: true,
                    is_change: true,
                  });
                }}
                InputProps={{
                  endAdornment: total_loader && (
                    <InputAdornment position="end" style={{ marginRight: 0 }}>
                      <img
                        src={Loader}
                        width="100"
                        height="100"
                        alt="loading"
                      />
                    </InputAdornment>
                  ),
                  onKeyUp: (e) => {
                    const value = e.target.value;
                    clearTimeout(typingTimeout);
                    typingTimeout =
                      value.length > 0 && setTimeout(() => editTotal(), 1250);
                  },
                }}
              />
            </Column>

            <Column>
              <div style={{ opacity: 0 }}>
                <Button
                  icon="delete_forever"
                  iconSize={20}
                  backgroundColor="red-opacity"
                  textColor="red"
                  size="lg"
                  fullWidth
                />
              </div>
            </Column>
          </Grid>
        )}
        {Object.keys(new_package.services).filter(
          (key) => new_package.services[key] !== undefined
        ).length > 0 && (
          <Button
            className="mt-2 mb-1"
            icon="local_mall"
            title={this.context.t(
              `['receipt/detail/id'].packageSale.completeButtonTitle`
            )}
            backgroundColor="teal-opacity"
            textColor="teal"
            disabled={this.state.save_button_disabled}
            size="md"
            fullWidth
            onClick={() => {
              const newPacket = [];
              const CleanArray = Object.keys(new_package.services).map(
                (key) => new_package.services[key]
              );
              const staffs = [];
              CleanArray.map((item) => {
                if (
                  staffs.includes(`${item?.staff_id}${item?.packet_id}`) ===
                  false
                ) {
                  staffs.push(`${item?.staff_id}${item?.packet_id}`);
                  newPacket.push(item);
                }
              });
              this.setState({
                save_button_disabled: true,
              });

              const fPacket = newPacket.map((item) => ({
                ...item,
                amount: `${CleanArray.filter(
                  (el) =>
                    el.staff_id === item.staff_id &&
                    el.packet_id === item.packet_id
                )
                  .map((el) => el.amount * el.period)
                  .reduce((a, b) => a + b, 0)}`,
              }));

              validatePackage() &&
                Axios.post(
                  `${this.context.api_endpoint}/company/packet/to/receipt`,
                  {
                    receipt_id: parseInt(this.props.match.params.id),
                    process_date:
                      this.state.new_package.services[1].period_start,
                    services: [],
                    packets: fPacket
                      .filter((item) => item !== undefined)
                      .map((data_packet) => {
                        return {
                          process_date: data_packet?.period_start,
                          staff_id: data_packet?.staff_id,
                          amount: parseInt(data_packet?.amount),
                          packet_id: data_packet?.packet_id,
                          note: data_packet?.note ?? "",
                          name: data_packet?.name,
                          services: [
                            ...Object.keys(new_package.services)
                              .filter(
                                (key) =>
                                  new_package.services[key] !== undefined &&
                                  new_package.services[key].staff_id ===
                                    data_packet?.staff_id &&
                                  new_package.services[key].packet_id ===
                                    data_packet?.packet_id
                              )
                              .map((item) =>
                                Object.assign(
                                  {
                                    service_id:
                                      new_package?.services[item]?.service_id,
                                    amount: new_package?.services[item]?.amount,
                                    period: new_package?.services[item]?.period,
                                    period_between:
                                      new_package?.services[item]
                                        ?.period_between,
                                    period_start:
                                      new_package?.services[item]?.period_start,
                                    process_type: 1,
                                  },
                                  {}
                                )
                              ),
                          ],
                        };
                      }),
                  }
                )
                  .then((response) => {
                    const {
                      data: { data },
                    } = response;
                    if (response.status === 201) {
                      this.getSingleReceipt();
                      this.setState({
                        save_button_disabled: false,
                        package_sale_modal: false,
                        new_package: {
                          services: {},
                        },
                        __package: null,
                      });
                      toast.success(
                        this.context.t(
                          `receipt/detail/id.packageSale.packageSaleSuccess`
                        )
                      );
                    }
                  })
                  .catch((e) => {
                    if (e.response.status === 401) {
                      toast.error(e.response.data.message);
                    }
                  });
            }}
          />
        )}
      </AlertDialog>
    );
  };

  /**
   * Ürün satışını eklemek için açılan dialog.
   */
  _ProductSalesDialogBuilder = () => {
    const { product_transaction, selected_stock } = this.state;

    return (
      <AlertDialog
        containerStyle={{
          overflow: "visible",
        }}
        fullWidth
        maxWidth="sm"
        title={this.context.t(`['receipt/detail/id'].stockSale.title`)}
        open={this.state.product_transaction_modal}
        closeHandler={() => this.setState({ product_transaction_modal: false })}
        buttons={[
          {
            title: this.context.t(
              `['receipt/detail/id'].stockSale.addButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              if (selected_stock.limit !== 0) {
                this.addSingleStockSaleIntoCheckout();
              }
              if (selected_stock.limit === 0) {
                toast.error("Seçtiğiniz ürünün stoğu bulunmamaktadır.");
              }
              this.setState({
                openInput: false,
                barcodeValue: "",
                stock_item_value: null,
              });
            },
          },

          {
            title: this.context.t(
              `['receipt/detail/id'].stockSale.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
            marginLeft: this.state.openInput ? "50px" : "0px",
            onClick: () => {
              this.setState({
                openInput: false,
                barcodeValue: "",
                stock_item_value: null,
              });
            },
          },
        ]}
      >
        <StaffSelect
          disabled={this.context.state.user.permission === 0}
          label={this.context.t(
            `['receipt/detail/id'].stockSale.staffInputLabel`
          )}
          items={this.state.staffs}
          selected={
            product_transaction.staff_id !== null
              ? product_transaction.staff_id
              : null
          }
          labelKey="full_name"
          valueKey="id"
          returnFullObject
          handler={(staff) =>
            this.setState({
              product_transaction: {
                ...product_transaction,
                staff_id: parseInt(staff.id),
              },
            })
          }
        />

        <AutocompleteInput
          label={this.context.t(
            `['receipt/detail/id'].stockSale.stockInputLabel`
          )}
          showField="amount"
          extraLimit="limit"
          labelKey="name"
          opcStyle={{ maxHeight: "30vh" }}
          valueKey="id"
          selected={
            this.state.stock_items !== null ? this.state.stock_items : ""
          }
          value={
            this.state.stock_item_value !== null
              ? this.state.stock_item_value
              : null
          }
          onRemove={() => {
            // this.setState({ stock_items: null });
            this.searchStockByKey("");
          }}
          returnFullObject
          selectedHandler={(stock_item) => {
            this.setState({
              product_transaction: {
                ...product_transaction,
                stock_item_id: parseInt(stock_item.id),
                amount: stock_item.amount,
              },
              selected_stock: stock_item,
            });
          }}
          asyncDataService={async (keyword) =>
            keyword.length > 0 ? this.searchStockByKey(keyword) : []
          }
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {!this.state.openInput ? (
            <button
              style={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                border: "none",
                background: "rgb(49, 137, 236)",
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background 0.3s, color 0.3s",
                disabled: true,
              }}
              onClick={() => {
                if (this.context.state?.balance?.data?.license?.barcode) {
                  this.setState({
                    openInput: true,
                  });
                } else {
                  toast.warn(
                    this.context.t(
                      `['receipt/detail/id'].stockSales.barcodeCheckAlert`
                    )
                  );
                }
              }}
              onMouseEnter={(e) => {
                e.target.style.opacity = "0.7";
              }}
              onMouseLeave={(e) => {
                e.target.style.opacity = "1";
              }}
            >
              {this.context.t(
                `['receipt/detail/id'].stockSales.selectWithBarcode`
              )}
              <AiOutlineBarcode
                color="black"
                size={"30px"}
                style={{ marginLeft: "15px" }}
              />
            </button>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  style={{
                    width: "300px",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    outline: "none",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "border-color 0.3s",
                    flex: 1,
                  }}
                  placeholder="Barkod kodunu giriniz."
                  type="text"
                  value={this.state.barcodeValue ? this.state.barcodeValue : ""}
                  onChange={(event) =>
                    this.setState({
                      barcodeValue: event.target.value,
                    })
                  }
                />
                <button
                  style={{
                    padding: "10px 20px",
                    borderRadius: "5px",

                    background: [
                      this.state.barcodeValue === "" ? "#8db7e7 " : "#3189ec ",
                    ],
                    border: "none",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.state.barcodeValue
                      ? this.stockItemHandler()
                      : toast.warn(
                          this.context.t(
                            `['receipt/detail/id'].barcodeAlert.pleaseEnterACode`
                          )
                        );
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.opacity =
                      this.state.barcodeValue !== "" ? "0.8" : null;
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.opacity =
                      this.state.barcodeValue !== "" ? "1" : null;
                  }}
                >
                  {this.context.t(
                    `['receipt/detail/id'].stockSales.selectStock`
                  )}
                </button>
              </div>
              <span
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={() => this.setState({ openInput: false })}
              >
                &#10006;
              </span>
            </div>
          )}
        </div>

        {/* <Select
          label={this.context.t(
            `['receipt/detail/id'].stockSale.stockInputLabel`
          )}
          items={this.state.stock_items}
          selected={product_transaction.stock_item_id}
          labelKey="name"
          valueKey="id"
          returnFullObject
          handler={(stock_item) =>
            this.setState({
              product_transaction: {
                ...product_transaction,
                stock_item_id: parseInt(stock_item.id),
                amount: stock_item.amount,
              },
            })
          }
        /> */}
      </AlertDialog>
    );
  };

  /**
   * Borç Bilgilendirme / Tahsilat mesajı Göndermek için açılan dialog.
   */
  _DebtInformationDialogBuilder = () => {
    const { message } = this.state;
    return (
      <AlertDialog
        fullWidth
        maxWidth="sm"
        title={this.context.t(`['receipt/detail/id'].sms.smsButtonTitle`)}
        open={this.state.add_sms_dialog}
        closeHandler={() => this.setState({ add_sms_dialog: false })}
        buttons={[
          {
            title: this.context.t(`['receipt/detail/id'].sms.sendButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              this.setState({
                message: message,
                alert_message_dialog: true,
              });
            },
          },
          {
            title: this.context.t(
              `['receipt/detail/id'].sms.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              this.setState({
                message: "",
              });
            },
          },
        ]}
      >
        <Column className="xs-12 sm-12 md-12 lg-8">
          <Input
            label={this.context.t(`sms.send.messageInputLabel`)}
            multiline={true}
            rows={10}
            value={message}
            onChange={(e) =>
              this.setState({ message: e.target.value, is_change: true })
            }
            helperText={CalculateMessageCharCount(message, "1")}
          />
          <Button
            title={this.context.t(`sms.send.autoText.nameSurname`)}
            size="xs"
            textColor="primary"
            outlined
            onClick={() =>
              this.setState({
                message: `${message}|ad soyad|`,
                is_change: true,
              })
            }
          />
          <Button
            className="ml-1"
            title={this.context.t(`sms.send.autoText.amountPaid`)}
            size="xs"
            textColor="primary"
            outlined
            onClick={() =>
              this.setState({
                message: `${message}|ödenen tutar|`,
                is_change: true,
              })
            }
          />
          <Button
            className="ml-1"
            title={this.context.t(`sms.send.autoText.remainingDebt`)}
            size="xs"
            textColor="primary"
            outlined
            onClick={() =>
              this.setState({
                message: `${message}|kalan borc|`,
                is_change: true,
              })
            }
          />
          <Button
            className="ml-1"
            title={this.context.t(`sms.send.autoText.businessUrl`)}
            size="xs"
            textColor="primary"
            outlined
            onClick={() =>
              this.setState({
                message: `${message}|işletme url|`,
                is_change: true,
              })
            }
          />
        </Column>
      </AlertDialog>
    );
  };

  /**
   * İndirim uygulamak için açılan dialog.
   */
  _DiscountDialogBuilder = () => {
    return (
      <AlertDialog
        fullWidth
        maxWidth="sm"
        title={this.context.t(`['receipt/detail/id'].applyDiscountButtonTitle`)}
        open={this.state.discount_modal}
        closeHandler={() => this.setState({ discount_modal: false })}
        buttons={[
          {
            title: this.context.t(
              `['receipt/detail/id'].applyDiscountButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () =>
              this.applyDiscount({
                type: this.state.discount_type,
                value: this.state.discount_value,
              }),
          },
          {
            title: this.context.t(
              `['receipt/detail/id'].applyDiscount.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Select
          label={this.context.t(
            `['receipt/detail/id'].applyDiscount.typeInputLabel`
          )}
          items={[
            {
              label:
                (this.context.state.currency
                  ? this.context.state.currency === "₺"
                    ? "TL"
                    : this.context.state.currency
                  : "TL") +
                this.context.t(`['receipt/detail/id'].applyDiscount.typeCASH`),
              value: "TL",
            },
            {
              label: this.context.t(
                `['receipt/detail/id'].applyDiscount.typePERCENT`
              ),
              value: "%",
            },
          ]}
          labelKey="label"
          valueKey="value"
          selected={this.state.discount_type}
          handler={(discount_type) =>
            this.setState({ discount_type, is_change: true })
          }
        />

        <Input
          label={`${this.context.t(
            `['receipt/detail/id'].applyDiscount.amountInputLabel`
          )} (${
            this.context.state.currency
              ? this.context.state.currency
              : this.state.discount_type
          })`}
          type="number"
          value={this.state.discount_value}
          onChange={(e) =>
            this.setState({
              discount_value: /[0-9,.]+/.test(e.target.value)
                ? Math.abs(e.target.value)
                : 0,
              is_change: true,
            })
          }
        />
      </AlertDialog>
    );
  };

  /**
   * Adisyonu silmek için açılan onay dialog'u.
   */
  _ReceiptDeleteDialogBuilder = () => {
    return (
      <AlertDialog
        open={this.state.delete_confirm}
        closeHandler={() => this.setState({ delete_confirm: false })}
        title={this.context.t(`['receipt/detail/id'].delete.title`)}
        buttons={[
          {
            title: this.context.t(
              `['receipt/detail/id'].delete.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => this.deleteSingleReceipt(this.state.delete_id),
          },
          {
            title: this.context.t(
              `['receipt/detail/id'].delete.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          {this.context.t(`['receipt/detail/id'].delete.alertText`)}
        </Alert>
      </AlertDialog>
    );
  };

  /**
   * açılan sözleşme pop-up içeriğinin düzenlendiği alan
   * tab:0
   */
  _ContractDialogEntrance = () => {
    return (
      <Grid>
        <Column className="sx-12 sm-12 md-12 lg-12 mb-1">
          <div
            style={{
              background: "rgb(49, 169, 243, 0.14)",
              borderRadius: "4px",
              display: "flex",
              padding: "10px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "1px solid rgb(91, 187, 245, 0.41)",
            }}
            onClick={() => {
              this.setState({
                contract_tab: 1,
              });
            }}
          >
            <div
              style={{
                padding: "4px 10px 0",
                color: "#31A9F3",
              }}
            >
              <AddIcon />
            </div>
            <div style={{ color: "#31A9F3" }}>Yeni Sözleşme Oluştur</div>
          </div>
        </Column>

        <Column className="sx-12 sm-12 md-12 lg-12 mb-1">
          <div
            style={{
              background: "rgb(29, 139, 62, 0.21)",
              borderRadius: "4px",
              display: "flex",
              padding: "10px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "1px solid rgb(58, 154, 87, 0.35)",
            }}
            onClick={() => {
              this.setState({
                contract_tab: 2,
              });
            }}
          >
            <div style={{ padding: "4px 10px 0", color: "#1D8B3E" }}>
              <BackupIcon />
            </div>
            <div style={{ color: "#1D8B3E" }}>Sözleşme Yükle</div>
          </div>
        </Column>

        <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
          <div
            style={{
              background: "rgb(254, 151, 151, 0.19)",
              borderRadius: "4px",
              display: "flex",
              padding: "10px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "1px solid rgb(185, 178, 178, 0.65)",
            }}
            onClick={() => {
              this.getCustomerAgreements(
                1,
                this.state.checkout_data.receipt_id
              );
              this.setState({
                contract_tab: 3,
              });
            }}
          >
            <div
              style={{
                padding: "4px 10px 0",
                color: "#FC8E8E",
              }}
            >
              <ListAltIcon />
            </div>
            <div style={{ color: "#FC8E8E" }}>Kayıtlı Sözleşmeler</div>
          </div>
        </Column>
      </Grid>
    );
  };

  /**
   * açılan sözleşme pop-up içeriğinin düzenlendiği alan
   * tab:1
   */
  _ContractDialogCreateNewContract = () => {
    const {
      contract_patterns,
      selected_pattern,
      selected_agreement_dialog_show,
      new_agreement_detail,
      contract_info_ful_name,
      contract_info_phone,
      contract_info_id_number,
      contract_info_tax_number,
      contract_info_address,
      contract_patterns_wo_clauses,
    } = this.state;

    const inputRefPDF = React.useRef();

    return (
      <>
        <h1 style={{ textAlign: "center" }}>Yeni Sözleşme Oluştur</h1>
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            <Select
              label={"Sözleşme şablonu seçiniz"}
              items={[...contract_patterns_wo_clauses]}
              valueKey="id"
              labelKey="template_name"
              handler={(id) => {
                this.setState({
                  selected_pattern: contract_patterns.filter(
                    (pat) => pat.Template.id == id
                  )[0],
                  new_agreement_detail: true,
                });
              }}
              selected={selected_pattern.Template?.id}
            />
          </Column>
          {!new_agreement_detail ? (
            <></>
          ) : (
            <Grid className="mb-4">
              <Column
                className="sx-12 sm-12 md-12 lg-12 mb-3"
                style={{ textAlign: "center" }}
              >
                Kişisel Bilgiler
              </Column>
              {/* Ad Soyad */}
              <Column className="sx-12 sm-12 md-12 lg-6">
                <Input
                  label="Ad Soyad"
                  fullWidth
                  value={contract_info_ful_name}
                  onChange={(e) => {
                    this.setState({
                      contract_info_ful_name: e.target.value,
                    });
                  }}
                />
              </Column>
              {/* Telefon numarası */}
              {selected_pattern.Template.phone && (
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <MaskedInput
                    mask="999 999 9999"
                    maskChar=""
                    label="Telefon Numarası"
                    fullWidth
                    value={contract_info_phone}
                    onChange={(e) => {
                      this.setState({
                        contract_info_phone: e.target.value,
                      });
                    }}
                  />
                </Column>
              )}
              {/* TC no */}
              {selected_pattern.Template.nationality_id && (
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <MaskedInput
                    label="T.C. Kimlik Numarası"
                    fullWidth
                    mask="99999999999"
                    maskChar=""
                    value={contract_info_id_number}
                    onChange={(e) => {
                      this.setState({
                        contract_info_id_number: e.target.value,
                      });
                    }}
                  />
                </Column>
              )}
              {/* Vergi No */}
              {selected_pattern.Template.tax_no && (
                <Column className="sx-12 sm-12 md-12 lg-6">
                  <Input
                    label="Vergi No."
                    type="number"
                    fullWidth
                    value={contract_info_tax_number}
                    onChange={(e) => {
                      this.setState({
                        contract_info_tax_number: e.target.value,
                      });
                    }}
                  />
                </Column>
              )}
              {/* Adress */}
              {selected_pattern.Template.address && (
                <Column className="sx-12 sm-12 md-12 lg-12">
                  <Input
                    label="Adres"
                    fullWidth
                    value={contract_info_address}
                    multiline={true}
                    onChange={(e) => {
                      this.setState({
                        contract_info_address: e.target.value,
                      });
                    }}
                  />
                </Column>
              )}
            </Grid>
          )}
        </Grid>

        <AlertDialog
          title={""}
          open={selected_agreement_dialog_show}
          fullWidth
          maxWidth="md"
          closeHandler={() =>
            this.setState({ selected_agreement_dialog_show: false })
          }
          disableBackdropClick={false}
        >
          <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
            <div
              ref={inputRefPDF}
              style={{
                margin: "0 20px 0 20px",
              }}
            >
              <Column className="sx-12 sm-12 md-12 lg-12">
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    fontFamily: "roboto",
                    fontSize: "0.8em",
                  }}
                >
                  {/* Sözleşme başlığı */}

                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        marginLeft: "4.5vw",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "80%",
                          height: "20vh",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{ objectFit: "cover", width: "100%" }}
                          src={`${this.context.api_endpoint}/${this.context.state.company_id}/${this.state.logo_src}`}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h1>{selected_pattern.Template?.company_name}</h1>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <h2>{selected_pattern.Template?.template_name}</h2>
                      </div>
                      <div
                        style={{
                          textAlign: "end",
                          width: "60%",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1.4em",
                          }}
                        >
                          {moment().format("DD/MM/YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Sözleşmede kişisel bilgilerin olduğu bölüm */}

                  <div
                    style={{
                      border: "1px solid black",
                      width: "80%",
                      borderRadius: "3px",
                      padding: "5px 10px",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* Ad soyad */}
                    <div
                      style={{
                        width: "45%",
                        fontWeight: "bold",
                      }}
                    >
                      {this.context.t(
                        `[settings/agreements].personal_info.name_and_surname`
                      )}
                      :{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {contract_info_ful_name}
                      </span>
                    </div>
                    {/* Telefon Numarası */}
                    {selected_pattern.Template?.phone ? (
                      <div
                        style={{
                          width: "45%",
                          fontWeight: "bold",
                        }}
                      >
                        {this.context.t(
                          `[settings/agreements].personal_info.phone`
                        )}
                        :{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {contract_info_phone}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* tc No */}
                    {selected_pattern.Template?.nationality_id ? (
                      <div
                        style={{
                          width: "45%",
                          fontWeight: "bold",
                        }}
                      >
                        {this.context.t(
                          `[settings/agreements].personal_info.id_number`
                        )}
                        :
                        <span style={{ fontWeight: "normal" }}>
                          {contract_info_id_number}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* vergi no */}
                    {selected_pattern.Template?.tax_no ? (
                      <div
                        style={{
                          width: "45%",
                          fontWeight: "bold",
                        }}
                      >
                        {this.context.t(
                          `[settings/agreements].personal_info.tax_number`
                        )}
                        :{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {contract_info_tax_number}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* adres */}
                    {selected_pattern.Template?.address ? (
                      <div
                        style={{
                          width: "90%",
                          fontWeight: "bold",
                        }}
                      >
                        {this.context.t(
                          `[settings/agreements].personal_info.address`
                        )}
                        :{" "}
                        <span
                          style={{
                            fontWeight: "normal",
                            overflowWrap: "break-word",
                          }}
                        >
                          {contract_info_address}
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* Sözleşmede ödeme bilgilerinin olduğu bölüm */}

                  {selected_pattern.Template?.services_employee_name ||
                  selected_pattern.Template?.show_service ? (
                    <div
                      style={{
                        border: "1px solid black",
                        width: "80%",
                        borderRadius: "3px",
                        margin: "10px 0 0 0",
                        padding: "5px 10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {selected_pattern.Template.services_employee_name ? (
                          <div style={{ width: "45%", fontWeight: "bold" }}>
                            Personel Adı
                          </div>
                        ) : (
                          <></>
                        )}
                        {selected_pattern.Template.show_service ? (
                          <div style={{ width: "45%", fontWeight: "bold" }}>
                            Hizmet
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div style={{ display: "flex", marginTop: "10px" }}>
                        {selected_pattern.Template.services_employee_name ? (
                          <div
                            style={{
                              width: "45%",
                              marginTop: "5px",
                            }}
                          >
                            {/* Çalışan listesi */}
                            {this.state.staffArr.map((item) => (
                              <div>{item}</div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}

                        {selected_pattern.Template.show_service ? (
                          <div style={{ width: "45%", marginTop: "5px" }}>
                            {/* Hizmet Listesi hizmetleri */}
                            {this.state.servicesArr.map((item) => (
                              <div>{item}</div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {selected_pattern.Template?.show_amount ? (
                    <div
                      style={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        margin: "10px 70px 10px auto",
                      }}
                    >
                      Net Toplam:{" "}
                      {parseFloat(this.getAllTotalService()).toFixed(2)}
                      {this.context.state.currency
                        ? this.context.state.currency
                        : "₺"}
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* Sözleşmenin maddelerinin içerdiği bölüm */}

                  {this.state.clauses
                    .filter((clauseInfo) =>
                      selected_pattern.Clauses.includes(clauseInfo.id)
                    )
                    .filter((el) => el.type === 2).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "85%",
                        borderBottom: "1px solid #b3b3b3",
                      }}
                    >
                      <span style={{ paddingRight: "20px", fontSize: "16px" }}>
                        Evet
                      </span>
                      <span style={{ fontSize: "16px" }}>Hayır</span>
                    </div>
                  )}

                  {selected_pattern.Clauses &&
                    selected_pattern.Clauses.map((el, index) => {
                      return (
                        <>
                          {this.state.clauses.filter(
                            (clauseInfo) =>
                              clauseInfo.id === el && clauseInfo.type === 2
                          ).length > 0 ? (
                            <div
                              style={{
                                width: "85%",
                                padding: "5px 0px",
                                display: "flex",
                                flexWrap: "wrap",
                                textAlign: "start",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  id="content-to-send"
                                  style={{ width: "85%" }}
                                >
                                  {this.state.clauses
                                    .filter(
                                      (clauseInfo) => clauseInfo.id === el
                                    )[0]
                                    .content.split("\n")
                                    .map((str) => (
                                      <p contentEditable="true">{str}</p>
                                    ))}
                                </span>
                                <div
                                  style={{
                                    height: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginRight: "4px",
                                    width: "12%",
                                  }}
                                  id="checkbox-outliner"
                                >
                                  <input
                                    className="agr-checkbox"
                                    type="checkbox"
                                    name={el}
                                    id={el}
                                    onClick={() => {
                                      if (
                                        document.getElementById(`${el}`).checked
                                      ) {
                                        document.getElementById(
                                          `${el}${index}`
                                        ).checked = false;
                                      } else {
                                        document.getElementById(
                                          `${el}${index}`
                                        ).checked = true;
                                      }
                                    }}
                                  />
                                  <input
                                    className="agr-checkbox"
                                    type="checkbox"
                                    name={`${el}${index}`}
                                    id={`${el}${index}`}
                                    onClick={() => {
                                      // document.querySelector(
                                      //   `#${el}${index}`
                                      // );
                                      if (
                                        document.getElementById(`${el}${index}`)
                                          .checked
                                      ) {
                                        document.getElementById(
                                          `${el}`
                                        ).checked = false;
                                      } else {
                                        document.getElementById(
                                          `${el}`
                                        ).checked = true;
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}

                  {this.state.clauses
                    .filter((clauseInfo) =>
                      selected_pattern.Clauses.includes(clauseInfo.id)
                    )
                    .filter((el) => el.type === 1).length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "85%",
                        borderBottom: "1px solid #b3b3b3",
                      }}
                    ></div>
                  )}

                  {selected_pattern.Clauses &&
                    selected_pattern.Clauses.map((el, index) => {
                      return (
                        <>
                          {this.state.clauses.filter(
                            (clauseInfo) =>
                              clauseInfo.id === el && clauseInfo.type !== 2
                          ).length > 0 ? (
                            <div
                              style={{
                                width: "85%",
                                padding: "5px 0px",
                                display: "flex",
                                flexWrap: "wrap",
                                textAlign: "start",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span id="content-to-send">
                                  {this.state.clauses
                                    .filter(
                                      (clauseInfo) => clauseInfo.id === el
                                    )[0]
                                    .content.split("\n")
                                    .map((str) => (
                                      <p contentEditable="true">{str}</p>
                                    ))}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}

                  {/* Footer */}
                  <div
                    style={{
                      width: "85%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      padding: "20px 0",
                    }}
                  >
                    <div
                      style={{
                        width: "70%",
                      }}
                    >
                      <div>{selected_pattern.Template?.company_name}</div>
                      <span>{selected_pattern.Template?.company_info}</span>
                    </div>
                    <div>
                      <div>{contract_info_ful_name}</div>
                    </div>
                  </div>
                </div>
              </Column>
            </div>
          </Column>

          <Column
            className="xs-12 sm-12 md-12 lg-12 mt-3"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ width: "25%" }}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    title="Yazdır"
                    fullWidth
                    textColor="white"
                    backgroundColor="green"
                  />
                )}
                content={() => inputRefPDF.current}
              />
            </div>
            <div
              style={{
                width: "25%",
                display: "flex",
              }}
            >
              <Button
                icon={this.Signature()}
                backgroundColor="primary-opacity"
                title="İmzaya Gönder"
                outlined
                fullWidth
                textColor="primary"
                onClick={async () => {
                  await this.setState({
                    content_to_signiture: {
                      ...this.state.content_to_signiture,
                      receipt_id: parseInt(this.state.checkout_data.receipt_id),
                      title: selected_pattern.Template?.template_name,
                      name: contract_info_ful_name,
                      company_name: selected_pattern.Template?.company_name,
                      company_info: selected_pattern.Template?.company_info,
                      address: selected_pattern.Template?.address
                        ? contract_info_address
                        : null,
                      nationality_id: selected_pattern.Template?.nationality_id
                        ? contract_info_id_number
                        : null,
                      tax_no: selected_pattern.Template?.tax_no
                        ? contract_info_tax_number
                        : null,
                      phone: selected_pattern.Template?.phone
                        ? contract_info_phone
                        : null,
                      date: moment().format("DD/MM/YYYY"),
                      show_service: selected_pattern.Template?.show_service,
                      show_amount: selected_pattern.Template?.show_amount,
                      show_staff:
                        selected_pattern.Template?.services_employee_name,
                      clauses: [
                        ...[
                          ...inputRefPDF.current.querySelectorAll(
                            "#content-to-send"
                          ),
                        ]
                          .map((item) => item.innerText)
                          .filter(
                            (item, index) =>
                              index >=
                              [
                                ...inputRefPDF.current.querySelectorAll(
                                  "#checkbox-outliner"
                                ),
                              ].length
                          )
                          .map((item) => {
                            return {
                              content: item,
                              type: 1,
                              confirm: false,
                            };
                          }),
                        ...[
                          ...inputRefPDF.current.querySelectorAll(
                            "#checkbox-outliner"
                          ),
                        ]
                          .map((item) => item.children)
                          .map((item) => item[0])
                          .map((item) => item.checked)
                          .map((item, index) => {
                            return {
                              content: [
                                ...inputRefPDF.current.querySelectorAll(
                                  "#content-to-send"
                                ),
                              ].map((item) => item.innerText)[index],
                              type: 2,
                              confirm: item,
                            };
                          }),
                      ],
                    },

                    send_to_signiture_aproval_modal: true,
                  });
                }}
              />
            </div>
            <div style={{ width: "25%" }}>
              <Button
                icon="clear"
                title="Kapat"
                outlined
                fullWidth
                textColor="#ccc"
                onClick={async () => {
                  this.setState({ selected_agreement_dialog_show: false });
                }}
              />
            </div>
          </Column>
        </AlertDialog>

        <AlertDialog
          title="Düzenlediğiniz sözleşmeyi mobil imza için göndermeyi onaylıyor musunuz?"
          open={this.state.send_to_signiture_aproval_modal}
          fullWidth
          maxWidth="sm"
          closeHandler={() =>
            this.setState({ send_to_signiture_aproval_modal: false })
          }
          disableBackdropClick={true}
          buttons={[
            {
              title: "Onayla",
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.sendAgreementToMobile();
              },
            },
            {
              title: "Vazgeç",
              icon: "close",
              textColor: "#ccc",
              outlined: true,
              onClick: async () => {
                await this.setState({
                  send_to_signiture_aproval_modal: false,
                });
              },
            },
          ]}
        >
          <span>
            Gönderilen sözleşmeyi mobil uygulamanızdan Listeler Sözleşme Listesi
            kısmından görüntüleyebilir, düzenleyebilir ve imzalayabilirsiniz.
          </span>
        </AlertDialog>
      </>
    );
  };

  /**
   * açılan sözleşme pop-up içeriğinin düzenlendiği alan
   * tab:2
   */
  _ContractDialogUploadContract = () => {
    const { file, agreement_customer_for_receipt, agreement_title } =
      this.state;
    return (
      <>
        <h1 style={{ textAlign: "center" }}>Sözleşme Yükle</h1>
        <Grid>
          <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
            <input
              id="PDFUploadInput"
              type="file"
              accept="application/pdf"
              style={{ display: "none" }}
              onChange={(e) =>
                this.setState({ file: e, pdf_viewer_dialog: true })
              }
            />
            <ImageUploadBox htmlFor="PDFUploadInput">
              <i className="material-icons">cloud_upload</i> Bu adisyona ait
              sözleşme yüklemek için tıklayınız.
            </ImageUploadBox>
          </Column>

          {file && (
            <Column
              className="sx-12 sm-12 md-12 lg-12 mb-2"
              style={{ borderTop: "1px solid #ccc", paddingTop: "2vh" }}
            >
              <Column className="sx-12 sm-12 md-12 lg-4">
                <Input
                  label="Ad Soyad"
                  fullWidth
                  value={agreement_customer_for_receipt}
                  onChange={(e) => {
                    this.setState({
                      agreement_customer_for_receipt: e.target.value,
                    });
                  }}
                />
              </Column>
              <Column className="sx-12 sm-12 md-12 lg-4">
                <TextField
                  label="Sözleşme Adı"
                  variant="outlined"
                  fullWidth
                  value={agreement_title}
                  inputProps={{ maxLength: 45 }}
                  onChange={(e) => {
                    this.setState({
                      agreement_title: e.target.value,
                    });
                  }}
                />
              </Column>
              <Column className="sx-12 sm-12 md-12 lg-4">
                <Button
                  size="lg"
                  title="Kaydet"
                  fullWidth
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  onClick={() => {
                    if (!agreement_title) {
                      toast.warning(
                        "Lütfen eksik alanları doldurunuz: Sözleşme Adı"
                      );
                    } else {
                      this.uploadAgreement(file);
                    }
                  }}
                />
              </Column>
            </Column>
          )}
        </Grid>
      </>
    );
  };

  /**
   * açılan sözleşme pop-up içeriğinin düzenlendiği alan
   * tab:3
   */
  _ContractDialogContracts = () => {
    return (
      <>
        <h1 style={{ textAlign: "center" }}>Kayıtlı Sözleşmeler</h1>
        <Grid>
          <Column className="sx-12 sm-12 md-12 lg-12 mb-5">
            <Table
              isExist={false}
              headings={{
                date: {
                  label: "Tarih",
                },
                name: {
                  label: "Ad Soyad",
                },
                agreement_name: {
                  label: "Sözleşme Adı",
                },
                bytes: {
                  label: "Boyutu",
                  suffix: "mb",
                },
                _: {
                  label: "İşlemler",
                },
              }}
              rows={this.state.customer_agreements}
              loaded={this.state.agreement_load}
              pagination={this.state.pagination_agreement}
              buttons={[
                {
                  title: "",
                  icon: "find_in_page",
                  textColor: "blue",
                  onClick: (row) => window.open(row.url, "_blank"),
                },
                {
                  title: "",
                  icon: <WhatsApp fontSize="small" />,
                  textColor: "green",
                  onClick: (row) => {
                    if (!this.context.state.whatsapp_permission) {
                      toast.warning(
                        "Ücretli içerik. Lütfen müşteri temsilciniz ile iletişime geçiniz."
                      );
                    } else {
                      this.setState({
                        wp_aproval_modal: true,
                        send_wp_id: row.id,
                        send_wp_customer_name: row.name,
                        send_wp_agreement_name: row.agreement_name,
                      });
                    }
                  },
                },
                {
                  title: "",
                  icon: "sms",
                  textColor: "orange",
                  onClick: (row) => {
                    this.setState({
                      sms_aproval_modal: true,
                      send_sms_id: row.id,
                      send_sms_customer_name: row.name,
                      send_sms_agreement_name: row.agreement_name,
                    });
                  },
                },
                {
                  title: "",
                  icon: "delete_forever",
                  textColor: "red",
                  onClick: (row) => {
                    this.setState({
                      agreement_delete_id: row.id,
                      delete_agreement_dialog: true,
                    });
                  },
                },
              ]}
            />
          </Column>

          {/* **
        sms onayı için açılan dialog
        */}
          <AlertDialog
            title="Kayıtlı sözleşmeyi SMS ile iletmek ister misiniz?"
            open={this.state.sms_aproval_modal}
            closeHandler={() => this.setState({ sms_aproval_modal: false })}
            buttons={[
              {
                title: "Gönder",
                icon: "send",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  this.sendSMSAgreement(this.state.send_sms_id);
                },
              },
              {
                title: "İptal",
                icon: "clear",
                textColor: "grey",
                onClick: () => {
                  this.setState({
                    send_sms_id: null,
                    send_sms_customer_name: null,
                    send_sms_agreement_name: null,
                  });
                },
              },
            ]}
          >
            {this.state.send_sms_customer_name} isimli müşteriye SMS ile '
            {this.state.send_sms_agreement_name}' göndermek istediğinize emin
            misiniz?
          </AlertDialog>

          {/* **
        wp onayı için açılan dialog
        */}
          <AlertDialog
            title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
            // title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
            open={this.state.wp_aproval_modal}
            closeHandler={() => this.setState({ wp_aproval_modal: false })}
            buttons={[
              {
                title: "Gönder",
                icon: "send",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  this.sendWPAgreement(this.state.send_wp_id);
                },
              },
              {
                title: "İptal",
                icon: "clear",
                textColor: "grey",
                onClick: () => {
                  this.setState({
                    send_wp_id: null,
                    send_wp_customer_name: null,
                    send_wp_agreement_name: null,
                  });
                },
              },
            ]}
          >
            {this.state.send_wp_customer_name} isimli müşteriye '
            {this.state.send_wp_agreement_name}' Whatsapp ile göndermek
            istediğinize emin misiniz?
          </AlertDialog>

          <AlertDialog
            title="Sözleşmeyi Silmeyi Onaylıyor Musunuz?"
            open={this.state.delete_agreement_dialog}
            closeHandler={() =>
              this.setState({ delete_agreement_dialog: false })
            }
            buttons={[
              {
                title: "Onayla ve Sil",
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  this.deleteAgreement(this.state.agreement_delete_id);
                },
              },
              {
                title: "Vazgeç",
                icon: "close",
                textColor: "#ccc",
              },
            ]}
          >
            <Alert severity="warning">
              <b>Sözleşme kalıcı olarak silinecektir.</b>
              <br />
              Bu işlemin geri dönüşü olmayacaktır. Sözleşme kalıcı olarak
              silinecektir.
            </Alert>
          </AlertDialog>
        </Grid>
      </>
    );
  };

  packageSummaryGenerator = () => {
    const { checkout_data, services } = this.state;

    const t = [
      ...Object.keys(checkout_data.packages)
        .filter(
          (key) =>
            checkout_data.packages[key] !== undefined &&
            checkout_data.packages[key].process_date !== null
        )
        .map((key) => checkout_data.packages[key]),
    ];

    const _unique = [
      ...new Set(
        Object.keys(checkout_data.packages)
          .filter(
            (key) =>
              checkout_data.packages[key] !== undefined &&
              checkout_data.packages[key].process_date !== null
          )
          .map((key) => checkout_data.packages[key].service_id)
      ),
    ];

    const data = _unique.map((id) => ({
      service: services.filter((item) => item.id === id)[0],
      total: t.filter((service) => service.service_id === id).length.toString(),
      completed: t
        .filter((service) => service.service_id === id)
        .filter((transaction) => transaction.customer_state === 0)
        .length.toString(),
      pending: t
        .filter((service) => service.service_id === id)
        .filter((transaction) => transaction.customer_state === 2)
        .length.toString(),
    }));

    return data;
  };

  applyDiscount = (config) => {
    const { checkout_data, discount_target } = this.state;
    const discount_value =
      config.type === "TL"
        ? (config.value / this.getTotalAmounts()[discount_target]) * 100
        : config.value;

    discount_target &&
      discount_value !== 0 &&
      this.setState({
        discount_applied: true,
        is_change: true,
        checkout_data: {
          ...checkout_data,
          groups: [
            ...Object.keys(checkout_data["groups"]).map((key, index) => {
              if (checkout_data["groups"][key] !== undefined)
                return {
                  ...checkout_data["groups"][key],
                  amount:
                    checkout_data["groups"][key].amount -
                      (checkout_data["groups"][key].amount * discount_value) /
                        100 >
                    0
                      ? checkout_data["groups"][key].amount -
                        (checkout_data["groups"][key].amount * discount_value) /
                          100
                      : 0,
                };
            }),
          ],
          [discount_target]: Object.assign(
            ...Object.keys(checkout_data[discount_target]).map((key, index) => {
              if (checkout_data[discount_target][key] !== undefined)
                return {
                  [index]: {
                    ...checkout_data[discount_target][key],
                    amount:
                      checkout_data[discount_target][key].amount -
                        (checkout_data[discount_target][key].amount *
                          discount_value) /
                          100 >
                      0
                        ? checkout_data[discount_target][key].amount -
                          (checkout_data[discount_target][key].amount *
                            discount_value) /
                            100
                        : 0,
                  },
                };
            }),
            {}
          ),
        },
      });

    if (discount_target === "groupsAmount") {
      setTimeout(() => {
        this.setState({
          checkout_data: {
            ...this.state.checkout_data,
            packages: {
              ...Object.assign({}, [
                ...Object.values(this.state.checkout_data.packages).map(
                  (item) => ({
                    ...item,
                    amount: item.amount - (item.amount * discount_value) / 100,
                  })
                ),
              ]),
            },
          },
        });
      }, 250);
    }
  };

  addSingleServiceIntoCheckout = () => {
    const { checkout_data, service_transaction } = this.state;
    // console.log("service_transaction", service_transaction);
    if (
      Validate([
        {
          value: service_transaction.service_id,
          condition: Number,
          field: "Hizmet seçimi",
        },
        {
          value: service_transaction.staff_id,
          condition: Number,
          field: "Personel seçimi",
        },
        // {
        //   value: service_transaction.amount,
        //   condition: Number,
        //   field: "İşlem tutarı",
        // },
      ])
    ) {
      this.setState({
        is_change: true,
        checkout_data: {
          ...checkout_data,
          services: {
            ...checkout_data.services,
            [Object.keys(checkout_data.services).length]: {
              ...service_transaction,
              customer_state: service_transaction.customer_state
                ? service_transaction.customer_state
                : 2,
              process_date: moment().format("YYYY-MM-DD"),
              process_type: 2,
            },
          },
        },
      });

      toast.success(
        this.context.t(`['receipt/detail/id'].serviceTransaction.successAdd`)
      );
    }
  };

  addSingleStockSaleIntoCheckout = () => {
    const { checkout_data, product_transaction, service_transaction } =
      this.state;
    if (
      Validate([
        {
          value: product_transaction.stock_item_id,
          condition: Number,
          field: "Ürün seçimi",
        },
        {
          value: product_transaction.staff_id,
          condition: Number,
          field: "Personel seçimi",
        },
        {
          value: product_transaction.amount,
          condition: Number,
          field: "İşlem tutarı",
        },
      ])
    ) {
      this.setState({
        is_change: true,
        checkout_data: {
          ...checkout_data,
          sales: {
            ...checkout_data.sales,
            [Object.keys(checkout_data.sales).length + 1]: {
              ...product_transaction,
              first_amount:
                product_transaction.amount * product_transaction.quantity,
              staff_id: product_transaction.staff_id,
            },
          },
        },
      });
      toast.success(
        this.context.t(`['receipt/detail/id'].productTransactions.successAdd`)
      );
    }
  };

  getTotalAmounts = () => {
    const { checkout_data } = this.state;
    // {
    //   console.log(
    //     [...Object.keys(checkout_data.packages)]
    //       .map((key) =>
    //         checkout_data.packages[key] !== undefined
    //           ? checkout_data.packages[key].first_amount
    //           : undefined
    //       )
    //       .filter((item) => item !== undefined)
    //   );
    // }
    const arr = {
      paids: [...Object.keys(checkout_data.paids)]
        .map((key) =>
          checkout_data.paids[key] !== undefined &&
          checkout_data.paids[key].pay_amount !== null
            ? checkout_data.paids[key].payment_type === 3
              ? checkout_data.paids[key].pay_amount / 100
              : checkout_data.paids[key].pay_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      installment_total: [...Object.keys(checkout_data.installment)]
        .filter(
          (key) =>
            checkout_data.installment[key] !== undefined &&
            checkout_data.installment[key].installment_status === 1 &&
            checkout_data.installment[key].installment_amount !== null
        )
        .map((key) =>
          checkout_data.installment[key] !== undefined
            ? checkout_data.installment[key].installment_type === 3
              ? checkout_data.installment[key].installment_amount / 100
              : checkout_data.installment[key].installment_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      installment: [...Object.keys(checkout_data.installment)]
        .filter(
          (key) =>
            checkout_data.installment[key] !== undefined &&
            checkout_data.installment[key].installment_amount !== null
        )
        .map((key) =>
          checkout_data.installment[key] !== undefined
            ? checkout_data.installment[key].installment_type === 3
              ? checkout_data.installment[key].installment_amount / 100
              : checkout_data.installment[key].installment_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      packages: [...Object.keys(checkout_data.groupsAmount)]
        .map((key) =>
          checkout_data.groupsAmount[key] !== undefined &&
          checkout_data.groupsAmount[key].amount !== null
            ? checkout_data.groupsAmount[key].amount
            : undefined
        )
        .filter((item) => item !== undefined),
      _fpackages: [...Object.keys(checkout_data.packages)]
        .map((key) =>
          checkout_data.packages[key] !== undefined &&
          checkout_data.packages[key].first_amount !== null
            ? checkout_data.packages[key].first_amount
            : undefined
        )
        .filter((item) => item !== undefined),
      groupsAmount: [...Object.keys(checkout_data.groupsAmount)]
        .map((key) =>
          checkout_data.groupsAmount[key] !== undefined &&
          checkout_data.groupsAmount[key].amount !== null
            ? checkout_data.groupsAmount[key].amount
            : undefined
        )
        .filter((item) => item !== undefined),
      _fgroups: [...Object.keys(checkout_data.groupsAmount)]
        .map((key) =>
          checkout_data.groupsAmount[key] !== undefined &&
          checkout_data.groupsAmount[key].first_amount !== null
            ? checkout_data.groupsAmount[key].first_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      services: [...Object.keys(checkout_data.services)]
        .map((key) =>
          checkout_data.services[key] !== undefined &&
          checkout_data.services[key].amount !== null
            ? checkout_data.services[key].amount
            : undefined
        )
        .filter((item) => item !== undefined),

      _fservices: [...Object.keys(checkout_data.services)]
        .map((key) =>
          checkout_data.services[key] !== undefined &&
          checkout_data.services[key].first_amount !== null
            ? checkout_data.services[key].first_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      sales: [...Object.keys(checkout_data.sales)]
        .map((key) =>
          checkout_data.sales[key] !== undefined &&
          checkout_data.sales[key].amount !== null
            ? checkout_data.sales[key].amount *
              checkout_data.sales[key].quantity
            : undefined
        )
        .filter((item) => item !== undefined),

      _fsales: [...Object.keys(checkout_data.sales)]
        .map((key) =>
          checkout_data.sales[key] !== undefined &&
          checkout_data.sales[key].first_amount !== null
            ? checkout_data.sales[key].first_amount *
              checkout_data.sales[key].quantity
            : undefined
        )
        .filter((item) => item !== undefined),

      point_usage: [...Object.keys(checkout_data.paids)]
        .map((key) =>
          checkout_data.paids[key] !== undefined &&
          checkout_data.paids[key].pay_amount !== null
            ? checkout_data.paids[key].payment_type === 3
              ? checkout_data.paids[key].pay_amount
              : 0
            : undefined
        )
        .filter((item) => item !== undefined),

      point_installment_usage: [...Object.keys(checkout_data.installment)]
        .map((key) =>
          checkout_data.installment[key] !== undefined
            ? checkout_data.installment[key].installment_type === 3 &&
              checkout_data.installment[key].installment_amount !== null
              ? checkout_data.installment[key].installment_amount
              : 0
            : undefined
        )
        .filter((item) => item !== undefined),
    };

    const total = {
      paids: arr.paids.length > 0 ? arr.paids.reduce((a, b) => a + b) : 0,

      installment:
        arr.installment.length > 0
          ? arr.installment.reduce((a, b) => a + b)
          : 0,

      installment_total:
        arr.installment_total.length > 0
          ? arr.installment_total.reduce((a, b) => a + b)
          : 0,

      services:
        arr.services.length > 0 ? arr.services.reduce((a, b) => a + b) : 0,

      _fservices:
        arr._fservices.length > 0 ? arr._fservices.reduce((a, b) => a + b) : 0,

      packages:
        arr.packages.length > 0 ? arr.packages.reduce((a, b) => a + b) : 0,
      groupsAmount:
        arr.groupsAmount.length > 0
          ? arr.groupsAmount.reduce((a, b) => a + b)
          : 0,
      _fgroups:
        arr._fgroups.length > 0 ? arr._fgroups.reduce((a, b) => a + b) : 0,
      _fpackages:
        arr._fpackages.length > 0 ? arr._fpackages.reduce((a, b) => a + b) : 0,

      sales: arr.sales.length > 0 ? arr.sales.reduce((a, b) => a + b) : 0,

      _fsales: arr._fsales.length > 0 ? arr._fsales.reduce((a, b) => a + b) : 0,

      point_usage:
        arr.point_usage.length > 0
          ? arr.point_usage.reduce((a, b) => a + b)
          : 0,

      point_installment_usage:
        arr.point_installment_usage.length > 0
          ? arr.point_installment_usage.reduce((a, b) => a + b)
          : 0,
    };

    return total;
  };

  getAllTotalService = () => {
    const total = this.getTotalAmounts();
    return total.services + total.sales + total.packages;
  };
  getAllTotalGroupService = () => {
    const total = this.getTotalAmounts();
    return total.services + total.sales + total.groupsAmount;
  };
  getPointPaidsDiffEarningTotal = () => {
    const earning_total = this.state.customer.point;
    return earning_total - this.getTotalAmounts().point_usage > 0
      ? earning_total - this.getTotalAmounts().point_usage
      : 0;
  };

  getPointInstallmentDiffEarningTotal = () => {
    const earning_total = this.state.customer.point;
    return earning_total - this.getTotalAmounts().point_installment_usage > 0
      ? earning_total - this.getTotalAmounts().point_installment_usage
      : 0;
  };

  getPaidsDiffTotal = (count) => {
    const total = this.getTotalAmounts();
    return count > 0
      ? parseFloat(total.services + total.sales + total.packages) / count
      : total.services +
          total.sales +
          total.packages -
          (total.paids + total.installment) >=
        0
      ? parseFloat(
          total.services +
            total.sales +
            total.packages -
            (total.paids + total.installment)
        )
      : 0;
  };

  getInstallmentDiffTotal = (count) => {
    const total = this.getTotalAmounts();
    return count > 0
      ? parseFloat(this.getPaidsDiffTotal()) / count
      : this.getPaidsDiffTotal() - (total.installment - total.paids) >= 0
      ? parseFloat(this.getPaidsDiffTotal() - (total.installment - total.paids))
      : 0;
  };

  validatePointPaids = () => {
    if (
      this.state.customer.point -
        (this.getTotalAmounts().point_usage +
          this.getTotalAmounts().point_installment_usage) <
      0
    ) {
      toast.warning(
        "Birikmiş müşteri puanından daha fazla puan kullanıldı. Ödemelerinizi düzenleyin!"
      );
      return false;
    }

    return true;
  };

  //** Detail Dialog Models */

  //** */ Services Dialog
  _TypeServicesDetailDialog = () => {
    const {
      services_detail_dialog_modal,
      checkout_data,
      staffs,
      service_transaction,
      services,
      services_by_selected_staff,
      services_by_selected_staff_show,
    } = this.state;
    return (
      <AlertDialog
        open={services_detail_dialog_modal}
        title={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Clear
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  services_detail_dialog_modal: false,
                  service_transaction: {
                    staff_id: null,
                    service_id: null,
                    amount: null,
                    first_amount: null,
                    process_date: null,
                    process_type: null,
                  },
                  services_by_selected_staff_show: false,
                });
              }}
            />
          </div>
        }
        disableBackdropClick={false}
        fullWidth={true}
        maxWidth={false}
        // maxWidth="lg"
        closeHandler={() => {
          this.setState({
            services_detail_dialog_modal: false,
          });
        }}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            {/* Servis başlığı */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <ServicesSectionHeading
                style={{
                  backgroundColor: "rgba(13, 78, 108, 0.04)",
                  color: "#3189ec",
                  border: "1px solid #3189ec",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <AddBox />
                  <h3 style={{ marginLeft: "15px" }}>
                    {this.context.t(
                      `['receipt/detail/id'].serviceTransactions.soldTitle`
                    )}
                  </h3>
                </div>
              </ServicesSectionHeading>
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-8">
              <ServicesSectionHeading
                style={{
                  backgroundColor: "rgba(13, 78, 108, 0.04)",
                  color: "#3189ec",
                  border: "1px solid #3189ec",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Layers />
                  <h3 style={{ marginLeft: "15px" }}>
                    {this.context.t(
                      `['receipt/detail/id'].serviceTransactions.title`
                    )}
                    (
                    {
                      Object.values(checkout_data.services).filter(
                        (value) => value !== undefined
                      ).length
                    }
                    )
                  </h3>
                </div>
              </ServicesSectionHeading>
            </Column>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12">
            <Column className="xs-12 sm-12 md-4 lg-4">
              <StaffList className="no-scroll-bar">
                {staffs
                  .filter((m) => m.deleted_at === null)
                  .map((staff) => (
                    <MuiThemeProvider theme={selectedmuiTheme}>
                      <ListItem
                        button
                        selected={staff.id == service_transaction.staff_id}
                        onClick={() => {
                          this.setState({
                            service_transaction: {
                              service_id: null,
                              amount: null,
                              first_amount: null,
                              process_date: null,
                              process_type: null,
                              staff_id: parseInt(staff.id),
                            },
                            services_by_selected_staff_show: false,
                            services_by_selected_staff: [],
                          });
                        }}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              color: `${
                                staff.id == service_transaction.staff_id
                                  ? "white"
                                  : "#3189ec"
                              }`,
                            },
                          }}
                          primary={`${staff.name} ${staff.surname}`}
                        />
                      </ListItem>
                    </MuiThemeProvider>
                  ))}
              </StaffList>
              <Column className="xs-12 sm-12">
                <Button
                  className="mt-1 mb-1 mx-auto"
                  fullWidth
                  backgroundColor="primary"
                  icon="search"
                  // size="lg"
                  textColor="white"
                  title="Uygun hizmetleri getir"
                  disabled={!service_transaction.staff_id}
                  onClick={() => {
                    this.getServicesByStaffId();
                  }}
                />
              </Column>

              {services_by_selected_staff_show && (
                <div
                  style={{
                    overflow: "hidden",
                    border: "1px solid white",
                  }}
                >
                  <ServicesList className="no-scroll-bar">
                    {services_by_selected_staff.map((service) => (
                      <MuiThemeProvider theme={selectedmuiTheme}>
                        <ListItem
                          button
                          selected={
                            service.id == service_transaction.service_id
                          }
                          onClick={() => {
                            console.log("service: " + JSON.stringify(service));
                            this.setState({
                              service_transaction: {
                                ...service_transaction,
                                service_id: parseInt(service.id),
                                amount: Math.round(service.amount),
                                first_amount: Math.round(service.amount),
                                process_date: moment().format("YYYY-MM-DD"),
                                process_type: 2,
                              },
                              is_change: true,
                            });
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                color: `${
                                  service.id == service_transaction.service_id
                                    ? "white"
                                    : "#3129ec"
                                }`,
                              }}
                            >{`${service.name} (${service.process_time} dk)`}</p>

                            <p
                              style={{
                                color: `${
                                  service.id == service_transaction.service_id
                                    ? "white"
                                    : "#3189ec"
                                }`,
                              }}
                            >{`${service.amount} ${
                              this.context.state.currency
                                ? this.context.state.currency
                                : "₺"
                            }`}</p>
                          </div>
                        </ListItem>
                      </MuiThemeProvider>
                    ))}
                  </ServicesList>
                </div>
              )}
              {services_by_selected_staff_show && (
                <Column className="xs-12 sm-12 md-4 lg-12 mt-2">
                  <Column className="xs-12 sm-12 md-4 lg-6">
                    <Button
                      icon="add"
                      title="Ekle"
                      disabled={
                        !(
                          service_transaction.staff_id &&
                          service_transaction.service_id
                        )
                      }
                      backgroundColor="primary"
                      textColor="white"
                      fullWidth
                      onClick={() => {
                        this.addSingleServiceIntoCheckout();
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-4 lg-6">
                    <Button
                      icon="clear"
                      title="Temizle"
                      textColor="#ccc"
                      outlined={true}
                      fullWidth
                      onClick={() => {
                        this.setState({
                          service_transaction: {
                            staff_id: null,
                            service_id: null,
                            amount: null,
                            first_amount: null,
                            process_date: null,
                            process_type: null,
                          },
                          services_by_selected_staff_show: false,
                        });
                      }}
                    />
                  </Column>
                </Column>
              )}
            </Column>

            <Column className="xs-12 sm-12 md-8 lg-8">
              <Services
                className="no-scroll-bar"
                style={{ boxShadow: "inset 0 0 5px #3189ec" }}
              >
                {
                  //Hizmet Listesi
                  Object.keys(checkout_data.services).filter(
                    (key) => checkout_data.services[key] !== undefined
                  ).length > 0 ? (
                    Object.keys(checkout_data.services).map(
                      (key, index) =>
                        checkout_data.services[key] !== undefined &&
                        checkout_data.services[key].process_type !== 1 && (
                          <Grid
                            style={{ margin: "8px" }}
                            className="no-gutters-row"
                            key={index}
                          >
                            <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                              <MuiThemeProvider theme={themeForDatePicker}>
                                <DatePickerInput
                                  className="mb-1"
                                  label={this.context.t(
                                    `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                  )}
                                  error={false}
                                  value={
                                    moment(
                                      checkout_data.services[key].process_date
                                    ).format("YYYY-MM-DD") === "0001-01-01"
                                      ? null
                                      : moment(
                                          checkout_data.services[key]
                                            .process_date
                                        )
                                  }
                                  onChange={(process_date) => {
                                    this.setState({
                                      checkout_data: {
                                        ...checkout_data,
                                        services: {
                                          ...checkout_data.services,
                                          [key]: {
                                            ...checkout_data.services[key],
                                            process_date:
                                              moment(process_date).format(
                                                "YYYY-MM-DD"
                                              ),
                                          },
                                        },
                                      },
                                      is_change: true,
                                    });
                                  }}
                                />
                              </MuiThemeProvider>
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-3">
                              <Select
                                className="mb-1"
                                label={this.context.t(
                                  `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                                )}
                                items={this.state.staffs
                                  .filter(
                                    (staff) =>
                                      staff.id ===
                                      checkout_data.services[key].staff_id
                                  )[0]
                                  .services.map((service) => service.service)}
                                selected={
                                  checkout_data.services[key].service_id
                                    ? this.state.services.filter(
                                        (item) =>
                                          item.id ===
                                          checkout_data.services[key].service_id
                                      ).length
                                      ? checkout_data.services[key].service_id
                                      : "Silinmiş Hizmet"
                                    : "Silinmiş Hizmet"
                                }
                                labelKey="name"
                                valueKey="id"
                                title={
                                  this.state.services.filter(
                                    (item) =>
                                      item.id ===
                                      checkout_data.services[key].service_id
                                  ).length > 0
                                    ? this.state.services.filter(
                                        (item) =>
                                          item.id ===
                                          checkout_data.services[key].service_id
                                      )[0].name
                                    : "Silinmiş Hizmet"
                                }
                                handler={(service_id) =>
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      services: {
                                        ...checkout_data.services,
                                        [key]: {
                                          ...checkout_data.services[key],
                                          service_id: parseInt(service_id),
                                          process_type: 2,
                                        },
                                      },
                                    },
                                    is_change: true,
                                  })
                                }
                              />
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-2">
                              <StaffSelect
                                className="mb-1"
                                disabled={
                                  this.context.state.user.permission === 0
                                }
                                label={this.context.t(
                                  `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                                )}
                                items={this.state.staffs}
                                selected={
                                  checkout_data.services[key].staff_id
                                    ? checkout_data.services[key].staff_id
                                    : ""
                                }
                                labelKey="full_name"
                                valueKey="id"
                                handler={(staff_id) =>
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      services: {
                                        ...checkout_data.services,
                                        [key]: {
                                          ...checkout_data.services[key],
                                          staff_id: parseInt(staff_id),
                                          process_type: 2,
                                        },
                                      },
                                    },
                                    is_change: true,
                                  })
                                }
                              />
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-2">
                              <Select
                                className="mb-1"
                                disabled={
                                  this.context.state.user.permission === 0
                                }
                                label={"Durumu"}
                                items={this.state.customer_s}
                                selected={
                                  checkout_data.services[key].customer_state !==
                                  null
                                    ? `${checkout_data.services[key].customer_state}`
                                    : ""
                                }
                                labelKey="label"
                                valueKey="value"
                                handler={(customer_state) => {
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      services: {
                                        ...checkout_data.services,
                                        [key]: {
                                          ...checkout_data.services[key],
                                          customer_state:
                                            parseInt(customer_state),
                                          process_type: 2,
                                        },
                                      },
                                    },
                                    is_change: true,
                                  });
                                }}
                              />
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-2">
                              <MaskedInput
                                className="mb-1"
                                label={
                                  this.context.t(
                                    `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                                  ) +
                                  (this.context.state.currency
                                    ? " (" + this.context.state.currency + ")"
                                    : "(₺)")
                                }
                                mask="9999999999999"
                                maskChar=""
                                value={
                                  checkout_data.services[key].amount !== null
                                    ? checkout_data.services[key].amount
                                    : ""
                                }
                                onChange={(e) =>
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      services: {
                                        ...checkout_data.services,
                                        [key]: {
                                          ...checkout_data.services[key],
                                          amount: /[0-9,.]+/.test(
                                            e.target.value
                                          )
                                            ? Math.round(
                                                parseFloat(e.target.value)
                                              )
                                            : null,
                                          process_type: 2,
                                        },
                                      },
                                    },
                                    is_change: true,
                                  })
                                }
                              />
                            </Column>

                            <Column className="xs-12  sm-12 md-12 lg-1">
                              <Button
                                icon="delete_forever"
                                iconSize={21}
                                textColor="red"
                                size="lg"
                                fullWidth
                                onClick={() => {
                                  // delete checkout_data.services[key];
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      services: {
                                        ...checkout_data.services,
                                        [key]: undefined,
                                      },
                                    },
                                    is_change: true,
                                  });
                                  console.log(checkout_data.services);
                                }}
                              />
                            </Column>
                          </Grid>
                        )
                    )
                  ) : (
                    <NoDataContainer style={{ height: "70%" }}>
                      <img
                        src={require("../../../assets/images/manypixels/click_action.svg")}
                        alt="Malesef bulunamadı!"
                      />
                      <p>
                        {this.context.t(
                          `['receipt/detail/id'].serviceTransactions.noDataText`
                        )}
                      </p>
                    </NoDataContainer>
                  )
                }
              </Services>
            </Column>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 m-4">
            <ButtonContainer>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="check"
                  title={this.context.t(`receipts.detailButtons.confirmButton`)}
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth
                  disabled={this.state.save_button_disabled}
                  onClick={() => {
                    this.setState({
                      packageChanges: false,
                      save_button_disabled: true,
                    });
                    this.saveSingleReceipt();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="close"
                  title={this.context.t(`receipts.detailButtons.closeButton`)}
                  backgroundColor="white"
                  textColor="black"
                  fullWidth
                  outlined
                  onClick={() => {
                    this.setState({
                      services_detail_dialog_modal: false,
                      service_transaction: {
                        staff_id: null,
                        service_id: null,
                        amount: null,
                        first_amount: null,
                        process_date: null,
                        process_type: null,
                      },
                      services_by_selected_staff_show: false,
                    });
                  }}
                />
              </Column>
            </ButtonContainer>
          </Column>
        </Grid>
      </AlertDialog>
    );
  };
  // Package Dialog
  _TypePackageDetailDialog = () => {
    const {
      checkout_data,
      package_detail_dialog_modal,
      services_detail_dialog_modal,
      staffs,
      service_transaction,
      services,
      services_by_selected_staff,
      services_by_selected_staff_show,
    } = this.state;
    return (
      <AlertDialog
        open={package_detail_dialog_modal}
        title={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Clear
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  package_detail_dialog_modal: false,
                });
              }}
            />
          </div>
        }
        disableBackdropClick={false}
        fullWidth={true}
        maxWidth={false}
        closeHandler={() => {
          this.setState({
            package_detail_dialog_modal: false,
          });
        }}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            {/* Paket başlığı */}
            <ServicesSectionHeading
              style={{
                backgroundColor: "rgba(140,15,180, 0.1)",
                color: "#8c0fb4",
                border: "1px solid #8c0fb4",
              }}
            >
              <div
                style={{
                  justifySelf: "flex-start",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10vw",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    new_package_sales_dialog_modal: true,
                  });
                }}
              >
                <AddBox />
                {/* Yeni Paket Satışı */}
                {this.context.t(
                  `['receipt/detail/id'].serviceTransactions.soldTitle`
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "0 auto",
                }}
              >
                <ShoppingBasket />
                <h3 style={{ marginLeft: "15px" }}>
                  {this.context.t(
                    `['receipt/detail/id'].serviceTransactions.packageTitle`
                  )}
                </h3>
              </div>
            </ServicesSectionHeading>
          </Column>

          <Column
            className="xs-12 sm-12 md-12 lg-12"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                height: "auto",
                width: "80%",
                margin: "0 auto",
                overflow: "hidden",
              }}
            >
              <Column className={`xs-12 sm-12 md-4 lg-12`}>
                <Services
                  className="no-scroll-bar"
                  style={{ boxShadow: "inset 0 0 5px purple" }}
                >
                  {checkout_data.groups
                    .filter((el) => el !== undefined)
                    .map((data, indexGroup) => (
                      <PackageDetailContainer
                        key={indexGroup}
                        style={{
                          transition: "all 0.3s ease",
                          // height:
                          //   this.state.report2 &&
                          //   this.state.menu_open_id === indexGroup
                          //     ? Object.keys(checkout_data.packages).length === 0
                          //       ? "8vh"
                          //       : `calc(${
                          //           Object.keys(checkout_data.packages)
                          //             .filter(
                          //               (key) =>
                          //                 checkout_data.packages[key] !==
                          //                 undefined
                          //             )
                          //             .filter(
                          //               (key) =>
                          //                 checkout_data.packages[key]
                          //                   .receipt_package_id === data.id
                          //             ).length
                          //         }px * 76 + ${this.state.group_header_height})`
                          //     : `${this.state.group_header_height}`,
                        }}
                      >
                        <Grid
                          className="sx-12 sm-12 md-12 lg-12 group_header"
                          style={{
                            width: "100%",
                            paddingTop: "10px",
                            background:
                              "linear-gradient(0deg, rgba(254,0,255,0) 0%, rgba(140,15,180, 0.1) 90%)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderTop: "1px solid rgba(140,15,180, 0.4)",
                          }}
                        >
                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <MuiThemeProvider theme={themeForDatePicker}>
                              <DatePickerInput
                                className="mb-1"
                                label={this.context.t(
                                  `['receipt/detail/id'].serviceTransactions.packageDateInputLabel`
                                )}
                                error={false}
                                value={data.process_date}
                                onChange={(date) => {
                                  this.setState({
                                    selected_package_update_date:
                                      moment(date).format("YYYY-MM-DD"),
                                    selected_package_transition_id: data.id,
                                    services_update_date_modal: true,
                                    package_update_date: moment(date).diff(
                                      data.process_date,
                                      "days"
                                    ),
                                  });
                                }}
                              />
                            </MuiThemeProvider>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <ReportsHeader
                              onClick={() => {
                                if (
                                  this.state.packet_detail_array.includes(
                                    indexGroup
                                  )
                                ) {
                                  this.setState({
                                    packet_detail_array:
                                      this.state.packet_detail_array.filter(
                                        (index) => index !== indexGroup
                                      ),
                                  });
                                } else {
                                  this.setState({
                                    packet_detail_array: [
                                      ...this.state.packet_detail_array,
                                      indexGroup,
                                    ],
                                  });
                                }
                              }}
                            >
                              <p
                                style={{
                                  color: data.is_passive ? "red" : "black",
                                }}
                              >{`${data.package_name}`}</p>
                              <p>
                                &nbsp;
                                {`(${
                                  Object.keys(checkout_data.packages)
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key] !==
                                        undefined
                                    )
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key]
                                          .receipt_package_id === data.id
                                    ).length
                                })`}
                              </p>
                            </ReportsHeader>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <StaffSelect
                              className="mb-1"
                              disabled={
                                this.context.state.user.permission === 0
                              }
                              label={this.context.t(
                                `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                              )}
                              items={this.state.staffs}
                              selected={data.staff_id ? data.staff_id : ""}
                              labelKey="full_name"
                              valueKey="id"
                              handler={(staff_id) => {
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    groups: [
                                      ...this.state.checkout_data.groups.map(
                                        (item) =>
                                          item.id === data.id
                                            ? {
                                                ...item,
                                                staff_id: parseInt(staff_id),
                                              }
                                            : { ...item }
                                      ),
                                    ],
                                    groupsAmount: Object.assign(
                                      {},
                                      Object.values(
                                        this.state.checkout_data.groupsAmount
                                      ).map((item, index) =>
                                        item.id === data.id
                                          ? {
                                              ...item,
                                              staff_id: parseInt(staff_id),
                                            }
                                          : { ...item }
                                      )
                                    ),
                                    packages: Object.assign({}, [
                                      ...Object.values(
                                        this.state.checkout_data.packages
                                      ).filter(
                                        (item) =>
                                          item.receipt_package_id !== data.id
                                      ),
                                      ...Object.values(
                                        this.state.checkout_data.packages
                                      )
                                        .filter(
                                          (item) =>
                                            item.receipt_package_id === data.id
                                        )
                                        .map((item, key) => ({
                                          ...item,
                                          staff_id: parseInt(staff_id),
                                        })),
                                    ]),
                                  },
                                  is_change: true,
                                  packageChanges: true,
                                });
                              }}
                            />
                          </Column>

                          <Column
                            className="xs-12 sm-12 md-12 lg-3"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <InfoCard
                              style={{
                                backgroundColor: "rgba(249,186,132, 0.21)",
                                border: "1px solid rgba(249,186,132, 0.51)",
                                color: "rgb(219,146,92)",
                              }}
                              onClick={async () => {
                                await this.setState({
                                  selected_package_transition_id: data.id,
                                  selected_package_transition_update_state: 2,
                                  services_update_modal: true,
                                });
                              }}
                            >
                              <InfoCardText>
                                <span>
                                  {
                                    Object.keys(checkout_data.packages)
                                      .filter(
                                        (key) =>
                                          checkout_data.packages[key] !==
                                          undefined
                                      )
                                      .filter(
                                        (key) =>
                                          checkout_data.packages[key]
                                            .receipt_package_id === data.id &&
                                          checkout_data.packages[key]
                                            .customer_state === 2
                                      ).length
                                  }
                                </span>
                                <img src={Waiting} alt="waiting" />
                              </InfoCardText>
                            </InfoCard>

                            <InfoCard
                              style={{
                                backgroundColor: "rgb(29, 139, 62, 0.21)",
                                border: "1px solid rgb(58, 154, 87, 0.35)",
                                color: "rgb(29, 139, 62)",
                              }}
                              onClick={async () => {
                                await this.setState({
                                  selected_package_transition_id: data.id,
                                  selected_package_transition_update_state: 0,
                                  services_update_modal: true,
                                });
                              }}
                            >
                              <InfoCardText>
                                <span>
                                  {
                                    Object.keys(checkout_data.packages)
                                      .filter(
                                        (key) =>
                                          checkout_data.packages[key] !==
                                          undefined
                                      )
                                      .filter(
                                        (key) =>
                                          checkout_data.packages[key]
                                            .receipt_package_id === data.id &&
                                          checkout_data.packages[key]
                                            .customer_state === 0
                                      ).length
                                  }
                                </span>
                                <img src={HomeChcek} alt="home-check" />
                              </InfoCardText>
                            </InfoCard>

                            <InfoCard
                              style={{
                                backgroundColor: "rgb(254, 151, 151, 0.19)",
                                border: "1px solid rgb(185, 178, 178, 0.65)",
                                color: "rgb(254, 111, 111)",
                              }}
                              onClick={async () => {
                                await this.setState({
                                  selected_package_transition_id: data.id,
                                  selected_package_transition_update_state: 1,
                                  services_update_modal: true,
                                });
                              }}
                            >
                              <InfoCardText>
                                <span>
                                  {
                                    Object.keys(checkout_data.packages)
                                      .filter(
                                        (key) =>
                                          checkout_data.packages[key] !==
                                          undefined
                                      )
                                      .filter(
                                        (key) =>
                                          checkout_data.packages[key]
                                            .receipt_package_id === data.id &&
                                          checkout_data.packages[key]
                                            .customer_state === 1
                                      ).length
                                  }
                                </span>
                                <img src={NotCome} alt="note-come" />
                              </InfoCardText>
                            </InfoCard>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <MaskedInput
                              className="mb-1"
                              label={
                                this.context.t(
                                  `['receipt/detail/id'].serviceTransactions.amountInputLabel`
                                ) +
                                (this.context.state.currency
                                  ? " (" + this.context.state.currency + ")"
                                  : "(₺)")
                              }
                              mask="9999999999999"
                              maskChar=""
                              value={
                                checkout_data.groupsAmount[indexGroup]
                                  .amount !== null
                                  ? checkout_data.groupsAmount[indexGroup]
                                      .amount
                                  : ""
                              }
                              onChange={(e) => {
                                let dividedBy = Object.values(
                                  this.state.checkout_data.packages
                                ).filter(
                                  (el) => el.receipt_package_id === data.id
                                ).length;

                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    groupsAmount: {
                                      ...checkout_data.groupsAmount,
                                      [indexGroup]: {
                                        ...checkout_data.groupsAmount[
                                          indexGroup
                                        ],
                                        amount: /[0-9,.]+/.test(e.target.value)
                                          ? Math.round(
                                              parseFloat(e.target.value)
                                            )
                                          : null,
                                      },
                                    },
                                    groups: checkout_data.groups.map((item) =>
                                      item.id !==
                                      checkout_data.groups[indexGroup].id
                                        ? item
                                        : {
                                            ...item,
                                            amount: /[0-9,.]+/.test(
                                              e.target.value
                                            )
                                              ? Math.round(
                                                  parseFloat(e.target.value)
                                                )
                                              : null,
                                          }
                                    ),
                                    packages: {
                                      ...Object.assign({}, [
                                        ...Object.values(
                                          this.state.checkout_data.packages
                                        )
                                          .filter(
                                            (el) =>
                                              el.receipt_package_id === data.id
                                          )
                                          .map((item) => ({
                                            ...item,
                                            amount: e.target.value / dividedBy,
                                          })),
                                        ...Object.values(
                                          this.state.checkout_data.packages
                                        ).filter(
                                          (el) =>
                                            el.receipt_package_id !== data.id
                                        ),
                                      ]),
                                    },
                                  },
                                  is_change: true,
                                });
                              }}
                            />
                          </Column>

                          <Column
                            style={{ display: "flex" }}
                            className="xs-12 sm-12 md-12 lg-1"
                          >
                            <Button
                              icon="delete_forever"
                              iconSize={21}
                              textColor="red"
                              fullWidth
                              size="lg"
                              onClick={async () => {
                                console.log(indexGroup);
                                console.log(
                                  checkout_data.groups[indexGroup].id
                                );
                                this.setState({
                                  delete_package_id:
                                    checkout_data.groups[indexGroup].id,
                                  package_delete_modal: true,
                                  deleted_packages_services_transaction_package_id:
                                    checkout_data.groups[indexGroup].id,
                                });
                                setTimeout(async () => {
                                  // await this.setState({
                                  //   key_for_delete_services: indexGroup,
                                  //   last_services_from_package_to_delete_modal: true,
                                  // });
                                  this.setState({
                                    deleted_packages: [
                                      ...this.state.deleted_packages,
                                      this.state
                                        .deleted_packages_services_transaction_package_id,
                                    ],
                                  });
                                }, 250);
                              }}
                            />
                            <div
                              style={{ cursor: "pointer", marginTop: "17px" }}
                              onClick={() => {
                                if (
                                  this.state.packet_detail_array.includes(
                                    indexGroup
                                  )
                                ) {
                                  this.setState({
                                    packet_detail_array:
                                      this.state.packet_detail_array.filter(
                                        (index) => index !== indexGroup
                                      ),
                                  });
                                } else {
                                  this.setState({
                                    packet_detail_array: [
                                      ...this.state.packet_detail_array,
                                      indexGroup,
                                    ],
                                  });
                                }
                              }}
                            >
                              {!this.state.packet_detail_array.includes(
                                indexGroup
                              ) ? (
                                <ExpandLessIcon color="secondary" />
                              ) : (
                                <ExpandMoreIcon color="primary" />
                              )}
                            </div>
                          </Column>
                        </Grid>

                        <Grid>
                          {!this.state.packet_detail_array.includes(
                            indexGroup
                          ) && (
                            <Column className="xs-12 sm-12 md-12 lg-12 pr-5 pl-5">
                              {
                                //Paket Listesi
                                this.state.checkout_data.groups.length > 0 ? (
                                  Object.keys(checkout_data.packages)
                                    .filter(
                                      (key) =>
                                        checkout_data.packages[key]
                                          .receipt_package_id === data.id &&
                                        checkout_data.packages[key]
                                          .process_type === 1
                                    )
                                    .map((key, index) => (
                                      <Grid
                                        className="no-gutters-row"
                                        key={index}
                                        style={{
                                          paddingTop: "16px !important",
                                        }}
                                      >
                                        <MuiThemeProvider
                                          theme={themeForCardDetail}
                                        >
                                          <Tooltip
                                            placement="top-start"
                                            title={
                                              checkout_data.packages[key]
                                                .session_note === ""
                                                ? ""
                                                : checkout_data.packages[key]
                                                    .session_note
                                            }
                                            arrow
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              padding: 0,
                                              position: "relative",
                                              backgroundColor:
                                                "white !important",
                                            }}
                                          >
                                            <div>
                                              <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                                                <MuiThemeProvider
                                                  theme={themeForDatePicker}
                                                >
                                                  <DatePickerInput
                                                    className="mb-1"
                                                    // label={this.context.t(
                                                    //   `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                                    // )}
                                                    label={`${this.context.t(
                                                      `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                                    )} / ${index + 1}. seans`}
                                                    error={false}
                                                    value={
                                                      moment(
                                                        checkout_data.packages[
                                                          key
                                                        ].process_date
                                                      ).format("YYYY-MM-DD") ===
                                                      "0001-01-01"
                                                        ? null
                                                        : moment(
                                                            checkout_data
                                                              .packages[key]
                                                              .process_date
                                                          )
                                                    }
                                                    // disablePast
                                                    onChange={(
                                                      process_date
                                                    ) => {
                                                      this.setState({
                                                        checkout_data: {
                                                          ...checkout_data,
                                                          packages: {
                                                            ...checkout_data.packages,
                                                            [key]: {
                                                              ...checkout_data
                                                                .packages[key],
                                                              process_date:
                                                                moment(
                                                                  process_date
                                                                ).format(
                                                                  "YYYY-MM-DD"
                                                                ),
                                                            },
                                                          },
                                                        },
                                                        is_change: true,
                                                        packageChanges: true,
                                                      });
                                                    }}
                                                  />
                                                </MuiThemeProvider>
                                              </Column>
                                              <Column className="xs-12 sm-12 md-12 lg-3">
                                                <Select
                                                  className="mb-1"
                                                  label={this.context.t(
                                                    `['receipt/detail/id'].serviceTransactions.nameInputLabel`
                                                  )}
                                                  title={
                                                    this.state.services.filter(
                                                      (item) =>
                                                        item.id ===
                                                        checkout_data.packages[
                                                          key
                                                        ].service_id
                                                    ).length > 0
                                                      ? this.state.services.filter(
                                                          (item) =>
                                                            item.id ===
                                                            checkout_data
                                                              .packages[key]
                                                              .service_id
                                                        )[0].name
                                                      : "Silinmiş Hizmet"
                                                  }
                                                  items={this.state.staffs
                                                    .filter(
                                                      (staff) =>
                                                        staff.id ===
                                                        checkout_data.packages[
                                                          key
                                                        ].staff_id
                                                    )[0]
                                                    .services.map(
                                                      (service) =>
                                                        service?.service
                                                    )}
                                                  selected={
                                                    checkout_data.packages[key]
                                                      .service_id
                                                      ? this.state.services.filter(
                                                          (item) =>
                                                            item.id ===
                                                            checkout_data
                                                              .packages[key]
                                                              .service_id
                                                        ).length
                                                        ? checkout_data
                                                            .packages[key]
                                                            .service_id
                                                        : "Silinmiş Hizmet"
                                                      : "Silinmiş Hizmet"
                                                  }
                                                  labelKey="name"
                                                  valueKey="id"
                                                  handler={(service_id) =>
                                                    this.setState({
                                                      checkout_data: {
                                                        ...checkout_data,
                                                        packages: {
                                                          ...checkout_data.packages,
                                                          [key]: {
                                                            ...checkout_data
                                                              .packages[key],
                                                            service_id:
                                                              parseInt(
                                                                service_id
                                                              ),
                                                          },
                                                        },
                                                      },
                                                      is_change: true,
                                                      packageChanges: true,
                                                    })
                                                  }
                                                />
                                              </Column>

                                              <Column className="xs-12 sm-12 md-12 lg-3">
                                                <StaffSelect
                                                  className="mb-1"
                                                  disabled={
                                                    this.context.state.user
                                                      .permission === 0
                                                  }
                                                  label={this.context.t(
                                                    `['receipt/detail/id'].serviceTransactions.staffInputLabel`
                                                  )}
                                                  items={this.state.staffs}
                                                  selected={
                                                    checkout_data.packages[key]
                                                      .staff_id
                                                      ? checkout_data.packages[
                                                          key
                                                        ].staff_id
                                                      : ""
                                                  }
                                                  labelKey="full_name"
                                                  valueKey="id"
                                                  handler={(staff_id) =>
                                                    this.setState({
                                                      checkout_data: {
                                                        ...checkout_data,
                                                        packages: {
                                                          ...checkout_data.packages,
                                                          [key]: {
                                                            ...checkout_data
                                                              .packages[key],
                                                            staff_id:
                                                              parseInt(
                                                                staff_id
                                                              ),
                                                          },
                                                        },
                                                      },
                                                      is_change: true,
                                                      packageChanges: true,
                                                    })
                                                  }
                                                />
                                              </Column>

                                              <Column className="xs-12 sm-12 md-12 lg-2">
                                                <Select
                                                  className="mb-1"
                                                  label={"Durumu"}
                                                  items={this.state.customer_s}
                                                  selected={
                                                    checkout_data.packages[key]
                                                      .customer_state !== null
                                                      ? `${checkout_data.packages[key].customer_state}`
                                                      : ""
                                                  }
                                                  labelKey="label"
                                                  valueKey="value"
                                                  handler={(customer_state) => {
                                                    this.setState({
                                                      checkout_data: {
                                                        ...checkout_data,
                                                        packages: {
                                                          ...checkout_data.packages,
                                                          [key]: {
                                                            ...checkout_data
                                                              .packages[key],
                                                            customer_state:
                                                              parseInt(
                                                                customer_state
                                                              ),
                                                          },
                                                        },
                                                      },
                                                      is_change: true,
                                                    });
                                                  }}
                                                />
                                              </Column>
                                              <Column className="xs-12 sm-12 md-12 lg-1">
                                                {/* delete session button */}
                                                <Column className="xs-12 sm-12 md-12 lg-4 mt-1">
                                                  <Button
                                                    icon="delete_forever"
                                                    iconSize={21}
                                                    textColor="red"
                                                    fullWidth
                                                    size="lg"
                                                    onClick={async () => {
                                                      delete checkout_data
                                                        .packages[key];
                                                      this.setState({
                                                        checkout_data: {
                                                          ...checkout_data,
                                                        },
                                                      });
                                                      // Paketteki son hizmet silinirse paketi de siler
                                                      setTimeout(() => {
                                                        if (
                                                          !Object.values(
                                                            checkout_data.packages
                                                          ).filter(
                                                            (item) =>
                                                              item.receipt_package_id ===
                                                              checkout_data
                                                                .groups[
                                                                indexGroup
                                                              ].id
                                                          ).length > 0
                                                        ) {
                                                          this.setState({
                                                            deleted_packages: [
                                                              ...this.state
                                                                .deleted_packages,
                                                              checkout_data
                                                                .groups[
                                                                indexGroup
                                                              ].id,
                                                            ],
                                                            checkout_data: {
                                                              ...this.state
                                                                .checkout_data,
                                                              groups: [
                                                                ...this.state.checkout_data.groups.filter(
                                                                  (item) =>
                                                                    item.id !==
                                                                    checkout_data
                                                                      .groups[
                                                                      indexGroup
                                                                    ].id
                                                                ),
                                                              ],
                                                            },
                                                          });
                                                        }
                                                      }, 1000);
                                                    }}
                                                  />
                                                </Column>
                                                {/* Create appointment button */}
                                                <Column className="xs-12 sm-12 md-12 lg-4 mt-1">
                                                  <Button
                                                    icon="event"
                                                    iconSize={21}
                                                    textColor="blue"
                                                    size="lg"
                                                    fullWidth
                                                    onClick={() => {
                                                      if (
                                                        this.state
                                                          .packageChanges
                                                      ) {
                                                        toast.warning(
                                                          "Randevu almadan önce lütfen yaptığınız değişiklikleri kaydedin."
                                                        );
                                                      } else {
                                                        this.setState({
                                                          checkout_data_dialog: true,
                                                          selected_time:
                                                            moment().hour() +
                                                            ":" +
                                                            moment().minutes(),
                                                          selected_date:
                                                            new Date(
                                                              checkout_data.packages[
                                                                key
                                                              ].process_date
                                                            ).toISOString(),
                                                          selected_service:
                                                            checkout_data
                                                              .packages[key]
                                                              .service_id,
                                                          selected_staff:
                                                            checkout_data
                                                              .packages[key]
                                                              .staff_id,
                                                          is_change: true,
                                                        });
                                                      }
                                                    }}
                                                  />
                                                </Column>
                                                {/* make a note button */}
                                                <Column className="xs-12 sm-12 lg-4 mt-1">
                                                  <Button
                                                    className="button-hover-note-add"
                                                    icon="note_add"
                                                    iconSize={21}
                                                    textColor="blue"
                                                    size="lg"
                                                    fullWidth
                                                    onClick={(row) => {
                                                      this.setState({
                                                        note_add_pop_up: true,
                                                        session_note: {
                                                          ...this.state
                                                            .session_note,
                                                          session_id:
                                                            checkout_data
                                                              .packages[key]
                                                              .receipt_transaction_uniqe_id,
                                                          key: key,
                                                        },
                                                      });
                                                    }}
                                                  />
                                                </Column>
                                              </Column>
                                            </div>
                                          </Tooltip>
                                        </MuiThemeProvider>
                                      </Grid>
                                    ))
                                ) : (
                                  <NoDataContainer style={{ height: "70%" }}>
                                    <img
                                      src={require("../../../assets/images/manypixels/click_action.svg")}
                                      alt="Malesef bulunamadı!"
                                    />
                                    <p>
                                      {this.context.t(
                                        `['receipt/detail/id'].serviceTransactions.noDataPackageText`
                                      )}
                                    </p>
                                  </NoDataContainer>
                                )
                              }
                            </Column>
                          )}
                        </Grid>
                      </PackageDetailContainer>
                    ))}

                  {checkout_data.groups.length === 0 ? (
                    <NoDataContainer style={{ height: "70%" }}>
                      <img
                        src={require("../../../assets/images/no-data-img.png")}
                        alt="Malesef bulunamadı!"
                      />
                      <p>
                        {this.context.t(
                          `['receipt/detail/id'].serviceTransactions.noDataPackageText`
                        )}
                      </p>
                    </NoDataContainer>
                  ) : (
                    <></>
                  )}
                </Services>
              </Column>
            </div>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 mt-2">
            <ButtonContainer
              style={{ display: `${this.state.addPackage ? "none" : ""}` }}
            >
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="check"
                  title={this.context.t(`receipts.detailButtons.confirmButton`)}
                  backgroundColor="primary"
                  textColor="white"
                  disabled={this.state.save_button_disabled}
                  fullWidth
                  onClick={() => {
                    this.setState({
                      packageChanges: false,
                      save_button_disabled: true,
                    });
                    this.saveSingleReceipt();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="close"
                  title={this.context.t(`receipts.detailButtons.closeButton`)}
                  backgroundColor="white"
                  textColor="black"
                  fullWidth
                  outlined
                  onClick={() => {
                    this.setState({
                      package_detail_dialog_modal: false,
                    });
                  }}
                />
              </Column>
            </ButtonContainer>
          </Column>
        </Grid>

        <AlertDialog
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>Yeni Paket Satışı</h3>
              <Clear
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({
                    new_package_sales_dialog_modal: false,
                  });
                }}
              />
            </div>
          }
          open={this.state.new_package_sales_dialog_modal}
          disableBackdropClick={false}
          fullWidth={true}
          maxWidth={false}
          closeHandler={() => {
            this.setState({
              new_package_sales_dialog_modal: false,
            });
          }}
        >
          <Grid>
            <PackageSalesAdd
              customer={this.state.customer.id}
              inReceipt={true}
              receipt_id={parseInt(this.props.match.params.id)}
              getUpdatedReceipt={this.getSingleReceipt}
              goBack={this.goBack}
            />
          </Grid>
        </AlertDialog>

        <AlertDialog
          title={"Not ekle"}
          maxWidth={"sm"}
          fullWidth
          closeHandler={() => {
            this.setState({
              note_add_pop_up: false,
            });
          }}
          open={this.state.note_add_pop_up}
          buttons={[
            {
              title: "Ekle",
              backgroundColor: "primary",
              textColor: "white",
              onClick: () => {
                this.saveSingleTransactionNote();
              },
            },
            {
              title: "Vazgeç",
              textColor: "black",
              style: {
                border: "1px solid #666666",
              },
              onClick: () => {
                this.setState({
                  session_note: {
                    value: "",
                    session_id: null,
                  },
                  note_add_pop_up: false,
                });
              },
            },
          ]}
        >
          <TextField
            label="Not"
            variant="outlined"
            value={this.state.session_note.value}
            inputProps={{ maxLength: 90 }}
            fullWidth
            multiline
            helperText={`Karakter limitiniz ${
              90 - parseInt(this.state.session_note.value.length)
            }`}
            onChange={(e) => {
              this.setState({
                session_note: {
                  ...this.state.session_note,
                  value: e.target.value,
                },
              });
            }}
          />
        </AlertDialog>
      </AlertDialog>
    );
  };
  // Product Dialog
  _TypeProductDetailDialog = () => {
    const {
      product_detail_dialog_modal,
      product_transaction,
      checkout_data,
      service_transaction,
      show_detail_product,
    } = this.state;
    return (
      <AlertDialog
        open={product_detail_dialog_modal}
        title={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Clear
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  product_detail_dialog_modal: false,

                  products_by_selected_staff_show: false,
                });
              }}
            />
          </div>
        }
        disableBackdropClick={false}
        fullWidth={true}
        maxWidth={false}
        // maxWidth="lg"
        closeHandler={() => {
          this.setState({
            product_detail_dialog_modal: false,
          });
        }}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            {/* Servis başlığı */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <ServicesSectionHeading
                style={{
                  backgroundColor: "rgba(13, 78, 108, 0.04)",
                  color: "#3189ec",
                  border: "1px solid #3189ec",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <AddBox />
                  <h3 style={{ marginLeft: "15px" }}>
                    {this.context.t(
                      `['receipt/detail/id'].productTransactions.productSaleTitle`
                    )}

                    {/* Yeni Ürün Satışı */}
                  </h3>
                </div>
              </ServicesSectionHeading>
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-8">
              <ServicesSectionHeading
                style={{
                  backgroundColor: "rgba(13, 78, 108, 0.04)",
                  color: "#3189ec",
                  border: "1px solid #3189ec",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Layers />
                  <h3 style={{ marginLeft: "15px" }}>
                    {this.context.t(`['receipt/detail/id'].stockSales.title`)}
                  </h3>
                </div>
              </ServicesSectionHeading>
            </Column>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12">
            <Column className="xs-12 sm-12 md-12 lg-4">
              <StaffList className="no-scroll-bar">
                {this.state.staffs.map((staff) => (
                  <MuiThemeProvider theme={selectedmuiTheme}>
                    <ListItem
                      button
                      selected={
                        staff.id == this.state.service_transaction.staff_id
                      }
                      onClick={() => {
                        console.log(staff.id);
                        this.setState({
                          service_transaction: {
                            service_id: null,
                            amount: null,
                            first_amount: null,
                            process_date: null,
                            process_type: null,
                            staff_id: parseInt(staff.id),
                          },
                          services_by_selected_staff_show: false,
                          services_by_selected_staff: [],
                        });
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            color: `${
                              staff.id ==
                              this.state.service_transaction.staff_id
                                ? "white"
                                : "#3189ec"
                            }`,
                          },
                        }}
                        primary={`${staff.name} ${staff.surname}`}
                      />
                    </ListItem>
                  </MuiThemeProvider>
                ))}
              </StaffList>
              <Column className="xs-12 sm-12">
                <Button
                  className="mt-1 mb-1 mx-auto"
                  fullWidth
                  backgroundColor="primary"
                  icon="search"
                  // size="lg"
                  textColor="white"
                  title="Ürünleri getir"
                  disabled={!this.state.service_transaction.staff_id}
                  onClick={() => {
                    this.setState({
                      products_by_selected_staff_show: true,
                    });
                  }}
                />
              </Column>
              {this.state.products_by_selected_staff_show && (
                <div
                  style={{
                    overflow: "hidden",
                    border: "1px solid white",
                  }}
                >
                  <ServicesList className="no-scroll-bar">
                    {this.state.stock_items.map((item) => (
                      <MuiThemeProvider theme={selectedmuiTheme}>
                        <ListItem
                          button
                          selected={
                            item.id == product_transaction.stock_item_id
                          }
                          onClick={() => {
                            //consle.log(item)
                            this.setState({
                              product_transaction: {
                                ...this.state._product_transaction,
                                stock_item_id: parseInt(item.id),
                                amount: Math.round(item.amount),
                              },
                              is_change: true,
                            });
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                color: `${
                                  item.id == product_transaction.stock_item_id
                                    ? "white"
                                    : "#3189ec"
                                }`,
                              }}
                            >{`${item.name} (${item.limit})`}</p>
                            <p
                              style={{
                                color: `${
                                  item.id == product_transaction.stock_item_id
                                    ? "white"
                                    : "#3189ec"
                                }`,
                              }}
                            >{`${item.amount} ${
                              this.context.state.currency
                                ? this.context.state.currency
                                : "₺"
                            }`}</p>
                          </div>
                        </ListItem>
                      </MuiThemeProvider>
                    ))}
                  </ServicesList>
                </div>
              )}
              {this.state.products_by_selected_staff_show && (
                <Column className="xs-12 sm-12 md-4 lg-12 mt-2">
                  <Column className="xs-12 sm-12 md-4 lg-6">
                    <Button
                      icon="add"
                      title="Ekle"
                      disabled={!product_transaction.staff_id}
                      backgroundColor="primary"
                      textColor="white"
                      fullWidth
                      onClick={() => {
                        this.addSingleStockSaleIntoCheckout();
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-4 lg-6">
                    <Button
                      icon="clear"
                      title="Temizle"
                      textColor="#ccc"
                      outlined={true}
                      fullWidth
                      onClick={() => {
                        this.setState({
                          service_transaction: {
                            staff_id: null,
                            service_id: null,
                            amount: null,
                            first_amount: null,
                            process_date: null,
                            process_type: null,
                          },
                          products_by_selected_staff_show: false,
                        });
                      }}
                    />
                  </Column>
                </Column>
              )}
            </Column>

            <Column className="xs-12 sm-12 md-8 lg-8">
              <Services
                className="no-scroll-bar"
                style={{
                  boxShadow: "inset 0 0 5px #3189ec",
                }}
              >
                {
                  // Ürün Listesi
                  Object.keys(checkout_data.sales).filter(
                    (key) => checkout_data.sales[key] !== undefined
                  ).length ? (
                    Object.keys(checkout_data.sales)
                      .filter((key) => checkout_data.sales[key] !== undefined)
                      .map((key) => (
                        <Grid
                          className="no-gutters-row"
                          style={{ margin: "8px" }}
                        >
                          <Column className="xs-12 sm-12 md-12 mt-1 lg-2">
                            <MuiThemeProvider theme={themeForDatePicker}>
                              <DatePickerInput
                                className="mb-1"
                                label={this.context.t(
                                  `['receipt/detail/id'].serviceTransactions.dateInputLabel`
                                )}
                                error={false}
                                value={
                                  moment(
                                    checkout_data.sales[key].sold_date
                                  ).format("YYYY-MM-DD") === "0001-01-01"
                                    ? null
                                    : moment(checkout_data.sales[key].sold_date)
                                }
                                disabled={true}
                                onChange={(process_date) => {
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      sales: {
                                        ...checkout_data.sales,
                                        [key]: {
                                          ...checkout_data.sales[key],
                                          sold_date:
                                            moment(process_date).toISOString(),
                                        },
                                      },
                                    },
                                    is_change: true,
                                  });
                                }}
                              />
                            </MuiThemeProvider>
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <Input
                              readOnly
                              label={this.context.t(
                                `['receipt/detail/id'].stockSales.nameInputLabel`
                              )}
                              inputProps={{
                                style: {
                                  color: `${
                                    checkout_data.sales[key].stock_item
                                      ?.is_passive
                                      ? "red"
                                      : "black"
                                  }`,
                                },
                              }}
                              value={
                                checkout_data.sales[key].stock_item_id
                                  ? this.state.stock_items.filter(
                                      (item) =>
                                        item.id ===
                                        checkout_data.sales[key].stock_item_id
                                    ).length
                                    ? this.state.stock_items.filter(
                                        (item) =>
                                          item.id ===
                                          checkout_data.sales[key].stock_item_id
                                      )[0].name
                                    : this.context.t(
                                        `['receipt/detail/id'].stockSales.deletedName`
                                      )
                                  : this.context.t(
                                      `['receipt/detail/id'].stockSales.deletedName`
                                    )
                              }
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <StaffSelect
                              disabled={
                                this.context.state.user.permission === 0
                              }
                              label={this.context.t(
                                `['receipt/detail/id'].stockSales.staffInputLabel`
                              )}
                              items={this.state.staffs}
                              selected={
                                checkout_data.sales[key].staff_id
                                  ? checkout_data.sales[key].staff_id
                                  : null
                              }
                              labelKey="full_name"
                              valueKey="id"
                              handler={(staff_id) =>
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    sales: {
                                      ...checkout_data.sales,
                                      [key]: {
                                        ...checkout_data.sales[key],
                                        staff_id: parseInt(staff_id),
                                      },
                                    },
                                  },
                                  is_change: true,
                                })
                              }
                            />
                          </Column>

                          {/* INPUTCHECK */}
                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <Input
                              label={this.context.t(
                                `['receipt/detail/id'].stockSales.countInputLabel`
                              )}
                              value={checkout_data.sales[key].quantity}
                              onChange={(e) =>
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    sales: {
                                      ...checkout_data.sales,
                                      [key]: {
                                        ...checkout_data.sales[key],
                                        quantity:
                                          e.target.value &&
                                          /[0-9]+/.test(e.target.value)
                                            ? parseInt(e.target.value)
                                            : 0,
                                      },
                                    },
                                  },
                                  is_change: true,
                                })
                              }
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <Input
                              readOnly
                              type="number"
                              label={
                                this.context.t(
                                  `['receipt/detail/id'].stockSales.amountInputLabel`
                                ) +
                                (this.context.state.currency
                                  ? " (" + this.context.state.currency + ")"
                                  : "(₺)")
                              }
                              value={checkout_data.sales[key].amount}
                              onChange={(e) =>
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    sales: {
                                      ...checkout_data.sales,
                                      [key]: {
                                        ...checkout_data.sales[key],
                                        amount:
                                          e.target.value &&
                                          /[0-9,.]+/.test(e.target.value)
                                            ? parseFloat(e.target.value)
                                            : 0,
                                      },
                                    },
                                  },
                                  is_change: true,
                                })
                              }
                            />
                          </Column>

                          <Column className="xs-12 sm-12 md-12 lg-1">
                            <Button
                              icon="delete_forever"
                              iconSize={21}
                              textColor="red"
                              size="lg"
                              fullWidth
                              onClick={() =>
                                this.setState({
                                  checkout_data: {
                                    ...checkout_data,
                                    sales: {
                                      ...checkout_data.sales,
                                      [key]: undefined,
                                    },
                                  },
                                  is_change: true,
                                })
                              }
                            />
                          </Column>
                        </Grid>
                      ))
                  ) : (
                    <NoDataContainer style={{ height: "70%" }}>
                      <img
                        src={require("../../../assets/images/no-data-img.png")}
                        alt="Malesef bulunamadı!"
                      />
                      <p>
                        {this.context.t(
                          `['receipt/detail/id'].stockSales.noDataText`
                        )}
                      </p>
                    </NoDataContainer>
                  )
                }
              </Services>
            </Column>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 m-4">
            <ButtonContainer>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="check"
                  title={this.context.t(`receipts.detailButtons.confirmButton`)}
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth
                  onClick={() => {
                    this.saveSingleReceipt();
                    this.setState({
                      packageChanges: false,
                      product_detail_dialog_modal: false,
                      product_transaction: {
                        staff_id: null,
                        stock_item_id: null,
                        amount: null,
                      },
                      products_by_selected_staff_show: false,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="close"
                  title={this.context.t(`receipts.detailButtons.closeButton`)}
                  backgroundColor="white"
                  textColor="black"
                  fullWidth
                  outlined
                  onClick={() => {
                    this.setState({
                      product_detail_dialog_modal: false,
                      product_transaction: {
                        staff_id: null,
                        stock_item_id: null,
                        amount: null,
                      },
                      products_by_selected_staff_show: false,
                    });
                  }}
                />
              </Column>
            </ButtonContainer>
          </Column>
        </Grid>
      </AlertDialog>
    );
  };
  // Payment Dialog
  _TypePaymentDetailDialog = () => {
    const {
      payment_detail_dialog_modal,
      checkout_data,
      advanced_amount,
      installment_data,
    } = this.state;
    return (
      <AlertDialog
        open={this.state.payment_detail_dialog_modal}
        disableBackdropClick={false}
        fullWidth={true}
        maxWidth={false}
        // maxWidth="lg"
        closeHandler={() => {
          this.setState({
            payment_detail_dialog_modal: false,
          });
        }}
        backgroundColor={"rgba(246,248,249)"}
        title={
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Clear
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  payment_detail_dialog_modal: false,
                });
              }}
            />
          </div>
        }
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12">
            {/* Servis başlığı */}
            <Column className="xs-12 sm-12 md-12 lg-6">
              <ServicesSectionHeading
                style={{
                  backgroundColor: "rgba(173,255,47,0.08)",
                  color: "green",
                  border: "1px solid green",
                }}
              >
                <div
                  style={{
                    justifySelf: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      addPayment_dialog: true,
                    });
                  }}
                >
                  <AddBox />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "0 auto",
                  }}
                >
                  <Payment />
                  <h3>
                    {this.context.t(`['receipt/detail/id'].payments.title`)}
                  </h3>
                </div>
              </ServicesSectionHeading>
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <ServicesSectionHeading
                style={{
                  backgroundColor: "rgba(255,0,0, 0.08)",
                  color: "red",
                  border: "1px solid red",
                }}
              >
                <div
                  style={{
                    justifySelf: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      installment_dialog: true,
                    });
                  }}
                >
                  <AddBox />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "0 auto",
                  }}
                >
                  <Payment />
                  <h3>Taksitli Ödemeler</h3>
                </div>
              </ServicesSectionHeading>
            </Column>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12">
            <Column className="xs-12 sm-12 md-4 lg-6">
              <Services
                className="no-scroll-bar"
                style={{ boxShadow: "inset 0 0 5px green" }}
              >
                <ListSection className="no-scroll-bar p-2">
                  {Object.keys(checkout_data.paids).filter(
                    (key) => checkout_data.paids[key] !== undefined
                  ).length > 0 ? (
                    Object.keys(checkout_data.paids).map(
                      (key, index) =>
                        checkout_data.paids[key] !== undefined && (
                          <Grid className="no-gutters-row" key={index}>
                            <Column className="xs-12 sm-12 md-12 mt-1 lg-auto">
                              <MuiThemeProvider theme={themeForDatePicker}>
                                <DatePickerInput
                                  label={this.context.t(
                                    `['receipt/detail/id'].payments.dateInputLabel`
                                  )}
                                  value={
                                    new Date(
                                      moment(
                                        checkout_data.paids[key].payment_date
                                      )
                                        .local()
                                        .toISOString()
                                    )
                                  }
                                  onChange={(payment_date) => {
                                    this.setState({
                                      checkout_data: {
                                        ...checkout_data,
                                        paids: {
                                          ...checkout_data.paids,
                                          [key]: {
                                            ...checkout_data.paids[key],
                                            payment_date,
                                          },
                                        },
                                      },
                                      is_change: true,
                                    });
                                  }}
                                />
                              </MuiThemeProvider>
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-3">
                              <Select
                                label={this.context.t(
                                  `['receipt/detail/id'].payments.typeInputLabel`
                                )}
                                items={[
                                  {
                                    label: this.context.t(
                                      `['receipt/detail/id'].payments.typeCASH`
                                    ),
                                    value: "0",
                                  },
                                  {
                                    label: this.context.t(
                                      `['receipt/detail/id'].payments.typeCARD`
                                    ),
                                    value: "1",
                                  },
                                  {
                                    label: this.context.t(
                                      `['receipt/detail/id'].payments.typeEFT`
                                    ),
                                    value: "2",
                                  },
                                  {
                                    label: this.context.t(
                                      `['receipt/detail/id'].payments.typePOINT`
                                    ),
                                    value: "3",
                                  },
                                ]}
                                selected={`${checkout_data.paids[key].payment_type}`}
                                labelKey="label"
                                valueKey="value"
                                handler={(payment_type) =>
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      paids: {
                                        ...checkout_data.paids,
                                        [key]: {
                                          ...checkout_data.paids[key],
                                          payment_type: parseInt(payment_type),
                                        },
                                      },
                                    },
                                    is_change: true,
                                  })
                                }
                              />
                            </Column>
                            <Column className="xs-12 sm-12 md-12 lg-3">
                              {/* Select Staff For Paids */}

                              <StaffSelect
                                label="Personel"
                                items={this.state.staffs}
                                selected={
                                  checkout_data.paids[key]?.staff_id
                                    ? checkout_data.paids[key]?.staff_id
                                    : ""
                                }
                                labelKey="full_name"
                                valueKey="id"
                                handler={(staff_id) =>
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      paids: {
                                        ...checkout_data.paids,
                                        [key]: {
                                          ...checkout_data.paids[key],
                                          staff_id: parseInt(staff_id),
                                        },
                                      },
                                    },
                                    is_change: true,
                                  })
                                }
                              />
                            </Column>
                            <Column className="xs-12 sm-12 md-12 lg-3">
                              <MaskedInput
                                label={
                                  checkout_data.paids[key].payment_type === 3
                                    ? this.context.t(
                                        `['receipt/detail/id'].payments.pointInputLabel`
                                      )
                                    : this.context.t(
                                        `['receipt/detail/id'].payments.amountInputLabel`
                                      ) +
                                      (this.context.state.currency
                                        ? "(" +
                                          this.context.state.currency +
                                          ")"
                                        : "₺")
                                }
                                mask="9999999999999"
                                maskChar=""
                                value={
                                  checkout_data.paids[key].pay_amount !== null
                                    ? parseFloat(
                                        checkout_data.paids[key].pay_amount
                                      )
                                    : ""
                                }
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      paids: {
                                        ...checkout_data.paids,
                                        [key]: {
                                          ...checkout_data.paids[key],
                                          pay_amount:
                                            e.target.value &&
                                            /[0-9,.]+/.test(e.target.value)
                                              ? parseFloat(e.target.value)
                                              : null,
                                        },
                                      },
                                    },
                                    is_change: true,
                                  });
                                }}
                              />
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-1">
                              <Button
                                icon="delete_forever"
                                iconSize={21}
                                textColor="red"
                                size="lg"
                                fullWidth
                                onClick={() =>
                                  this.setState({
                                    checkout_data: {
                                      ...checkout_data,
                                      paids: {
                                        ...checkout_data.paids,
                                        [key]: undefined,
                                      },
                                    },
                                    is_change: true,
                                  })
                                }
                              />
                            </Column>
                          </Grid>
                        )
                    )
                  ) : (
                    <NoDataContainer>
                      <img
                        src={require("../../../assets/images/manypixels/online_payment_.svg")}
                        alt="Malesef bulunamadı!"
                      />
                      <p>
                        {this.context.t(
                          `['receipt/detail/id'].payments.noDataText`
                        )}
                      </p>
                    </NoDataContainer>
                  )}
                </ListSection>
              </Services>
            </Column>

            <Column className="xs-12 sm-12 md-8 lg-6">
              <Services
                className="no-scroll-bar"
                style={{ boxShadow: "inset 0 0 5px red" }}
              >
                <ListSection className="no-scroll-bar p-2">
                  {installment_data.length > 0 ? (
                    <>
                      {installment_data.map((installment, index) => {
                        return (
                          <Grid className="no-gutters-row" key={index}>
                            <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                              <MuiThemeProvider theme={themeForDatePicker}>
                                <DatePickerInput
                                  label={`${this.context.t(
                                    `['receipt/detail/id'].payments.dateInputLabel`
                                  )} / ${index + 1}. taksit`}
                                  value={
                                    new Date(
                                      moment(installment.installment_date)
                                        .local()
                                        .toISOString()
                                    )
                                  }
                                  onChange={(installment_date) => {
                                    installment_data[index].installment_date =
                                      installment_date;
                                  }}
                                />
                              </MuiThemeProvider>
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-3">
                              <Select
                                label={"Ödeme Durumu"}
                                items={[
                                  {
                                    label: "Bekleniyor",
                                    value: "0",
                                  },
                                  {
                                    label: "Ödendi",
                                    value: "1",
                                  },
                                  {
                                    label: "Ödenmedi",
                                    value: "2",
                                  },
                                ]}
                                selected={`${
                                  installment.installment_status
                                    ? installment.installment_status
                                    : 0
                                }`}
                                labelKey="label"
                                valueKey="value"
                                handler={(installment_status) => {
                                  installment_data[index].installment_status =
                                    parseInt(installment_status);
                                  this.setState({
                                    selected_installment_status:
                                      parseInt(installment_status),
                                  });
                                }}
                              />
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-3">
                              <Select
                                label={this.context.t(
                                  `['receipt/detail/id'].payments.typeInputLabel`
                                )}
                                items={[
                                  {
                                    label: this.context.t(
                                      `['receipt/detail/id'].payments.typeCASH`
                                    ),
                                    value: "0",
                                  },
                                  {
                                    label: this.context.t(
                                      `['receipt/detail/id'].payments.typeCARD`
                                    ),
                                    value: "1",
                                  },
                                  {
                                    label: this.context.t(
                                      `['receipt/detail/id'].payments.typeEFT`
                                    ),
                                    value: "2",
                                  },
                                  // {
                                  //   label: this.context.t(
                                  //     `['receipt/detail/id'].payments.typePOINT`
                                  //   ),
                                  //   value: "3",
                                  // },
                                ]}
                                selected={`${installment.installment_type}`}
                                labelKey="label"
                                valueKey="value"
                                handler={(installment_type) => {
                                  installment_data[index].installment_type =
                                    parseInt(installment_type);
                                }}
                              />
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-2">
                              <MaskedInput
                                label={
                                  installment.installment_type === 3
                                    ? this.context.t(
                                        `['receipt/detail/id'].payments.pointInputLabel`
                                      )
                                    : this.context.t(
                                        `['receipt/detail/id'].payments.amountInputLabel`
                                      ) +
                                      (this.context.state.currency
                                        ? "(" +
                                          this.context.state.currency +
                                          ")"
                                        : "₺")
                                }
                                value={
                                  installment.installment_amount !== null
                                    ? parseFloat(
                                        installment.installment_amount
                                      ).toFixed(0)
                                    : ""
                                }
                                onChange={(e) => {
                                  // console.log(e.target.value)
                                  installment_data[index].installment_amount =
                                    e.target.value &&
                                    /[0-9,.]+/.test(e.target.value)
                                      ? parseFloat(e.target.value)
                                      : null;
                                }}
                              />
                            </Column>

                            <Column className="xs-12 sm-12 md-12 lg-1">
                              <Button
                                icon="delete_forever"
                                iconSize={21}
                                textColor="red"
                                size="lg"
                                fullWidth
                                onClick={() =>
                                  this.setState({
                                    installment_data: [
                                      ...this.state.installment_data.slice(
                                        0,
                                        index
                                      ),
                                    ],
                                  })
                                }
                              />
                            </Column>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {Object.keys(checkout_data.installment).filter(
                        (key) => checkout_data.installment[key] !== undefined
                      ).length > 0 ? (
                        Object.keys(checkout_data.installment).map(
                          (key, index) =>
                            checkout_data.installment[key] !== undefined && (
                              <Grid className="no-gutters-row" key={index}>
                                <Column className="xs-12 sm-12 md-12 mt-1 lg-3">
                                  <MuiThemeProvider theme={themeForDatePicker}>
                                    <DatePickerInput
                                      label={`${this.context.t(
                                        `['receipt/detail/id'].payments.dateInputLabel`
                                      )} / ${index + 1}. taksit`}
                                      value={
                                        new Date(
                                          moment(
                                            checkout_data.installment[key]
                                              .installment_date
                                          )
                                            .local()
                                            .toISOString()
                                        )
                                      }
                                      onChange={(installment_date) => {
                                        this.setState({
                                          checkout_data: {
                                            ...checkout_data,
                                            installment: {
                                              ...checkout_data.installment,
                                              [key]: {
                                                ...checkout_data.installment[
                                                  key
                                                ],
                                                installment_date,
                                              },
                                            },
                                          },
                                          is_change: true,
                                        });
                                      }}
                                    />
                                  </MuiThemeProvider>
                                </Column>

                                <Column className="xs-12 sm-12 md-12 lg-3">
                                  <Select
                                    label={"Ödeme Durumu"}
                                    items={[
                                      {
                                        label: "Bekleniyor",
                                        value: "0",
                                      },
                                      {
                                        label: "Ödendi",
                                        value: "1",
                                      },
                                      {
                                        label: "Ödenmedi",
                                        value: "2",
                                      },
                                    ]}
                                    selected={`${checkout_data.installment[key].installment_status}`}
                                    labelKey="label"
                                    valueKey="value"
                                    handler={(installment_status) =>
                                      this.setState({
                                        checkout_data: {
                                          ...checkout_data,
                                          installment: {
                                            ...checkout_data.installment,
                                            [key]: {
                                              ...checkout_data.installment[key],
                                              installment_status:
                                                parseInt(installment_status),
                                            },
                                          },
                                        },
                                        is_change: true,
                                        selected_installment_status:
                                          parseInt(installment_status),
                                      })
                                    }
                                  />
                                </Column>

                                <Column className="xs-12 sm-12 md-12 lg-3">
                                  <Select
                                    label={this.context.t(
                                      `['receipt/detail/id'].payments.typeInputLabel`
                                    )}
                                    items={[
                                      {
                                        label: this.context.t(
                                          `['receipt/detail/id'].payments.typeCASH`
                                        ),
                                        value: "0",
                                      },
                                      {
                                        label: this.context.t(
                                          `['receipt/detail/id'].payments.typeCARD`
                                        ),
                                        value: "1",
                                      },
                                      {
                                        label: this.context.t(
                                          `['receipt/detail/id'].payments.typeEFT`
                                        ),
                                        value: "2",
                                      },
                                      // {
                                      //   label: this.context.t(
                                      //     `['receipt/detail/id'].payments.typePOINT`
                                      //   ),
                                      //   value: "3",
                                      // },
                                    ]}
                                    selected={`${checkout_data.installment[key].installment_type}`}
                                    labelKey="label"
                                    valueKey="value"
                                    handler={(installment_type) =>
                                      this.setState({
                                        checkout_data: {
                                          ...checkout_data,
                                          installment: {
                                            ...checkout_data.installment,
                                            [key]: {
                                              ...checkout_data.installment[key],
                                              installment_type:
                                                parseInt(installment_type),
                                            },
                                          },
                                        },
                                        is_change: true,
                                      })
                                    }
                                  />
                                </Column>

                                <Column className="xs-12 sm-12 md-12 lg-2">
                                  <MaskedInput
                                    label={
                                      checkout_data.installment[key]
                                        .installment_type === 3
                                        ? this.context.t(
                                            `['receipt/detail/id'].payments.pointInputLabel`
                                          )
                                        : this.context.t(
                                            `['receipt/detail/id'].payments.amountInputLabel`
                                          ) +
                                          (this.context.state.currency
                                            ? "(" +
                                              this.context.state.currency +
                                              ")"
                                            : "₺")
                                    }
                                    mask="9999999999999"
                                    maskChar=""
                                    value={
                                      checkout_data.installment[key]
                                        .installment_amount
                                        ? parseFloat(
                                            checkout_data.installment[key]
                                              .installment_amount
                                          )
                                        : ""
                                    }
                                    onChange={(e) => {
                                      // console.log(e.target.value)
                                      this.setState({
                                        checkout_data: {
                                          ...checkout_data,
                                          installment: {
                                            ...checkout_data.installment,
                                            [key]: {
                                              ...checkout_data.installment[key],
                                              installment_amount:
                                                e.target.value &&
                                                /[0-9,.]+/.test(e.target.value)
                                                  ? parseFloat(e.target.value)
                                                  : null,
                                            },
                                          },
                                        },
                                        is_change: true,
                                      });
                                    }}
                                  />
                                </Column>

                                <Column className="xs-12 sm-12 md-12 lg-1">
                                  <Button
                                    icon="delete_forever"
                                    iconSize={21}
                                    textColor="red"
                                    size="lg"
                                    fullWidth
                                    onClick={
                                      () =>
                                        this.setState({
                                          checkout_data: {
                                            ...checkout_data,
                                            installment: {
                                              ...checkout_data.installment,
                                              [key]: undefined,
                                            },
                                          },
                                          is_change: true,
                                        })
                                      // this.setState({

                                      //   installment_data: [
                                      //     ...this.state.installment_data.slice(
                                      //       0,
                                      //       index
                                      //     ),
                                      //   ],
                                      // })
                                      // {
                                      //   console.log("index:", index);
                                      //   console.log(
                                      //     "checkoutData:",
                                      //     checkout_data
                                      //   );
                                      // }
                                    }
                                  />
                                </Column>
                              </Grid>
                            )
                        )
                      ) : (
                        <NoDataContainer>
                          <img
                            src={require("../../../assets/images/manypixels/online_payment_.svg")}
                            alt="Malesef bulunamadı!"
                          />
                          <p>
                            {this.context.t(
                              `['receipt/detail/id'].payments.noDataText`
                            )}
                          </p>
                        </NoDataContainer>
                      )}
                    </>
                  )}
                </ListSection>
              </Services>
            </Column>
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 m-4">
            <ButtonContainer>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="check"
                  disabled={this.state.save_button_disabled}
                  title={this.context.t(`receipts.detailButtons.confirmButton`)}
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth
                  onClick={() => {
                    this.setState({
                      payment_detail_dialog_modal: false,
                      save_button_disabled: true,
                    });
                    this.saveSingleReceipt();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Button
                  icon="close"
                  title={this.context.t(`receipts.detailButtons.closeButton`)}
                  backgroundColor="white"
                  textColor="black"
                  fullWidth
                  outlined
                  onClick={() => {
                    this.setState({
                      payment_detail_dialog_modal: false,
                    });
                  }}
                />
              </Column>
            </ButtonContainer>
          </Column>
        </Grid>
      </AlertDialog>
    );
  };
}
export default SingleReceipt;

const InfoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 2px 5px;
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

const InfoCardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const ReportsHeader = styled.div`
  width: 100%;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const ReportsHeaderIcon = styled.span`
  padding-right: 10px;
`;
const ReportsContainer = styled.div`
  border-bottom: 1px solid #b2b2b2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  flex-direction: column;
  overflow: hidden;
`;
const SectionBox = styled.div`
  width: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  padding-top: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  color: var(--dark-primary);

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const ListSection = styled.div`
  overflow-x: scroll;
  margin-bottom: auto;
  padding-left: 5px;
  padding-right: 5px;
`;

const PaymentSection = styled.div`
  width: 95%;
  height: 57px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
`;

const SubTotalText = styled.div`
  span {
    font-size: 13px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`;

const CustomerBox = styled.div``;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 57px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 5px;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #3c3c3c;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;

const SelectedBoxPhoto = styled.img`
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 5px;
  background-color: #fafafa;
  margin-right: 16px;
`;

const CustomerBoxInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 576px) {
    flex - direction: column;
    div, img {margin: 0 !important}
  }

  div{
    b, span {margin: 0; padding: 0; display: block;}
    b{
      font - size: 15px; font-weight: 500
    }

    &.call_button{
      margin - left: 24px;
      flex: 1
      display:flex;
      justify-content: flex-end;
    }
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 32vh;
    object-fit: contain;
  }
  p {
    color: #407bff;
    font-size: 18px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const AdvancedType = styled.div`
  display: flex;
  width: 190px;
  height: 88px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
`;

const ColorMean = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  justify-content: center;

  &:nth-last-child(1) {
    margin: 0;
  }

  div.color {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-right: 8px;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  span {
    flex: 1;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    color: #000000;
  }
`;

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const ReceiptProfile = styled.div`
  display: flex;
  flex-direction: column;
  height: 57px;
  width: 100%;
  border-radius: 5px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    padding: 2px;
  }
`;

const SaveChangesButton = styled.div`
  position: fixed;
  top: 75px;
  right: 4vw;
  z-index: 999;
  width: 20vw;
`;

const ServicesSectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--dark-primary);
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  margin-bottom:16px;

  h3{font - size: 18px; font-weight: 600; flex: 1; margin: 0; padding: 0}
`;

const StaffList = styled.div`
  height: 26vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  margin: 0;
`;

const ServicesList = styled.div`
  height: 22.5vh;
  box-shadow: inset 0 0 5px #3189ec;
  border-radius: 10px;
  overflow: scroll;
`;

const Services = styled.div`
  height: 63vh;
  border-radius: 10px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PackageDetailContainer = styled.div`
  flex-direction: column;
  overflow: hidden;
`;
