import React from "react";
import styled from "styled-components";
import { Column, Grid } from "../../theme/Grid";
import Button from "../../theme/Button";

const CardSection = (props) => (
  <Grid
    className={`${props.className ? ` ${props.className}` : " no-gutters"}`}
  >
    {props.title && (
      <Column className="xs-12 sm-12">
        <CardHead
          className={`${
            props.cardHeadClassName ? props.cardHeadClassName : `mb-2`
          }`}
        >
          <SectionTitle>{props.title}</SectionTitle>
          <ButtonContainer>
            {props.buttons &&
              props.buttons.length > 0 &&
              props.buttons.map((btn, index) => (
                <Button
                  key={index}
                  backgroundColor="primary"
                  textColor="white"
                  {...btn}
                />
              ))}
          </ButtonContainer>
        </CardHead>
      </Column>
    )}
    {props.includesColumn === true || props.includesColumn === undefined ? (
      props.children
    ) : (
      <Column className="xs-12 sm-12">{props.children}</Column>
    )}
  </Grid>
);

export default CardSection;

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: row;

  button {
    margin: 0 16px;

    &:nth-last-child(1) {
      margin: 0;
    }
  }
`;

const SectionTitle = styled.h3`
  width: 100%;
  font-size: 19px;
  padding: 0;
  margin: 0;
  color: var(--dark-primary);
`;
