import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { ButtonBase } from "@material-ui/core";
import { Helmet } from "react-helmet";

const NavigationItem = ({
  history,
  disabled,
  to,
  title,
  icon: Icon,
  onClick,
  prefixTitle,
  themeType,
  menuStatus,
  disableRipple,
  old,
}) => (
  <>
    {window.location.pathname.includes(to) && (
      <Helmet>
        <title>{prefixTitle + " - " + title}</title>
      </Helmet>
    )}
    <ButtonBase
      disableRipple={disableRipple}
      disabled={disabled}
      style={{
        opacity: disabled ? 0.4 : 1,
      }}
      onClick={() => onClick()}
      // title={title}
    >
      <NavLink
        className={`btn nav-item ${themeType} ${
          menuStatus ? "opened" : "closed"
        }  ${old && "old"}`}
        activeClassName="active"
        to={to}
      >
        <Icon />
        <span className={`limited-line-1 ${old && "old"}`} label={to}>
          {title}
        </span>
      </NavLink>
    </ButtonBase>
  </>
);

export default withRouter(NavigationItem);
