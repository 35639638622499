/* eslint-disable eqeqeq */

const CalculateMessageCharCount = (text, messageType) => {
  let content = "";
  let length = text.length + 5;

  //Türkçe ise
  if (messageType == 1) {
    for (let i = 0; i < length; i++) {
      if (
        text.charAt(i) === "€" ||
        text.charAt(i) === "{" ||
        text.charAt(i) === "}" ||
        text.charAt(i) === "[" ||
        text.charAt(i) === "]" ||
        text.charAt(i) === "~" ||
        text.charAt(i) === "^" ||
        text.charAt(i) === "\\" ||
        text.charAt(i) === "|" ||
        text.charAt(i) === "ç" ||
        text.charAt(i) === "Ç" ||
        text.charAt(i) === "ş" ||
        text.charAt(i) === "ğ" ||
        text.charAt(i) === "ı" ||
        text.charAt(i) === "Ş" ||
        text.charAt(i) === "İ" ||
        text.charAt(i) === "\r" ||
        text.charAt(i) === "\n" ||
        text.charAt(i) === "Ğ"
      ) {
        length = length + 1;
      }

      if (length < 156) content = "1 SMS (" + length + "  / 918 Karakter)";
      else if (length < 293) content = "2 SMS (" + length + " / 918 Karakter)";
      else if (length < 439) content = "3 SMS (" + length + "  / 918 Karakter)";
      else if (length < 585) content = "4 SMS (" + length + "  / 918 Karakter)";
      else if (length < 736) content = "5 SMS (" + length + "  / 918 Karakter)";
      else if (length < 883) content = "6 SMS (" + length + "  / 918 Karakter)";
      else if (length < 919) content = "7 SMS (" + length + "  / 918 Karakter)";
      else
        content =
          "918 Karakter Sınırını Aştınız. (" + length + "  / 918 Karakter)";
    }
  } else if (messageType == 2) {
    length = text.length;
    for (let i = 0; i <= 450; i++) {
      if (
        text.charAt(i) === "€" ||
        text.charAt(i) === "{" ||
        text.charAt(i) === "}" ||
        text.charAt(i) === "[" ||
        text.charAt(i) === "]" ||
        text.charAt(i) === "~" ||
        text.charAt(i) === "^" ||
        text.charAt(i) === "\\" ||
        text.charAt(i) === "|" ||
        text.charAt(i) === "ç" ||
        text.charAt(i) === "Ç" ||
        text.charAt(i) === "ş" ||
        text.charAt(i) === "ğ" ||
        text.charAt(i) === "ı" ||
        text.charAt(i) === "Ş" ||
        text.charAt(i) === "İ" ||
        text.charAt(i) === "\r" ||
        text.charAt(i) === "\n" ||
        text.charAt(i) === "Ğ"
      ) {
        length = length + 1;
      }
      content = "Kalan " + (450 - length) + " Karakter";
      if (length > 450) content = "450 Karakter Sınırını Aştınız.";
    }
  } else if (messageType == 3) {
    for (let i = 0; i < length; i++) {
      if (
        text.charAt(i) === "€" ||
        text.charAt(i) === "{" ||
        text.charAt(i) === "}" ||
        text.charAt(i) === "[" ||
        text.charAt(i) === "]" ||
        text.charAt(i) === "~" ||
        text.charAt(i) === "^" ||
        text.charAt(i) === "\\" ||
        text.charAt(i) === "|" ||
        text.charAt(i) === "ç" ||
        text.charAt(i) === "Ç" ||
        text.charAt(i) === "ş" ||
        text.charAt(i) === "ğ" ||
        text.charAt(i) === "ı" ||
        text.charAt(i) === "Ş" ||
        text.charAt(i) === "İ" ||
        text.charAt(i) === "\r" ||
        text.charAt(i) === "\n" ||
        text.charAt(i) === "Ğ"
      ) {
        length = length + 1;
      }
    }
    content = "(" + length + "  / 2000 Karakter)";
    if (length > 2000) {
      content =
        "2000 Karakter Sınırını Aştınız. (" + length + "  / 2000 Karakter)";
    }
  } else {
    for (let i = 0; i < length; i++) {
      if (
        text.charAt(i) === "€" ||
        text.charAt(i) === "{" ||
        text.charAt(i) === "}" ||
        text.charAt(i) === "~" ||
        text.charAt(i) === "^" ||
        text.charAt(i) === "\\" ||
        text.charAt(i) === "|" ||
        text.charAt(i) === "\r" ||
        text.charAt(i) === "\n"
      ) {
        length = length + 1;
      }
      if (length < 161) content = "1 SMS (" + length + " Karakter)";
      else if (length < 307) content = "2 SMS (" + length + " Karakter)";
      else if (length < 460) content = "3 SMS (" + length + " Karakter)";
      else if (length < 613) content = "4 SMS (" + length + " Karakter)";
      else content = "612 Karakter Sınırını Aştınız. (" + length + " Karakter)";
    }
  }

  return content;
};

export default CalculateMessageCharCount;
