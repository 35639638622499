import React from "react";
import { Doughnut } from "react-chartjs-2";

const CDoughnut = React.memo(
  (props) => {
    return <Doughnut data={props.data} style={props.style}></Doughnut>;
  },
  (prevProps, nextProps) => {
    return prevProps.data.datasets["0"].data.every(
      (value, index) => value === nextProps.data.datasets["0"].data[index]
    );
  }
);

export default CDoughnut;
