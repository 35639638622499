import React, { useContext, useState } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Button from "../../../theme/Button";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import { useHistory } from "react-router-dom";

const ProductsAdd = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [newProduct, setNewProduct] = useState({
    name: "",
    amount: 0,
    bounty: 0,
    point: 0,
    limit: 0,
    barcode: null,
  });
  const [isInsertProductButtonClicked, setIsInsertProductButtonClicked] =
    useState(false); // Control that button is checked to stop sending too request.
  //     ...newProduct,
  const insertProduct = () => {
    Axios.post(`${context.api_endpoint}/company/stock/item/insert`, {
      ...newProduct,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['products/add'].insertToast`));
          history.push("/products");
          //console.log(response.data)
          setIsInsertProductButtonClicked(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
        setIsInsertProductButtonClicked(false);
      });
  };

  const validateProduct = () => {
    return Validate([
      {
        field: "Ürün adı",
        value: newProduct.name,
        condition: /[A-Za-z]+/,
      },
      {
        field: "Ürün ücreti",
        value: newProduct.amount,
        condition: Number,
      },
      {
        field: "Stok adeti",
        value: newProduct.limit,
        condition: Number,
      },
      {
        field: "Prim yüzdesi",
        value: newProduct.bounty,
        condition: Number,
      },
    ]);
  };

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[1]}
      limited_permission="sales"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-4">
          <Input
            required
            label={context.t(`['products/add'].nameInputLabel`)}
            value={newProduct.name}
            helperText={context.t(`['products/add'].nameInputPlaceholder`)}
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                name: e.target.value,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-4">
          <Input
            required
            label={context.t(`['products/add'].amountInputLabel`)}
            value={newProduct.amount}
            helperText={context.t(`['products/add'].amountInputHint`)}
            type="number"
            InputProps={{
              min: 0,
              step: 0.1,
            }}
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                amount:
                  e.target.value.length > 0 ? parseFloat(e.target.value) : null,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-4">
          <MaskedInput
            required
            label={context.t(`['products/add'].bountyInputLabel`)}
            value={newProduct.bounty}
            helperText={context.t(`['products/add'].bountyInputHint`)}
            mask="99"
            maskChar=""
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                bounty: e.target.value ? parseInt(e.target.value) : 0,
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-4">
          <MaskedInput
            label={context.t(`['products/add'].pointInputLabel`)}
            value={newProduct.point}
            helperText={context.t(`['products/add'].pointInputHint`)}
            mask="99999"
            maskChar=""
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                point: e.target.value ? parseInt(e.target.value) : 0,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-4">
          <MaskedInput
            required
            label={context.t(`['products/add'].limitInputLabel`)}
            helperText={context.t(`['products/add'].limitInputHint`)}
            mask="9999999"
            maskChar=""
            type="text"
            value={newProduct.limit ?? ""}
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                limit: e.target.value ? parseInt(e.target.value) : 0,
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-4">
          <Input
            disabled={!context.state?.balance?.data?.license?.barcode}
            label={context.t(`['products/add'].addBarcode`)}
            helperText={
              context.state?.balance?.data?.license?.barcode
                ? context.t(`['products/add'].addBarcodeExplain`)
                : context.t(`['products/add'].barcodeCheckTitle`)
            }
            type="text"
            value={newProduct.barcode ? newProduct.barcode : ""}
            onChange={(e) =>
              setNewProduct({
                ...newProduct,
                barcode: e.target.value.trim(),
              })
            }
          />
        </Column>
      </Grid>

      <Grid>
        <Column className="xs-12 sm-12">
          <Button
            icon="check"
            title={context.t(`['products/add'].createButtonTitle`)}
            textColor="white"
            backgroundColor="primary"
            fullWidth
            disabled={isInsertProductButtonClicked}
            onClick={() => {
              setIsInsertProductButtonClicked(true);
              setTimeout(() => {
                setIsInsertProductButtonClicked(false);
              }, 1000);
              insertProduct();
            }}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default ProductsAdd;
