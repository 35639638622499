import React, { useEffect } from "react";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CenterDialogModal({
  open,
  handleClose,
  message,
  type,
}) {
  useEffect(() => {
    let timer = setInterval(() => {
      handleClose();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`bringFront`}
    >
      <DialogTitle id="alert-dialog-slide-title" className={`${type}-dialog`}>
        {message}
      </DialogTitle>
    </Dialog>
  );
}
