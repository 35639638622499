import React, { Component } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import Table from "../../../theme/Table";
import { Grid, Column } from "../../../theme/Grid";
import moment from "moment";
import Axios from "axios";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Select from "../../../theme/CustomMUI/Select";
import { Alert } from "@material-ui/lab";
import Input from "../../../theme/CustomMUI/Input";
import { toast } from "react-toastify";

class AppointmentFilter extends Component {
  static contextType = AppContext;

  state = {
    loaded: false,
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getAppointmentsByFilter(page),
    },
    data: [],

    staffs: [],

    start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    staff_id: null,
    service_key: "",
    customer_key: "",
    appointment_state: null,
  };

  getAppointmentsByFilter = (page = 1) => {
    const {
      start_date,
      end_date,
      staff_id,
      service_key,
      customer_key,
      appointment_state,
      pagination,
    } = this.state;

    Axios.post(
      `${this.context.api_endpoint}/company/appointment/list?page=${page}`,
      {
        start_date,
        end_date,
        staff_id,
        service_key,
        customer_key,
        appointment_state,
      }
    )
      .then(({ data }) => {
        // console.log(data)
        this.setState({
          loaded: true,
          pagination: {
            ...pagination,
            page: data.data.page,
            total_page: data.data.records.length,
          },
          data: [
            ...data.data.records.map((item) => ({
              ...item,
              customer: {
                ...item.customer,
                phone: `0${item.customer.phone.substring(
                  0,
                  3
                )} ${item.customer.phone.substring(
                  3,
                  6
                )} ${item.customer.phone.substring(6, 10)}`,
              },
              created_at: moment(item.created_at)
                .tz("Europe/Istanbul")
                .format("LLL"),
              appointment_start_date: moment(item.appointment_start_date)
                .tz("Europe/Istanbul")
                .format("LLL"),
            })),
          ].filter((item) => item !== undefined),
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getAppointmentIndex = () => {
    Axios.get(`${this.context.api_endpoint}/company/appointments/index`)
      .then((response) => {
        if (response.status === 201) {
          const { data } = response.data;
          this.setState({
            staffs: [...data.staffs],
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount = () => {
    this.getAppointmentIndex();
    this.getAppointmentsByFilter();
  };

  render() {
    return (
      <AuthContainer limited_permission="appointment">
        <Grid>
          <Column className="xs-12 sm-12 md-4 lg-3">
            <Alert severity="info" className="mb-2">
              <div>
                <b>{this.context.t(`['appointments/filter'].alertBoldText`)}</b>
              </div>
              {this.context.t(`['appointments/filter'].alertText`)}
            </Alert>

            <DatePickerInput
              className="mb-1"
              required
              label={this.context.t(`['appointments/filter'].startInputLabel`)}
              value={new Date(moment(this.state.start_date).toISOString())}
              onChange={async (start_date) => {
                await this.setState({
                  loaded: false,
                  start_date: moment(start_date).format("YYYY-MM-DD"),
                });
                await this.getAppointmentsByFilter();
              }}
            />

            <DatePickerInput
              className="mb-1"
              required
              label={this.context.t(`['appointments/filter'].endInputLabel`)}
              minDate={new Date(moment(this.state.start_date).toISOString())}
              value={new Date(moment(this.state.end_date).toISOString())}
              onChange={async (end_date) => {
                await this.setState({
                  loaded: false,
                  end_date: moment(end_date).format("YYYY-MM-DD"),
                });
                await this.getAppointmentsByFilter();
              }}
            />

            <Select
              label={this.context.t(`['appointments/filter'].statusInputLabel`)}
              items={[
                {
                  label: this.context.t(
                    `['appointments/filter'].statusInputItems[0]`
                  ),
                  value: "null",
                },
                {
                  label: this.context.t(
                    `['appointments/filter'].statusInputItems[1]`
                  ),
                  value: 0,
                },
                {
                  label: this.context.t(
                    `['appointments/filter'].statusInputItems[2]`
                  ),
                  value: 1,
                },
                {
                  label: this.context.t(
                    `['appointments/filter'].statusInputItems[3]`
                  ),
                  value: 2,
                },
                {
                  label: this.context.t(
                    `['appointments/filter'].statusInputItems[4]`
                  ),
                  value: 3,
                },
              ]}
              labelKey="label"
              valueKey="value"
              selected={`${this.state.appointment_state}`}
              handler={async (value) => {
                await this.setState({
                  appointment_state: value === "null" ? null : parseInt(value),
                });
                await this.getAppointmentsByFilter();
              }}
            />

            <Select
              label={this.context.t(`['appointments/filter'].staffInputLabel`)}
              items={[
                {
                  id: "null",
                  full_name: this.context.t(
                    `['appointments/filter'].allStaffsText`
                  ),
                },
                ...this.state.staffs,
              ]}
              labelKey="full_name"
              valueKey="id"
              selected={`${this.state.staff_id}`}
              handler={async (staff_id) => {
                await this.setState({
                  staff_id: staff_id !== "null" ? parseInt(staff_id) : null,
                });
                await this.getAppointmentsByFilter();
              }}
            />

            <Input
              label={this.context.t(
                `['appointments/filter'].serviceInputLabel`
              )}
              onChange={async ({ target: { value } }) => {
                await this.setState({ service_key: value });
                await this.getAppointmentsByFilter();
              }}
            />

            <Input
              label={this.context.t(
                `['appointments/filter'].customerInputLabel`
              )}
              onChange={async ({ target: { value } }) => {
                await this.setState({ customer_key: value });
                await this.getAppointmentsByFilter();
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-8 lg-9">
            <Table
              refreshAction={() =>
                this.getAppointmentsByFilter(this.state.pagination.page)
              }
              loaded={this.state.loaded}
              headings={{
                appointment_start_date: {
                  label: this.context.t(
                    `['appointments/filter'].headings.appointmentStartDate`
                  ),
                  style: { width: 200 },
                },
                "customer.full_name": {
                  label: this.context.t(
                    `['appointments/filter'].headings.customerFullName`
                  ),
                  //with_photo: "customer.photo",
                  limited_line: 1,
                },
                "service.name": {
                  label: this.context.t(
                    `['appointments/filter'].headings.serviceName`
                  ),
                  limited_line: 1,
                },
                "staff.full_name": {
                  label: this.context.t(
                    `['appointments/filter'].headings.staffFullName`
                  ),
                  with_photo: "staff.detail.profile_photo",
                  limited_line: 1,
                },
                "customer.phone": {
                  label: this.context.t(
                    `['appointments/filter'].headings.customerPhone`
                  ),
                },
                created_at: {
                  label: this.context.t(
                    `['appointments/filter'].headings.createdAt`
                  ),
                },
              }}
              rows={this.state.data}
              pagination={this.state.pagination}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }
}

export default AppointmentFilter;
