const getFilterModel = (conf) => {
  const FilterModel = {
    // 0: Borçsuz,    1: Borçlu
    debt: [],

    // Risk listesi günü
    risk_list: 0,

    // Tüm servis id'lerini yollayacağım
    services: [],

    // Tüm staff id'lerini yollayacağım
    staffs: [],

    // 0: Kadın,    1: Erkek
    gender: [],

    // Yaş aralığı
    age_range: null,
  };

  if (conf && conf.type === "FULFILLED") {
    FilterModel.debt = [0, 1];
    FilterModel.risk_list = 0;
    FilterModel.services = [];
    FilterModel.staffs = [];

    FilterModel.gender = [0, 1];
    FilterModel.age_range = null;
  }

  return FilterModel;
};

export default getFilterModel;
