import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import { InputAdornment } from '@material-ui/core';
import HoverHelperText from '../HoverHelperText';


const MaskedInput = (props) => {
  return (
    <div className={props.className ? props.className : "mb-2"}>
    {props.hoverHelperText && <HoverHelperText hoverHelperText={props.hoverHelperText} />}
      <InputMask
        mask={props.mask}
        maskChar={props.maskChar}
        defaultValue={props.defaultValue}
        autoComplete={props.autoComplete ? props.autoComplete : "off"}
        disabled={props.disabled}
        readOnly={props.readOnly}
        value={props.value}
        onChange={
          props.onChange
            ? props.onChange
            : () => console.log('onChange is not defined!')
        }
      >
        {() =>
          <TextField
            type={props.type}
            autoComplete={props.autoComplete ? props.autoComplete : "new-password"}
            label={props.label}
            readOnly={props.readOnly}
            value={props.value}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            helperText={props.helperText}
            multiline={props.multiline}
            required={props.required}
            autoFocus={props.autoFocus}
            InputProps={{
              startAdornment: props.startAdornment
                ? (
                  <InputAdornment>
                    <span style={{color: '#808080', fontWeight: '400 !important'}}>{props.startAdornment}</span>
                  </InputAdornment>
                )
                : undefined
            }}

            rows={props.rows}
            variant="outlined"
            fullWidth
          />}
      </InputMask>
    </div>
  )
}

export default MaskedInput;
