import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar/index";
import Header from "./Header/Header";
import Licences from "./License/index";
import Statisctic from "./Statistics";
import SayAboutUs from "./SayAboutUs";
import UserMostLike from "./UserMostLike";
import ChatBox from "./components/ChatBox";
import Footer from "./Footer/Footer";
import { useParams } from "react-router-dom";
import Axios from "axios";
import AppContext from "../../../context/store";
import TimeIsUp from "../Campaigns/TimeIsUp/TimeIsUp";
import Videos from "./Videos/Videos";

function CampaignsPageIndex() {
  const [timeIsUP, setTimeIsUP] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [packages, setPackages] = useState([]);

  return (
    <div style={{ position: "relative" }}>
      <ChatBox />
      <Navbar timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
      <Licences packages={packages} />
      <Header />
      <Videos />
      <UserMostLike />
      <SayAboutUs />
      <Footer />
      <Statisctic />
    </div>
  );
}

export default CampaignsPageIndex;
